import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { DollarSign, Clock, Calendar, AlertCircle, Settings, Info, Scale, Percent, CheckCircle, ShoppingBag, Plus, Edit } from 'lucide-react';
import { Switch } from '../ui/switch';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Checkbox } from '../ui/checkbox';
import { Button } from '../ui/button';
import { cn } from '../../lib/utils';
import ScrollableTabs from './ScrollableTabs';
import ExtrasModal from './ExtrasModal';
import { isEqual } from 'lodash';

// Custom black Switch component
export const BlackSwitch = (props) => (
  <Switch 
    {...props}
    className={cn(
      "data-[state=checked]:bg-gray-900",
      props.className
    )}
  />
);

// ToggleGroup component for consistent UI
const ToggleGroup = ({
  options = [],
  value,
  onChange,
  className,
  fullWidth = false,
}) => {
  return (
    <div 
      className={cn(
        "flex flex-wrap gap-1",
        fullWidth && "grid grid-cols-3 gap-1",
        className
      )}
    >
      {options.map((option) => (
        <Button
          key={option.value}
          type="button"
          variant="outline"
          className={cn(
            "flex-1",
            "justify-center",
            "whitespace-nowrap",
            value === option.value && "border-gray-900 text-gray-900 font-medium"
          )}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
};

// Add this constant at the top of the file, right after imports
const DAY_ORDER = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

/**
 * A simplified Wash, Dry, Fold Drop-Off component
 * for use within the WashDryFoldPanel
 */
const SimpleWDFDropOff = ({ data = {}, onChange, locationData, fieldVisibility = {} }) => {
  // Set default enabled value to true
  const defaultData = { ...data };
  if (defaultData.enabled === undefined) {
    defaultData.enabled = true;
  }

  // Track initial render to prevent unnecessary onChange calls
  const isFirstRender = useRef(true);
  
  // Track metadata initialization
  const hasInitialized = useRef(false);
  
  // Track previous data sent to parent to prevent unnecessary updates
  const prevDataSentToParent = useRef(null);

  // Initialize data from props and metadata
  useEffect(() => {
    // Only run this on first render
    if (!isFirstRender.current) return;
    isFirstRender.current = false;
    
    // Check for metadata to preload from
    let dataToUse = { ...defaultData };
    let metadataFound = false;
    
    // CRITICAL FIX: Check all possible metadata paths
    
    // First: Check direct metadata at services.2.dropOff
    if (data?.metadata?.services?.[2]?.dropOff) {
      const metadataDropOff = data.metadata.services[2].dropOff;
      metadataFound = true;
      
      // Create an initial state from the metadata
      dataToUse = buildStateFromMetadata(metadataDropOff, defaultData);
    } 
    // Second: Check if metadata exists at services.2 level
    else if (data?.metadata?.services?.[2]) {
      // Handle legacy format where dropOff data might be directly in services.2
      const legacyMetadata = data.metadata.services[2];
      
      // See if these are dropOff fields
      if (legacyMetadata.dropOff) {
        metadataFound = true;
        
        // Create an initial state from the legacy metadata dropOff section
        dataToUse = buildStateFromMetadata(legacyMetadata.dropOff, defaultData);
        
        // Add additional fields from the parent object if available
        if (legacyMetadata.enabled !== undefined) {
          dataToUse.enabled = legacyMetadata.enabled;
        }
      }
      // Check if metadata is directly in services.2 structure
      else if (legacyMetadata.pricing || legacyMetadata.schedule || legacyMetadata.turnaround) {
        metadataFound = true;
        
        // Create an initial state from the legacy metadata
        dataToUse = buildStateFromMetadata(legacyMetadata, defaultData);
      }
    }
    // Third: Check if the direct data objects have the necessary fields
    else if (data.dropOff) {
      metadataFound = true;
      
      // Use the dropOff directly
      dataToUse = {
        ...defaultData,
        ...data,
        // If dropOff exists, use it
        ...(data.dropOff ? { ...data.dropOff } : {}),
        
        // Ensure metadata is properly structured
        metadata: {
          services: {
            "2": {
              dropOff: data.dropOff,
              enabled: data.enabled !== false,
              ...(data.pickupDelivery ? { pickupDelivery: data.pickupDelivery } : {})
            }
          }
        }
      };
    }
    
    if (metadataFound) {
      // Initialize the state with metadata values
      setWdfData(dataToUse);
      
      // Set raw input values for controlled inputs
      const newRawInputValues = {
        regularPerPound: dataToUse.pricing?.regularPerPound?.toString() || '',
        minimumOrderAmount: dataToUse.pricing?.minimumOrder?.amount?.toString() || '',
        sameDayPrice: dataToUse.pricing?.sameDay?.pricePerPound?.toString() || '',
        expressUpcharge: dataToUse.turnaround?.express?.upcharge?.toString() || '',
        // Fix the path to correctly access standardHours from metadata
        standardHours: (dataToUse.metadata?.services?.[2]?.dropOff?.turnaround?.standardHours || dataToUse.turnaround?.standardHours || '').toString(),
        expressHours: dataToUse.turnaround?.express?.hours?.toString() || '',
        sameDayTurnaroundHours: dataToUse.turnaround?.sameDay?.turnaroundHours?.toString() || '',
        cutoffTime: dataToUse.turnaround?.sameDay?.cutoff || '',
      };
      
      setRawInputValues(newRawInputValues);
      
      // Set previous metadata for change tracking
      setPreviousMetadata(dataToUse.metadata);
    } else {
      // If no metadata, check if data has direct properties
      // This handles the case where data has the fields but not in metadata structure
      if (data && Object.keys(data).length > 0) {
        setWdfData({
          ...defaultData,
          ...data
        });
      }
    }
  }, [data, defaultData]);

  // Set up state with minimal initialization
  const [wdfData, setWdfData] = useState(defaultData || {
    enabled: true, // The only default we really need
    metadata: locationData?.id ? { 
      services: { 
        "2": { 
          enabled: true 
        } 
      } 
    } : null
  });

  // Store previous metadata for comparison
  const [previousMetadata, setPreviousMetadata] = useState(
    defaultData.metadata || null
  );

  const [activeTab, setActiveTab] = useState('general');
  
  const [rawInputValues, setRawInputValues] = useState({
    regularPerPound: '',
    sameDayPrice: '',
    minimumAmount: '',
    turnaroundHours: '',
    weeklyRate: '',
    biweeklyRate: '',
    monthlyRate: ''
  });

  // Required fields tracking
  const [requiredFieldsStatus, setRequiredFieldsStatus] = useState({
    general: true,  // Service is enabled by default
    pricing: false,
    turnaround: false,
    schedule: false,
    discounts: true, // Optional unless enabled
    extras: true     // Optional unless enabled
  });

  // State for extras modal
  const [extrasModalType, setExtrasModalType] = useState(null);
  const [showExtrasModal, setShowExtrasModal] = useState(false);

  // Memoize the data to send to parent
  const dataToSend = useMemo(() => {
    // Calculate if all required fields are complete
    const allRequiredFieldsComplete = Object.entries(requiredFieldsStatus)
      .filter(([field, isComplete]) => field !== 'other') // Exclude optional fields
      .every(([_, isComplete]) => isComplete === true);

    // Create a copy of the data for parent
    return {
      ...wdfData,
      requirementsToAdvance: allRequiredFieldsComplete
    };
  }, [wdfData, requiredFieldsStatus]);
  
  // Function to safely send updates to parent component
  const sendUpdateToParent = (updatedData) => {
    // Make sure the updatedData has the correct structure
    if (!updatedData.metadata) {
      updatedData.metadata = { services: { "2": {} } };
    }
    
    // Create a clone to avoid reference issues
    const dataToSend = JSON.parse(JSON.stringify(updatedData));
    
    // Remove fieldVisibility from the data to prevent it from being saved to the database
    if (dataToSend.fieldVisibility) {
      delete dataToSend.fieldVisibility;
    }
    
    // Ensure dropOff data is properly placed in the services.2 structure
    // This is critical for database updates
    if (!dataToSend.metadata.services) dataToSend.metadata.services = {};
    if (!dataToSend.metadata.services["2"]) dataToSend.metadata.services["2"] = {};
    if (!dataToSend.metadata.services["2"].dropOff) {
      dataToSend.metadata.services["2"].dropOff = {
        enabled: dataToSend.enabled
      };
    }
    
    // If there's a pricing field at the root, make sure it's copied to metadata
    if (dataToSend.pricing) {
      dataToSend.metadata.services["2"].dropOff.pricing = dataToSend.pricing;
    }
    
    // Same for other important fields
    ['turnaround', 'schedule', 'discounts', 'extras', 'other'].forEach(field => {
      if (dataToSend[field]) {
        dataToSend.metadata.services["2"].dropOff[field] = dataToSend[field];
      }
    });
    
    // Also remove fieldVisibility from the metadata structure if it exists
    if (dataToSend.metadata.services["2"].dropOff.fieldVisibility) {
      delete dataToSend.metadata.services["2"].dropOff.fieldVisibility;
    }
    
    // LOG 1: See what we're sending to parent
    console.log("SimpleWDFDropOff sending to parent:", {
      hasMetadata: !!dataToSend.metadata,
      hasServices: !!dataToSend.metadata?.services,
      hasService2: !!dataToSend.metadata?.services?.[2],
      hasDropOff: !!dataToSend.metadata?.services?.[2]?.dropOff,
      dropOffKeys: Object.keys(dataToSend.metadata?.services?.[2]?.dropOff || {})
    });
    
    // Send data to parent if it has changed
    if (!isEqual(prevDataSentToParent.current, dataToSend)) {
      onChange(dataToSend);
      prevDataSentToParent.current = dataToSend;
    }
  };

  // Helper function to recursively check for empty objects in metadata
  const ensureNestedDataIsComplete = (section, sourceData, path = '') => {
    if (!section || !sourceData) return section;
    
    // Handle arrays
    if (Array.isArray(section) && Array.isArray(sourceData)) {
      return section;
    }
    
    // Only process objects
    if (typeof section !== 'object' || section === null) return section;
    if (typeof sourceData !== 'object' || sourceData === null) return section;
    
    // Check each key in the section
    Object.keys(section).forEach(key => {
      const currentPath = path ? `${path}.${key}` : key;
      
      // If it's an empty object, replace with sourceData if available
      if (
        typeof section[key] === 'object' && 
        section[key] !== null &&
        !Array.isArray(section[key]) &&
        Object.keys(section[key]).length === 0 &&
        sourceData[key] && 
        Object.keys(sourceData[key]).length > 0
      ) {
        section[key] = JSON.parse(JSON.stringify(sourceData[key]));
      } 
      // If it's not empty, recursively check nested objects
      else if (typeof section[key] === 'object' && section[key] !== null && !Array.isArray(section[key])) {
        section[key] = ensureNestedDataIsComplete(section[key], sourceData[key], currentPath);
      }
    });
    
    return section;
  };

  // Initialize metadata only once when component mounts
  useEffect(() => {
    if (!hasInitialized.current && locationData?.id && !wdfData.metadata) {
      hasInitialized.current = true;
      
      setWdfData(prev => {
        // Only update if metadata doesn't already exist
        if (prev.metadata) return prev;
        
        const newMetadata = {
          services: {
            "2": {
              enabled: prev.enabled
            }
          }
        };
        
        // Also set as previous metadata to prevent immediate database update
        setPreviousMetadata(newMetadata);
        
        return {
          ...prev,
          metadata: newMetadata
        };
      });
    }
  }, [locationData?.id, wdfData.metadata]);

  // Effect to update required fields status
  useEffect(() => {
    // Only validate if the service is enabled
    if (!wdfData.enabled) {
      // If service is disabled, all requirements are considered met
      setRequiredFieldsStatus({
        general: true,
        pricing: true,
        turnaround: true,
        schedule: true,
        discounts: true,
        extras: true
      });
      return;
    }

    // Get references to each section's data safely
    const pricing = wdfData.pricing || {};
    const sameDay = pricing.sameDay || {};
    const minimumOrder = pricing.minimumOrder || {};
    const turnaround = wdfData.turnaround || {};
    const schedule = wdfData.schedule || {};
    const discounts = wdfData.discounts || {};
    const recurring = discounts.recurring || {};
    const extras = wdfData.extras || {};

    // Check pricing completion requirements:
    // a) Regular price per pound must have a value
    // b) If minimum order is enabled, must have a value
    // c) If same day service is enabled, must have a price value
    const hasPricing = !!pricing.regularPerPound;
    const hasValidMinOrder = !minimumOrder.enabled || !!minimumOrder.amount;
    const hasValidSameDay = !sameDay.enabled || !!sameDay.pricePerPound;
    const isPricingComplete = hasPricing && hasValidMinOrder && hasValidSameDay;
    
    // Check turnaround completion requirements:
    // a) Standard turnaround hours must have a value
    // b) Same day cutoff time is now optional
    const hasTurnaround = !!turnaround.standardHours;
    // Same day cutoff time is now optional, so the validation only checks
    // that same day service has a price if enabled
    const hasValidSameDayTurnaround = !sameDay.enabled || !!sameDay.pricePerPound;
    const isTurnaroundComplete = hasTurnaround && hasValidSameDayTurnaround;
    
    // Check schedule completion based on mode
    // a) One of the 3 options must be selected
    // b) Same Every Day: open and close times must have values
    // c) Custom By Day: all enabled days must have open and close times
    let hasSchedule = false;
    if (schedule.mode === 'self_serve') {
      // If using self-serve hours, it's always valid
      hasSchedule = true;
    } else if (schedule.mode === 'same_every_day') {
      // If same every day, check that open and close times exist
      const sameTimeEveryday = schedule.sameTimeEveryday || {};
      hasSchedule = !!sameTimeEveryday.openTime && !!sameTimeEveryday.closeTime;
    } else if (schedule.mode === 'custom_by_day') {
      // If custom by day, all open days must have valid hours
      const businessHours = schedule.businessHours || {};
      const allDaysValid = Object.values(businessHours).every(day => 
        !day.isOpen || (!!day.openTime && !!day.closeTime)
      );
      // At least one day must be open
      const atLeastOneDayOpen = Object.values(businessHours).some(day => 
        day.isOpen
      );
      hasSchedule = allDaysValid && atLeastOneDayOpen;
    }
    
    // Check discounts requirements
    // Only validate if discounts are enabled
    let hasValidDiscounts = true;
    if (discounts.enabled) {
      // Check if all enabled recurring discount types have rates
      const weekly = recurring.weekly || {};
      const biweekly = recurring.biweekly || {};
      const monthly = recurring.monthly || {};
      
      const weeklyValid = !weekly.enabled || !!weekly.rate;
      const biweeklyValid = !biweekly.enabled || !!biweekly.rate;
      const monthlyValid = !monthly.enabled || !!monthly.rate;
      
      hasValidDiscounts = weeklyValid && biweeklyValid && monthlyValid;
    }
    
    // Check extras requirements
    // Only validate if extras are enabled
    let hasValidExtras = true;
    const specialtyItems = extras.specialtyItems || {};
    const addons = extras.addons || {};
    
    if ((specialtyItems.enabled && (!specialtyItems.items || specialtyItems.items.length === 0)) || 
        (addons.enabled && (!addons.items || addons.items.length === 0))) {
      // Mark as invalid only when a specific toggle is on but no items are added for that toggle
      hasValidExtras = false;
    }
    
    setRequiredFieldsStatus({
      general: true,
      pricing: isPricingComplete,
      turnaround: isTurnaroundComplete,
      schedule: hasSchedule,
      discounts: hasValidDiscounts,
      extras: hasValidExtras
    });
  }, [wdfData]);

  // Update the initializeBusinessHours function (if present) or add it
  const initializeBusinessHours = () => {
    const businessHours = {};
    DAY_ORDER.forEach(day => {
      businessHours[day] = {
        isOpen: true,
        openTime: '',
        closeTime: ''
      };
    });
    return businessHours;
  };

  // Make a helper function to log state changes for debugging
  const logStateChange = (what, prevState, newState, changes) => {
    // Console log removed
  };

  // Function to check if metadata has changed with deeper comparison
  const hasMetadataChanged = (currentMetadata, prevMetadata) => {
    // If either is missing, we can't compare
    if (!currentMetadata || !prevMetadata) return true;
    
    try {
      // Deep comparison of the services.2.dropOff section only
      const currentService = currentMetadata.services?.[2]?.dropOff;
      const prevService = prevMetadata.services?.[2]?.dropOff;
      
      if (!currentService || !prevService) return true;
      
      // If they're identical objects, no need to stringify
      if (currentService === prevService) return false;
      
      // Compare the stringified versions for deep equality
      const currentJson = JSON.stringify(currentService);
      const prevJson = JSON.stringify(prevService);
      const result = currentJson !== prevJson;
      
      return result;
    } catch (e) {
      console.error('Error comparing metadata:', e);
      return false; // Assume no change on error to prevent infinite updates
    }
  };

  // Make all updates capture the full state in metadata and notify parent
  const handleUpdate = (updates) => {
    setWdfData(prev => {
      // Create a new state object with the updates
      const updatedData = {
        ...prev,
        ...updates
      };
      
      // Get clean metadata using our helper
      const metadata = prepareCleanMetadata(updatedData);
      
      // Prepare the final updated data
      const finalData = {
        ...updatedData,
        metadata: metadata
      };
      
      // Send update to parent with the updated data
      const dataToSend = {
        ...finalData,
        requirementsToAdvance: Object.values(requiredFieldsStatus).every(Boolean)
      };
      
      // We'll use setTimeout with 0 delay to ensure this runs after the state update
      setTimeout(() => sendUpdateToParent(dataToSend), 0);
      
      // Return the updated state
      return finalData;
    });
  };

  const handlePricingUpdate = (updates) => {
    console.log("SimpleWDFDropOff - Pricing update:", updates);
    
    setWdfData(prev => {
      // Update the pricing object
      const pricing = prev.pricing || {};
      const updatedData = {
        ...prev,
        pricing: {
          ...pricing,
          ...updates
        }
      };
      
      // Simple metadata update - focus on just what's needed
      const metadata = {
        services: {
          "2": {
            enabled: true,
            dropOff: {
              enabled: true,
              pricing: {
                ...updatedData.pricing
              }
            }
          }
        }
      };
      
      // Prepare the final updated data
      const finalData = {
        ...updatedData,
        metadata: metadata
      };
      
      // Send update to parent immediately
      onChange(finalData);
      
      return finalData;
    });
  };

  const handleSameDayUpdate = (updates) => {
    setWdfData(prev => {
      // Get the current sameDay pricing
      const pricing = prev.pricing || {};
      const currentSameDay = pricing.sameDay || {};
      
      // If we're updating with an empty cutoff, remove it instead of storing empty string
      const processedUpdates = { ...updates };
      if ('cutoff' in updates && !updates.cutoff) {
        delete processedUpdates.cutoff;
        
        // Also remove it from current if it exists
        if (currentSameDay.cutoff) {
          delete currentSameDay.cutoff;
        }
      }
      
      // Create updated pricing with new sameDay values
      const updatedPricing = {
        ...pricing,
        sameDay: {
          ...currentSameDay,
          ...processedUpdates
        }
      };
      
      // Apply to whole state
      const updatedData = {
        ...prev,
        pricing: updatedPricing
      };
      
      // Update metadata
      const metadata = updatedData.metadata || { services: { "2": {} } };
      if (!metadata.services["2"].dropOff) metadata.services["2"].dropOff = {};
      if (!metadata.services["2"].dropOff.pricing) metadata.services["2"].dropOff.pricing = {};
      if (!metadata.services["2"].dropOff.pricing.sameDay) {
        metadata.services["2"].dropOff.pricing.sameDay = {};
      }
      
      // Apply sameDay updates to metadata
      Object.keys(processedUpdates).forEach(key => {
        metadata.services["2"].dropOff.pricing.sameDay[key] = processedUpdates[key];
      });
      
      // If cutoff field was deleted, remove it from metadata as well
      if ('cutoff' in updates && !updates.cutoff && metadata.services["2"].dropOff.pricing.sameDay.cutoff) {
        delete metadata.services["2"].dropOff.pricing.sameDay.cutoff;
      }
      
      // Prepare final data
      const finalData = {
        ...updatedData,
        metadata: metadata
      };
      
      // Send update to parent
      const dataToSend = {
        ...finalData,
        requirementsToAdvance: Object.values(requiredFieldsStatus).every(Boolean)
      };
      
      // Use setTimeout with 0 delay to ensure this runs after the state update
      setTimeout(() => sendUpdateToParent(dataToSend), 0);
      
      // Return the updated state
      return finalData;
    });
  };

  const handleMinimumOrderUpdate = (updates) => {
    setWdfData(prev => {
      // Update the minimumOrder nested object
      const pricing = prev.pricing || {};
      const minimumOrder = pricing.minimumOrder || {};
      
      const updatedData = {
        ...prev,
        pricing: {
          ...pricing,
          minimumOrder: {
            ...minimumOrder,
            ...updates
          }
        }
      };
      
      // Update or initialize metadata
      const metadata = updatedData.metadata || { services: { "2": {} } };
      if (!metadata.services["2"]) metadata.services["2"] = {};
      if (!metadata.services["2"].dropOff) metadata.services["2"].dropOff = {};
      if (!metadata.services["2"].dropOff.pricing) metadata.services["2"].dropOff.pricing = {};
      
      // If minimum_order doesn't exist, create it
      if (!metadata.services["2"].dropOff.pricing.minimum_order) {
        metadata.services["2"].dropOff.pricing.minimum_order = {};
      }
      
      // Apply minimum order updates to metadata using snake_case
      const metadataMinOrder = metadata.services["2"].dropOff.pricing.minimum_order;
      
      // Map camelCase to snake_case and update
      if ('enabled' in updates) metadataMinOrder.enabled = updates.enabled;
      if ('isDollarBased' in updates) {
        metadataMinOrder.is_dollar_based = updates.isDollarBased;
        metadataMinOrder.type = updates.isDollarBased ? 'dollar' : 'weight';
      }
      if ('amount' in updates) {
        // Store the amount value directly without type conversion
        metadataMinOrder.amount = updates.amount;
        
        // Ensure the amount is also stored in the local state with the same type
        updatedData.pricing.minimumOrder.amount = updates.amount;
        
        // Update raw input value to ensure UI consistency
        setRawInputValues(prev => ({
          ...prev,
          minimumAmount: updates.amount
        }));
      }
      
      // Prepare the final updated data
      const finalData = {
        ...updatedData,
        metadata: metadata
      };
      
      // Send update to parent immediately
      const dataToSend = {
        ...finalData,
        requirementsToAdvance: Object.values(requiredFieldsStatus).every(Boolean)
      };
      
      // Use setTimeout with 0 delay to ensure this runs after the state update
      setTimeout(() => sendUpdateToParent(dataToSend), 0);
      
      // Return the updated state
      return finalData;
    });
  };

  const handleTurnaroundUpdate = (updates) => {
    setWdfData(prev => {
      // Update the turnaround object
      const turnaround = prev.turnaround || {};
      
      const updatedData = {
        ...prev,
        turnaround: {
          ...turnaround,
          ...updates
        }
      };
      
      // Update or initialize metadata
      const metadata = updatedData.metadata || { services: { "2": {} } };
      if (!metadata.services["2"]) metadata.services["2"] = {};
      if (!metadata.services["2"].dropOff) metadata.services["2"].dropOff = {};
      if (!metadata.services["2"].dropOff.turnaround) metadata.services["2"].dropOff.turnaround = {};
      
      // Apply turnaround updates to metadata using snake_case
      const metadataTurnaround = metadata.services["2"].dropOff.turnaround;
      
      // Map camelCase to snake_case and update
      if ('standardHours' in updates) metadataTurnaround.standard_hours = updates.standardHours;
      if ('expressEnabled' in updates) metadataTurnaround.express_enabled = updates.expressEnabled;
      if ('expressHours' in updates) metadataTurnaround.express_hours = updates.expressHours;
      if ('expressUpcharge' in updates) metadataTurnaround.express_upcharge = updates.expressUpcharge;
      
      // Prepare the final updated data
      const finalData = {
        ...updatedData,
        metadata: metadata
      };
      
      // Send update to parent immediately
      const dataToSend = {
        ...finalData,
        requirementsToAdvance: Object.values(requiredFieldsStatus).every(Boolean)
      };
      
      // Use setTimeout with 0 delay to ensure this runs after the state update
      setTimeout(() => sendUpdateToParent(dataToSend), 0);
      
      // Return the updated state
      return finalData;
    });
  };

  const handleExpressTurnaroundUpdate = (updates) => {
    setWdfData(prev => {
      // Update the express section nested in turnaround
      const turnaround = prev.turnaround || {};
      const express = turnaround.express || {};
      
      const updatedTurnaround = {
        ...turnaround,
        express: {
          ...express,
          ...updates
        }
      };
      
      const updatedData = {
        ...prev,
        turnaround: updatedTurnaround
      };
      
      // Update or initialize metadata
      const metadata = updatedData.metadata || { services: { "2": {} } };
      if (!metadata.services["2"]) metadata.services["2"] = {};
      if (!metadata.services["2"].dropOff) metadata.services["2"].dropOff = {};
      if (!metadata.services["2"].dropOff.turnaround) metadata.services["2"].dropOff.turnaround = {};
      if (!metadata.services["2"].dropOff.turnaround.express) metadata.services["2"].dropOff.turnaround.express = {};
      
      // Apply express turnaround updates to metadata using snake_case
      const metadataExpress = metadata.services["2"].dropOff.turnaround.express;
      
      // Map camelCase to snake_case and update
      Object.keys(updates).forEach(key => {
        // Convert camelCase keys to snake_case for metadata if needed
        const metadataKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
        metadataExpress[metadataKey] = updates[key];
      });
      
      // Prepare the final updated data
      const finalData = {
        ...updatedData,
        metadata: metadata
      };
      
      // Send update to parent immediately
      const dataToSend = {
        ...finalData,
        requirementsToAdvance: Object.values(requiredFieldsStatus).every(Boolean)
      };
      
      // Use setTimeout with 0 delay to ensure this runs after the state update
      setTimeout(() => sendUpdateToParent(dataToSend), 0);
      
      // Return the updated state
      return finalData;
    });
  };

  const handleScheduleUpdate = (updates) => {
    setWdfData(prev => {
      // Update the schedule object
      const schedule = prev.schedule || {};
      
      // Create a base schedule object with only the mode
      let newSchedule = {
        mode: updates.mode
      };
      
      // If we're switching to same_every_day mode, make sure openTime and closeTime are present
      if (updates.mode === 'same_every_day') {
        newSchedule.sameTimeEveryday = {
          ...(schedule.sameTimeEveryday || {}),
          openTime: schedule.sameTimeEveryday?.openTime || '',
          closeTime: schedule.sameTimeEveryday?.closeTime || ''
        };
      }
      
      // If we're switching to custom_by_day mode, ensure each day is properly initialized
      if (updates.mode === 'custom_by_day') {
        // Initialize business hours if not already present
        if (!schedule.businessHours) {
          const defaultBusinessHours = {};
          // Initialize business hours for each day
          ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].forEach(day => {
            defaultBusinessHours[day] = {
              isOpen: true,
              openTime: '',
              closeTime: ''
            };
          });
          newSchedule.businessHours = defaultBusinessHours;
        } else {
          newSchedule.businessHours = schedule.businessHours;
        }
      }
      
      // Include any other updates provided
      if (updates.businessHours && updates.mode === 'custom_by_day') {
        newSchedule.businessHours = updates.businessHours;
      }
      
      if (updates.sameTimeEveryday && updates.mode === 'same_every_day') {
        newSchedule.sameTimeEveryday = updates.sameTimeEveryday;
      }
      
      const updatedData = {
        ...prev,
        schedule: newSchedule
      };
      
      // Update or initialize metadata
      const metadata = updatedData.metadata || { services: { "2": {} } };
      if (!metadata.services["2"]) metadata.services["2"] = {};
      if (!metadata.services["2"].dropOff) metadata.services["2"].dropOff = {};
      if (!metadata.services["2"].dropOff.schedule) metadata.services["2"].dropOff.schedule = {};
      
      // Apply schedule updates to metadata using snake_case
      const metadataSchedule = metadata.services["2"].dropOff.schedule;
      
      // Reset the metadata schedule to match our new schedule
      Object.keys(metadataSchedule).forEach(key => {
        if (key !== 'mode') {
          delete metadataSchedule[key];
        }
      });
      
      // Update the mode, converting 'self_serve' to 'same_as_self_serve'
      metadataSchedule.mode = newSchedule.mode === 'self_serve' ? 'same_as_self_serve' : newSchedule.mode;
      
      // Add the appropriate data based on mode
      if (newSchedule.mode === 'same_every_day' && newSchedule.sameTimeEveryday) {
        metadataSchedule.same_time_everyday = {
          open_time: newSchedule.sameTimeEveryday.openTime,
          close_time: newSchedule.sameTimeEveryday.closeTime
        };
      }
      
      if (newSchedule.mode === 'custom_by_day') {
        metadataSchedule.business_hours = {};
        
        // Always use the businessHours from newSchedule, which should be properly initialized
        const hours = newSchedule.businessHours || {};
        
        // Convert each day to snake_case
        Object.entries(hours).forEach(([day, dayData]) => {
          metadataSchedule.business_hours[day] = {
            is_open: dayData.isOpen,
            open_time: dayData.openTime,
            close_time: dayData.closeTime
          };
        });
      }
      
      // Prepare the final updated data
      const finalData = {
        ...updatedData,
        metadata: metadata
      };
      
      // Send update to parent immediately
      const dataToSend = {
        ...finalData,
        requirementsToAdvance: Object.values(requiredFieldsStatus).every(Boolean)
      };
      
      // Use setTimeout with 0 delay to ensure this runs after the state update
      setTimeout(() => sendUpdateToParent(dataToSend), 0);
      
      // Return the updated state
      return finalData;
    });
  };

  const handleSameDayTimeUpdate = (field, value) => {
    setWdfData(prev => {
      const schedule = prev.schedule || {};
      const sameTimeEveryday = schedule.sameTimeEveryday || {};
      
      // Create updated data structure
      const updatedData = {
        ...prev,
        schedule: {
          ...schedule,
          sameTimeEveryday: {
            ...sameTimeEveryday,
            [field]: value
          }
        }
      };
      
      // Update or initialize metadata
      const metadata = updatedData.metadata || { services: { "2": {} } };
      if (!metadata.services["2"]) metadata.services["2"] = {};
      if (!metadata.services["2"].dropOff) metadata.services["2"].dropOff = {};
      if (!metadata.services["2"].dropOff.schedule) metadata.services["2"].dropOff.schedule = {};
      if (!metadata.services["2"].dropOff.schedule.same_time_everyday) {
        metadata.services["2"].dropOff.schedule.same_time_everyday = {};
      }
      
      // Apply same day time updates to metadata with snake_case conversion
      if (field === 'openTime') {
        metadata.services["2"].dropOff.schedule.same_time_everyday.open_time = value;
      } else if (field === 'closeTime') {
        metadata.services["2"].dropOff.schedule.same_time_everyday.close_time = value;
      } else {
        // For any other field (unlikely), just use as is
        metadata.services["2"].dropOff.schedule.same_time_everyday[field] = value;
      }
      
      // Prepare the final updated data
      const finalData = {
        ...updatedData,
        metadata: metadata
      };
      
      // Send update to parent immediately
      const dataToSend = {
        ...finalData,
        requirementsToAdvance: Object.values(requiredFieldsStatus).every(Boolean)
      };
      
      // Use setTimeout with 0 delay to ensure this runs after the state update
      setTimeout(() => sendUpdateToParent(dataToSend), 0);
      
      // Return the updated state
      return finalData;
    });
  };

  const handleOtherUpdate = (updates) => {
    setWdfData(prev => {
      // Update the other object
      const other = prev.other || {};
      
      const updatedData = {
        ...prev,
        other: {
          ...other,
          ...updates
        }
      };
      
      // Update or initialize metadata
      const metadata = updatedData.metadata || { services: { "2": {} } };
      if (!metadata.services["2"]) metadata.services["2"] = {};
      if (!metadata.services["2"].dropOff) metadata.services["2"].dropOff = {};
      if (!metadata.services["2"].dropOff.other) metadata.services["2"].dropOff.other = {};
      
      // Apply other updates to metadata using snake_case
      const metadataOther = metadata.services["2"].dropOff.other;
      
      // Map camelCase to snake_case and update
      Object.keys(updates).forEach(key => {
        const metadataKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
        metadataOther[metadataKey] = updates[key];
      });
      
      // Prepare the final updated data
      const finalData = {
        ...updatedData,
        metadata: metadata
      };
      
      // Send update to parent immediately
      const dataToSend = {
        ...finalData,
        requirementsToAdvance: Object.values(requiredFieldsStatus).every(Boolean)
      };
      
      // Use setTimeout with 0 delay to ensure this runs after the state update
      setTimeout(() => sendUpdateToParent(dataToSend), 0);
      
      // Return the updated state
      return finalData;
    });
  };

  // Handler for discounts
  const handleDiscountsUpdate = (updates) => {
    setWdfData(prev => {
      // Update the discounts object
      const discounts = prev.discounts || {};
      
      const updatedData = {
        ...prev,
        discounts: {
          ...discounts,
          ...updates
        }
      };
      
      // Update or initialize metadata
      const metadata = updatedData.metadata || { services: { "2": {} } };
      if (!metadata.services["2"]) metadata.services["2"] = {};
      if (!metadata.services["2"].dropOff) metadata.services["2"].dropOff = {};
      if (!metadata.services["2"].dropOff.discounts) metadata.services["2"].dropOff.discounts = {};
      
      // Apply discounts updates to metadata using snake_case
      const metadataDiscounts = metadata.services["2"].dropOff.discounts;
      
      // Map camelCase to snake_case and update
      Object.keys(updates).forEach(key => {
        if (key === 'recurring') {
          // For recurring, we need to keep the existing structure
          metadataDiscounts.recurring = metadataDiscounts.recurring || {};
          // We'll handle recurring updates separately
        } else {
          // For all other keys, just convert to snake_case
          const metadataKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
          metadataDiscounts[metadataKey] = updates[key];
        }
      });
      
      // Prepare the final updated data
      const finalData = {
        ...updatedData,
        metadata: metadata
      };
      
      // Send update to parent immediately
      const dataToSend = {
        ...finalData,
        requirementsToAdvance: Object.values(requiredFieldsStatus).every(Boolean)
      };
      
      // Use setTimeout with 0 delay to ensure this runs after the state update
      setTimeout(() => sendUpdateToParent(dataToSend), 0);
      
      // Return the updated state
      return finalData;
    });
  };

  // Handler for recurring discount updates
  const handleRecurringDiscountUpdate = (type, updates) => {
    setWdfData(prev => {
      // Update the recurring discount for the specific type
      const discounts = prev.discounts || {};
      const recurring = discounts.recurring || {};
      const typeData = recurring[type] || {};
      
      const updatedRecurring = {
            ...recurring,
            [type]: {
              ...typeData,
              ...updates
            }
      };
      
      const updatedData = {
        ...prev,
        discounts: {
          ...discounts,
          recurring: updatedRecurring
        }
      };
      
      // Update or initialize metadata
      const metadata = updatedData.metadata || { services: { "2": {} } };
      if (!metadata.services["2"]) metadata.services["2"] = {};
      if (!metadata.services["2"].dropOff) metadata.services["2"].dropOff = {};
      if (!metadata.services["2"].dropOff.discounts) metadata.services["2"].dropOff.discounts = {};
      if (!metadata.services["2"].dropOff.discounts.recurring) metadata.services["2"].dropOff.discounts.recurring = {};
      if (!metadata.services["2"].dropOff.discounts.recurring[type]) metadata.services["2"].dropOff.discounts.recurring[type] = {};
      
      // Apply recurring discount updates to metadata using snake_case
      const metadataRecurringType = metadata.services["2"].dropOff.discounts.recurring[type];
      
      // Map camelCase to snake_case and update
      Object.keys(updates).forEach(key => {
        const metadataKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
        metadataRecurringType[metadataKey] = updates[key];
      });
      
      // Prepare the final updated data
      const finalData = {
        ...updatedData,
        metadata: metadata
      };
      
      // Send update to parent immediately
      const dataToSend = {
        ...finalData,
        requirementsToAdvance: Object.values(requiredFieldsStatus).every(Boolean)
      };
      
      // Use setTimeout with 0 delay to ensure this runs after the state update
      setTimeout(() => sendUpdateToParent(dataToSend), 0);
      
      // Return the updated state
      return finalData;
    });
  };

  const updateDay = (dayKey, update) => {
    setWdfData(prev => {
      // Get the current schedule data
      const schedule = prev.schedule || {};
      const businessHours = schedule.businessHours || {};
      
      // Make sure the day has default values if it doesn't exist
      const day = businessHours[dayKey] || { isOpen: true, openTime: '', closeTime: '' };
      
      // If we're toggling a day off (isOpen: false), preserve the existing times
      // This way, when a user turns it back on, their times are still there
      const updatedDay = {
        ...day,
        ...update
      };
      
      // Make sure all open days have times, even if empty strings
      if (updatedDay.isOpen && updatedDay.openTime === undefined) {
        updatedDay.openTime = '';
      }
      if (updatedDay.isOpen && updatedDay.closeTime === undefined) {
        updatedDay.closeTime = '';
      }
      
      // Create a new version of businessHours with the day updated
      const updatedBusinessHours = {
        ...businessHours,
        [dayKey]: updatedDay
      };
      
      // Create the updated data structure
      const updatedData = {
        ...prev,
        schedule: {
          ...schedule,
          businessHours: updatedBusinessHours
        }
      };
      
      // Update or initialize metadata
      const metadata = updatedData.metadata || { services: { "2": {} } };
      if (!metadata.services["2"]) metadata.services["2"] = {};
      if (!metadata.services["2"].dropOff) metadata.services["2"].dropOff = {};
      if (!metadata.services["2"].dropOff.schedule) metadata.services["2"].dropOff.schedule = {};
      if (!metadata.services["2"].dropOff.schedule.business_hours) {
        metadata.services["2"].dropOff.schedule.business_hours = {};
      }
      
      // Update the day in metadata
      if (!metadata.services["2"].dropOff.schedule.business_hours[dayKey]) {
        metadata.services["2"].dropOff.schedule.business_hours[dayKey] = {};
      }
      
      // Apply day updates to metadata using snake_case
      const metadataDay = metadata.services["2"].dropOff.schedule.business_hours[dayKey];
      
      // Map camelCase to snake_case and update
      Object.keys(updatedDay).forEach(key => {
        if (key === 'openTime') {
          metadataDay.open_time = updatedDay.openTime;
        } else if (key === 'closeTime') {
          metadataDay.close_time = updatedDay.closeTime;
        } else if (key === 'isOpen') {
          metadataDay.is_open = updatedDay.isOpen;
        } else {
          // For all other keys, just convert to snake_case
          const metadataKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
          metadataDay[metadataKey] = updatedDay[key];
        }
      });
      
      // Prepare the final updated data
      const finalData = {
        ...updatedData,
        metadata: metadata
      };
      
      // Send update to parent immediately
      const dataToSend = {
        ...finalData,
        requirementsToAdvance: Object.values(requiredFieldsStatus).every(Boolean)
      };
      
      // Use setTimeout with 0 delay to ensure this runs after the state update
      setTimeout(() => sendUpdateToParent(dataToSend), 0);
      
      // Return the updated state
      return finalData;
    });
  };

  // Format time for display
  const formatTimeDisplay = (time24) => {
    if (!time24) return '';
    
    try {
      const [hours, minutes] = time24.split(':');
      const hour = parseInt(hours, 10);
      const minute = parseInt(minutes, 10);
      
      const period = hour >= 12 ? 'PM' : 'AM';
      const hour12 = hour % 12 || 12;
      
      return `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
    } catch (e) {
      return time24;
    }
  };

  // Handler for extras updates
  const handleExtrasUpdate = (type, updates) => {
    console.log('SimpleWDFDropOff - Updating extras:', type, updates);
    
    setWdfData(prev => {
      // Make sure extras field exists
      const extras = prev.extras || {};
      const typeData = extras[type] || {};
      
      // Create updated extras data
      const updatedTypeData = {
        ...typeData,
        ...updates
      };
      
      const updatedExtras = {
        ...extras,
        [type]: updatedTypeData
      };
      
      const completeUpdates = {
        ...prev,
        extras: updatedExtras
      };
      
      // Call handleUpdate to ensure database gets updated immediately
      setTimeout(() => {
        // Convert type to snake_case for metadata
        const metadataType = type === 'specialtyItems' ? 'specialty_items' : type;
        
        // Create a clean extras object for the database
        const cleanExtras = {
          ...extras,
          [type]: updatedTypeData
        };
        
        handleUpdate({extras: cleanExtras});
      }, 0);
      
      return completeUpdates;
    });
  };

  // Helper function to build state from metadata
  const buildStateFromMetadata = (metadata, defaultData) => {
    return {
      ...defaultData,
      enabled: metadata.enabled !== false,
      // Map metadata to state properties
      ...(metadata.pricing ? {
        pricing: {
          // Convert snake_case to camelCase
          ...(metadata.pricing.regularPerPound !== undefined ? { regularPerPound: metadata.pricing.regularPerPound } : {}),
          ...(metadata.pricing.cost_plus_weight !== undefined ? { costPlusWeight: metadata.pricing.cost_plus_weight } : {}),
          ...(metadata.pricing.minimum_order ? {
            minimumOrder: {
              enabled: metadata.pricing.minimum_order.enabled,
              isDollarBased: metadata.pricing.minimum_order.is_dollar_based,
              amount: metadata.pricing.minimum_order.amount
            }
          } : {}),
          ...(metadata.pricing.same_day ? {
            sameDay: {
              enabled: metadata.pricing.same_day.enabled,
              pricePerPound: metadata.pricing.same_day.price_per_pound
            }
          } : {})
        }
      } : {}),
      // Map turnaround data
      ...(metadata.turnaround ? {
        turnaround: {
          standardHours: metadata.turnaround.standard_hours,
          expressEnabled: metadata.turnaround.express_enabled,
          ...((metadata.turnaround.same_day || metadata.pricing?.same_day) ? {
            sameDay: {
              enabled: metadata.pricing?.same_day?.enabled || metadata.turnaround?.same_day?.enabled || false,
              cutoff: metadata.turnaround?.same_day?.cutoff || metadata.pricing?.same_day?.cutoff,
              turnaroundHours: metadata.turnaround?.same_day?.turnaround_hours || metadata.pricing?.same_day?.turnaround_hours
            }
          } : {}),
          ...(metadata.turnaround.express ? {
            express: {
              hours: metadata.turnaround.express.hours,
              upcharge: metadata.turnaround.express.upcharge
            }
          } : {})
        }
      } : {}),
      // Map schedule data
      ...(metadata.schedule ? {
        schedule: {
          mode: metadata.schedule.mode === 'same_as_self_serve' ? 'self_serve' : metadata.schedule.mode,
          ...(metadata.schedule.same_time_everyday ? {
            sameTimeEveryday: {
              openTime: metadata.schedule.same_time_everyday.open_time || '',
              closeTime: metadata.schedule.same_time_everyday.close_time || ''
            }
          } : {}),
          ...(metadata.schedule.business_hours ? {
            businessHours: Object.entries(metadata.schedule.business_hours).reduce((acc, [day, dayData]) => {
              acc[day] = {
                isOpen: dayData.is_open !== false,
                openTime: dayData.open_time || '',
                closeTime: dayData.close_time || ''
              };
              return acc;
            }, {})
          } : {})
        }
      } : {}),
      // Include other sections
      ...(metadata.discounts ? { discounts: metadata.discounts } : {}),
      ...(metadata.extras ? { extras: metadata.extras } : {}),
      ...(metadata.other ? { other: metadata.other } : {}),
      // Include the original metadata
      metadata: {
        services: {
          "2": {
            dropOff: metadata
          }
        }
      }
    };
  };

  // Helper function to prepare metadata for update
  const prepareCleanMetadata = (updatedData) => {
    // Create a base metadata structure
    const metadata = updatedData.metadata || { services: { "2": {} } };
    if (!metadata.services) metadata.services = {};
    if (!metadata.services["2"]) metadata.services["2"] = {};
    
    // Ensure dropOff structure exists
    if (!metadata.services["2"].dropOff) {
      metadata.services["2"].dropOff = { enabled: updatedData.enabled || true };
    }
    
    // Copy current state fields to metadata
    ['pricing', 'turnaround', 'schedule', 'discounts', 'extras', 'other'].forEach(field => {
      if (updatedData[field]) {
        metadata.services["2"].dropOff[field] = updatedData[field];
      }
    });
    
    // Explicitly remove fieldVisibility from metadata to prevent it from being saved to the database
    if (metadata.services["2"].dropOff.fieldVisibility) {
      delete metadata.services["2"].dropOff.fieldVisibility;
    }
    
    return metadata;
  };

  // Render General tab content
  const renderGeneralContent = () => (
    <div className="space-y-6">
      <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
        <div className="flex items-center gap-3">
          <Settings className="w-5 h-5 text-gray-400" />
          <div>
            <span className="text-sm font-medium text-gray-700">Wash & Fold Drop-Off Service</span>
            <p className="text-xs text-gray-500 mt-1">
              Enable this service to allow customers to drop off laundry for washing, drying, and folding.
            </p>
          </div>
        </div>
        <BlackSwitch 
          checked={wdfData.enabled} 
          onCheckedChange={(checked) => handleUpdate({ enabled: checked })}
        />
      </div>
        
      <AnimatePresence>
        {wdfData.enabled && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="flex items-center p-4 rounded-lg bg-blue-50 border border-blue-100">
              <Info className="w-5 h-5 text-blue-500 mr-3 flex-shrink-0" />
              <p className="text-sm text-blue-700">
                Configure the details of your Wash & Fold Drop-Off service in the tabs below. You'll need to set up pricing, turnaround times, and schedule to complete this section.
              </p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );

  const renderPricingContent = () => {
    // Get pricing data safely with empty objects as fallbacks
    const pricing = wdfData.pricing || {};
    const sameDay = pricing.sameDay || {};
    const minimumOrder = pricing.minimumOrder || {};

    return (
      <div className="space-y-4">
        {/* Regular Price Per Pound */}
        <div>
          <Label htmlFor="price-per-pound">Regular Price Per Pound</Label>
          <div className="flex mt-1">
            <div className="flex items-center px-3 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
              <span className="text-gray-500">$</span>
            </div>
            <Input
              id="price-per-pound"
              type="number"
              min="0"
              step="0.01"
              value={rawInputValues.regularPerPound || pricing.regularPerPound || ''}
              onChange={(e) => {
                const value = e.target.value;
                
                // Don't proceed if the field is empty
                if (value.trim() === '') return;
                
                const numValue = parseFloat(value);
                if (!isNaN(numValue)) {
                  // Make sure we're sending a clean object to updatePricing
                  // This ensures both the local state and metadata are updated
                  const update = { regularPerPound: numValue };
                  handlePricingUpdate(update);
                  
                  // Also update any corresponding metadata directly to ensure it propagates
                  // This is a belt-and-suspenders approach to ensure the data is updated
                  setWdfData(prev => {
                    if (!prev.metadata?.services?.[2]?.dropOff?.pricing) return prev;
                    
                    // Create a new metadata object with the updated value
                    const updatedMetadata = {
                      ...prev.metadata,
                      services: {
                        ...prev.metadata.services,
                        "2": {
                          ...prev.metadata.services["2"],
                          dropOff: {
                            ...prev.metadata.services["2"].dropOff,
                            pricing: {
                              ...prev.metadata.services["2"].dropOff.pricing,
                              regularPerPound: numValue
                            }
                          }
                        }
                      }
                    };
                    
                    // Create the data to send to parent
                    const dataToSend = {
                      ...prev,
                      pricing: {
                        ...prev.pricing,
                        regularPerPound: numValue
                      },
                      metadata: updatedMetadata,
                      requirementsToAdvance: Object.values(requiredFieldsStatus).every(Boolean)
                    };
                    
                    // Send the update to parent
                    setTimeout(() => sendUpdateToParent(dataToSend), 0);
                    
                    // Return the updated state
                    return dataToSend;
                  });
                }
              }}
              className="rounded-l-none"
              placeholder="0.00"
            />
          </div>
        </div>

        {/* Minimum order section */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <DollarSign className="w-5 h-5 text-gray-400" />
            <span className="text-sm font-medium text-gray-700">Minimum Order Required</span>
          </div>
          <BlackSwitch 
            checked={minimumOrder.enabled || false} 
            onCheckedChange={(checked) => handleMinimumOrderUpdate({ enabled: checked })}
          />
        </div>

        <AnimatePresence>
          {minimumOrder.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
              className="p-4 rounded-lg bg-white border border-gray-200 space-y-3"
            >
              <div>
                <Label htmlFor="minimum-order-type">Minimum Order Type</Label>
                <div className="flex gap-2 mt-1">
                  <Button
                    type="button"
                    variant="outline"
                    className={cn(
                      "flex-1 flex items-center justify-center gap-2",
                      minimumOrder.isDollarBased && "border-gray-900 text-gray-900 font-medium"
                    )}
                    onClick={() => handleMinimumOrderUpdate({ isDollarBased: true })}
                  >
                    <DollarSign className="w-4 h-4" />
                    <span>Dollar Amount</span>
                  </Button>
                  <Button
                    type="button"
                    variant="outline"
                    className={cn(
                      "flex-1 flex items-center justify-center gap-2",
                      !minimumOrder.isDollarBased && "border-gray-900 text-gray-900 font-medium"
                    )}
                    onClick={() => handleMinimumOrderUpdate({ isDollarBased: false })}
                  >
                    <Scale className="w-4 h-4" />
                    <span>Weight (lbs)</span>
                  </Button>
                </div>
              </div>

              <div>
                <Label htmlFor="minimum-order-amount">
                  {minimumOrder.isDollarBased ? 'Minimum Order Amount' : 'Minimum Weight (lbs)'}
                </Label>
                <div className="flex mt-1">
                  {minimumOrder.isDollarBased && (
                    <div className="flex items-center px-3 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                      <span className="text-gray-500">$</span>
                    </div>
                  )}
                  <Input
                    id="minimum-order-amount"
                    type="text"
                    value={rawInputValues.minimumAmount || minimumOrder?.amount || ''}
                    onChange={(e) => {
                      const value = e.target.value;
                      
                      if (value.trim() === '') return;
                      
                      handleMinimumOrderUpdate({ amount: value });
                    }}
                    onBlur={() => {
                      if (rawInputValues.minimumAmount === '') {
                        handleMinimumOrderUpdate({ amount: '' });
                        setRawInputValues(prev => ({ ...prev, minimumAmount: '' }));
                      }
                    }}
                    className={minimumOrder.isDollarBased ? "rounded-l-none" : ""}
                    placeholder={minimumOrder.isDollarBased ? "Enter amount" : "Enter weight"}
                  />
                </div>
                <p className="text-xs text-gray-500 mt-1">
                  {minimumOrder.isDollarBased 
                    ? "Customers must spend at least this amount to place an order."
                    : "Customers must have at least this much weight to place an order."}
                </p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Same Day Service */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <Calendar className="w-5 h-5 text-gray-400" />
            <span className="text-sm font-medium text-gray-700">Same Day Service</span>
          </div>
          <BlackSwitch 
            checked={sameDay.enabled} 
            onCheckedChange={(checked) => handleSameDayUpdate({ enabled: checked })}
          />
        </div>

        <AnimatePresence>
          {sameDay.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
              className="p-4 rounded-lg bg-white border border-gray-200 space-y-3"
            >
              <div>
                <Label htmlFor="same-day-price">Same Day Price Per Pound</Label>
                <div className="flex mt-1">
                  <div className="flex items-center px-3 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                    <span className="text-gray-500">$</span>
                  </div>
                  <Input
                    id="same-day-price"
                    type="number"
                    min="0"
                    step="0.01"
                    value={rawInputValues.sameDayPrice || sameDay.pricePerPound || ''}
                    onChange={(e) => {
                      const value = e.target.value;
                      
                      if (value.trim() === '') return;
                      
                      const numValue = parseFloat(value);
                      if (!isNaN(numValue)) {
                        handleSameDayUpdate({ pricePerPound: numValue });
                      }
                    }}
                    className="rounded-l-none"
                    placeholder="0.00"
                  />
                </div>
                <p className="text-xs text-gray-500 mt-1">
                  The price per pound for same day orders.
                </p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  const renderScheduleContent = () => {
    // Get schedule data safely
    const schedule = wdfData.schedule || {};
    const mode = schedule.mode || 'self_serve';
    const sameTimeEveryday = schedule.sameTimeEveryday || {};
    
    // Initialize business hours if not present
    const businessHours = schedule.businessHours || initializeBusinessHours();
    
    return (
      <div className="space-y-6">
        <div className="mb-4">
          <Label className="text-sm mb-2 block">Schedule Type</Label>
          <ToggleGroup
            options={[
              { label: 'Same as Self-Serve', value: 'self_serve' },
              { label: 'Same Every Day', value: 'same_every_day' },
              { label: 'Custom by Day', value: 'custom_by_day' }
            ]}
            value={mode}
            onChange={(value) => handleScheduleUpdate({ mode: value })}
            fullWidth
            className="w-full"
          />
        </div>
        
        {mode === 'self_serve' && (
          <div className="bg-gray-50 rounded-md p-4 text-center text-gray-600">
            <p className="text-center">
              This service will use the same operating hours as your Self-Serve laundry.
            </p>
          </div>
        )}
        
        {mode === 'same_every_day' && (
          <div className="space-y-4">
            <div>
              <Label className="text-sm mb-2 block">Hours (Open - Close)</Label>
              <div className="flex items-center gap-2">
                <Input
                  type="time"
                  value={sameTimeEveryday.openTime || ''}
                  onChange={(e) => handleSameDayTimeUpdate('openTime', e.target.value)}
                  className="flex-1"
                  placeholder="09:00"
                />
                <span>to</span>
                <Input
                  type="time"
                  value={sameTimeEveryday.closeTime || ''}
                  onChange={(e) => handleSameDayTimeUpdate('closeTime', e.target.value)}
                  className="flex-1"
                  placeholder="17:00"
                />
              </div>
            </div>
            
            <div className="text-sm bg-gray-50 rounded-md p-3">
              {sameTimeEveryday.openTime && sameTimeEveryday.closeTime ? 
                `Hours: ${formatTimeDisplay(sameTimeEveryday.openTime)} – ${formatTimeDisplay(sameTimeEveryday.closeTime)} every day` : 
                'Please set your opening and closing hours'
              }
            </div>
          </div>
        )}
        
        {mode === 'custom_by_day' && (
          <div className="border rounded-md overflow-hidden overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" style={{ width: '120px' }}>
                    Day
                  </th>
                  <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Hours
                  </th>
                  <th scope="col" className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider text-center" style={{ width: '80px' }}>
                    Open
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {Object.entries(businessHours)
                  .sort(([dayA], [dayB]) => {
                    return DAY_ORDER.indexOf(dayA) - DAY_ORDER.indexOf(dayB);
                  })
                  .map(([day, dayData]) => (
                    <tr key={day} className={cn(!dayData.isOpen && "bg-gray-100 text-gray-400")}>
                      <td className={cn(
                        "px-4 py-2 whitespace-nowrap text-sm font-medium", 
                        dayData.isOpen ? "text-gray-900" : "text-gray-400"
                      )}>
                        {day}
                      </td>
                      <td className="px-4 py-2 text-sm text-gray-500">
                        {dayData.isOpen ? (
                          <div className="flex items-center gap-2 flex-wrap sm:flex-nowrap">
                            <Input
                              type="time"
                              value={dayData.openTime || ''}
                              onChange={(e) => updateDay(day, { openTime: e.target.value })}
                              disabled={!dayData.isOpen}
                              className="min-w-[120px] flex-1"
                              placeholder="09:00"
                            />
                            <span className="mx-1">to</span>
                            <Input
                              type="time"
                              value={dayData.closeTime || ''}
                              onChange={(e) => updateDay(day, { closeTime: e.target.value })}
                              disabled={!dayData.isOpen}
                              className="min-w-[120px] flex-1"
                              placeholder="17:00"
                            />
                          </div>
                        ) : (
                          <span className="text-gray-400">Closed</span>
                        )}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-center">
                        <BlackSwitch
                          checked={dayData.isOpen}
                          onCheckedChange={(checked) => updateDay(day, { isOpen: checked })}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  const renderTurnaroundContent = () => {
    // Get turnaround data safely
    const turnaround = wdfData.turnaround || {};
    const sameDay = wdfData.pricing?.sameDay || {};
    
    return (
      <div className="space-y-4">
        {/* Standard Turnaround Time */}
        <div>
          <Label htmlFor="standard-turnaround">Standard Turnaround Time (hours)</Label>
          <Input
            id="standard-turnaround"
            type="number"
            min="1"
            step="1"
            value={rawInputValues.turnaroundHours || turnaround.standardHours || ''}
            onChange={(e) => {
              const value = e.target.value;
              setRawInputValues(prev => ({ ...prev, turnaroundHours: value }));
              
              if (value.trim() === '') return;
              const numValue = parseInt(value);
              
              if (!isNaN(numValue) && numValue > 0) {
                handleTurnaroundUpdate({ standardHours: numValue });
              }
            }}
            onBlur={() => {
              if (rawInputValues.turnaroundHours === '') {
                setRawInputValues(prev => ({ ...prev, turnaroundHours: '' }));
              }
            }}
            className="mt-1"
            placeholder="24"
          />
          <p className="text-xs text-gray-500 mt-1">
            The normal time needed to complete a customer's order.
          </p>
        </div>

        {/* Note about Same Day Service - only show if not enabled */}
        {!wdfData.pricing?.sameDay?.enabled && (
          <div className="p-4 bg-blue-50 rounded-lg border border-blue-100">
            <div className="flex items-start">
              <Info className="w-5 h-5 text-blue-500 mr-3 flex-shrink-0 mt-0.5" />
              <div>
                <p className="text-sm text-blue-700">
                  For faster turnaround options, enable Same Day Service in the Pricing tab.
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Show Same Day service details if it's enabled */}
        {wdfData.pricing?.sameDay?.enabled && (
          <div className="space-y-4">
            <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
              <div className="flex items-start">
                <Calendar className="w-5 h-5 text-gray-400 mr-3 flex-shrink-0 mt-0.5" />
                <div>
                  <h3 className="text-sm font-medium text-gray-700">Same Day Service</h3>
                  <p className="text-xs text-gray-500 mt-1">
                    Configure how quickly same day orders will be processed.
                  </p>
                </div>
              </div>
            </div>

            <div className="space-y-4 p-4 rounded-lg bg-white border border-gray-200">
              <div>
                <Label htmlFor="same-day-cutoff">Cutoff Time for Same Day Service (Optional)</Label>
                  <Input
                    id="same-day-cutoff"
                    type="time"
                  value={turnaround.sameDay?.cutoff || sameDay.cutoff || ''}
                  onChange={(e) => {
                    handleSameDayUpdate({ cutoff: e.target.value });
                  }}
                  placeholder="14:00"
                    aria-label="Same day cutoff time"
                  />
                <p className="text-xs text-gray-500 mt-1">
                  Last time customers can drop off for same-day service (leave empty if not applicable)
                </p>
              </div>

              {/* Same Day Turnaround Time field has been removed */}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderDiscountsContent = () => {
    // Get discounts data safely
    const discounts = wdfData.discounts || {};
    const recurring = discounts.recurring || {};
    const weekly = recurring.weekly || { enabled: false, rate: '' };
    const biweekly = recurring.biweekly || { enabled: false, rate: '' };
    const monthly = recurring.monthly || { enabled: false, rate: '' };
    
    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <Percent className="w-5 h-5 text-gray-400" />
            <span className="text-sm font-medium text-gray-700">Offer Discounts</span>
          </div>
          <BlackSwitch 
            checked={discounts?.enabled} 
            onCheckedChange={(checked) => handleDiscountsUpdate({ enabled: checked })}
          />
        </div>

        <AnimatePresence>
          {discounts?.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
              className="space-y-4"
            >
              <div className="p-4 bg-white rounded-lg border border-gray-200">
                <h3 className="text-sm font-medium text-gray-700 mb-3">Recurring Service Discounts</h3>
                <p className="text-xs text-gray-500 mb-4">
                  Offer special pricing for customers who sign up for recurring wash & fold service.
                </p>
                
                <div className="overflow-hidden border border-gray-200 rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Frequency
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Offer
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Rate ($ per lb)
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {/* Weekly Row */}
                      <tr>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">Weekly</div>
                          <div className="text-xs text-gray-500">Every week</div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <BlackSwitch
                            checked={weekly.enabled}
                            onCheckedChange={(checked) => handleRecurringDiscountUpdate('weekly', { enabled: checked })}
                          />
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex items-center px-2 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                              <span className="text-gray-500">$</span>
                            </div>
                            <Input
                              type="number"
                              min="0"
                              step="0.01"
                              value={rawInputValues.weeklyRate || weekly.rate || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setRawInputValues(prev => ({ ...prev, weeklyRate: value }));
                                
                                if (value.trim() === '') return;
                                const numValue = parseFloat(value);
                                
                                if (!isNaN(numValue)) {
                                  handleRecurringDiscountUpdate('weekly', { rate: numValue });
                                }
                              }}
                              onBlur={() => {
                                if (rawInputValues.weeklyRate === '') {
                                  handleRecurringDiscountUpdate('weekly', { rate: 0 });
                                  setRawInputValues(prev => ({ ...prev, weeklyRate: '0' }));
                                }
                              }}
                              className="rounded-l-none w-20"
                              placeholder="0.00"
                              disabled={!weekly.enabled}
                            />
                          </div>
                        </td>
                      </tr>
                      
                      {/* Biweekly Row */}
                      <tr>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">Biweekly</div>
                          <div className="text-xs text-gray-500">Every two weeks</div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <BlackSwitch
                            checked={biweekly.enabled}
                            onCheckedChange={(checked) => handleRecurringDiscountUpdate('biweekly', { enabled: checked })}
                          />
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex items-center px-2 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                              <span className="text-gray-500">$</span>
                            </div>
                            <Input
                              type="number"
                              min="0"
                              step="0.01"
                              value={rawInputValues.biweeklyRate || biweekly.rate || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setRawInputValues(prev => ({ ...prev, biweeklyRate: value }));
                                
                                if (value.trim() === '') return;
                                const numValue = parseFloat(value);
                                
                                if (!isNaN(numValue)) {
                                  handleRecurringDiscountUpdate('biweekly', { rate: numValue });
                                }
                              }}
                              onBlur={() => {
                                if (rawInputValues.biweeklyRate === '') {
                                  handleRecurringDiscountUpdate('biweekly', { rate: 0 });
                                  setRawInputValues(prev => ({ ...prev, biweeklyRate: '0' }));
                                }
                              }}
                              className="rounded-l-none w-20"
                              placeholder="0.00"
                              disabled={!biweekly.enabled}
                            />
                          </div>
                        </td>
                      </tr>
                      
                      {/* Monthly Row */}
                      <tr>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">Monthly</div>
                          <div className="text-xs text-gray-500">Once a month</div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <BlackSwitch
                            checked={monthly.enabled}
                            onCheckedChange={(checked) => handleRecurringDiscountUpdate('monthly', { enabled: checked })}
                          />
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex items-center px-2 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                              <span className="text-gray-500">$</span>
                            </div>
                            <Input
                              type="number"
                              min="0"
                              step="0.01"
                              value={rawInputValues.monthlyRate || monthly.rate || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setRawInputValues(prev => ({ ...prev, monthlyRate: value }));
                                
                                if (value.trim() === '') return;
                                const numValue = parseFloat(value);
                                
                                if (!isNaN(numValue)) {
                                  handleRecurringDiscountUpdate('monthly', { rate: numValue });
                                }
                              }}
                              onBlur={() => {
                                if (rawInputValues.monthlyRate === '') {
                                  handleRecurringDiscountUpdate('monthly', { rate: 0 });
                                  setRawInputValues(prev => ({ ...prev, monthlyRate: '0' }));
                                }
                              }}
                              className="rounded-l-none w-20"
                              placeholder="0.00"
                              disabled={!monthly.enabled}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                <div className="mt-3 text-sm text-gray-500 flex items-start gap-2">
                  <CheckCircle className="w-4 h-4 text-gray-400 mt-0.5 flex-shrink-0" />
                  <span>These rates will override the regular price per pound when a customer selects a recurring service.</span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  const renderExtrasContent = () => {
    // Get extras data safely
    const extras = wdfData.extras || {};
    
    // Check both locations for specialty items data
    let specialtyItemsData = extras.specialtyItems || { enabled: false, items: [] };
    
    // If we have metadata with specialty_items, use that data
    if (wdfData.metadata?.services?.[2]?.dropOff?.extras?.specialty_items) {
      const metadataItems = wdfData.metadata.services[2].dropOff.extras.specialty_items;
      specialtyItemsData = {
        enabled: metadataItems.enabled !== false,
        items: metadataItems.items || []
      };
    }
    
    const addons = extras.addons || { enabled: false, items: [] };
    
    const openExtrasModal = (type) => {
      setExtrasModalType(type);
      setShowExtrasModal(true);
    };
    
    const closeExtrasModal = () => {
      setShowExtrasModal(false);
    };
    
    const handleSaveExtrasItems = (items) => {
      console.log('SimpleWDFDropOff - Saving extras items for:', extrasModalType);
      
      if (extrasModalType === 'specialty') {
        // Process items for database normalization - prepare data format
        const normalizedItems = items.map(item => {
          // If item has chargeByWeight, create a new object with cost_plus_weight
          if (item.hasOwnProperty('chargeByWeight')) {
            const { chargeByWeight, ...rest } = item;
            return {
              ...rest,
              cost_plus_weight: chargeByWeight
            };
          }
          return item;
        });
        
        // Update the state and trigger database update
        handleExtrasUpdate('specialtyItems', { items: normalizedItems });
      } else if (extrasModalType === 'addon') {
        // Process items for database normalization
        const normalizedItems = items.map(item => {
          // If item has chargeByWeight, create a new object with cost_plus_weight
          if (item.hasOwnProperty('chargeByWeight')) {
            const { chargeByWeight, ...rest } = item;
            return {
              ...rest,
              cost_plus_weight: chargeByWeight
            };
          }
          return item;
        });
        
        // Update the state and trigger database update
        handleExtrasUpdate('addons', { items: normalizedItems });
      }
      
      // Close the modal
      setShowExtrasModal(false);
    };
    
    return (
      <div className="space-y-4">
        {/* Specialty Items Section */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <ShoppingBag className="w-5 h-5 text-gray-400" />
            <div>
              <span className="text-sm font-medium text-gray-700">Specialty Items</span>
              <p className="text-xs text-gray-500 mt-1">
                For items that need special handling or pricing (e.g. comforters, wedding dresses)
              </p>
            </div>
          </div>
          <BlackSwitch 
            checked={specialtyItemsData.enabled} 
            onCheckedChange={(checked) => handleExtrasUpdate('specialtyItems', { enabled: checked })}
          />
        </div>
        
        <AnimatePresence>
          {specialtyItemsData.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
              className="p-4 rounded-lg bg-white border border-gray-200"
            >
              {specialtyItemsData.items && specialtyItemsData.items.length > 0 ? (
                <div className="space-y-3">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-sm font-medium">Specialty Items ({specialtyItemsData.items.length})</span>
                    <Button 
                      variant="outline" 
                      size="sm"
                      onClick={() => openExtrasModal('specialty')}
                      className="flex items-center gap-1"
                    >
                      <Edit className="w-3 h-3" />
                      <span>Edit Items</span>
                    </Button>
                  </div>
                  <div className="max-h-36 overflow-y-auto pr-2">
                    {specialtyItemsData.items.map((item, index) => (
                      <div key={index} className="flex justify-between items-center py-2 border-b border-gray-100 last:border-0">
                        <span className="text-sm">{item.name}</span>
                        <div className="flex items-center gap-3">
                          <span className="text-sm font-medium">${item.price}</span>
                          {(item.cost_plus_weight) && (
                            <span className="text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded">+Weight</span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center border border-dashed border-gray-300 rounded-md p-6">
                  <button 
                    type="button"
                    className="flex items-center gap-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                    onClick={() => openExtrasModal('specialty')}
                  >
                    <Plus className="w-4 h-4" />
                    <span>Add Specialty Item</span>
                  </button>
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>

        {/* Add-ons Section */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <Plus className="w-5 h-5 text-gray-400" />
            <div>
              <span className="text-sm font-medium text-gray-700">Service Add-ons</span>
              <p className="text-xs text-gray-500 mt-1">
                Additional services customers can add to their order (e.g. stain treatment, fabric softener)
              </p>
            </div>
          </div>
          <BlackSwitch 
            checked={addons.enabled} 
            onCheckedChange={(checked) => handleExtrasUpdate('addons', { enabled: checked })}
          />
        </div>
        
        <AnimatePresence>
          {addons.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
              className="p-4 rounded-lg bg-white border border-gray-200"
            >
              {addons.items && addons.items.length > 0 ? (
                <div className="space-y-3">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-sm font-medium">Service Add-ons ({addons.items.length})</span>
                    <Button 
                      variant="outline" 
                      size="sm"
                      onClick={() => openExtrasModal('addon')}
                      className="flex items-center gap-1"
                    >
                      <Edit className="w-3 h-3" />
                      <span>Edit Add-ons</span>
                    </Button>
                  </div>
                  <div className="max-h-36 overflow-y-auto pr-2">
                    {addons.items.map((item, index) => (
                      <div key={index} className="flex justify-between items-center py-2 border-b border-gray-100 last:border-0">
                        <span className="text-sm">{item.name}</span>
                        <div className="flex items-center">
                          <span className="text-sm font-medium">${item.price}</span>
                          <span className="text-xs text-gray-500 ml-1">/ {item.unit}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center border border-dashed border-gray-300 rounded-md p-6">
                  <button 
                    type="button"
                    className="flex items-center gap-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                    onClick={() => openExtrasModal('addon')}
                  >
                    <Plus className="w-4 h-4" />
                    <span>Add Add-on Service</span>
                  </button>
                </div>
              )}
              {!addons.items || addons.items.length === 0 && (
                <p className="text-xs text-gray-500 text-center mt-3">
                  Click to configure additional services customers can select
                </p>
              )}
            </motion.div>
          )}
        </AnimatePresence>
        
        {/* Modal for editing extras */}
        {showExtrasModal && (
          <ExtrasModal 
            open={showExtrasModal}
            onClose={closeExtrasModal}
            type={extrasModalType === 'specialty' ? 'specialty' : 'addon'}
            items={extrasModalType === 'specialty' ? specialtyItemsData.items || [] : addons.items || []}
            onSave={handleSaveExtrasItems}
          />
        )}
      </div>
    );
  };

  const renderOtherContent = () => (
    <div className="space-y-4">
      <div>
        <Label htmlFor="special-instructions">Special instructions or notes</Label>
        <textarea
          id="special-instructions"
          value={wdfData.other?.notes || ''}
          onChange={(e) => handleOtherUpdate({ notes: e.target.value })}
          placeholder="Any additional information about your Wash & Fold service..."
          className="w-full mt-1 p-2 min-h-[120px] border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
    </div>
  );

  // Add an effect to make sure metadata is synced with state on any state change
  useEffect(() => {
    // Skip the first render since we initialize metadata there
    if (isFirstRender.current) return;
    
    // If we have state but no metadata or incomplete metadata, fix it
    if (!wdfData.metadata?.services?.[2]?.dropOff 
        || Object.keys(wdfData.metadata.services[2].dropOff).length === 0) {
      
      // Create a clean copy of state data without metadata or helper properties
      const cleanData = { ...wdfData };
      if (cleanData.metadata) delete cleanData.metadata;
      if (cleanData.isComplete) delete cleanData.isComplete;
      
      // Create a full metadata structure from current state
      const fixedMetadata = {
        services: {
          "2": {
            dropOff: {
              enabled: cleanData.enabled,
              pricing: cleanData.pricing || {},
              turnaround: cleanData.turnaround || {},
              schedule: cleanData.schedule || {},
              discounts: cleanData.discounts || {},
              extras: cleanData.extras || {},
              other: cleanData.other || {}
            }
          }
        }
      };
      
      // Update state with the fixed metadata
      setWdfData(prev => ({
        ...prev,
        metadata: fixedMetadata
      }));
    }
  }, [wdfData.enabled, wdfData.pricing, wdfData.turnaround, 
      wdfData.schedule, wdfData.discounts, wdfData.extras, wdfData.other]);

  useEffect(() => {
    // Optional: Clean up old turnaroundHours data if it exists
    setWdfData(prev => {
      if (prev.pricing?.sameDay?.turnaroundHours) {
        // Create new state without the turnaroundHours field
        const updatedData = { ...prev };
        if (updatedData.pricing && updatedData.pricing.sameDay) {
          const { turnaroundHours, ...restSameDay } = updatedData.pricing.sameDay;
          updatedData.pricing.sameDay = restSameDay;
          
          // Also remove from metadata if it exists
          if (updatedData.metadata?.services?.["2"]?.dropOff?.pricing?.sameDay?.turnaroundHours) {
            delete updatedData.metadata.services["2"].dropOff.pricing.sameDay.turnaroundHours;
          }
          
          // Send update to parent
          onChange(updatedData);
          return updatedData;
        }
      }
      return prev;
    });
  }, []);

  return (
    <div className="space-y-6">
      <div className="border-b">
        <ScrollableTabs
          tabs={[
            {
              id: 'general',
              label: 'General',
              onClick: () => setActiveTab('general'),
              isActive: activeTab === 'general'
            },
            {
              id: 'pricing',
              label: 'Pricing',
              onClick: () => setActiveTab('pricing'),
              isActive: activeTab === 'pricing',
              indicator: wdfData.enabled && !requiredFieldsStatus.pricing ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'turnaround',
              label: 'Turnaround',
              onClick: () => setActiveTab('turnaround'),
              isActive: activeTab === 'turnaround',
              indicator: wdfData.enabled && !requiredFieldsStatus.turnaround && 
                !(wdfData.turnaround?.standardHours && 
                  (!wdfData.pricing?.sameDay?.enabled || 
                    ((wdfData.pricing?.sameDay?.cutoff || wdfData.turnaround?.sameDay?.cutoff) && 
                     (wdfData.pricing?.sameDay?.turnaroundHours || wdfData.turnaround?.sameDay?.turnaroundHours))
                  )
                ) ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'schedule',
              label: 'Schedule',
              onClick: () => setActiveTab('schedule'),
              isActive: activeTab === 'schedule',
              indicator: wdfData.enabled && !requiredFieldsStatus.schedule ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'discounts',
              label: 'Discounts',
              onClick: () => setActiveTab('discounts'),
              isActive: activeTab === 'discounts',
              indicator: wdfData.enabled && !requiredFieldsStatus.discounts ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'extras',
              label: 'Extras',
              onClick: () => setActiveTab('extras'),
              isActive: activeTab === 'extras',
              indicator: wdfData.enabled && !requiredFieldsStatus.extras ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'other',
              label: 'Other',
              onClick: () => setActiveTab('other'),
              isActive: activeTab === 'other'
            }
          ]}
          className="px-2 py-2"
        />
      </div>

      <div className="p-2 sm:p-4">
        {activeTab === 'general' && renderGeneralContent()}
        {activeTab === 'pricing' && renderPricingContent()}
        {activeTab === 'turnaround' && renderTurnaroundContent()}
        {activeTab === 'schedule' && renderScheduleContent()}
        {activeTab === 'discounts' && renderDiscountsContent()}
        {activeTab === 'extras' && renderExtrasContent()}
        {activeTab === 'other' && renderOtherContent()}
      </div>
    </div>
  );
};

export default SimpleWDFDropOff; 