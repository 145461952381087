import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { motion } from 'framer-motion';
import { 
  Clock, 
  DollarSign, 
  Users, 
  ShieldCheck, 
  Truck, 
  Plus, 
  Trash,
  ChevronDown,
  X
} from 'lucide-react';
import { cn } from '../../lib/utils';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Textarea } from '../../components/ui/text-area';
import { Switch } from '../../components/ui/switch';
import { Label } from "../../components/ui/label";
import { Checkbox } from "../../components/ui/checkbox";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../components/ui/tabs";
import ScrollableTabs from './ScrollableTabs';
import SimpleWDFDropOff from './SimpleWDFDropOff';
import SimpleWDFPickupDelivery from './SimpleWDFPickupDelivery';
import { isEqual } from 'lodash';
import { getSupabaseClient } from '../../supabaseClient';


/**
 * A component for configuring Wash & Fold service details
 * for use within the ServiceDetailsManager component
 */
const WashDryFoldPanel = ({ 
  locationData, 
  serviceData = {},
  onUpdate 
}) => {
  // State for managing which tab is active
  const [serviceType, setServiceType] = useState('drop-off');
  
  // Track if this is the first render
  const isFirstRender = useRef(true);
  
  // Track previous data sent to parent to prevent loops
  const prevUpdateData = useRef(null);
  
  // Add refs to track completion status for each tab
  const dropOffStatusRef = useRef(false);
  const pickupDeliveryStatusRef = useRef(false);
  
  // Add ref for debouncing parent updates
  const updateParentTimeoutRef = useRef(null);
  
  // Track whether we've sent completion updates to parent
  const lastParentUpdateSent = useRef(false);
  
  // Track last database update to prevent redundant calls
  const lastDbUpdateRef = useRef(null);
  
  // Add state for tracking tab transitions
  const [isTabTransitioning, setIsTabTransitioning] = useState(false);
  
  // Set up state for the wash & fold data
  const [washFoldData, setWdfData] = useState(serviceData || {
    enabled: true,
    dropOff: {
      enabled: true
    },
    pickupDelivery: {
      enabled: true
    }
  });
  const [activeTab, setActiveTab] = useState('hours');
  
  // Required fields tracking
  const [requiredFieldsStatus, setRequiredFieldsStatus] = useState({
    general: true,  // Service is enabled by default
    pricing: false,
    turnaround: false,
    schedule: false,
    discounts: true, // Optional unless enabled
    extras: true     // Optional unless enabled
  });
  
  // Refs to track previous data for each handler
  const prevDropOffData = useRef(null);
  const prevPickupDeliveryData = useRef(null);
  
  // Memoize the data to be sent to parent to avoid unnecessary updates
  const dataForParent = useMemo(() => {
    // Check if both service types are enabled
    const dropOffEnabled = washFoldData.dropOff?.enabled !== false;
    const pickupDeliveryEnabled = washFoldData.pickupDelivery?.enabled !== false;
    
    // FIXED: Check the actual requirementsToAdvance property in the data objects
    // Look in both the direct objects and in the metadata hierarchy
    let dropOffComplete = false;
    let pickupDeliveryComplete = false;
    
    // Check in multiple locations for dropOff completion status
    if (dropOffStatusRef.current === true) {
      dropOffComplete = true;
    } else if (washFoldData.dropOff?.requirementsToAdvance === true) {
      dropOffComplete = true;
    } else if (washFoldData.metadata?.services?.[2]?.dropOff?.requirementsToAdvance === true) {
      dropOffComplete = true;
    }
    
    // Check in multiple locations for pickupDelivery completion status
    if (pickupDeliveryStatusRef.current === true) {
      pickupDeliveryComplete = true;
    } else if (washFoldData.pickupDelivery?.requirementsToAdvance === true) {
      pickupDeliveryComplete = true;
    } else if (washFoldData.metadata?.services?.[2]?.pickupDelivery?.requirementsToAdvance === true) {
      pickupDeliveryComplete = true;
    }
    
    console.log(`WashDryFoldPanel - requirements check: dropOff: ${dropOffComplete}, pickupDelivery: ${pickupDeliveryComplete}`);
    console.log(`WashDryFoldPanel - current data:`, washFoldData);
    
    // Check overall requirement status
    let allRequiredFieldsComplete = false;
    
    if (dropOffEnabled && pickupDeliveryEnabled) {
      // If both are enabled, check if at least one is complete
      allRequiredFieldsComplete = dropOffComplete || pickupDeliveryComplete;
    } else if (dropOffEnabled) {
      // Only drop-off is enabled, just check its status
      allRequiredFieldsComplete = dropOffComplete;
    } else if (pickupDeliveryEnabled) {
      // Only pickup delivery is enabled, just check its status
      allRequiredFieldsComplete = pickupDeliveryComplete;
    }
    
    // Check if all fields are complete from state (as a backup)
    const allFieldsComplete = Object.entries(requiredFieldsStatus)
      .filter(([field, isComplete]) => field !== 'discounts' && field !== 'extras') // Exclude optional fields
      .every(([_, isComplete]) => isComplete === true);
    
    // Use the most permissive completion status
    const finalRequirementsToAdvance = allRequiredFieldsComplete || allFieldsComplete;
    
    console.log(`WashDryFoldPanel - Final requirements status: ${finalRequirementsToAdvance}`);
    
    // Ensure metadata is properly structured and all state is captured
    let metadata = washFoldData.metadata || { services: { "2": {} } };
    if (!metadata.services) metadata.services = {};
    if (!metadata.services["2"]) metadata.services["2"] = {};
    
    // Create enhanced metadata structure with explicit completion flags
    metadata = {
      services: {
        "2": {
          ...metadata.services["2"],
          enabled: washFoldData.enabled !== false,
          // Make sure dropOff section has requirementsToAdvance
          dropOff: {
            ...(washFoldData.dropOff || {}),
            requirementsToAdvance: dropOffComplete
          },
          // Make sure pickupDelivery section has requirementsToAdvance
          pickupDelivery: {
            ...(washFoldData.pickupDelivery || {}),
            requirementsToAdvance: pickupDeliveryComplete
          },
          // Also include top-level requirementsToAdvance
          requirementsToAdvance: finalRequirementsToAdvance
        }
      }
    };
    
    // Return the full data to be sent to parent
    return {
      ...washFoldData,
      requirementsToAdvance: finalRequirementsToAdvance,
      metadata
    };
  }, [washFoldData, requiredFieldsStatus, dropOffStatusRef.current, pickupDeliveryStatusRef.current]);
  
  // Function to update the database directly (no debouncing)
  const updateDatabase = async (data) => {
    try {
      // Only proceed if we have locationData with an id
      if (!locationData || !locationData.id) {
        console.warn('Cannot update database: missing location ID');
        return;
      }

      // CRITICAL FIX: Don't proceed if there's no actual drop-off data
      if (data.metadata?.services?.[2] && !data.metadata.services[2].dropOff) {
        return; // Skip silently - no need to log every time
      }
      
      // Prevent rapid-fire updates by comparing with last update
      const dataString = JSON.stringify(data);
      if (lastDbUpdateRef.current === dataString) {
        return; // No change from last update, skip
      }
      
      // Save this update to prevent duplicates
      lastDbUpdateRef.current = dataString;
      
      console.log("Updating database with new data");

      // Create a deep copy of the data to avoid reference issues
      const metadataToSave = data.metadata ? JSON.parse(JSON.stringify(data.metadata)) : { services: {} };
      
      // Remove fieldVisibility from the metadata to prevent it from being saved to the database
      if (metadataToSave.services?.[2]?.dropOff?.fieldVisibility) {
        delete metadataToSave.services[2].dropOff.fieldVisibility;
      }
      
      if (metadataToSave.services?.[2]?.pickupDelivery?.fieldVisibility) {
        delete metadataToSave.services[2].pickupDelivery.fieldVisibility;
      }
      
      // Get the Supabase client - use business type from location data if available
      const businessType = locationData.business_type || 'laundromat';
      const supabase = getSupabaseClient(businessType);
      
      // First check if the record exists
      const { data: existingSettings, error: fetchError } = await supabase
        .from('vapi_location_settings')
        .select('id, metadata')
        .eq('id', locationData.id)
        .maybeSingle();
      
      if (fetchError) {
        console.error('Error checking location settings:', fetchError);
        return;
      }
      
      // If record exists, update it
      if (existingSettings) {
        // Merge with existing metadata to preserve other services
        const existingMetadata = existingSettings.metadata || {};
        if (!existingMetadata.services) existingMetadata.services = {};
        
        // Prepare merged metadata preserving other services
        const mergedMetadata = {
          ...existingMetadata,
          services: {
            ...existingMetadata.services,
            "2": {
              ...existingMetadata.services["2"],
              ...metadataToSave.services["2"]
            }
          }
        };
        
        const result = await supabase
          .from('vapi_location_settings')
          .update({ 
            metadata: mergedMetadata
          })
          .eq('id', locationData.id);
          
        if (result && result.error) {
          console.error('Error updating location settings:', result.error);
        }
      } else {
        // If no record exists, create one
        const result = await supabase
          .from('vapi_location_settings')
          .insert({ 
            id: locationData.id,
            metadata: metadataToSave
          });
          
        if (result && result.error) {
          console.error('Error creating location settings:', result.error);
        }
      }
    } catch (error) {
      console.error('Error in database update:', error);
    }
  };
  
  // Effect to update parent component (with anti-loop protection)
  useEffect(() => {
    // Use a ref for storing the previous value and running on change
    const prevDataValue = JSON.stringify(dataForParent); 
    const currentRefValue = prevUpdateData.current ? JSON.stringify(prevUpdateData.current) : null;
    
    if (prevDataValue !== currentRefValue) {
      // Only notify parent component - we now update database directly in state change handlers
      onUpdate(dataForParent);
      
      // Store current data for next comparison
      prevUpdateData.current = dataForParent;
    }
  }, [dataForParent, onUpdate]);
  
  // Add this initialization effect right after the state is set up
  useEffect(() => {
    // Only run this on first mount and if we have location data
    if (!isFirstRender.current || !locationData?.id) return;
    
    
    
    let initialData = {
      enabled: true,
      dropOff: {
        enabled: true
      },
      pickupDelivery: {
        enabled: true
      }
    };
    
    // If service data has metadata, use it as initial data
    if (serviceData?.metadata?.services?.[2]) {
      const metadata = serviceData.metadata.services[2];
      
      
      // Build a clean initial state from metadata
      initialData = {
        enabled: metadata.enabled !== false,
        // Include dropOff data if it exists
        dropOff: metadata.dropOff || { enabled: true },
        // Include pickupDelivery data if it exists
        pickupDelivery: metadata.pickupDelivery || { enabled: true },
        // Keep the original metadata but clean it up
        metadata: {
          services: {
            "2": {
              enabled: metadata.enabled !== false,
              ...(metadata.dropOff ? { dropOff: metadata.dropOff } : {}),
              ...(metadata.pickupDelivery ? { pickupDelivery: metadata.pickupDelivery } : {})
            }
          }
        }
      };
    } else {
      // Otherwise initialize with a proper structure
      
      
      const newMetadata = {
        services: {
          "2": {
            enabled: true,
            dropOff: {
              enabled: true,
              pricing: {},
              turnaround: {},
              schedule: {},
              discounts: {},
              extras: {},
              other: {}
            },
            pickupDelivery: {
              enabled: true
            }
          }
        }
      };
      
      // Include the metadata in the initial data
      initialData.metadata = newMetadata;
      
      
      
      // Make an immediate database update for initialization
      // This is important to ensure the data is saved right away
      updateDatabase({ metadata: newMetadata });
    }
    
    // Update the state with the initial data
    setWdfData(initialData);
    
    // Mark first render as complete
    isFirstRender.current = false;
  }, [locationData?.id, serviceData]);
  
  // Here at the top of the component, add debug output for initial serviceData
  useEffect(() => {
    // Log the initial data when component mounts
    
    
  }, []);
  
  // Add an effect to check initial state of dropOff and pickupDelivery
  useEffect(() => {
    // Check if dropOff data already indicates completion
    if (washFoldData.dropOff?.requirementsToAdvance === true) {
      
      dropOffStatusRef.current = true;
      setRequiredFieldsStatus(prev => ({
        ...prev,
        pricing: true,
        turnaround: true,
        schedule: true
      }));
    }
    
    // Check if pickupDelivery data already indicates completion
    if (washFoldData.pickupDelivery?.requirementsToAdvance === true) {
      
      pickupDeliveryStatusRef.current = true;
      setRequiredFieldsStatus(prev => ({
        ...prev,
        pricing: true,
        turnaround: true,
        schedule: true
      }));
    }
    
    // Also check if serviceData itself already indicates completion
    if (serviceData.requirementsToAdvance === true) {
      
      // Consider both tabs complete if the overall service is complete
      dropOffStatusRef.current = true;
      pickupDeliveryStatusRef.current = true;
      setRequiredFieldsStatus(prev => ({
        ...prev,
        pricing: true,
        turnaround: true,
        schedule: true
      }));
    }
  }, []); // Only run on mount
  
  // Add effect to update refs whenever washFoldData changes
  useEffect(() => {
    // Skip first render and useless rerenders
    if (isFirstRender.current || !washFoldData) {
      return;
    }
    
    // Only update refs if relevant data changed
    if (washFoldData.dropOff?.requirementsToAdvance === true) {
      dropOffStatusRef.current = true;
    }
    
    if (washFoldData.pickupDelivery?.requirementsToAdvance === true) {
      pickupDeliveryStatusRef.current = true;
    }
    
    // Don't force-trigger parent updates on every change, only when needed
    if ((dropOffStatusRef.current || pickupDeliveryStatusRef.current) && 
        !lastParentUpdateSent.current) {
      
      // Clear any pending timeouts
    if (updateParentTimeoutRef.current) {
      clearTimeout(updateParentTimeoutRef.current);
    }
    
      // Only signal completion once
    updateParentTimeoutRef.current = setTimeout(() => {
        setRequiredFieldsStatus(prev => ({ ...prev })); 
        lastParentUpdateSent.current = true;
    }, 50);
    }
  }, [washFoldData]);
  
  // Add these state variables near the top of the component
  const [isLoadingTab, setIsLoadingTab] = useState(true);
  const [fieldVisibility, setFieldVisibility] = useState({
    pricing: false,
    turnaround: false,
    schedule: false,
    discounts: false,
    extras: false
  });
  
  // Modify the handleServiceTypeChange function to make transitions immediate
  const handleServiceTypeChange = (newType) => {
    if (newType === serviceType) return; // Don't process if no actual change
    
    console.log(`WashDryFoldPanel - Switching service type from ${serviceType} to ${newType}`);
    
    // Store current tab's completion status in the appropriate ref
    if (serviceType === 'drop-off') {
      dropOffStatusRef.current = washFoldData.dropOff?.requirementsToAdvance === true;
      console.log(`WashDryFoldPanel - Storing dropOff status: ${dropOffStatusRef.current}`);
    } else if (serviceType === 'pickup-delivery') {
      pickupDeliveryStatusRef.current = washFoldData.pickupDelivery?.requirementsToAdvance === true;
      console.log(`WashDryFoldPanel - Storing pickupDelivery status: ${pickupDeliveryStatusRef.current}`);
    }
    
    // Apply the new tab type immediately without transitions
    setServiceType(newType);
    
    // No animations or delays - just a direct update
    if (newType === 'drop-off' && dropOffStatusRef.current) {
      setRequiredFieldsStatus(prev => {
        const updatedStatus = {
        ...prev,
        pricing: true,
        turnaround: true,
        schedule: true
        };
        
        // Trigger a database update to reflect the completion status
        const updatedData = {
          ...washFoldData,
          dropOff: {
            ...(washFoldData.dropOff || {}),
            requirementsToAdvance: true
          }
        };
        
        // Update the database with the new status
        setTimeout(() => updateDatabase(updatedData), 0);
        
        return updatedStatus;
      });
    } else if (newType === 'pickup-delivery' && pickupDeliveryStatusRef.current) {
      setRequiredFieldsStatus(prev => {
        const updatedStatus = {
        ...prev,
        pricing: true,
        turnaround: true,
        schedule: true
        };
        
        // Trigger a database update to reflect the completion status
        const updatedData = {
          ...washFoldData,
          pickupDelivery: {
            ...(washFoldData.pickupDelivery || {}),
            requirementsToAdvance: true
          }
        };
        
        // Update the database with the new status
        setTimeout(() => updateDatabase(updatedData), 0);
        
        return updatedStatus;
      });
    }
  };
  
  // Add this effect to control visibility on mount and tab switches
  useEffect(() => {
    // On initial mount, don't show any indicators until loaded
    if (isLoadingTab) return;
    
    if (serviceType === 'drop-off') {
      // Check if drop-off is complete
      const isComplete = dropOffStatusRef.current || washFoldData.dropOff?.requirementsToAdvance === true;
      
      // Only show indicators for incomplete fields in drop-off tab
      setFieldVisibility({
        pricing: !isComplete,
        turnaround: !isComplete,
        schedule: !isComplete,
        discounts: false,
        extras: false
      });
    } else if (serviceType === 'pickup-delivery') {
      // Check if pickup-delivery is complete
      const isComplete = pickupDeliveryStatusRef.current || washFoldData.pickupDelivery?.requirementsToAdvance === true;
      
      // Only show indicators for incomplete fields in pickup-delivery tab
      setFieldVisibility({
        pricing: !isComplete,
        turnaround: !isComplete,
        schedule: !isComplete,
        discounts: false,
        extras: false
      });
    }
  }, [serviceType, isLoadingTab]);
  
  // Add this effect to finish initial loading
  useEffect(() => {
    // Set loading to false after a delay on mount
    const timer = setTimeout(() => {
      setIsLoadingTab(false);
    }, 500);
    
    return () => clearTimeout(timer);
  }, []);
  
  // Handle data changes from drop-off component with deep comparison and memoization
  const handleDropOffDataChange = (dropOffData) => {
    // Only process if we have valid data to work with
    if (!dropOffData) {
      return; // No valid data to process
    }
    
    console.log("WashDryFoldPanel - Processing DropOff update:", dropOffData);
    
    // Check for completion status directly in the incoming data
    const isComplete = dropOffData.requirementsToAdvance === true;
    if (isComplete) {
      dropOffStatusRef.current = true;
      console.log("WashDryFoldPanel - Setting dropOffStatusRef to true");
    }
    
    // First, update local state
    setWdfData(prev => {
      // Create a clean update structure
      const updatedData = {
        ...prev,
        dropOff: {
          ...(prev.dropOff || {}),
          ...dropOffData,
          requirementsToAdvance: isComplete
        }
      };
      
      // Extract metadata if present
      if (dropOffData.metadata) {
        if (!updatedData.metadata) updatedData.metadata = {};
        if (!updatedData.metadata.services) updatedData.metadata.services = {};
        if (!updatedData.metadata.services[2]) updatedData.metadata.services[2] = {};
        
        // Merge the metadata
        updatedData.metadata.services[2].dropOff = dropOffData.metadata?.services?.[2]?.dropOff || dropOffData;
        updatedData.metadata.services[2].dropOff.requirementsToAdvance = isComplete;
      }
      
      // Check if business hours are included
      if (dropOffData.metadata?.businessHours) {
        updatedData.businessHours = dropOffData.metadata.businessHours;
      }
      
      // Check if address is included
      if (dropOffData.metadata?.address) {
        if (!updatedData.metadata) updatedData.metadata = {};
        updatedData.metadata.address = dropOffData.metadata.address;
      }
      
      // Create a clean database update structure
      const metadataToUpdate = {
        metadata: {
          services: {
            "2": {
              enabled: true,
              dropOff: {
                ...(dropOffData.metadata?.services?.[2]?.dropOff || dropOffData),
                requirementsToAdvance: isComplete
              }
            }
          }
        }
      };
      
      // Add business hours if present
      if (dropOffData.metadata?.businessHours) {
        metadataToUpdate.metadata.services[2].businessHours = dropOffData.metadata.businessHours;
      }
      
      // Add address if present
      if (dropOffData.metadata?.address) {
        metadataToUpdate.metadata.address = dropOffData.metadata.address;
      }
      
      // Update the database with clean data
      console.log("WashDryFoldPanel - Updating database with:", metadataToUpdate);
      updateDatabase(metadataToUpdate);
      
      return updatedData;
    });
  };
  
  // Handle data changes from pickup delivery component
  const handlePickupDeliveryDataChange = (pickupDeliveryData) => {
    // Only process if we have valid data to work with
    if (!pickupDeliveryData) {
      return; // No valid data to process
    }
    
    console.log("WashDryFoldPanel - Processing PickupDelivery update:", pickupDeliveryData);
    
    // Check for completion status directly in the incoming data
    const isComplete = pickupDeliveryData.requirementsToAdvance === true;
    if (isComplete) {
      pickupDeliveryStatusRef.current = true;
      console.log("WashDryFoldPanel - Setting pickupDeliveryStatusRef to true");
    }
    
    // First, update local state
        setWdfData(prev => {
      // Create a clean update structure
      const updatedData = {
        ...prev,
        pickupDelivery: {
          ...(prev.pickupDelivery || {}),
          ...pickupDeliveryData,
          requirementsToAdvance: isComplete
        }
      };
      
      // Extract metadata if present
      if (pickupDeliveryData.metadata) {
        if (!updatedData.metadata) updatedData.metadata = {};
        if (!updatedData.metadata.services) updatedData.metadata.services = {};
        if (!updatedData.metadata.services[2]) updatedData.metadata.services[2] = {};
        
        // Merge the metadata
        updatedData.metadata.services[2].pickupDelivery = pickupDeliveryData.metadata?.services?.[2]?.pickupDelivery || pickupDeliveryData;
        updatedData.metadata.services[2].pickupDelivery.requirementsToAdvance = isComplete;
      }
      
      // Create a clean database update structure
      const metadataToUpdate = {
      metadata: {
          services: {
            "2": {
              enabled: true,
              pickupDelivery: {
                ...(pickupDeliveryData.metadata?.services?.[2]?.pickupDelivery || pickupDeliveryData),
                requirementsToAdvance: isComplete
              }
            }
          }
        }
      };
      
      // Update the database with clean data
      console.log("WashDryFoldPanel - Updating database with:", metadataToUpdate);
      updateDatabase(metadataToUpdate);
      
      return updatedData;
    });
    
    // Check for completion status - this is now handled separately from state update
    if (isComplete) {
      // Force update to parent for completion
          setTimeout(() => {
        const dataForParent = { 
              washDryFold: {
            ...washFoldData,
            pickupDelivery: {
              ...(washFoldData.pickupDelivery || {}),
              requirementsToAdvance: true
            },
                requirements: checkRequirements()
              }
        };
        
        // Send the data to parent
        onUpdate(dataForParent);
          }, 0);
        }
  };
  
  // Format time for display
  const formatTime = (time24h) => {
    if (!time24h) return '';
    
    try {
      const [hours, minutes] = time24h.split(':');
      const hour = parseInt(hours, 10);
      const minute = parseInt(minutes, 10);
      
      const period = hour >= 12 ? 'PM' : 'AM';
      const hour12 = hour % 12 || 12;
      
      return `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
    } catch (e) {
      return time24h;
    }
  };
  
  // Initialize business hours
  const initializeBusinessHours = () => {
    if (washFoldData.businessHours) return washFoldData.businessHours;
    
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const hours = {};
    
    days.forEach(day => {
      hours[day] = {
        isOpen: true,
        openTime: '09:00',
        closeTime: '17:00'
      };
    });
    
    return hours;
  };
  
  // Handle business hours update
  const handleHoursUpdate = (day, field, value) => {
    const businessHours = washFoldData.businessHours || initializeBusinessHours();
    
    setWdfData(prev => ({
        ...prev,
      businessHours: {
        ...businessHours,
        [day]: {
          ...businessHours[day],
          [field]: value
        }
      }
    }));
  };
  
  // Tab button component
  const TabButton = ({ active, icon: Icon, label, onClick }) => (
    <button
      className={cn(
        "flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-colors",
        active 
          ? "bg-primary text-white" 
          : "bg-white hover:bg-gray-100 text-gray-700"
      )}
      onClick={onClick}
    >
      <Icon className="w-4 h-4 mr-2" />
      <span>{label}</span>
    </button>
  );
  
  // Render Pickup & Delivery content
  const renderPickupDeliveryContent = () => (
    <div>
      <ScrollableTabs>
        <div className="flex space-x-2 mb-6 overflow-x-auto">
          <TabButton
            active={activeTab === 'hours'}
            icon={Clock}
            label="Hours"
            onClick={() => setActiveTab('hours')}
          />
          <TabButton
            active={activeTab === 'pricing'}
            icon={DollarSign}
            label="Pricing"
            onClick={() => setActiveTab('pricing')}
          />
          <TabButton
            active={activeTab === 'turnaround'}
            icon={Truck}
            label="Turnaround & Delivery"
            onClick={() => setActiveTab('turnaround')}
          />
          <TabButton
            active={activeTab === 'other-info'}
            icon={ChevronDown}
            label="Other Info"
            onClick={() => setActiveTab('other-info')}
          />
        </div>
        
        <div className="mt-6">
          {activeTab === 'hours' && renderHoursContent()}
          {activeTab === 'pricing' && renderPricingContent()}
          {activeTab === 'turnaround' && renderTurnaroundContent()}
          {activeTab === 'other-info' && renderOtherInfoContent()}
        </div>
      </ScrollableTabs>
    </div>
  );
  
  // Render Hours tab content
  const renderHoursContent = () => (
    <div className="space-y-6">
      <div className="rounded-lg border border-gray-200 overflow-hidden">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Day</th>
              <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Open</th>
              <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Hours</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {Object.entries(washFoldData.businessHours || initializeBusinessHours()).map(([day, hours]) => (
              <tr key={day} className="bg-white">
                <td className="py-3 px-4 text-sm text-gray-700">{day}</td>
                <td className="py-3 px-4">
                  <Switch
                    checked={hours.isOpen}
                    onCheckedChange={(checked) => handleHoursUpdate(day, 'isOpen', checked)}
                  />
                </td>
                <td className="py-3 px-4 space-x-2">
                  {hours.isOpen ? (
                    <div className="flex items-center space-x-2">
                      <Input
                        type="time"
                        value={hours.openTime}
                        onChange={(e) => handleHoursUpdate(day, 'openTime', e.target.value)}
                        className="w-32"
                      />
                      <span>to</span>
                      <Input
                        type="time"
                        value={hours.closeTime}
                        onChange={(e) => handleHoursUpdate(day, 'closeTime', e.target.value)}
                        className="w-32"
                      />
                    </div>
                  ) : (
                    <span className="text-gray-500">Closed</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
  
  // Render Pricing tab content
  const renderPricingContent = () => (
    <div className="space-y-6">
      <div className="space-y-4">
        <div>
          <Label htmlFor="price-per-pound">Price per pound ($)</Label>
          <Input
            id="price-per-pound"
            type="number"
            min="0"
            step="0.01"
            value={washFoldData.pricingPerPound || ''}
            onChange={(e) => handleDataChange('pricingPerPound', parseFloat(e.target.value))}
            placeholder="e.g. 1.99"
            className="mt-1"
          />
        </div>
        
        <div>
          <Label htmlFor="minimum-weight">Minimum weight (lbs)</Label>
          <Input
            id="minimum-weight"
            type="number"
            min="0"
            step="0.5"
            value={washFoldData.minimumWeight || ''}
            onChange={(e) => handleDataChange('minimumWeight', parseFloat(e.target.value))}
            placeholder="e.g. 10"
            className="mt-1"
          />
        </div>
        
        <div className="flex items-center space-x-2 mt-4">
          <Checkbox
            id="offer-express"
            checked={washFoldData.offerExpressService || false}
            onCheckedChange={(checked) => handleDataChange('offerExpressService', checked)}
          />
          <Label htmlFor="offer-express">Offer express service</Label>
        </div>
        
        {washFoldData.offerExpressService && (
          <div className="pl-6 mt-2 space-y-4">
            <div>
              <Label htmlFor="express-price-multiplier">Express service price multiplier</Label>
              <Input
                id="express-price-multiplier"
                type="number"
                min="1"
                step="0.1"
                value={washFoldData.expressPriceMultiplier || ''}
                onChange={(e) => handleDataChange('expressPriceMultiplier', parseFloat(e.target.value))}
                placeholder="e.g. 1.5 for 50% more"
                className="mt-1"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
  
  // Render Turnaround tab content
  const renderTurnaroundContent = () => (
    <div className="space-y-6">
      <div>
        <Label htmlFor="standard-turnaround">Standard turnaround time (hours)</Label>
        <Input
          id="standard-turnaround"
          type="number"
          min="1"
          step="1"
          value={washFoldData.standardTurnaroundHours || ''}
          onChange={(e) => handleDataChange('standardTurnaroundHours', parseInt(e.target.value, 10))}
          placeholder="e.g. 24"
          className="mt-1"
        />
      </div>
      
      {washFoldData.offerExpressService && (
        <div>
          <Label htmlFor="express-turnaround">Express turnaround time (hours)</Label>
          <Input
            id="express-turnaround"
            type="number"
            min="1"
            step="1"
            value={washFoldData.expressTurnaroundHours || ''}
            onChange={(e) => handleDataChange('expressTurnaroundHours', parseInt(e.target.value, 10))}
            placeholder="e.g. 4"
            className="mt-1"
          />
        </div>
      )}
      
      {serviceType === 'pickup-delivery' && (
        <>
          <div className="mt-6 mb-2">
            <h3 className="text-lg font-medium text-gray-900">Delivery Options</h3>
          </div>
          
          <div>
            <Label htmlFor="delivery-fee">Delivery fee ($)</Label>
            <Input
              id="delivery-fee"
              type="number"
              min="0"
              step="0.01"
              value={washFoldData.deliveryFee || ''}
              onChange={(e) => handleDataChange('deliveryFee', parseFloat(e.target.value))}
              placeholder="e.g. 5.99"
              className="mt-1"
            />
          </div>
          
          <div>
            <Label htmlFor="delivery-radius">Delivery radius (miles)</Label>
            <Input
              id="delivery-radius"
              type="number"
              min="0"
              step="0.1"
              value={washFoldData.deliveryRadius || ''}
              onChange={(e) => handleDataChange('deliveryRadius', parseFloat(e.target.value))}
              placeholder="e.g. 10"
              className="mt-1"
            />
          </div>
          
          <div>
            <Label htmlFor="min-order-delivery">Minimum order for delivery ($)</Label>
            <Input
              id="min-order-delivery"
              type="number"
              min="0"
              step="0.01"
              value={washFoldData.minOrderForDelivery || ''}
              onChange={(e) => handleDataChange('minOrderForDelivery', parseFloat(e.target.value))}
              placeholder="e.g. 20.00"
              className="mt-1"
            />
          </div>
        </>
      )}
    </div>
  );
  
  // Render Other Info tab content
  const renderOtherInfoContent = () => (
    <div className="space-y-6">
      <div>
        <Label htmlFor="special-instructions">Special instructions or notes</Label>
        <Textarea
          id="special-instructions"
          value={washFoldData.specialInstructions || ''}
          onChange={(e) => handleDataChange('specialInstructions', e.target.value)}
          placeholder="Any additional information about your Wash & Fold service..."
          className="mt-1 h-32"
        />
      </div>
    </div>
  );
  
  // Add state to preload both child components
  const [isDropOffPreloaded, setIsDropOffPreloaded] = useState(false);
  const [isPickupDeliveryPreloaded, setIsPickupDeliveryPreloaded] = useState(false);

  // Add a preload effect that runs on component mount
  useEffect(() => {
    // Set a timeout to preload drop-off data first, then pickup-delivery
    const dropOffTimer = setTimeout(() => {
      
      setIsDropOffPreloaded(true);
      
      // After drop-off is loaded, load pickup-delivery
      const pickupDeliveryTimer = setTimeout(() => {
        
        setIsPickupDeliveryPreloaded(true);
      }, 300);
      
      return () => clearTimeout(pickupDeliveryTimer);
    }, 300);
    
    return () => clearTimeout(dropOffTimer);
  }, []);

  // Add this function below the updateDatabase function
  
  /**
   * Checks if the WashDryFold service has met its requirements to advance
   * Examines both dropOff and pickupDelivery services based on their enabled status
   * @returns {boolean} True if requirements are met, false otherwise
   */
  const checkRequirements = () => {
    // Check if both service types are enabled
    const dropOffEnabled = washFoldData.dropOff?.enabled !== false;
    const pickupDeliveryEnabled = washFoldData.pickupDelivery?.enabled !== false;
    
    // Check for completion status in multiple places
    const dropOffComplete = 
      dropOffStatusRef.current === true || 
      washFoldData.dropOff?.requirementsToAdvance === true ||
      washFoldData.metadata?.services?.[2]?.dropOff?.requirementsToAdvance === true;
    
    const pickupDeliveryComplete = 
      pickupDeliveryStatusRef.current === true || 
      washFoldData.pickupDelivery?.requirementsToAdvance === true ||
      washFoldData.metadata?.services?.[2]?.pickupDelivery?.requirementsToAdvance === true;
    
    console.log(`checkRequirements - dropOff enabled: ${dropOffEnabled}, complete: ${dropOffComplete}`);
    console.log(`checkRequirements - pickupDelivery enabled: ${pickupDeliveryEnabled}, complete: ${pickupDeliveryComplete}`);
    
    // Calculate overall requirements based on which services are enabled
    if (dropOffEnabled && pickupDeliveryEnabled) {
      // If both are enabled, at least one must be complete
      return dropOffComplete || pickupDeliveryComplete;
    } else if (dropOffEnabled) {
      // Only drop-off is enabled, just check its status
      return dropOffComplete;
    } else if (pickupDeliveryEnabled) {
      // Only pickup delivery is enabled, just check its status
      return pickupDeliveryComplete;
    }
    
    // If neither is enabled (unlikely), return false
    return false;
  };

  // Update the useEffect to use the checkRequirements function
  useEffect(() => {
    // If we have a valid requirements status, update the database
    if (washFoldData?.metadata?.services) {
      const finalRequirementsStatus = checkRequirements();
      const currentDbStatus = washFoldData.metadata.services[2]?.requirementsToAdvance;
      
      // Only update if there's a change from the current status
      if (currentDbStatus !== finalRequirementsStatus) {
        console.log(`WashDryFoldPanel - Updating requirementsToAdvance to ${finalRequirementsStatus}`);
        
        const metadataToUpdate = {
          metadata: {
            services: {
              "2": {
                requirementsToAdvance: finalRequirementsStatus
              }
            }
          }
        };
        
        // If we have service-specific requirements, include them
        const dropOffEnabled = washFoldData.dropOff?.enabled !== false;
        const pickupDeliveryEnabled = washFoldData.pickupDelivery?.enabled !== false;
        
        if (dropOffEnabled) {
          const dropOffComplete = 
            dropOffStatusRef.current === true || 
            washFoldData.dropOff?.requirementsToAdvance === true ||
            washFoldData.metadata?.services?.[2]?.dropOff?.requirementsToAdvance === true;
            
          metadataToUpdate.metadata.services[2].dropOff = {
            ...(washFoldData.metadata.services[2]?.dropOff || {}),
            requirementsToAdvance: dropOffComplete
          };
        }
        
        if (pickupDeliveryEnabled) {
          const pickupDeliveryComplete = 
            pickupDeliveryStatusRef.current === true || 
            washFoldData.pickupDelivery?.requirementsToAdvance === true ||
            washFoldData.metadata?.services?.[2]?.pickupDelivery?.requirementsToAdvance === true;
            
          metadataToUpdate.metadata.services[2].pickupDelivery = {
            ...(washFoldData.metadata.services[2]?.pickupDelivery || {}),
            requirementsToAdvance: pickupDeliveryComplete
          };
        }
        
        // Update the database
        updateDatabase(metadataToUpdate);
      }
    }
  }, [washFoldData, dropOffStatusRef.current, pickupDeliveryStatusRef.current]);

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="mb-6">
        <Tabs defaultValue="drop-off" onValueChange={handleServiceTypeChange} value={serviceType}>
          <TabsList className="mb-4">
            <TabsTrigger value="drop-off">Drop Off</TabsTrigger>
            <TabsTrigger value="pickup-delivery">Pickup & Delivery</TabsTrigger>
          </TabsList>
          
          {/* Handle both tabs without animations */}
          <div>
            <div style={{ display: serviceType === 'drop-off' ? 'block' : 'none', transition: 'none' }}>
              {(isDropOffPreloaded || serviceType === 'drop-off') && (
                <SimpleWDFDropOff 
                  data={{
                    ...washFoldData.dropOff || {},
                    requirementsToAdvance: dropOffStatusRef.current || washFoldData.dropOff?.requirementsToAdvance,
                  }}
                  fieldVisibility={fieldVisibility}
                  onChange={handleDropOffDataChange} 
                  locationData={locationData}
                />
              )}
            </div>
            
            <div style={{ display: serviceType === 'pickup-delivery' ? 'block' : 'none', transition: 'none' }}>
              {(isPickupDeliveryPreloaded || serviceType === 'pickup-delivery') && (
                <SimpleWDFPickupDelivery 
                  data={{
                    ...washFoldData.pickupDelivery || {},
                    requirementsToAdvance: pickupDeliveryStatusRef.current || washFoldData.pickupDelivery?.requirementsToAdvance,
                  }}
                  fieldVisibility={fieldVisibility}
                  onChange={handlePickupDeliveryDataChange}
                  locationData={locationData}
                />
              )}
            </div>
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export default WashDryFoldPanel; 