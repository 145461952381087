import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSupabaseClient } from '../supabaseClient';
import { useBusinessType } from '../context/BusinessContext';
import { 
  Building2, 
  Save, 
  Loader, 
  ExternalLink, 
  AlertCircle,
  Check, 
  Info,
  X
} from 'lucide-react';
import { toast } from '../components/ui/sonner-toast';
import { motion, AnimatePresence } from 'framer-motion';
import PageHeader from '../components/PageHeader';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { Button } from '../components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../components/ui/card';

const CurbsideIntegration = () => {
  const { businessType } = useBusinessType();
  const supabase = getSupabaseClient(businessType);
  const navigate = useNavigate();
  
  // State for form inputs
  const [url, setUrl] = useState('');
  const [signupUrl, setSignupUrl] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  
  // State for loading, errors, and settings ID
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [curbsideSettingsId, setCurbsideSettingsId] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [showSetupInstructions, setShowSetupInstructions] = useState(false);
  const [userData, setUserData] = useState(null);
  
  // Add validation state
  const [formIsValid, setFormIsValid] = useState(false);

  // Animation variants
  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.3,
        ease: [0.16, 1, 0.3, 1],
        staggerChildren: 0.15
      }
    }
  };

  const cardVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.2,
        ease: [0, 0, 0.2, 1]
      }
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  // Track changes in form fields
  useEffect(() => {
    if (loading) return;
    
    // Check if all required fields are filled
    const isValid = url.trim() !== '' && username.trim() !== '' && password.trim() !== '';
    setFormIsValid(isValid);
    
    setHasChanges(true);
  }, [url, signupUrl, username, password, loading]);

  // Fetch existing settings
  const fetchSettings = async () => {
    try {
      setLoading(true);
      
      // Get current user
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      
      if (user) {
        setUserData(user);
        
        // Check for existing settings
        const { data, error } = await supabase
          .from('curbside_settings')
          .select('*')
          .eq('account_id', user.id)
          .maybeSingle();
          
        if (error && error.code !== 'PGRST116') throw error;
        
        if (data) {
          // Set form fields with existing data
          setUrl(data.url || '');
          setSignupUrl(data.signup_url || '');
          setUsername(data.username || '');
          setPassword(data.password || '');
          setCurbsideSettingsId(data.id);
        }
      }
      
      setHasChanges(false);
    } catch (err) {
      console.error('Error fetching Curbside settings:', err);
      setError('Failed to load settings');
    } finally {
      setLoading(false);
    }
  };

  // Save settings to database
  const handleSave = async () => {
    try {
      setSaving(true);
      setError(null);
      
      if (!userData) {
        const { data: { user }, error: userError } = await supabase.auth.getUser();
        if (userError) throw userError;
        setUserData(user);
      }
      
      if (!userData) throw new Error('User not found');
      
      // Form validation
      if (!url.trim()) {
        throw new Error('URL is required');
      }
      
      if (!username.trim()) {
        throw new Error('Username is required');
      }
      
      if (!password.trim()) {
        throw new Error('Password is required');
      }
      
      let result;
      
      if (curbsideSettingsId) {
        // Update existing record
        const { data, error } = await supabase
          .from('curbside_settings')
          .update({
            url: url.trim(),
            signup_url: signupUrl.trim(),
            username: username.trim(),
            password: password.trim(),
            updated_at: new Date().toISOString()
          })
          .eq('id', curbsideSettingsId)
          .select()
          .single();
          
        if (error) throw error;
        result = data;
      } else {
        // Insert new record
        const { data, error } = await supabase
          .from('curbside_settings')
          .insert({
            account_id: userData.id,
            url: url.trim(),
            signup_url: signupUrl.trim(),
            username: username.trim(),
            password: password.trim(),
            service_options: {},
            updated_at: new Date().toISOString()
          })
          .select()
          .single();
          
        if (error) throw error;
        result = data;
        setCurbsideSettingsId(result.id);
      }
      
      setSuccess(true);
      setHasChanges(false);
      toast.success("Curbside settings saved successfully");
      
      // Reset success state after 3 seconds
      setTimeout(() => setSuccess(false), 3000);
    } catch (err) {
      console.error('Error saving Curbside settings:', err);
      setError(err.message || 'Failed to save settings');
      toast.error(err.message || 'Failed to save settings');
    } finally {
      setSaving(false);
    }
  };
  
  // Send setup request to API
  const handleSetupCurbside = async () => {
    if (!curbsideSettingsId) {
      toast.error("Please save your settings first");
      return;
    }
    
    try {
      setLoading(true);
      
      // Make API request to setup Curbside
      const response = await fetch('https://cleaner-automation-936dbbf97f9e.herokuapp.com/api/browser/curbside/setup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          accountId: userData.id
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to setup Curbside integration');
      }
      
      toast.success("Curbside integration setup started successfully");
    } catch (err) {
      console.error('Error setting up Curbside:', err);
      toast.error(err.message || 'Failed to setup Curbside integration');
    } finally {
      setLoading(false);
    }
  };

  if (loading && !userData) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="space-y-4 text-center">
          <Loader className="w-8 h-8 text-gray-500 animate-spin mx-auto" />
          <p className="text-sm text-gray-600">Loading settings...</p>
        </div>
      </div>
    );
  }

  return (
    <motion.div 
      className="min-h-screen bg-gray-50 flex flex-col items-center"
      variants={pageVariants}
      initial="initial"
      animate="animate"
    >
      <div className="w-full max-w-4xl px-4">
        <PageHeader
          title="Curbside Integration"
          description="Connect to Curbside POS for laundry services integration"
          icon={Building2}
          primaryAction={hasChanges ? {
            onClick: handleSave,
            icon: saving ? (
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ 
                  duration: 1,
                  repeat: Infinity,
                  ease: "linear"
                }}
              >
                <Loader className="w-5 h-5" />
              </motion.div>
            ) : (
              <Save className="w-5 h-5" />
            ),
            text: saving ? "Saving..." : "Save Settings",
            disabled: saving,
            className: `bg-gray-900 text-white hover:bg-gray-800 ${saving ? "opacity-70 cursor-not-allowed" : ""}`,
          } : null}
        />

        <main className="w-full mx-auto py-8 space-y-6">
          <AnimatePresence>
            {error && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="mb-6 p-4 bg-red-50 border border-red-100 rounded-lg flex items-center text-red-600 text-sm"
              >
                <AlertCircle className="w-4 h-4 mr-2 flex-shrink-0" />
                {error}
              </motion.div>
            )}
            
            {success && (
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                transition={{ duration: 0.2 }}
                className="mb-6 p-4 bg-green-50 border border-green-100 rounded-lg flex items-center text-green-600 text-sm"
              >
                <Check className="w-4 h-4 mr-2" />
                Settings saved successfully
              </motion.div>
            )}
          </AnimatePresence>

          {/* Info Card */}
          <motion.div variants={cardVariants} className="bg-blue-50 rounded-lg border border-blue-100 p-4 mb-6">
            <div className="flex">
              <Info className="w-5 h-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
              <div>
                <p className="text-sm text-blue-700 mb-2">
                  You need to create an employee account on Curbside POS to connect the integration.
                </p>
                <Button 
                  variant="outline"
                  className="text-blue-600 border-blue-200 hover:bg-blue-100 text-sm"
                  onClick={() => setShowSetupInstructions(true)}
                >
                  Learn how to set up Curbside
                </Button>
              </div>
            </div>
          </motion.div>

          {/* Settings Form */}
          <motion.div variants={cardVariants} className="bg-white rounded-xl border border-gray-200 shadow-lg p-8">
            <div className="space-y-6">
              <div>
                <Label htmlFor="url" className="text-gray-700">
                  Curbside Subdomain <span className="text-red-500">*</span>
                </Label>
                <div className="mt-1 flex rounded-md">
                  <Input
                    id="url"
                    placeholder="curbsidesprint"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="rounded-r-none"
                  />
                  <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    -pds.curbsidelaundries.com
                  </span>
                </div>
                <p className="mt-1 text-xs text-gray-500">
                  Enter only the subdomain part, e.g., "curbsidesprint" from https://curbsidesprint-pds.curbsidelaundries.com/
                </p>
              </div>
              
              <div>
                <Label htmlFor="signup_url" className="text-gray-700">
                  Customer Signup URL
                </Label>
                <Input
                  id="signup_url"
                  placeholder="https://example.com/signup"
                  value={signupUrl}
                  onChange={(e) => setSignupUrl(e.target.value)}
                  className="mt-1"
                />
                <p className="mt-1 text-xs text-gray-500">
                  The URL to send customers when they want to create a new account
                </p>
              </div>
              
              <div>
                <Label htmlFor="username" className="text-gray-700">
                  Username <span className="text-red-500">*</span>
                </Label>
                <Input
                  id="username"
                  placeholder="bella@aiforlaundromats.com"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="mt-1"
                />
                <p className="mt-1 text-xs text-gray-500">
                  Email used for the employee account you created
                </p>
              </div>
              
              <div>
                <Label htmlFor="password" className="text-gray-700">
                  Password <span className="text-red-500">*</span>
                </Label>
                <Input
                  id="password"
                  type="password"
                  placeholder="••••••••"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mt-1"
                />
                <p className="mt-1 text-xs text-gray-500">
                  Password for the employee account you created
                </p>
              </div>
            </div>
          </motion.div>

          {/* Setup Integration Button - moved to bottom and improved validation */}
          <motion.div variants={cardVariants} className="mt-6">
            <Button
              onClick={handleSetupCurbside}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white py-3 h-auto text-base"
              disabled={loading || !curbsideSettingsId || !formIsValid}
            >
              <ExternalLink className="w-5 h-5 mr-2" />
              Setup Integration
            </Button>
            {(!formIsValid || !curbsideSettingsId) && (
              <p className="text-center text-gray-500 text-sm mt-2">
                {!curbsideSettingsId 
                  ? "Save your settings first to enable integration setup" 
                  : "Fill in all required fields to enable integration setup"}
              </p>
            )}
          </motion.div>
        </main>
      </div>
      
      {/* Replace AlertDialog with a custom Modal */}
      {showSetupInstructions && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4 overflow-y-auto">
          <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
            <div className="p-6 border-b border-gray-200">
              <div className="flex items-start justify-between">
                <div>
                  <h2 className="text-xl font-semibold text-gray-900">How to Set Up Curbside Integration</h2>
                  <p className="text-gray-600 mt-1">
                    Follow these steps to create an employee account and set up the integration with Curbside POS.
                  </p>
                </div>
                <button
                  onClick={() => setShowSetupInstructions(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
            </div>

            <div className="p-6 space-y-6">
              <div className="space-y-2">
                <h3 className="text-lg font-medium text-gray-900">Step 1: Access the Curbside Admin Interface</h3>
                <p className="text-gray-600">
                  Navigate to <span className="font-mono bg-gray-100 px-1 py-0.5 rounded">https://curbsidesprint-pds.curbsidelaundries.com/User</span> (replace "curbsidesprint" with your Curbside subdomain).
                </p>
              </div>
              
              <div className="space-y-2">
                <h3 className="text-lg font-medium text-gray-900">Step 2: Create a New Employee Account</h3>
                <p className="text-gray-600">
                  Click on the <span className="font-medium">+ New Employee</span> button.
                </p>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-gray-700 font-medium mb-2">Fill in the following information:</p>
                  <ul className="list-disc pl-5 space-y-2 text-gray-600">
                    <li><strong>First Name/Last Name:</strong> Any name you prefer</li>
                    <li><strong>Email (Login):</strong> bella@aiforlaundromats.com (recommended)</li>
                    <li><strong>Password:</strong> Create a secure password</li>
                    <li><strong>Phone:</strong> Your business phone number</li>
                  </ul>
                </div>
              </div>
              
              <div className="space-y-2">
                <h3 className="text-lg font-medium text-gray-900">Step 3: Configure Employee Permissions</h3>
                <p className="text-gray-600">
                  In the <span className="font-medium">Current Role</span> section, select <span className="font-medium">Manager</span> as the role.
                </p>
                <p className="text-gray-600">
                  In the <span className="font-medium">Switchable Roles</span> section, enable all three options: Manager, Attendant, and Driver.
                </p>
                <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-r-lg">
                  <div className="flex">
                    <AlertCircle className="w-5 h-5 text-yellow-500 mr-3 flex-shrink-0 mt-0.5" />
                    <p className="text-yellow-700 text-sm">
                      These permissions are necessary for our system to access order information, manage services, and process bookings on your behalf.
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="space-y-2">
                <h3 className="text-lg font-medium text-gray-900">Step 4: Save Employee Information</h3>
                <p className="text-gray-600">
                  Create the employee account and make note of the login credentials.
                </p>
              </div>
              
              <div className="space-y-2">
                <h3 className="text-lg font-medium text-gray-900">Step 5: Enter Credentials in This Form</h3>
                <p className="text-gray-600">
                  Return to this page and fill in the subdomain, username, and password you created.
                </p>
              </div>
              
              <div className="space-y-2">
                <h3 className="text-lg font-medium text-gray-900">Step 6: Activate the Integration</h3>
                <p className="text-gray-600">
                  After saving your settings, click the "Setup Integration" button to complete the setup.
                </p>
              </div>
            </div>
            
            <div className="p-6 border-t border-gray-200 flex justify-end">
              <Button
                onClick={() => setShowSetupInstructions(false)}
                className="bg-gray-100 text-gray-700 hover:bg-gray-200"
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default CurbsideIntegration; 