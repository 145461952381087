import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { getSupabaseClient } from '../../../supabaseClient';
import { useBusinessType } from '../../../context/BusinessContext';
import ServiceDetailsManager from '../../../components/onboarding-knowledge/ServiceDetailsManager';
import OnboardingSideNav from '../../../components/onboarding-knowledge/OnboardingSideNav';

const ServiceDetailsPage = () => {
  const { businessType } = useBusinessType();
  const supabase = getSupabaseClient(businessType);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [services, setServices] = useState([]);
  const [currentStep, setCurrentStep] = useState('business_knowledge.service_details');
  
  // Fetch data if not provided in location state
  useEffect(() => {
    const fetchData = async () => {
      // If we have data in location state, use it
      if (location.state?.locations && location.state?.services) {
        setLocations(location.state.locations);
        setServices(location.state.services);
        setIsLoading(false);
        return;
      }
      
      try {
        setIsLoading(true);
        
        // Get the current user
        const { data: { user }, error: userError } = await supabase.auth.getUser();
        if (userError) throw userError;
        
        // Fetch locations from vapi_location_settings
        const { data: locationsData, error: locationsError } = await supabase
          .from('vapi_location_settings')
          .select('*')
          .eq('account_id', user.id);
          
        if (locationsError) throw locationsError;
        
        // Fetch current step from onboarding_data
        const { data: onboardingData, error: onboardingError } = await supabase
          .from('onboarding_data')
          .select('current_step')
          .eq('account_id', user.id)
          .single();
          
        if (onboardingError) throw onboardingError;
        
        // Check if user is in the correct step, if not, update it
        if (onboardingData?.current_step !== 'business_knowledge.service_details') {
          const { error: updateError } = await supabase
            .from('onboarding_data')
            .update({ current_step: 'business_knowledge.service_details' })
            .eq('account_id', user.id);
            
          if (updateError) console.error('Error updating step:', updateError);
        }
        
        // Set the current step
        setCurrentStep(onboardingData?.current_step || 'business_knowledge.service_details');
        
        // Get the business type directly from the first location (it should be the same for all)
        const businessTypeStr = locationsData?.[0]?.business_type || businessType;
        
        // Fetch type ID based on business type name
        const { data: typeData, error: typeError } = await supabase
          .from('onboarding_types')
          .select('id')
          .eq('name', businessTypeStr)
          .single();
          
        if (typeError) throw typeError;
        
        const typeId = typeData?.id;
        
        // Fetch services for the selected type using a single JOIN query
        const { data: serviceData, error: serviceError } = await supabase
          .from('onboarding_services_mappings')
          .select(`
            service_id,
            services:onboarding_services(*)
          `)
          .eq('type_id', typeId);
          
        if (serviceError) throw serviceError;
        
        // Extract the services from the nested structure
        const servicesData = serviceData
          .map(item => item.services)
          .filter(Boolean) // Remove any null entries
          .sort((a, b) => a.name.localeCompare(b.name)); // Sort by name
        
        // Set the data
        setLocations(locationsData || []);
        setServices(servicesData || []);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLocations([]);
        setServices([]);
        // Don't automatically navigate away - we'll show an error UI instead
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, [supabase, location.state, businessType]);
  
  const handleBack = () => {
    navigate('/onboarding/business-knowledge');
  };
  
  const handleComplete = async (serviceDetails) => {
    try {
      // Get the current user
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      
      if (userError) throw userError;
      
      if (user) {
        // The metadataToKnowledgeBase function and update to business_workflows 
        // should already be handled in ServiceDetailsManager, but we'll navigate 
        // to the next section here as a fallback
        
        console.log("ServiceDetailsManager completed - navigating to business workflows");
        
        // Navigate to business workflows section
        navigate('/onboarding/business-workflows');
      }
    } catch (error) {
      console.error("Error in handle complete:", error);
    }
  };
  
  const handleSelectStep = (step) => {
    // Redirect to the appropriate step
    if (step === 'business_knowledge.business_type') {
      navigate('/onboarding/business-knowledge#business-type');
    } else if (step === 'business_knowledge.services') {
      navigate('/onboarding/business-knowledge#services');
    } else if (step === 'business_knowledge.locations') {
      navigate('/onboarding/business-knowledge#locations');
    } else if (step === 'business_knowledge.review') {
      navigate('/onboarding/business-knowledge#review');
    }
  };
  
  if (isLoading) {
    // Return null instead of showing a loading spinner
    return null;
  }
  
  // If we don't have any locations or services, show an error
  if (locations.length === 0 || services.length === 0) {
    return (
      <div className="flex h-screen">
        <OnboardingSideNav currentStep={currentStep} onSelectStep={handleSelectStep} />
        <div className="flex flex-col items-center justify-center flex-1">
          <div className="text-xl text-gray-700 mb-4">
            No locations or services found. Please complete the previous steps first.
          </div>
          <button
            onClick={() => navigate('/onboarding/business-knowledge')}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }
  
  return (
    <div className="flex min-h-screen bg-gray-50">
      <OnboardingSideNav currentStep={currentStep} onSelectStep={handleSelectStep} />
      <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6 lg:px-8 flex justify-center">
        <div className="w-full max-w-[800px] pt-[50px]">
          <ServiceDetailsManager
            locations={locations}
            services={services}
            onComplete={handleComplete}
            onBack={handleBack}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailsPage; 