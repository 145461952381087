import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion } from 'framer-motion';
import { 
  Clock, 
  DollarSign, 
  Users, 
  ShieldCheck, 
  Truck, 
  Plus, 
  Trash,
  ChevronDown,
  X,
  Edit,
  Percent
} from 'lucide-react';
import { cn } from '../../lib/utils';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Textarea } from '../../components/ui/text-area';
import { Switch } from '../../components/ui/switch';
import { Label } from "../../components/ui/label";
import { Checkbox } from "../../components/ui/checkbox";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../components/ui/tabs";
import ScrollableTabs from './ScrollableTabs';
import ExtrasModal from './ExtrasModal';
import SimpleDCDropOff from './SimpleDCDropOff';
import SimpleDCPickupDelivery from './SimpleDCPickupDelivery';

/**
 * A component for configuring Dry Cleaning service details
 * for use within the ServiceDetailsManager component
 */
const DryCleaningPanel = ({ 
  locationData, 
  serviceData = {},
  onUpdate 
}) => {
  // Set up state for the dry cleaning data
  const [dryCleaningData, setDryCleaningData] = useState(serviceData || {
    enabled: true,
    dropOff: {
      enabled: true
    },
    pickupDelivery: {
      enabled: true
    }
  });
  const [activeTab, setActiveTab] = useState('general');
  const [serviceType, setServiceType] = useState('drop-off');
  
  // State for extras modal
  const [extrasModalType, setExtrasModalType] = useState(null);
  const [showExtrasModal, setShowExtrasModal] = useState(false);
  
  // Add a ref to store previous metadata for comparison
  const prevMetadataRef = useRef(null);
  
  // Add a reference to store the timeout for debouncing parent updates
  const updateParentTimeoutRef = useRef(null);
  
  // Add state for tracking tab transitions
  const [isTabTransitioning, setIsTabTransitioning] = useState(false);
  
  // Create refs to store the completion status of each tab
  const dropOffStatusRef = useRef(false);
  const pickupDeliveryStatusRef = useRef(false);
  
  // Add at the top with other state variables
  const [isLoadingTab, setIsLoadingTab] = useState(true);
  const [fieldVisibility, setFieldVisibility] = useState({
    pricing: false,
    schedule: false,
    instructions: false,
    discounts: false,
    extras: false
  });
  
  // Add state to preload both child components
  const [isDropOffPreloaded, setIsDropOffPreloaded] = useState(false);
  const [isPickupDeliveryPreloaded, setIsPickupDeliveryPreloaded] = useState(false);
  
  // Debounce function to prevent too many database calls
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  
  // Function to update the database
  const updateDatabase = async (metadata) => {
    try {
      if (!locationData?.id) {
        return;
      }
      
      // Here you would add your database update logic
      // Similar to other panel components
    } catch (err) {
    }
  };
  
  // Create a debounced version of updateDatabase
  const debouncedUpdateDatabase = useCallback(
    debounce(updateDatabase, 1000),
    [updateDatabase]
  );
  
  // Required fields tracking
  const [requiredFieldsStatus, setRequiredFieldsStatus] = useState({
    general: true,  // Service is enabled by default
    pricing: false,
    schedule: false,
    instructions: false,
    discounts: true, // Optional unless enabled
    extras: true     // Optional unless enabled
  });
  
  // Add an effect to check initial state of dropOff and pickupDelivery
useEffect(() => {
    // Check if dropOff data already indicates completion
    if (dryCleaningData.dropOff?.requirementsToAdvance === true) {
      dropOffStatusRef.current = true;
      setRequiredFieldsStatus(prev => ({
        ...prev,
        pricing: true,
        schedule: true,
        instructions: true
      }));
    }
    
    // Check if pickupDelivery data already indicates completion
    if (dryCleaningData.pickupDelivery?.requirementsToAdvance === true) {
      pickupDeliveryStatusRef.current = true;
      setRequiredFieldsStatus(prev => ({
        ...prev,
        pricing: true,
        schedule: true,
        instructions: true
      }));
    }
    
    // Also check if serviceData itself already indicates completion
    if (serviceData.requirementsToAdvance === true) {
      // Consider both tabs complete if the overall service is complete
      dropOffStatusRef.current = true;
      pickupDeliveryStatusRef.current = true;
      setRequiredFieldsStatus(prev => ({
        ...prev,
        pricing: true,
        schedule: true,
        instructions: true
      }));
    }
  }, []); // Only run on mount
  
  // Add effect to update refs whenever dryCleaningData changes
  useEffect(() => {
    // Update dropOff completion status in ref (but don't reset to false if it was true)
    if (dryCleaningData.dropOff?.requirementsToAdvance === true) {
      dropOffStatusRef.current = true;
    }
    
    // Update pickupDelivery completion status in ref (but don't reset to false if it was true)
    if (dryCleaningData.pickupDelivery?.requirementsToAdvance === true) {
      pickupDeliveryStatusRef.current = true;
    }
    
    // Force-trigger update to parent after any dryCleaningData change
    if (updateParentTimeoutRef.current) {
      clearTimeout(updateParentTimeoutRef.current);
    }
    
    // Immediately signal to the parent that the data has changed
    updateParentTimeoutRef.current = setTimeout(() => {
      // If either tab is complete, inform the parent
      if (dropOffStatusRef.current || pickupDeliveryStatusRef.current) {
        // This will trigger the main useEffect with the debounce
        setRequiredFieldsStatus(prev => ({ ...prev })); 
      }
    }, 50);
  }, [dryCleaningData]);
  
  // Add a new effect to preserve completion status on tab switch
  useEffect(() => {
    // When service type changes, make sure we don't lose completion status
    if (serviceType === 'drop-off' && dryCleaningData.dropOff?.requirementsToAdvance === true) {
      setRequiredFieldsStatus(prev => ({
        ...prev,
        pricing: true,
        schedule: true,
        instructions: true
      }));
    } else if (serviceType === 'pickup-delivery' && dryCleaningData.pickupDelivery?.requirementsToAdvance === true) {
      setRequiredFieldsStatus(prev => ({
        ...prev,
        pricing: true,
        schedule: true,
        instructions: true
      }));
    }
  }, [serviceType, dryCleaningData.dropOff?.requirementsToAdvance, dryCleaningData.pickupDelivery?.requirementsToAdvance]);
  
  // Effect to update parent component when data changes - with debounce
  useEffect(() => {
    // Clear any existing timeout to debounce rapid changes
    if (updateParentTimeoutRef.current) {
      clearTimeout(updateParentTimeoutRef.current);
    }
    
    // Set a new timeout to debounce the update
    updateParentTimeoutRef.current = setTimeout(() => {
      // Explicitly check both tab completion statuses from refs
      const dropOffComplete = dropOffStatusRef.current || dryCleaningData.dropOff?.requirementsToAdvance === true;
      const pickupDeliveryComplete = pickupDeliveryStatusRef.current || dryCleaningData.pickupDelivery?.requirementsToAdvance === true;
      
      // Service is complete if EITHER tab is complete (not both required)
      const serviceComplete = dropOffComplete || pickupDeliveryComplete;
      
      // Check if all required fields are complete from the state (as a backup)
      const allFieldsComplete = Object.entries(requiredFieldsStatus)
    .filter(([field, isComplete]) => field !== 'other') // Exclude optional fields
    .every(([_, isComplete]) => isComplete === true);

      // Use the most permissive completion status (either method shows completion)
      const allRequiredFieldsComplete = serviceComplete || allFieldsComplete;

      // Create the metadata structure
      const fullMetadata = {
        services: {
          "3": {  // Assuming service ID 3 is for Dry Cleaning
            enabled: dryCleaningData.enabled !== false,
            dropOff: {
              ...dryCleaningData.dropOff || {},
              requirementsToAdvance: dropOffComplete
            },
            pickupDelivery: {
              ...dryCleaningData.pickupDelivery || {},
              requirementsToAdvance: pickupDeliveryComplete
            },
            // Also include the completion status at this level
            requirementsToAdvance: allRequiredFieldsComplete
          }
        }
      };

      // Update the parent with both the full dryCleaningData and the metadata
  onUpdate({
        ...dryCleaningData,
    requirementsToAdvance: allRequiredFieldsComplete, // This must be true when all fields are complete
    metadata: fullMetadata
  });
  
      // Check if metadata has changed to avoid unnecessary database updates
      if (prevMetadataRef.current === null || 
          JSON.stringify(prevMetadataRef.current) !== JSON.stringify(fullMetadata)) {
        
        // Save the new metadata for comparison
        prevMetadataRef.current = fullMetadata;
        
        // Update the database if location data is available
        if (locationData?.id) {
          debouncedUpdateDatabase(fullMetadata);
        }
      }
    }, 300); // 300ms debounce to stabilize updates
    
    // Cleanup function to clear the timeout if the component unmounts
    return () => {
      if (updateParentTimeoutRef.current) {
        clearTimeout(updateParentTimeoutRef.current);
      }
    };
  }, [dryCleaningData, requiredFieldsStatus, onUpdate, locationData, debouncedUpdateDatabase, serviceType]);
  
  // Format time for display
  const formatTime = (time24h) => {
    if (!time24h) return '';
    
    try {
      const [hours, minutes] = time24h.split(':');
      const hour = parseInt(hours, 10);
      const minute = parseInt(minutes, 10);
      
      const period = hour >= 12 ? 'PM' : 'AM';
      const hour12 = hour % 12 || 12;
      
      return `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
    } catch (e) {
      return time24h;
    }
  };
  
  // Initialize business hours
  const initializeBusinessHours = () => {
    if (dryCleaningData.businessHours) return dryCleaningData.businessHours;
    
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const hours = {};
    
    days.forEach(day => {
      hours[day] = {
        isOpen: true,
        openTime: '09:00',
        closeTime: '17:00'
      };
    });
    
    return hours;
  };
  
  // Handle business hours update
  const handleHoursUpdate = (day, field, value) => {
    const businessHours = dryCleaningData.businessHours || initializeBusinessHours();
    
    setDryCleaningData(prev => ({
      ...prev,
      businessHours: {
        ...businessHours,
        [day]: {
          ...businessHours[day],
          [field]: value
        }
      }
    }));
  };
  
  // Handle pricing updates
  const handlePricingItemsUpdate = (items) => {
    setDryCleaningData(prev => ({
      ...prev,
      pricing: {
        ...prev.pricing,
        items
      }
    }));
    
    // Update required fields status
    setRequiredFieldsStatus(prev => ({
      ...prev,
      pricing: items && items.length > 0
    }));
  };

  // Handle minimum order update
  const handleMinimumOrderUpdate = (updates) => {
    setDryCleaningData(prev => ({
      ...prev,
      delivery: {
        ...prev.delivery,
        ...updates
      }
    }));
  };
  
  // Handle turnaround time update
  const handleTurnaroundUpdate = (updates) => {
    setDryCleaningData(prev => ({
      ...prev,
      turnaround: {
        ...prev.turnaround,
        ...updates
      }
    }));
  };
  
  // Handle schedule updates
  const handleScheduleUpdate = (updates) => {
    setDryCleaningData(prev => ({
      ...prev,
      schedule: {
        ...prev.schedule,
        ...updates
      }
    }));
    
    // Update schedule completion status
    const hasTimeWindows = updates.timeWindows && 
      Object.values(updates.timeWindows).some(windows => 
        Array.isArray(windows) && windows.length > 0
      );
    
    setRequiredFieldsStatus(prev => ({
      ...prev,
      schedule: hasTimeWindows
    }));
  };
  
  // Handle business info updates
  const handleDataChange = (field, value) => {
    setDryCleaningData(prev => ({
      ...prev,
      [field]: value
    }));
  };
  
  // Handle discounts updates
  const handleDiscountsUpdate = (updates) => {
    setDryCleaningData(prev => ({
      ...prev,
      discounts: {
        ...prev.discounts,
        ...updates
      }
    }));
    
    // Update required field status
    if ('enabled' in updates) {
      setRequiredFieldsStatus(prev => ({
        ...prev,
        discounts: !updates.enabled || validateDiscounts(dryCleaningData.discounts || {})
      }));
    }
  };
  
  // Handle recurring discount update (for first order, second order, etc.)
  const handleRecurringDiscountUpdate = (type, updates) => {
    setDryCleaningData(prev => {
      const discounts = prev.discounts || {};
      const recurring = discounts.recurring || {};
      
      return {
        ...prev,
        discounts: {
          ...discounts,
          recurring: {
            ...recurring,
            [type]: {
              ...(recurring[type] || {}),
              ...updates
            }
          }
        }
      };
    });
    
    // Validate discounts after update
    setTimeout(() => {
      setRequiredFieldsStatus(prev => ({
        ...prev,
        discounts: !dryCleaningData.discounts?.enabled || validateDiscounts(dryCleaningData.discounts || {})
      }));
    }, 0);
  };
  
  // Validate discounts
  const validateDiscounts = (discounts) => {
    if (!discounts.enabled) return true;
    
    const recurring = discounts.recurring || {};
    const types = ['firstOrder', 'secondOrder', 'thirdOrder', 'fourthOrder', 'fifthOrder'];
    
    // For enabled discount options, check if they have a rate
    return !types.some(type => 
      recurring[type]?.enabled && !recurring[type]?.rate
    );
  };
  
  // Handle extras updates
  const handleExtrasUpdate = (type, updates) => {
    setDryCleaningData(prev => {
      const extras = prev.extras || {};
      
      return {
        ...prev,
        extras: {
          ...extras,
          [type]: {
            ...(extras[type] || {}),
            ...updates
          }
        }
      };
    });
    
    // Update required field status for extras
    setTimeout(() => {
      const extras = dryCleaningData.extras || {};
      const addons = extras.addons || {};
      
      const extrasComplete = !addons.enabled || 
        (addons.items && addons.items.length > 0);
      
      setRequiredFieldsStatus(prev => ({
        ...prev,
        extras: extrasComplete
      }));
    }, 0);
  };
  
  // Handle instructions update
  const handleInstructionsUpdate = (updates) => {
    setDryCleaningData(prev => ({
      ...prev,
      instructions: {
        ...prev.instructions,
        ...updates
      }
    }));
    
    // Update required field status
    if ('firstPickup' in updates) {
      setRequiredFieldsStatus(prev => ({
        ...prev,
        instructions: !!updates.firstPickup && updates.firstPickup.trim() !== ''
      }));
    }
  };
  
  // Open extras modal
  const openExtrasModal = (type) => {
    setExtrasModalType(type);
    setShowExtrasModal(true);
  };
  
  // Close extras modal
  const closeExtrasModal = () => {
    setShowExtrasModal(false);
  };
  
  // Handle save extras items
  const handleSaveExtrasItems = (items) => {
    if (extrasModalType === 'pricing') {
      handlePricingItemsUpdate(items);
    } else if (extrasModalType === 'addon') {
      handleExtrasUpdate('addons', { items });
    }
  };
  
  // Modify the handleServiceTypeChange function to make transitions immediate
  const handleServiceTypeChange = (newType) => {
    if (newType === serviceType) return; // Don't process if no actual change
    
    // Store current tab's completion status in the appropriate ref
    if (serviceType === 'drop-off') {
      dropOffStatusRef.current = dryCleaningData.dropOff?.requirementsToAdvance === true;
    } else if (serviceType === 'pickup-delivery') {
      pickupDeliveryStatusRef.current = dryCleaningData.pickupDelivery?.requirementsToAdvance === true;
    }
    
    // Apply the new tab type immediately without transitions
    setServiceType(newType);
    
    // No animations or delays - just a direct update
    if (newType === 'drop-off' && dropOffStatusRef.current) {
      setRequiredFieldsStatus(prev => ({
      ...prev,
        pricing: true,
        schedule: true,
        instructions: true
      }));
    } else if (newType === 'pickup-delivery' && pickupDeliveryStatusRef.current) {
      setRequiredFieldsStatus(prev => ({
        ...prev,
        pricing: true,
        schedule: true,
        instructions: true
      }));
    }
    
    // Update field visibility immediately
    if (newType === 'drop-off') {
      // Only show indicators for incomplete fields in drop-off tab
      const isComplete = dropOffStatusRef.current || dryCleaningData.dropOff?.requirementsToAdvance === true;
      setFieldVisibility({
        pricing: !isComplete,
        schedule: !isComplete,
        instructions: !isComplete,
        discounts: false,
        extras: false
      });
    } else {
      // Only show indicators for incomplete fields in pickup-delivery tab
      const isComplete = pickupDeliveryStatusRef.current || dryCleaningData.pickupDelivery?.requirementsToAdvance === true;
      setFieldVisibility({
        pricing: !isComplete,
        schedule: !isComplete,
        instructions: !isComplete,
        discounts: false,
        extras: false
      });
    }
  };
  
  // Add this effect to control visibility on mount and tab switches
  useEffect(() => {
    // On initial mount, don't show any indicators until loaded
    if (isLoadingTab) return;
    
    if (serviceType === 'drop-off') {
      // Check if drop-off is complete
      const isComplete = dropOffStatusRef.current || dryCleaningData.dropOff?.requirementsToAdvance === true;
      
      // Only show indicators for incomplete fields in drop-off tab
      setFieldVisibility({
        pricing: !isComplete,
        schedule: !isComplete,
        instructions: !isComplete,
        discounts: false,
        extras: false
      });
    } else if (serviceType === 'pickup-delivery') {
      // Check if pickup-delivery is complete
      const isComplete = pickupDeliveryStatusRef.current || dryCleaningData.pickupDelivery?.requirementsToAdvance === true;
      
      // Only show indicators for incomplete fields in pickup-delivery tab
      setFieldVisibility({
        pricing: !isComplete,
        schedule: !isComplete,
        instructions: !isComplete,
        discounts: false,
        extras: false
      });
    }
  }, [serviceType, isLoadingTab]);

  // Initialize the refs with any existing completion status on mount
  useEffect(() => {
    dropOffStatusRef.current = dryCleaningData.dropOff?.requirementsToAdvance === true;
    pickupDeliveryStatusRef.current = dryCleaningData.pickupDelivery?.requirementsToAdvance === true;
  }, []);
  
  // Completely replace the handleDropOffDataChange function
  const handleDropOffDataChange = (dropOffData) => {
    // Check for requirementsToAdvance and store it in the ref immediately
    if (dropOffData.requirementsToAdvance === true) {
      dropOffStatusRef.current = true;
      
      // CRITICAL: Immediately notify parent of completion without waiting for debounce
      setTimeout(() => {
        onUpdate({
          ...dryCleaningData,
          requirementsToAdvance: true,
          metadata: {
            services: {
              "3": {
                enabled: dryCleaningData.enabled !== false,
                dropOff: { ...dropOffData, requirementsToAdvance: true },
                pickupDelivery: dryCleaningData.pickupDelivery || {},
                requirementsToAdvance: true
              }
            }
          }
        });
      }, 0);
    }
    
    // Update the data state with the previous dropOff data preserved
    setDryCleaningData(prev => {
      const newDropOff = {
        ...dropOffData,
        // If new data shows completion, preserve it, otherwise check the ref and the previous state
        requirementsToAdvance: dropOffData.requirementsToAdvance === true ? 
          true : 
          (dropOffStatusRef.current || prev.dropOff?.requirementsToAdvance === true)
      };
      
      return {
        ...prev,
        dropOff: newDropOff
      };
    });

    // Always update field status if we're on the drop-off tab and it's reporting completion
    if (serviceType === 'drop-off' && (dropOffData.requirementsToAdvance === true || dropOffStatusRef.current)) {
      setRequiredFieldsStatus(prev => {
        // Only update if not already all complete
        if (!prev.pricing || !prev.schedule || !prev.instructions) {
          return {
            ...prev,
            pricing: true,
            schedule: true,
            instructions: true
          };
        }
        return prev;
      });
    }
  };
  
  // Similarly completely replace the pickup delivery handler
  const handlePickupDeliveryDataChange = (pickupDeliveryData) => {
    // Check for requirementsToAdvance and store it in the ref immediately
    if (pickupDeliveryData.requirementsToAdvance === true) {
      pickupDeliveryStatusRef.current = true;
      
      // CRITICAL: Immediately notify parent of completion without waiting for debounce
      setTimeout(() => {
        onUpdate({
          ...dryCleaningData,
          requirementsToAdvance: true,
          metadata: {
            services: {
              "3": {
                enabled: dryCleaningData.enabled !== false,
                dropOff: dryCleaningData.dropOff || {},
                pickupDelivery: { ...pickupDeliveryData, requirementsToAdvance: true },
                requirementsToAdvance: true
              }
            }
          }
        });
      }, 0);
    }
    
    // Update the data state with the previous pickupDelivery data preserved
    setDryCleaningData(prev => {
      const newPickupDelivery = {
        ...pickupDeliveryData,
        // If new data shows completion, preserve it, otherwise check the ref and the previous state
        requirementsToAdvance: pickupDeliveryData.requirementsToAdvance === true ? 
          true : 
          (pickupDeliveryStatusRef.current || prev.pickupDelivery?.requirementsToAdvance === true)
      };
      
      const updatedData = {
        ...prev,
        pickupDelivery: newPickupDelivery
      };
      
      // If pickupDelivery has metadata, ensure it's preserved at the top level too
      if (pickupDeliveryData.metadata) {
        updatedData.metadata = {
          ...(prev.metadata || {}),
          ...pickupDeliveryData.metadata
        };
      }
      
      return updatedData;
    });
    
    // Always update field status if we're on the pickup-delivery tab and it's reporting completion
    if (serviceType === 'pickup-delivery' && (pickupDeliveryData.requirementsToAdvance === true || pickupDeliveryStatusRef.current)) {
      setRequiredFieldsStatus(prev => {
        // Only update if not already all complete
        if (!prev.pricing || !prev.schedule || !prev.instructions) {
          return {
      ...prev,
            pricing: true,
            schedule: true,
            instructions: true
          };
        }
        return prev;
      });
    }
  };
  
  // Render General tab content
  const renderGeneralContent = () => (
    <div className="space-y-6">
      {/* Service Settings section removed as requested */}
    </div>
  );
  
  // Render Pricing tab content
  const renderPricingContent = () => {
    const pricing = dryCleaningData.pricing || {};
    const delivery = dryCleaningData.delivery || {};
    const items = pricing.items || [];
    
    return (
      <div className="space-y-6">
        {/* Order Minimum Section */}
        <div className="p-4 rounded-lg border border-gray-200 bg-gray-50">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <DollarSign className="w-5 h-5 text-gray-500" />
              <span className="font-medium">Order Minimum</span>
            </div>
            <Switch
              checked={delivery.minimumOrderEnabled || false}
              onCheckedChange={(checked) => handleMinimumOrderUpdate({ minimumOrderEnabled: checked })}
            />
          </div>
          
          {delivery.minimumOrderEnabled && (
            <div className="mt-4 space-y-2">
              <Label htmlFor="min-order-amount">Minimum Order Amount ($)</Label>
              <Input
                id="min-order-amount"
                value={delivery.minimumOrder || ''}
                onChange={(e) => handleMinimumOrderUpdate({ minimumOrder: e.target.value })}
                placeholder="e.g. 20.00"
                type="text"
                inputMode="decimal"
              />
            </div>
          )}
        </div>
        
        {/* Pricing Items Section */}
        <div className="p-4 rounded-lg border border-gray-200">
          <div className="flex justify-between items-center mb-3">
            <h3 className="font-medium">Dry Cleaning Price List</h3>
            {items.length > 0 && (
              <Button 
                variant="outline" 
                size="sm"
                onClick={() => openExtrasModal('pricing')}
                className="flex items-center gap-1"
              >
                <Edit className="w-3 h-3" />
                <span>Edit Items</span>
              </Button>
            )}
          </div>
          
          {items.length > 0 ? (
            <div className="space-y-2 max-h-80 overflow-y-auto pr-2">
              {items.map((item, index) => (
                <div 
                  key={index} 
                  className="flex justify-between items-center py-2 border-b border-gray-100 last:border-0"
                >
                  <span className="text-sm">{item.name}</span>
                  <span className="text-sm font-medium">${item.price}</span>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center border border-dashed border-gray-300 rounded-md p-6">
              <button 
                type="button"
                className="flex items-center gap-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                onClick={() => openExtrasModal('pricing')}
              >
                <Plus className="w-4 h-4" />
                <span>Add Price Items</span>
              </button>
            </div>
          )}
          
          {items.length === 0 && (
            <p className="text-xs text-gray-500 text-center mt-3">
              Click to add dress shirts, pants, suits, etc. with their prices
            </p>
          )}
        </div>
      </div>
    );
  };
  
  // Render Schedule tab content
  const renderScheduleContent = () => {
    const schedule = dryCleaningData.schedule || {};
    const timeWindows = schedule.timeWindows || {};
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    
    const handleAddTimeWindow = (day, startTime, endTime) => {
      const updatedTimeWindows = { ...timeWindows };
      if (!updatedTimeWindows[day]) {
        updatedTimeWindows[day] = [];
      }
      
      updatedTimeWindows[day].push({ startTime, endTime });
      handleScheduleUpdate({ timeWindows: updatedTimeWindows });
    };
    
    const handleRemoveTimeWindow = (day, index) => {
      const updatedTimeWindows = { ...timeWindows };
      if (updatedTimeWindows[day]) {
        updatedTimeWindows[day] = updatedTimeWindows[day].filter((_, i) => i !== index);
      }
      handleScheduleUpdate({ timeWindows: updatedTimeWindows });
    };
    
    return (
      <div className="space-y-6">
        <div className="p-4 rounded-lg border border-gray-200">
          <h3 className="font-medium mb-4">Dry Cleaning Service Schedule</h3>
          
          <div className="space-y-4">
            {days.map((day) => (
              <div key={day} className="space-y-2">
                <div className="flex justify-between items-center">
                  <span className="font-medium text-sm">{day}</span>
                  <div className="flex items-center gap-2">
                    <div className="relative">
                      <input
                        type="time"
                        value={schedule.addTimeWindow?.day === day ? schedule.addTimeWindow.startTime : '09:00'}
                        onChange={(e) => handleScheduleUpdate({
                          addTimeWindow: { day, startTime: e.target.value, endTime: schedule.addTimeWindow?.endTime || '17:00' }
                        })}
                        className="w-28 px-2 py-1 text-sm border border-gray-200 rounded"
                      />
                    </div>
                    <span className="text-gray-500">to</span>
                    <div className="relative">
                      <input
                        type="time"
                        value={schedule.addTimeWindow?.day === day ? schedule.addTimeWindow.endTime : '17:00'}
                        onChange={(e) => handleScheduleUpdate({
                          addTimeWindow: { day, startTime: schedule.addTimeWindow?.startTime || '09:00', endTime: e.target.value }
                        })}
                        className="w-28 px-2 py-1 text-sm border border-gray-200 rounded"
                      />
                    </div>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => handleAddTimeWindow(
                        day,
                        schedule.addTimeWindow?.day === day ? schedule.addTimeWindow.startTime : '09:00',
                        schedule.addTimeWindow?.day === day ? schedule.addTimeWindow.endTime : '17:00'
                      )}
                    >
                      Add
                    </Button>
                  </div>
                </div>
                
                {timeWindows[day] && timeWindows[day].length > 0 && (
                  <div className="space-y-2 ml-4">
                    {timeWindows[day].map((window, index) => (
                      <div key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded">
                        <span className="text-sm">
                          {formatTime(window.startTime)} - {formatTime(window.endTime)}
                        </span>
                        <Button
                          variant="ghost"
                          size="sm"
                          className="text-gray-500 hover:text-red-500 h-auto py-1"
                          onClick={() => handleRemoveTimeWindow(day, index)}
                        >
                          <X className="w-4 h-4" />
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  // Render Instructions tab content 
  const renderInstructionsContent = () => {
    const instructions = dryCleaningData.instructions || {};
    
    return (
      <div className="space-y-6">
        {/* First Pickup Instructions */}
        <div className="p-4 rounded-lg border border-gray-200">
          <h3 className="font-medium mb-3">Pickup Instructions</h3>
          <div className="space-y-3">
            <div>
              <Label htmlFor="first-pickup-instructions" className="text-sm">
                Instructions for First Pickup
              </Label>
              <Textarea
                id="first-pickup-instructions"
                value={instructions.firstPickup || ''}
                onChange={(e) => handleInstructionsUpdate({ firstPickup: e.target.value })}
                placeholder="Provide detailed instructions for the first pickup..."
                className="mt-1 h-24"
              />
            </div>
          </div>
        </div>
        
        {/* Handling Instructions */}
        <div className="p-4 rounded-lg border border-gray-200">
          <h3 className="font-medium mb-3">Handling Instructions</h3>
          <div className="space-y-3">
            <div>
              <Label htmlFor="general-instructions" className="text-sm">
                General Handling Instructions
              </Label>
              <Textarea
                id="general-instructions"
                value={instructions.handling || ''}
                onChange={(e) => handleInstructionsUpdate({ handling: e.target.value })}
                placeholder="Provide general instructions for handling dry cleaning items..."
                className="mt-1 h-24"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  // Render Discounts tab content
  const renderDiscountsContent = () => {
    const discounts = dryCleaningData.discounts || {};
    const recurring = discounts.recurring || {};
    
    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <Percent className="w-5 h-5 text-gray-400" />
            <div>
              <span className="text-sm font-medium text-gray-700">Offer Discounts</span>
              <p className="text-xs text-gray-500 mt-1">
                Configure recurring order discounts for loyal customers
              </p>
            </div>
          </div>
          <Switch 
            checked={discounts.enabled || false}
            onCheckedChange={(checked) => handleDiscountsUpdate({ enabled: checked })}
          />
        </div>

        {discounts.enabled && (
          <div className="space-y-6">
            <div className="p-4 rounded-lg border border-gray-200">
              <h3 className="font-medium mb-4">Recurring Order Discounts</h3>
              
              <div className="space-y-3">
                {[
                  { key: 'firstOrder', label: 'First Order' },
                  { key: 'secondOrder', label: 'Second Order' },
                  { key: 'thirdOrder', label: 'Third Order' },
                  { key: 'fourthOrder', label: 'Fourth Order' },
                  { key: 'fifthOrder', label: 'Fifth Order' }
                ].map((item) => {
                  const itemData = recurring[item.key] || {};
                  
                  return (
                    <div key={item.key} className="flex items-center gap-3">
                      <Switch
                        checked={itemData.enabled || false}
                        onCheckedChange={(checked) => 
                          handleRecurringDiscountUpdate(item.key, { enabled: checked })
                        }
                      />
                      <div className="flex items-center gap-2 flex-1">
                        <span className="text-sm">{item.label}</span>
                        {itemData.enabled && (
                          <div className="flex items-center ml-auto">
                            <Input
                              value={itemData.rate || ''}
                              onChange={(e) => 
                                handleRecurringDiscountUpdate(item.key, { rate: e.target.value })
                              }
                              placeholder="e.g. 10"
                              className="w-20 text-right"
                            />
                            <span className="text-sm ml-2">% off</span>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  
  // Render Extras tab content
  const renderExtrasContent = () => {
    const extras = dryCleaningData.extras || {};
    const addons = extras.addons || { enabled: false, items: [] };
    
    return (
      <div className="space-y-4">
        {/* Service Add-ons Section */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <Plus className="w-5 h-5 text-gray-400" />
            <div>
              <span className="text-sm font-medium text-gray-700">Service Add-ons</span>
              <p className="text-xs text-gray-500 mt-1">
                Additional services customers can add to their order (e.g. stain treatment, rush service)
              </p>
            </div>
          </div>
          <Switch 
            checked={addons.enabled || false} 
            onCheckedChange={(checked) => handleExtrasUpdate('addons', { enabled: checked })}
          />
        </div>
        
        <AnimatePresence>
          {addons.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
              className="p-4 rounded-lg bg-white border border-gray-200"
            >
              {addons.items && addons.items.length > 0 ? (
                <div className="space-y-3">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-sm font-medium">Service Add-ons ({addons.items.length})</span>
                    <Button 
                      variant="outline" 
                      size="sm"
                      onClick={() => openExtrasModal('addon')}
                      className="flex items-center gap-1"
                    >
                      <Edit className="w-3 h-3" />
                      <span>Edit Add-ons</span>
                    </Button>
                  </div>
                  <div className="max-h-36 overflow-y-auto pr-2">
                    {addons.items.map((item, index) => (
                      <div key={index} className="flex justify-between items-center py-2 border-b border-gray-100 last:border-0">
                        <span className="text-sm">{item.name}</span>
                        <div className="flex items-center">
                          <span className="text-sm font-medium">${item.price}</span>
                          <span className="text-xs text-gray-500 ml-1">/ {item.unit}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center border border-dashed border-gray-300 rounded-md p-6">
                  <button 
                    type="button"
                    className="flex items-center gap-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                    onClick={() => openExtrasModal('addon')}
                  >
                    <Plus className="w-4 h-4" />
                    <span>Add Add-on Service</span>
                  </button>
                </div>
              )}
              {!addons.items || addons.items.length === 0 && (
                <p className="text-xs text-gray-500 text-center mt-3">
                  Click to configure additional services customers can select
                </p>
              )}
            </motion.div>
          )}
        </AnimatePresence>
        
        {/* Modal for editing extras */}
        {showExtrasModal && (
          <ExtrasModal 
            open={showExtrasModal}
            onClose={closeExtrasModal}
            type={extrasModalType === 'pricing' ? 'pricing' : 'addon'}
            items={extrasModalType === 'pricing' ? dryCleaningData.pricing?.items || [] : addons.items || []}
            onSave={handleSaveExtrasItems}
          />
        )}
      </div>
    );
  };
  
  // Render Other Info tab content
  const renderOtherInfoContent = () => (
    <div className="space-y-6">
      <div>
        <Label htmlFor="special-instructions">Special instructions or notes</Label>
        <Textarea
          id="special-instructions"
          value={dryCleaningData.specialInstructions || ''}
          onChange={(e) => handleDataChange('specialInstructions', e.target.value)}
          placeholder="Any additional information about your Dry Cleaning service..."
          className="mt-1 h-32"
        />
      </div>
    </div>
  );
  
  // Add a preload effect that runs on component mount
  useEffect(() => {
    // Set a timeout to preload drop-off data first, then pickup-delivery
    const dropOffTimer = setTimeout(() => {
      setIsDropOffPreloaded(true);
      
      // After drop-off is loaded, load pickup-delivery
      const pickupDeliveryTimer = setTimeout(() => {
        setIsPickupDeliveryPreloaded(true);
      }, 300);
      
      return () => clearTimeout(pickupDeliveryTimer);
    }, 300);
    
    return () => clearTimeout(dropOffTimer);
  }, []);
  
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="mb-6">
        <Tabs defaultValue="drop-off" onValueChange={handleServiceTypeChange} value={serviceType}>
          <TabsList className="mb-4">
            <TabsTrigger value="drop-off">Drop Off</TabsTrigger>
            <TabsTrigger value="pickup-delivery">Pickup & Delivery</TabsTrigger>
          </TabsList>
          
          {/* Handle both tabs without animations */}
          <div>
            <div style={{ display: serviceType === 'drop-off' ? 'block' : 'none', transition: 'none' }}>
              {(isDropOffPreloaded || serviceType === 'drop-off') && (
            <SimpleDCDropOff 
                  data={{
                    ...dryCleaningData.dropOff || {},
                    requirementsToAdvance: dropOffStatusRef.current || dryCleaningData.dropOff?.requirementsToAdvance,
                    fieldVisibility: fieldVisibility
                  }}
              onChange={handleDropOffDataChange} 
              locationData={locationData}
            />
              )}
            </div>
          
            <div style={{ display: serviceType === 'pickup-delivery' ? 'block' : 'none', transition: 'none' }}>
              {(isPickupDeliveryPreloaded || serviceType === 'pickup-delivery') && (
            <SimpleDCPickupDelivery 
                  data={{
                    ...dryCleaningData.pickupDelivery || {},
                    requirementsToAdvance: pickupDeliveryStatusRef.current || dryCleaningData.pickupDelivery?.requirementsToAdvance,
                    fieldVisibility: fieldVisibility
                  }}
              onChange={handlePickupDeliveryDataChange}
              locationData={locationData}
            />
              )}
            </div>
          </div>
        </Tabs>
      </div>
      
      <div className="mt-6">
        {activeTab === 'general' && renderGeneralContent()}
        {activeTab === 'pricing' && renderPricingContent()}
        {activeTab === 'schedule' && renderScheduleContent()}
        {activeTab === 'instructions' && renderInstructionsContent()}
        {activeTab === 'discounts' && renderDiscountsContent()}
        {activeTab === 'extras' && renderExtrasContent()}
        {activeTab === 'other' && renderOtherInfoContent()}
      </div>
    </div>
  );
};

export default DryCleaningPanel; 