import React, { useState, useEffect, useCallback } from 'react';
import { getSupabaseClient } from '../../supabaseClient';

/**
 * CustomServicePanel component for handling any custom services.
 * This is a generic component that will be used for all custom services.
 * 
 * @param {Object} locationData - Data about the current location
 * @param {Object} serviceData - Data about this service at this location
 * @param {Function} onUpdate - Callback for when data is updated
 */
const CustomServicePanel = ({ 
  locationData, 
  serviceData = {},
  onUpdate 
}) => {
  const [customData, setCustomData] = useState(serviceData || {
    enabled: true,
    notes: ''
  });

  // Update parent when our state changes
  useEffect(() => {
    if (typeof onUpdate === 'function') {
      onUpdate({
        ...customData,
        requirementsToAdvance: true // Always allow advancement for custom services
      });
    }
  }, [customData, onUpdate]);

  // Load data from database when component mounts
  useEffect(() => {
    const loadLocationSettings = async () => {
      if (locationData?.id && serviceData?.id) {
        try {
          const businessType = locationData.business_type || 'laundromat';
          const supabase = getSupabaseClient(businessType);
          
          const { data, error } = await supabase
            .from('vapi_location_settings')
            .select('metadata')
            .eq('id', locationData.id)
            .maybeSingle();
            
          if (error) {
            return;
          }
          
          const serviceId = serviceData.id.toString();
          if (data?.metadata?.services?.[serviceId]?.notes) {
            setCustomData(prev => ({
              ...prev,
              notes: data.metadata.services[serviceId].notes
            }));
          }
        } catch (err) {
          console.log('Error loading custom service data:', err);
        }
      }
    };
    
    loadLocationSettings();
  }, [locationData, serviceData]);

  // Function to update database when data changes
  const updateDatabase = async (notes) => {
    if (!locationData?.id || !serviceData?.id) {
      return;
    }
    
    try {
      const businessType = locationData.business_type || 'laundromat';
      const supabase = getSupabaseClient(businessType);
      const serviceId = serviceData.id.toString();
      
      // First check if the record exists
      const { data: existingSettings, error: fetchError } = await supabase
        .from('vapi_location_settings')
        .select('metadata')
        .eq('id', locationData.id)
        .maybeSingle();
      
      if (fetchError) {
        return;
      }
      
      let result;
      
      // If record exists, update it
      if (existingSettings) {
        // Merge with existing metadata to preserve other services
        const existingMetadata = existingSettings.metadata || {};
        if (!existingMetadata.services) existingMetadata.services = {};
        
        // Prepare merged metadata preserving other services
        const mergedMetadata = {
          ...existingMetadata,
          services: {
            ...existingMetadata.services,
            [serviceId]: {
              ...(existingMetadata.services[serviceId] || {}),
              notes: notes
            }
          }
        };
        
        result = await supabase
          .from('vapi_location_settings')
          .update({ 
            metadata: mergedMetadata 
          })
          .eq('id', locationData.id);
      } else {
        // If record doesn't exist, create it
        const newMetadata = {
          services: {
            [serviceId]: {
              notes: notes
            }
          }
        };
        
        result = await supabase
          .from('vapi_location_settings')
          .insert({ 
            id: locationData.id,
            metadata: newMetadata
          });
      }
    } catch (err) {
      console.log('Error updating custom service data:', err);
    }
  };
  
  // Debounce function to prevent too many database calls
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };
  
  // Create a debounced version of updateDatabase
  const debouncedUpdateDatabase = useCallback(
    debounce((notes) => {
      if (locationData?.id) {
        updateDatabase(notes).catch(err => {
          console.log('Error in debounced update:', err);
        });
      }
    }, 1000), 
    [locationData, serviceData]
  );

  const handleNotesChange = (e) => {
    const newNotes = e.target.value;
    setCustomData(prev => ({
      ...prev,
      notes: newNotes
    }));
    
    // Update database with debounce
    debouncedUpdateDatabase(newNotes);
  };

  return (
    <div className="space-y-6 max-w-3xl mx-auto">
      {/* Information Box */}
      <div className="p-4 bg-blue-50 border border-blue-200 rounded-lg">
        <div className="flex items-start">
          <div className="flex-shrink-0 mt-0.5 mr-3">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
            </svg>
          </div>
          <div>
            <h3 className="text-sm font-medium text-blue-800">Custom Service Configuration</h3>
            <p className="mt-2 text-sm text-blue-700">
              You've added a custom service to your business. Use this space to describe how this service 
              works, including pricing, process, and any special requirements. This information will help 
              the AI assistant answer questions about this service accurately.
            </p>
          </div>
        </div>
      </div>

      {/* Notes Text Area */}
      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Service Information</h3>
        
        <div className="space-y-3">
          <label htmlFor="custom-service-notes" className="block text-sm font-medium text-gray-700">
            Describe your custom service in detail
          </label>
          <textarea
            id="custom-service-notes"
            className="w-full min-h-[200px] p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Please describe your custom service, including pricing, process, turnaround time, special handling requirements, etc."
            value={customData.notes || ''}
            onChange={handleNotesChange}
          />
          <p className="text-sm text-gray-500">
            The more details you provide, the better the AI assistant can answer customer questions about this service.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomServicePanel; 