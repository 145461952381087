import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  DollarSign, 
  Clock, 
  Calendar, 
  MapPin, 
  Truck, 
  Info, 
  Trash,
  Plus,
  Settings,
  ChevronDown,
  ChevronUp,
  Edit,
  X,
  Scale,
  Percent,
  CheckCircle,
  ShoppingBag
} from 'lucide-react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Switch } from '../ui/switch';
import { Textarea } from '../ui/text-area';
import { cn } from '../../lib/utils';
import ScrollableTabs from './ScrollableTabs';
import { BlackSwitch } from './SimpleWDFDropOff';
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle, 
  DialogFooter,
  DialogClose
} from '../ui/dialog';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '../ui/collapsible';
import { 
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent
} from '../ui/accordion';
import ExtrasModal from './ExtrasModal';
import { getSupabaseClient } from '../../supabaseClient';

/**
 * A simplified Pickup & Delivery component for Wash & Fold service
 */
const SimpleWDFPickupDelivery = ({ data = {}, onChange, locationData, fieldVisibility = {} }) => {
  // Set default enabled value to true
  const [pudData, setPudData] = useState(() => {
    // If empty data is provided, start with defaults
    if (!data || Object.keys(data).length === 0) {
      return {
        enabled: true,
        pricing: {
          baseRate: '',
          minimumOrder: '',
          deliveryFee: ''
        }
      };
    }
    return data;
  });

  // Track active zone for pricing/scheduling
  const [activeZoneForPricing, setActiveZoneForPricing] = useState(null);
  const [activeZoneForSchedule, setActiveZoneForSchedule] = useState(null);
  // Track active zone for turnaround times
  const [activeZoneId, setActiveZoneId] = useState(null);

  const [activeTab, setActiveTab] = useState('general');
  const [zipcode, setZipcode] = useState('');
  
  // Zone modal state
  const [isAddZoneOpen, setIsAddZoneOpen] = useState(false);
  const [zoneToEdit, setZoneToEdit] = useState(null);
  const [zoneFormData, setZoneFormData] = useState({
    name: '',
    zipcodes: ''
  });
  
  const [rawInputValues, setRawInputValues] = useState({
    regularPerPound: '',
    sameDayPrice: '',
    deliveryFee: '',
    minimumAmount: '',
    zoneRegularPerPound: {},
    zoneSameDayPrice: {},
    zoneDeliveryFee: {},
    zoneMinimumOrder: {},
    weeklyRate: '',
    biweeklyRate: '',
    monthlyRate: ''
  });

  // For time window management
  const [newWindow, setNewWindow] = useState({
    day: '',
    startTime: '',
    endTime: ''
  });

  // Required fields tracking
  const [requiredFieldsStatus, setRequiredFieldsStatus] = useState({
    general: true,
    serviceAreas: false,
    pricing: false,
    turnaround: false,
    schedule: false,
    instructions: false,
    discounts: true, // Conditionally required based on toggle
    extras: true     // Conditionally required based on toggle
  });

  // State for zone modal
  const [showZoneModal, setShowZoneModal] = useState(false);
  const [editingZone, setEditingZone] = useState(null);
  // State for delete confirmation modal
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [zoneToDelete, setZoneToDelete] = useState(null);

  // State for extras modal
  const [extrasModalType, setExtrasModalType] = useState(null);
  const [showExtrasModal, setShowExtrasModal] = useState(false);
  
  // Previous metadata ref to prevent unnecessary database updates
  const prevMetadataRef = useRef(null);
  
  // Function to update the database directly
  const updateDatabase = async (metadata) => {
    try {
      // Only proceed if we have locationData with an id
      if (!locationData || !locationData.id) {
        console.warn('Cannot update database: missing location ID');
        return;
      }
      
      console.log("SimpleWDFPickupDelivery - Database update happening");
      
      // Get the Supabase client - use business type from location data if available
      const businessType = locationData.business_type || 'laundromat';
      const supabase = getSupabaseClient(businessType);
      
      // First check if the record exists
      const { data: existingSettings, error: fetchError } = await supabase
        .from('vapi_location_settings')
        .select('id, metadata')
        .eq('id', locationData.id)
        .maybeSingle();
      
      if (fetchError) {
        console.error('Error checking location settings:', fetchError);
        return;
      }
      
      // If record exists, update it
      if (existingSettings) {
        // Merge with existing metadata to preserve other services
        const existingMetadata = existingSettings.metadata || {};
        if (!existingMetadata.services) existingMetadata.services = {};
        
        // Prepare merged metadata preserving other services
        const mergedMetadata = {
          ...existingMetadata,
          services: {
            ...existingMetadata.services,
            "2": {
              ...(existingMetadata.services["2"] || {}),
              pickupDelivery: metadata.services["2"].pickupDelivery
            }
          }
        };
        
        const result = await supabase
          .from('vapi_location_settings')
          .update({
            metadata: mergedMetadata
          })
          .eq('id', locationData.id);
        
        if (result && result.error) {
          console.error('Error updating location settings:', result.error);
        }
      } else {
        // If no record exists, create one
        const result = await supabase
          .from('vapi_location_settings')
          .insert({
            id: locationData.id,
            metadata: metadata
          });
        
        if (result && result.error) {
          console.error('Error creating location settings:', result.error);
        }
      }
    } catch (error) {
      console.error('Failed to update location settings:', error);
    }
  };
  
  // Function to transform pudData to metadata format
  const transformToMetadata = (data) => {
    // Start with an empty metadata object
    const metadata = {};
    
    // Explicitly remove fieldVisibility to prevent it from being saved to the database
    const cleanData = { ...data };
    if (cleanData.fieldVisibility) {
      delete cleanData.fieldVisibility;
    }
    
    // Only populate enabled field if service is disabled
    if (!cleanData.enabled) {
      metadata['enabled'] = false;
      return metadata;
    }
    
    // General tab
    metadata['enabled'] = cleanData.enabled;
    metadata['type'] = 'pickup_and_delivery';
    
    // Pricing tab
    if (cleanData.pricing) {
      metadata['pricing'] = metadata['pricing'] || {};
      metadata['pricing']['regular_price_per_pound'] = cleanData.pricing.regularPerPound || null;
      
      // Same day service
      if (cleanData.pricing.sameDay) {
        metadata['same_day'] = metadata['same_day'] || {};
        metadata['same_day']['enabled'] = !!cleanData.pricing.sameDay.enabled;
        metadata['same_day']['price_per_pound'] = cleanData.pricing.sameDay.pricePerPound || null;
        metadata['same_day']['cutoff_time'] = cleanData.pricing.sameDay.cutoff || null;
        metadata['same_day']['turnaround_hours'] = cleanData.pricing.sameDay.turnaroundHours || null;
      }
    }
    
    // Delivery fee
    if (cleanData.delivery) {
      metadata['pricing'] = metadata['pricing'] || {};
      metadata['pricing']['delivery_fee'] = cleanData.delivery.fee || null;
      
      // Minimum order
      if (cleanData.delivery.minimumOrder) {
        metadata['pricing']['minimum_order'] = metadata['pricing']['minimum_order'] || {};
        metadata['pricing']['minimum_order']['enabled'] = !!cleanData.delivery.minimumOrder.enabled;
        metadata['pricing']['minimum_order']['is_dollar_based'] = !!cleanData.delivery.minimumOrder.isDollarBased;
        metadata['pricing']['minimum_order']['amount'] = cleanData.delivery.minimumOrder.amount || null;
      }
    }
    
    // Turnaround tab
    if (cleanData.turnaround) {
      metadata['turnaround'] = metadata['turnaround'] || {};
      metadata['turnaround']['standard_hours'] = cleanData.turnaround.standardHours || null;
    }
    
    // Schedule tab
    if (cleanData.schedule && cleanData.schedule.timeWindows) {
      metadata['schedule'] = metadata['schedule'] || {};
      metadata['schedule']['time_windows'] = {};
      
      Object.entries(cleanData.schedule.timeWindows).forEach(([day, windows]) => {
        if (windows && windows.length > 0) {
          metadata['schedule']['time_windows'][day.toLowerCase()] = windows.map(window => ({
            start_time: window.startTime,
            end_time: window.endTime
          }));
        }
      });
    }
    
    // Instructions tab
    if (cleanData.instructions) {
      metadata['instructions'] = metadata['instructions'] || {};
      metadata['instructions']['first_pickup'] = cleanData.instructions.firstPickup || null;
    }
    
    // Discounts tab
    if (cleanData.discounts) {
      metadata['discounts'] = metadata['discounts'] || {};
      metadata['discounts']['enabled'] = !!cleanData.discounts.enabled;
      
      if (cleanData.discounts.recurring) {
        metadata['discounts']['recurring'] = metadata['discounts']['recurring'] || {};
        const recurring = cleanData.discounts.recurring;
        
        if (recurring.weekly) {
          metadata['discounts']['recurring']['weekly'] = metadata['discounts']['recurring']['weekly'] || {};
          metadata['discounts']['recurring']['weekly']['enabled'] = !!recurring.weekly.enabled;
          metadata['discounts']['recurring']['weekly']['rate'] = recurring.weekly.rate || null;
        }
        
        if (recurring.biweekly) {
          metadata['discounts']['recurring']['biweekly'] = metadata['discounts']['recurring']['biweekly'] || {};
          metadata['discounts']['recurring']['biweekly']['enabled'] = !!recurring.biweekly.enabled;
          metadata['discounts']['recurring']['biweekly']['rate'] = recurring.biweekly.rate || null;
        }
        
        if (recurring.monthly) {
          metadata['discounts']['recurring']['monthly'] = metadata['discounts']['recurring']['monthly'] || {};
          metadata['discounts']['recurring']['monthly']['enabled'] = !!recurring.monthly.enabled;
          metadata['discounts']['recurring']['monthly']['rate'] = recurring.monthly.rate || null;
        }
      }
    }
    
    // Extras tab
    if (cleanData.extras) {
      metadata['extras'] = metadata['extras'] || {};
      
      // Specialty items
      if (cleanData.extras.specialtyItems) {
        metadata['extras']['specialty_items'] = metadata['extras']['specialty_items'] || {};
        metadata['extras']['specialty_items']['enabled'] = !!cleanData.extras.specialtyItems.enabled;
        if (cleanData.extras.specialtyItems.items && cleanData.extras.specialtyItems.items.length > 0) {
          metadata['extras']['specialty_items']['items'] = cleanData.extras.specialtyItems.items.map(item => ({
            name: item.name,
            price: item.price,
            charge_by_weight: !!item.chargeByWeight
          }));
        }
      }
      
      // Add-ons
      if (cleanData.extras.addons) {
        metadata['extras']['addons'] = metadata['extras']['addons'] || {};
        metadata['extras']['addons']['enabled'] = !!cleanData.extras.addons.enabled;
        if (cleanData.extras.addons.items && cleanData.extras.addons.items.length > 0) {
          metadata['extras']['addons']['items'] = cleanData.extras.addons.items.map(item => ({
            name: item.name,
            price: item.price,
            unit: item.unit
          }));
        }
      }
    }
    
    // Service Areas tab
    if (cleanData.serviceAreas) {
      metadata['service_areas'] = metadata['service_areas'] || {};
      
      if (cleanData.serviceAreas.zones && cleanData.serviceAreas.zones.length > 0) {
        metadata['service_areas']['zones'] = cleanData.serviceAreas.zones.map(zone => ({
          id: zone.id,
          name: zone.name,
          zipcodes: zone.zipcodes,
          
          // Only include zone-specific settings if there are multiple zones
          ...(cleanData.serviceAreas.zones.length > 1 && zone.pricing ? {
            pricing: {
              regular_price_per_pound: zone.pricing.regularPerPound || null,
              delivery_fee: zone.pricing.deliveryFee || null,
              minimum_order: zone.pricing.minimumOrder || null,
              same_day: zone.pricing.sameDay ? {
                enabled: !!zone.pricing.sameDay.enabled,
                price_per_pound: zone.pricing.sameDay.pricePerPound || null,
                cutoff_time: zone.pricing.sameDay.cutoff || null,
                turnaround_hours: zone.pricing.sameDay.turnaroundHours || null
              } : null
            }
          } : null),
          
          ...(cleanData.serviceAreas.zones.length > 1 && zone.turnaround ? {
            turnaround: {
              standard_hours: zone.turnaround.standardHours || null
            }
          } : null),
          
          ...(cleanData.serviceAreas.zones.length > 1 && zone.schedule && zone.schedule.timeWindows ? {
            schedule: {
              time_windows: Object.entries(zone.schedule.timeWindows).reduce((acc, [day, windows]) => {
                acc[day.toLowerCase()] = windows.map(window => ({
                  start_time: window.startTime,
                  end_time: window.endTime
                }));
                return acc;
              }, {})
            }
          } : null)
        }));
      }
    }
    
    // Other tab
    if (cleanData.other && cleanData.other.notes) {
      metadata['notes'] = cleanData.other.notes;
    }
    
    return metadata;
  };

  // Effect to update parent component when data changes
  useEffect(() => {
    try {
      // Check if required fields are completed to enable progression
      const allRequiredFieldsComplete = Object.entries(requiredFieldsStatus)
        .every(([_, isComplete]) => isComplete === true);
      
      // Create the full metadata object with correct structure
      const metadataFields = transformToMetadata(pudData);
      const fullMetadata = {
        services: {
          "2": {
            pickupDelivery: metadataFields
          }
        }
      };
      
      // Update the parent component (only)
      if (onChange) {
        onChange({
          ...pudData,
          requirementsToAdvance: allRequiredFieldsComplete,
          metadata: fullMetadata
        });
      }
    } catch (error) {
      console.error('Error updating parent component:', error);
    }
  }, [pudData, requiredFieldsStatus, onChange]);

  // Reset activeZoneId when changing away from turnaround tab
  useEffect(() => {
    if (activeTab !== 'turnaround') {
      setActiveZoneId(null);
    }
  }, [activeTab]);

  // Effect to update required fields status
  useEffect(() => {
    // Only validate if the service is enabled
    if (!pudData.enabled) {
      setRequiredFieldsStatus({
        general: true,
        serviceAreas: true,
        pricing: true,
        schedule: true,
        instructions: true,
        discounts: true,
        extras: true
      });
      return;
    }

    // Get references to each section's data
    const delivery = pudData.delivery || {};
    const serviceAreas = pudData.serviceAreas || {};
    const pricing = pudData.pricing || {};
    const schedule = pudData.schedule || {};
    const sameDay = pricing.sameDay || {};
    const instructions = pudData.instructions || {};
    const discounts = pudData.discounts || {};
    const extras = pudData.extras || {};
    const specialtyItems = extras.specialtyItems || {};
    const addons = extras.addons || {};

    // SERVICE AREAS: User must have at least 1 service zone added
    const hasZones = Array.isArray(serviceAreas.zones) && serviceAreas.zones.length > 0;
    const isServiceAreasComplete = hasZones;

    // PRICING: Check for each service zone and global settings
    // For global pricing settings:
    const hasGlobalPricing = !!pricing.regularPerPound; // Delivery fee is now optional
    const hasValidGlobalMinimumOrder = !delivery.minimumOrderEnabled || !!delivery.minimumOrder;
    const hasValidGlobalSameDay = !sameDay.enabled || !!sameDay.pricePerPound; // Cutoff time and turnaround hours are now optional
    
    // Check each zone for pricing requirements
    let allZonesPricingComplete = true;
    if (hasZones) {
      for (const zone of serviceAreas.zones) {
        // Skip if zone uses global pricing
        if (!zone.customPricing) continue;
        
        const zonePricing = zone.pricing || {};
        const zoneSameDay = zonePricing.sameDay || {};
        
        // Check if required pricing fields are set for this zone
        const hasZonePricing = !!zonePricing.regularPerPound; // Zone delivery fee is now optional
        const hasValidZoneMinimumOrder = !zonePricing.minimumOrderEnabled || !!zonePricing.minimumOrder;
        const hasValidZoneSameDay = !zoneSameDay.enabled || !!zoneSameDay.pricePerPound; // Zone cutoff time and turnaround hours are now optional
        
        if (!(hasZonePricing && hasValidZoneMinimumOrder && hasValidZoneSameDay)) {
          allZonesPricingComplete = false;
          break;
        }
      }
    }
    
    const isPricingComplete = hasGlobalPricing && hasValidGlobalMinimumOrder && hasValidGlobalSameDay && allZonesPricingComplete;

    // TURNAROUND TIME: Check that turnaround times are set
    // For global turnaround settings:
    const turnaroundData = pudData.turnaround || {};
    const hasGlobalTurnaround = !!turnaroundData.standardHours;
    
    // Check if all service zones have specific turnaround time or use the global settings
    let allZonesHaveTurnaround = true;
    if (hasZones) {
      for (const zone of serviceAreas.zones) {
        // If zone has no specific turnaround, it uses global
        if (!zone.turnaround) continue;
        
        // Check if zone has a valid turnaround time
        if (!zone.turnaround.standardHours) {
          allZonesHaveTurnaround = false;
          break;
        }
      }
    }
    
    const isTurnaroundComplete = hasGlobalTurnaround && allZonesHaveTurnaround;

    // SCHEDULE: User must have at least one time window for each day with service
    // Check global schedule settings first
    let hasValidSchedule = false;
    const globalTimeWindows = schedule.timeWindows || {};
    const hasGlobalTimeWindows = Object.values(globalTimeWindows).some(windows => 
      Array.isArray(windows) && windows.length > 0
    );
    
    // Then check each zone's schedule
    let allZonesScheduleComplete = true;
    if (hasZones) {
      for (const zone of serviceAreas.zones) {
        // Skip if zone uses global schedule
        if (!zone.customSchedule) continue;
        
        const zoneSchedule = zone.schedule || {};
        const zoneTimeWindows = zoneSchedule.timeWindows || {};
        const hasZoneTimeWindows = Object.values(zoneTimeWindows).some(windows => 
          Array.isArray(windows) && windows.length > 0
        );
        
        if (!hasZoneTimeWindows) {
          allZonesScheduleComplete = false;
          break;
        }
      }
    }
    
    hasValidSchedule = hasGlobalTimeWindows && allZonesScheduleComplete;

    // INSTRUCTIONS: Instructions for first pickup must not be empty
    const hasValidInstructions = !!instructions.firstPickup && instructions.firstPickup.trim() !== '';

    // DISCOUNTS: If enabled, rate must not be empty for any rows where offer toggle is on
    let discountsComplete = true;
    if (discounts.enabled) {
      // Check recurring discounts
      const recurringDiscounts = discounts.recurring || {};
      if (recurringDiscounts.firstOrder && recurringDiscounts.firstOrder.enabled && !recurringDiscounts.firstOrder.rate) {
        discountsComplete = false;
      }
      if (recurringDiscounts.secondOrder && recurringDiscounts.secondOrder.enabled && !recurringDiscounts.secondOrder.rate) {
        discountsComplete = false;
      }
      if (recurringDiscounts.thirdOrder && recurringDiscounts.thirdOrder.enabled && !recurringDiscounts.thirdOrder.rate) {
        discountsComplete = false;
      }
      if (recurringDiscounts.fourthOrder && recurringDiscounts.fourthOrder.enabled && !recurringDiscounts.fourthOrder.rate) {
        discountsComplete = false;
      }
      if (recurringDiscounts.fifthOrder && recurringDiscounts.fifthOrder.enabled && !recurringDiscounts.fifthOrder.rate) {
        discountsComplete = false;
      }
    }

    // EXTRAS: If toggles are on, must have at least one item
    let extrasComplete = true;
    if (specialtyItems.enabled && (!specialtyItems.items || specialtyItems.items.length === 0)) {
      extrasComplete = false;
    }
    if (addons.enabled && (!addons.items || addons.items.length === 0)) {
      extrasComplete = false;
    }

    setRequiredFieldsStatus({
      general: true,
      serviceAreas: isServiceAreasComplete,
      pricing: isPricingComplete,
      turnaround: isTurnaroundComplete,
      schedule: hasValidSchedule,
      instructions: hasValidInstructions,
      discounts: !discounts.enabled || discountsComplete,
      extras: !specialtyItems.enabled && !addons.enabled || extrasComplete
    });
  }, [pudData]);

  // Format time for display
  const formatTimeDisplay = (time24) => {
    if (!time24) return '';
    
    try {
      const [hours, minutes] = time24.split(':');
      const hour = parseInt(hours, 10);
      const minute = parseInt(minutes, 10);
      
      const period = hour >= 12 ? 'PM' : 'AM';
      const hour12 = hour % 12 || 12;
      
      return `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
    } catch (e) {
      return time24;
    }
  };

  // Handler functions
  const handleUpdate = (updates) => {
    console.log('SimpleWDFPickupDelivery - UI Update:', Object.keys(updates));
    
    // Remove fieldVisibility from updates to prevent it from being saved to the database
    const cleanUpdates = { ...updates };
    if (cleanUpdates.fieldVisibility) {
      delete cleanUpdates.fieldVisibility;
    }
    
    // Update state
    setPudData(prev => {
      const updatedData = {
        ...prev,
        ...cleanUpdates
      };
      
      // Create metadata for database update
      if (locationData?.id) {
        // Transform our data to proper metadata format
        const metadataFields = transformToMetadata(updatedData);
        
        // Create a clean metadata structure
        const fullMetadata = {
          services: {
            "2": {
              pickupDelivery: metadataFields
            }
          }
        };
        
        // Always update the database with the latest data
        console.log('SimpleWDFPickupDelivery - Sending to database');
        updateDatabase(fullMetadata);
      }
      
      return updatedData;
    });
  };

  const handleDeliveryUpdate = (updates) => {
    setPudData(prev => {
      const delivery = prev.delivery || {};
      
      // Process updates to handle empty values
      const processedUpdates = { ...updates };
      if ('fee' in updates && (updates.fee === '' || updates.fee === null || updates.fee === undefined)) {
        // Remove fee property if empty
        delete processedUpdates.fee;
        
        // Also remove from current if it exists
        if (delivery.fee !== undefined) {
          delete delivery.fee;
        }
      }
      
      const updatedDelivery = {
        ...delivery,
        ...processedUpdates
      };
      
      const completeUpdates = {
        ...prev,
        delivery: updatedDelivery
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({delivery: updatedDelivery});
      }, 0);
      
      return completeUpdates;
    });
  };

  const handlePricingUpdate = (updates) => {
    // First update the local state with the pricing updates
    setPudData(prev => {
      const updatedPricing = {
        ...prev.pricing || {},
        ...updates
      };
      
      // Create complete updates object
      const completeUpdates = {
        ...prev,
        pricing: updatedPricing
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({pricing: updatedPricing});
      }, 0);
      
      return completeUpdates;
    });
  };

  const handleTurnaroundUpdate = (updates) => {
    setPudData(prev => {
      const turnaround = prev.turnaround || {};
      const updatedTurnaround = {
        ...turnaround,
        ...updates
      };
      
      const completeUpdates = {
        ...prev,
        turnaround: updatedTurnaround
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({turnaround: updatedTurnaround});
      }, 0);
      
      return completeUpdates;
    });
  };

  const handleExpressTurnaroundUpdate = (updates) => {
    setPudData(prev => ({
      ...prev,
      turnaround: {
        ...prev.turnaround,
        expressTurnaround: {
          ...prev.turnaround?.expressTurnaround || {},
          ...updates
        }
      }
    }));
  };

  const handleZoneTurnaroundUpdate = (zoneId, updates) => {
    setPudData(prev => {
      const zones = [...(prev.serviceAreas?.zones || [])];
      const zoneIndex = zones.findIndex(z => z.id === zoneId);
      
      if (zoneIndex === -1) return prev;
      
      const zone = zones[zoneIndex];
      const updatedZone = {
        ...zone,
        turnaround: {
          ...(zone.turnaround || {}),
          ...updates
        }
      };
      
      zones[zoneIndex] = updatedZone;
      
      return {
        ...prev,
        serviceAreas: {
          ...(prev.serviceAreas || {}),
          zones
        }
      };
    });
  };

  const handleSameDayUpdate = (updates) => {
    setPudData(prev => {
      // Ensure pricing exists
      const pricing = prev.pricing || {};
      
      // Get existing sameDay or create empty object
      const sameDay = pricing.sameDay || {};
      
      // Process updates to handle empty values
      const processedUpdates = { ...updates };
      if ('cutoff' in updates && !updates.cutoff) {
        // Remove cutoff property if empty instead of storing an empty string
        delete processedUpdates.cutoff;
        
        // Also remove from current if it exists
        if (sameDay.cutoff) {
          delete sameDay.cutoff;
        }
      }
      
      // Create updated sameDay object
      const updatedSameDay = {
        ...sameDay,
        ...processedUpdates
      };
      
      // Create updated pricing object
      const updatedPricing = {
        ...pricing,
        sameDay: updatedSameDay
      };
      
      const completeUpdates = {
        ...prev,
        pricing: updatedPricing
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({pricing: updatedPricing});
      }, 0);
      
      return completeUpdates;
    });
  };

  const handleScheduleUpdate = (updates) => {
    setPudData(prev => {
      const schedule = prev.schedule || {};
      const updatedSchedule = {
        ...schedule,
        ...updates
      };
      
      const completeUpdates = {
        ...prev,
        schedule: updatedSchedule
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({schedule: updatedSchedule});
      }, 0);
      
      return completeUpdates;
    });
  };

  const handleOtherUpdate = (updates) => {
    setPudData(prev => {
      const other = prev.other || {};
      const updatedOther = {
        ...other,
        ...updates
      };
      
      const completeUpdates = {
        ...prev,
        other: updatedOther
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({other: updatedOther});
      }, 0);
      
      return completeUpdates;
    });
  };

  const handleServiceAreasUpdate = (updates) => {
    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      return {
        ...prev,
        serviceAreas: {
          ...serviceAreas,
          ...updates
        }
      };
    });
  };

  const handleAddZipcode = () => {
    if (!zipcode || zipcode.length < 5) return;
    
    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zipcodes = serviceAreas.zipcodes || [];
      
      // Check if already exists
      if (zipcodes.includes(zipcode)) {
        return prev;
      }
      
      return {
        ...prev,
        serviceAreas: {
          ...serviceAreas,
          zipcodes: [...zipcodes, zipcode]
        }
      };
    });
    
    // Clear input
    setZipcode('');
  };

  const handleRemoveZipcode = (zipToRemove) => {
    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zipcodes = serviceAreas.zipcodes || [];
      
      return {
        ...prev,
        serviceAreas: {
          ...serviceAreas,
          zipcodes: zipcodes.filter(zip => zip !== zipToRemove)
        }
      };
    });
  };

  // Add a generic handler for time windows
  const handleAddTimeWindow = (day, startTime, endTime) => {
    if (!day || !startTime || !endTime) return;
    
    setPudData(prev => {
      const schedule = prev.schedule || {};
      const windows = schedule.timeWindows || {};
      const dayWindows = windows[day] || [];
      
      // Create updated schedule with new time window
      const updatedSchedule = {
        ...schedule,
        timeWindows: {
          ...windows,
          [day]: [...dayWindows, { startTime, endTime }]
        }
      };
      
      const completeUpdates = {
        ...prev,
        schedule: updatedSchedule
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({schedule: updatedSchedule});
      }, 0);
      
      return completeUpdates;
    });
    
    // Reset form
    setNewWindow({
      day: '',
      startTime: '',
      endTime: ''
    });
  };

  const handleRemoveTimeWindow = (day, index) => {
    setPudData(prev => {
      const schedule = prev.schedule || {};
      const windows = schedule.timeWindows || {};
      const dayWindows = [...(windows[day] || [])];
      
      dayWindows.splice(index, 1);
      
      const updatedDayWindows = dayWindows.length > 0 ? dayWindows : undefined;
      
      const updatedWindows = {
        ...windows,
        [day]: updatedDayWindows
      };
      
      // If the day has no windows, remove it
      if (!updatedDayWindows) {
        delete updatedWindows[day];
      }
      
      // Create updated schedule with window removed
      const updatedSchedule = {
        ...schedule,
        timeWindows: updatedWindows
      };
      
      const completeUpdates = {
        ...prev,
        schedule: updatedSchedule
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({schedule: updatedSchedule});
      }, 0);
      
      return completeUpdates;
    });
  };

  // Zone management handlers
  const handleOpenAddZone = () => {
    setZoneToEdit(null);
    setZoneFormData({
      name: '',
      zipcodes: ''
    });
    setIsAddZoneOpen(true);
  };

  const handleOpenEditZone = (zone) => {
    setZoneToEdit(zone);
    setZoneFormData({
      name: zone.name,
      zipcodes: zone.zipcodes.join(', ')
    });
    setIsAddZoneOpen(true);
  };

  const handleCloseZoneModal = () => {
    setIsAddZoneOpen(false);
    setZoneToEdit(null);
  };

  const handleSaveZone = () => {
    // Basic validation
    if (!zoneFormData.name.trim()) {
      return; // Should show validation error
    }

    // Parse zipcodes from comma-separated string
    const zipcodesArray = zoneFormData.zipcodes
      .split(',')
      .map(zip => zip.trim())
      .filter(zip => zip.length === 5 && !isNaN(zip));

    if (zipcodesArray.length === 0) {
      return; // Should show validation error
    }

    const newZone = {
      id: zoneToEdit ? zoneToEdit.id : Date.now().toString(),
      name: zoneFormData.name.trim(),
      zipcodes: zipcodesArray,
      pricing: zoneToEdit?.pricing || {
        regularPerPound: '',
        deliveryFee: '',
        minimumOrder: '',
        sameDay: {
          enabled: false,
          cutoff: '',
          pricePerPound: '',
          turnaroundHours: ''
        }
      },
      schedule: zoneToEdit?.schedule || {
        timeWindows: {}
      }
    };

    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zones = [...(serviceAreas.zones || [])];
      
      if (zoneToEdit) {
        // Update existing zone
        const index = zones.findIndex(z => z.id === zoneToEdit.id);
        if (index !== -1) {
          zones[index] = newZone;
        }
      } else {
        // Add new zone
        zones.push(newZone);
      }
      
      const updatedServiceAreas = {
        ...serviceAreas,
        zones
      };
      
      const completeUpdates = {
        ...prev,
        serviceAreas: updatedServiceAreas
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({serviceAreas: updatedServiceAreas});
      }, 0);
      
      return completeUpdates;
    });

    // Close modal
    handleCloseZoneModal();
  };

  const handleRemoveZone = (zoneId) => {
    // Find zone to display its name in confirmation modal
    const zoneToRemove = pudData.serviceAreas?.zones?.find(zone => zone.id === zoneId) || { id: zoneId, name: 'this zone' };
    setZoneToDelete(zoneToRemove);
    setShowDeleteConfirmModal(true);
  };

  // Actual function to delete zone after confirmation
  const confirmDeleteZone = () => {
    if (!zoneToDelete) return;
    
    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zones = (serviceAreas.zones || []).filter(zone => zone.id !== zoneToDelete.id);
      
      const updatedServiceAreas = {
        ...serviceAreas,
        zones
      };
      
      const completeUpdates = {
        ...prev,
        serviceAreas: updatedServiceAreas
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({serviceAreas: updatedServiceAreas});
      }, 0);
      
      return completeUpdates;
    });
    
    // Close modal and reset state
    setShowDeleteConfirmModal(false);
    setZoneToDelete(null);
  };

  // Function to close the modal without deleting
  const cancelDeleteZone = () => {
    setShowDeleteConfirmModal(false);
    setZoneToDelete(null);
  };

  // Handler for zone-specific pricing updates
  const handleZonePricingUpdate = (zoneId, updates) => {
    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zones = [...(serviceAreas.zones || [])];
      
      const zoneIndex = zones.findIndex(zone => zone.id === zoneId);
      if (zoneIndex === -1) return prev;
      
      const updatedZone = {
        ...zones[zoneIndex],
        pricing: {
          ...zones[zoneIndex].pricing,
          ...updates
        }
      };
      
      zones[zoneIndex] = updatedZone;
      
      return {
        ...prev,
        serviceAreas: {
          ...serviceAreas,
          zones
        }
      };
    });
  };

  // Handler for zone-specific same day updates
  const handleZoneSameDayUpdate = (zoneId, updates) => {
    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zones = [...(serviceAreas.zones || [])];
      
      const zoneIndex = zones.findIndex(zone => zone.id === zoneId);
      if (zoneIndex === -1) return prev;
      
      // Process updates to handle empty values
      const processedUpdates = { ...updates };
      if ('cutoff' in updates && !updates.cutoff) {
        // Remove cutoff property if empty instead of storing an empty string
        delete processedUpdates.cutoff;
        
        // Also remove from current if it exists
        const currentZone = zones[zoneIndex];
        if (currentZone.pricing?.sameDay?.cutoff) {
          // We need to create a new object structure to avoid modifying the existing one
          const updatedZone = { ...currentZone };
          if (!updatedZone.pricing) updatedZone.pricing = {};
          if (!updatedZone.pricing.sameDay) updatedZone.pricing.sameDay = {};
          
          const { cutoff, ...restSameDay } = updatedZone.pricing.sameDay;
          updatedZone.pricing.sameDay = restSameDay;
          
          zones[zoneIndex] = updatedZone;
          return {
            ...prev,
            serviceAreas: {
              ...serviceAreas,
              zones
            }
          };
        }
      }
      
      const updatedZone = {
        ...zones[zoneIndex],
        pricing: {
          ...zones[zoneIndex].pricing,
          sameDay: {
            ...zones[zoneIndex].pricing?.sameDay,
            ...processedUpdates
          }
        }
      };
      
      zones[zoneIndex] = updatedZone;
      
      return {
        ...prev,
        serviceAreas: {
          ...serviceAreas,
          zones
        }
      };
    });
  };

  // Handler for zone-specific schedule updates
  const handleZoneScheduleUpdate = (zoneId, updates) => {
    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zones = [...(serviceAreas.zones || [])];
      
      const zoneIndex = zones.findIndex(zone => zone.id === zoneId);
      if (zoneIndex === -1) return prev;
      
      const updatedZone = {
        ...zones[zoneIndex],
        schedule: {
          ...zones[zoneIndex].schedule,
          ...updates
        }
      };
      
      zones[zoneIndex] = updatedZone;
      
      const updatedServiceAreas = {
        ...serviceAreas,
        zones
      };
      
      const completeUpdates = {
        ...prev,
        serviceAreas: updatedServiceAreas
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({serviceAreas: updatedServiceAreas});
      }, 0);
      
      return completeUpdates;
    });
  };

  // Handler for zone-specific time window management
  const handleAddZoneTimeWindow = (zoneId, day, startTime, endTime) => {
    if (!day || !startTime || !endTime) return;
    
    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zones = [...(serviceAreas.zones || [])];
      
      const zoneIndex = zones.findIndex(zone => zone.id === zoneId);
      if (zoneIndex === -1) return prev;
      
      const zone = zones[zoneIndex];
      const schedule = zone.schedule || {};
      const timeWindows = schedule.timeWindows || {};
      const dayWindows = timeWindows[day] || [];
      
      const updatedZone = {
        ...zone,
        schedule: {
          ...schedule,
          timeWindows: {
            ...timeWindows,
            [day]: [...dayWindows, { startTime, endTime }]
          }
        }
      };
      
      zones[zoneIndex] = updatedZone;
      
      const updatedServiceAreas = {
        ...serviceAreas,
        zones
      };
      
      const completeUpdates = {
        ...prev,
        serviceAreas: updatedServiceAreas
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({serviceAreas: updatedServiceAreas});
      }, 0);
      
      return completeUpdates;
    });
    
    // Reset form
    setNewWindow({
      ...newWindow,
      day: '',
      startTime: '',
      endTime: ''
    });
  };

  const handleRemoveZoneTimeWindow = (zoneId, day, index) => {
    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zones = [...(serviceAreas.zones || [])];
      
      const zoneIndex = zones.findIndex(zone => zone.id === zoneId);
      if (zoneIndex === -1) return prev;
      
      const zone = zones[zoneIndex];
      const schedule = zone.schedule || {};
      const timeWindows = schedule.timeWindows || {};
      const dayWindows = [...(timeWindows[day] || [])];
      
      dayWindows.splice(index, 1);
      
      const updatedDayWindows = dayWindows.length > 0 ? dayWindows : undefined;
      
      const updatedTimeWindows = {
        ...timeWindows,
        [day]: updatedDayWindows
      };
      
      // If the day has no windows, remove it
      if (!updatedDayWindows) {
        delete updatedTimeWindows[day];
      }
      
      const updatedZone = {
        ...zone,
        schedule: {
          ...schedule,
          timeWindows: updatedTimeWindows
        }
      };
      
      zones[zoneIndex] = updatedZone;
      
      const updatedServiceAreas = {
        ...serviceAreas,
        zones
      };
      
      const completeUpdates = {
        ...prev,
        serviceAreas: updatedServiceAreas
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({serviceAreas: updatedServiceAreas});
      }, 0);
      
      return completeUpdates;
    });
  };

  // Helper function to get pricing for a zone (or use global pricing if zone pricing is empty)
  const getZonePricing = (zone) => {
    const globalPricing = pudData.pricing || {};
    const globalDelivery = pudData.delivery || {};
    const zonePricing = zone?.pricing || {};
    
    return {
      regularPerPound: zonePricing.regularPerPound || globalPricing.regularPerPound,
      deliveryFee: zonePricing.deliveryFee || globalDelivery.fee,
      minimumOrder: zonePricing.minimumOrder || globalDelivery.minimumOrder,
      sameDay: {
        ...globalPricing.sameDay,
        ...zonePricing.sameDay
      }
    };
  };
  
  // Helper function to copy global pricing to a zone
  const copyGlobalPricingToZone = (zoneId) => {
    const globalPricing = pudData.pricing || {};
    const globalDelivery = pudData.delivery || {};
    
    handleZonePricingUpdate(zoneId, {
      regularPerPound: globalPricing.regularPerPound,
      deliveryFee: globalDelivery.fee,
      minimumOrder: globalDelivery.minimumOrder,
      sameDay: {
        ...globalPricing.sameDay
      }
    });
  };
  
  // Helper function to copy global schedule to a zone
  const copyGlobalScheduleToZone = (zoneId) => {
    const globalSchedule = pudData.schedule || {};
    
    handleZoneScheduleUpdate(zoneId, {
      timeWindows: { ...globalSchedule.timeWindows }
    });
  };

  // Handler for minimum order updates
  const handleMinimumOrderUpdate = (updates) => {
    console.log('Updating minimum order with:', updates);
    
    setPudData(prev => {
      const delivery = prev.delivery || {};
      const minimumOrder = delivery.minimumOrder || {};
      
      // Create updated minimum order
      const updatedMinimumOrder = {
        ...minimumOrder,
        ...updates
      };
      
      // Create updated delivery with new minimum order
      const updatedDelivery = {
        ...delivery,
        minimumOrder: updatedMinimumOrder
      };
      
      const completeUpdates = {
        ...prev,
        delivery: updatedDelivery
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({delivery: updatedDelivery});
      }, 0);
      
      return completeUpdates;
    });
  };

  const handleInstructionsUpdate = (updates) => {
    setPudData(prev => {
      const instructions = prev.instructions || {};
      const updatedInstructions = {
        ...instructions,
        ...updates
      };
      
      const completeUpdates = {
        ...prev,
        instructions: updatedInstructions
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({instructions: updatedInstructions});
      }, 0);
      
      return completeUpdates;
    });
  };

  const handleDiscountsUpdate = (updates) => {
    setPudData(prev => {
      const discounts = prev.discounts || {};
      const updatedDiscounts = {
        ...discounts,
        ...updates
      };
      
      const completeUpdates = {
        ...prev,
        discounts: updatedDiscounts
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({discounts: updatedDiscounts});
      }, 0);
      
      return completeUpdates;
    });
  };

  const handleRecurringDiscountUpdate = (type, updates) => {
    console.log('Updating recurring discount:', type, updates);
    
    setPudData(prev => {
      const discounts = prev.discounts || {};
      const recurring = discounts.recurring || {};
      const typeData = recurring[type] || {};
      
      // Create updated recurring discounts
      const updatedTypeData = {
        ...typeData,
        ...updates
      };
      
      const updatedRecurring = {
        ...recurring,
        [type]: updatedTypeData
      };
      
      const updatedDiscounts = {
        ...discounts,
        recurring: updatedRecurring
      };
      
      const completeUpdates = {
        ...prev,
        discounts: updatedDiscounts
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({discounts: updatedDiscounts});
      }, 0);
      
      return completeUpdates;
    });
  };

  const handleExtrasUpdate = (type, updates) => {
    setPudData(prev => {
      // Make sure we have the extras field
      const extras = prev.extras || { addons: {}, specialty_items: {} };
      
      // Make sure we have the type field
      const typeData = extras[type] || {};
      
      // Update the type data
      const updatedTypeData = {
        ...typeData,
        ...updates
      };
      
      // Create the new extras object
      const updatedExtras = {
        ...extras,
        [type]: updatedTypeData
      };
      
      const completeUpdates = {
        ...prev,
        extras: updatedExtras
      };
      
      // Call handleUpdate to ensure database gets updated
      setTimeout(() => {
        handleUpdate({extras: updatedExtras});
      }, 0);
      
      return completeUpdates;
    });
  };

  // Tab button component
  const TabButton = ({ active, icon: Icon, label, onClick }) => (
    <button
      className={cn(
        "flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-colors",
        active 
          ? "bg-gray-900 text-white" 
          : "bg-white hover:bg-gray-100 text-gray-700"
      )}
      onClick={onClick}
    >
      <Icon className="w-4 h-4 mr-2" />
      <span>{label}</span>
    </button>
  );

  // Render tab content functions
  const renderGeneralContent = () => {
    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <Settings className="w-5 h-5 text-gray-400" />
            <div>
              <span className="text-sm font-medium text-gray-700">Pickup & Delivery Service</span>
              <p className="text-xs text-gray-500 mt-1">
                Enable pickup and delivery for your Wash & Fold service
              </p>
            </div>
          </div>
          <BlackSwitch 
            checked={pudData.enabled} 
            onCheckedChange={(checked) => handleUpdate({ enabled: checked })}
          />
        </div>
        
        <AnimatePresence>
          {pudData.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className="flex items-center p-4 rounded-lg bg-blue-50 border border-blue-100">
                <Info className="w-5 h-5 text-blue-500 mr-3 flex-shrink-0" />
                <p className="text-sm text-blue-700">
                  Configure your pickup and delivery service details in the tabs below. You'll need to set up delivery areas, pricing, and schedule to complete this section.
                </p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  const renderServiceAreasContent = () => {
    const serviceAreas = pudData.serviceAreas || {};
    const zones = serviceAreas.zones || [];

    return (
      <div className="space-y-6">
        <div className="bg-white rounded-lg border border-gray-200 p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900">Service Zones</h3>
            <Button 
              onClick={handleOpenAddZone}
              size="sm"
              className="bg-gray-900 hover:bg-gray-800 text-white"
            >
              <Plus className="w-4 h-4 mr-2" />
              Add Zone
            </Button>
          </div>
          
          <p className="text-sm text-gray-500 mb-6">
            Create service zones with specific ZIP codes for different delivery areas
          </p>
          
          {zones.length === 0 ? (
            <div className="flex flex-col items-center justify-center p-8 border border-dashed border-gray-300 rounded-lg bg-gray-50">
              <MapPin className="w-10 h-10 text-gray-400 mb-3" />
              <p className="text-sm font-medium text-gray-700 mb-2">No service zones defined yet</p>
              <p className="text-sm text-gray-500 mb-4 text-center max-w-md">
                Define zones to organize your delivery areas by neighborhood, city district, or service territory
              </p>
              <Button 
                onClick={handleOpenAddZone}
                variant="outline"
                className="border-gray-900 text-gray-900 hover:bg-gray-100"
              >
                <Plus className="w-4 h-4 mr-2" />
                Create Your First Zone
              </Button>
            </div>
          ) : (
            <Accordion
              type="single"
              collapsible
              className="w-full space-y-3"
            >
              {zones.map((zone) => (
                <AccordionItem 
                  key={zone.id} 
                  value={zone.id}
                  className="border border-gray-200 rounded-lg overflow-hidden shadow-sm px-0 py-0 border-b-0 mb-3"
                >
                  <AccordionTrigger className="px-4 py-3 hover:bg-gray-50 hover:no-underline w-full [&[data-state=open]>svg]:rotate-180">
                    <div className="flex justify-between items-center w-full">
                      <div className="flex items-center">
                        <MapPin className="w-5 h-5 text-gray-500 mr-2" />
                        <span className="font-medium text-gray-900">{zone.name}</span>
                        <span className="ml-2 text-sm text-gray-500">
                          ({zone.zipcodes.length} {zone.zipcodes.length === 1 ? 'ZIP' : 'ZIPs'})
                        </span>
                      </div>
                      <div className="flex items-center gap-1">
                        <div
                          role="button"
                          tabIndex={0}
                          className="p-1 h-8 text-gray-500 hover:text-gray-900 hover:bg-gray-100 rounded-md flex items-center justify-center cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenEditZone(zone);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              e.stopPropagation();
                              handleOpenEditZone(zone);
                            }
                          }}
                        >
                          <Edit className="w-4 h-4" />
                          <span className="sr-only">Edit</span>
                        </div>
                        <div
                          role="button"
                          tabIndex={0}
                          className="p-1 h-8 text-gray-500 hover:text-red-600 hover:bg-gray-100 rounded-md flex items-center justify-center cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveZone(zone.id);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              e.stopPropagation();
                              handleRemoveZone(zone.id);
                            }
                          }}
                        >
                          <Trash className="w-4 h-4" />
                          <span className="sr-only">Delete</span>
                        </div>
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="px-4 pb-4 pt-0 bg-gray-50 border-t border-gray-200">
                    <div className="pt-3">
                      <h4 className="text-sm font-medium text-gray-700 mb-3">ZIP Codes in this zone:</h4>
                      <div className="flex flex-wrap gap-2">
                        {zone.zipcodes.map(zip => (
                          <div 
                            key={zip} 
                            className="flex items-center px-3 py-1 bg-white border border-gray-200 rounded-full"
                          >
                            <span className="text-sm text-gray-800">{zip}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </div>
        
        {/* Zone Dialog */}
        <Dialog open={isAddZoneOpen} onOpenChange={setIsAddZoneOpen}>
          <DialogContent className="sm:max-w-md">
            <DialogHeader>
              <DialogTitle className="text-xl font-semibold text-gray-900">
                {zoneToEdit ? 'Edit Service Zone' : 'Add New Service Zone'}
              </DialogTitle>
            </DialogHeader>
            
            <div className="space-y-5 py-4">
              <div>
                <Label htmlFor="zone-name" className="text-sm font-medium text-gray-700 mb-1 block">
                  Zone Name
                </Label>
                <Input
                  id="zone-name"
                  value={zoneFormData.name}
                  onChange={(e) => setZoneFormData(prev => ({ ...prev, name: e.target.value }))}
                  placeholder="e.g., Downtown, East Side, Premium Area"
                  className="w-full border-gray-300 focus:border-gray-900 focus:ring-1 focus:ring-gray-900"
              />
              <p className="text-xs text-gray-500 mt-1">
                  Give your zone a descriptive name that identifies the area
              </p>
            </div>
            
            <div>
                <Label htmlFor="zone-zipcodes" className="text-sm font-medium text-gray-700 mb-1 block">
                  ZIP Codes for this zone
                </Label>
                <Textarea
                  id="zone-zipcodes"
                  value={zoneFormData.zipcodes}
                  onChange={(e) => setZoneFormData(prev => ({ ...prev, zipcodes: e.target.value }))}
                  placeholder="e.g., 10001, 10002, 10003"
                  className="w-full min-h-[120px] border-gray-300 focus:border-gray-900 focus:ring-1 focus:ring-gray-900"
                />
              <p className="text-xs text-gray-500 mt-1">
                  Enter multiple ZIP codes separated by commas (e.g., 90210, 90211)
              </p>
              </div>
            </div>
            
            <DialogFooter className="flex justify-between sm:justify-between border-t border-gray-200 pt-4">
              <Button
                type="button"
                variant="outline"
                onClick={handleCloseZoneModal}
                className="border-gray-300 text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </Button>
              <Button 
                type="button"
                onClick={handleSaveZone}
                disabled={!zoneFormData.name.trim() || !zoneFormData.zipcodes.trim()}
                className="bg-gray-900 hover:bg-gray-800 text-white"
              >
                {zoneToEdit ? 'Update Zone' : 'Add Zone'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  // Updated Pricing content with SimpleWDFDropOff.js style Minimum Order Required
  const renderPricingContent = () => {
    const delivery = pudData.delivery || {};
    const minimumOrder = delivery.minimumOrder || { enabled: false, isDollarBased: true };
    const pricing = pudData.pricing || {};
    const sameDay = pricing.sameDay || {};
    const serviceAreas = pudData.serviceAreas || {};
    const zones = serviceAreas.zones || [];

    return (
      <div className="space-y-6">
        {/* Default Pricing (Global) */}
        <div className="bg-white rounded-lg border border-gray-200 p-6 space-y-5">
          <h3 className="text-lg font-medium text-gray-900">Default Pricing</h3>
          
          <div className="space-y-5">
            {/* Regular Price Per Pound */}
            <div>
              <Label htmlFor="price-per-pound">Regular Price Per Pound</Label>
              <div className="flex mt-1">
                <div className="flex items-center px-3 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                  <span className="text-gray-500">$</span>
                </div>
                <Input
                  id="price-per-pound"
                  type="number"
                  min="0"
                  step="0.01"
                  value={rawInputValues.regularPerPound || pricing.regularPerPound || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    setRawInputValues(prev => ({ ...prev, regularPerPound: value }));
                    
                    if (value.trim() === '') return;
                    const numValue = parseFloat(value);
                    
                    if (!isNaN(numValue)) {
                      handlePricingUpdate({ regularPerPound: numValue });
                    }
                  }}
                  className="rounded-l-none"
                  placeholder="1.99"
                />
              </div>
              <p className="text-xs text-gray-500 mt-1">
                The standard price per pound for wash and fold service
              </p>
            </div>

            {/* Minimum Order Required */}
            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
              <div className="flex items-center gap-3">
                <DollarSign className="w-5 h-5 text-gray-400" />
                <span className="text-sm font-medium text-gray-700">Minimum Order Required</span>
          </div>
              <BlackSwitch 
                checked={minimumOrder.enabled || false} 
                onCheckedChange={(checked) => handleMinimumOrderUpdate({ enabled: checked })}
              />
        </div>
        
            <AnimatePresence>
              {minimumOrder.enabled && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
                  className="p-4 rounded-lg bg-white border border-gray-200 space-y-3"
                >
                  <div>
                    <Label htmlFor="minimum-order-type">Minimum Order Type</Label>
                    <div className="flex gap-2 mt-1">
            <Button
                        type="button"
                        variant="outline"
                        className={cn(
                          "flex-1 flex items-center justify-center gap-2",
                          minimumOrder.isDollarBased && "border-gray-900 text-gray-900 font-medium"
                        )}
                        onClick={() => handleMinimumOrderUpdate({ isDollarBased: true })}
                      >
                        <DollarSign className="w-4 h-4" />
                        <span>Dollar Amount</span>
            </Button>
                      <Button
                      type="button"
                        variant="outline"
                        className={cn(
                          "flex-1 flex items-center justify-center gap-2",
                          !minimumOrder.isDollarBased && "border-gray-900 text-gray-900 font-medium"
                        )}
                        onClick={() => handleMinimumOrderUpdate({ isDollarBased: false })}
                      >
                        <Scale className="w-4 h-4" />
                        <span>Weight (lbs)</span>
                      </Button>
                  </div>
              </div>
          
          <div>
                    <Label htmlFor="minimum-order-amount">
                      {minimumOrder.isDollarBased ? 'Minimum Order Amount' : 'Minimum Weight (lbs)'}
                    </Label>
            <div className="flex mt-1">
                      {minimumOrder.isDollarBased && (
              <div className="flex items-center px-3 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                <span className="text-gray-500">$</span>
              </div>
                      )}
              <Input
                        id="minimum-order-amount"
                type="number"
                min="0"
                        step={minimumOrder.isDollarBased ? "0.01" : "0.5"}
                        value={rawInputValues.minimumAmount || minimumOrder?.amount || ''}
                onChange={(e) => {
                  const value = e.target.value;
                          setRawInputValues(prev => ({ ...prev, minimumAmount: value }));
                  
                  if (value.trim() === '') return;
                  const numValue = parseFloat(value);
                  
                  if (!isNaN(numValue)) {
                            handleMinimumOrderUpdate({ amount: numValue });
                  }
                }}
                        className={minimumOrder.isDollarBased ? "rounded-l-none" : ""}
                        placeholder={minimumOrder.isDollarBased ? "10.00" : "10"}
              />
            </div>
                    <p className="text-xs text-gray-500 mt-1">
                      {minimumOrder.isDollarBased 
                        ? "Customers must spend at least this amount to place an order."
                        : "Customers must have at least this much weight to place an order."}
                    </p>
          </div>
                </motion.div>
              )}
            </AnimatePresence>
          
            {/* Same Day Service */}
            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
            <div className="flex items-center gap-3">
              <Calendar className="w-5 h-5 text-gray-400" />
              <span className="text-sm font-medium text-gray-700">Same Day Service</span>
            </div>
            <BlackSwitch 
              checked={sameDay.enabled} 
              onCheckedChange={(checked) => handleSameDayUpdate({ enabled: checked })}
            />
          </div>
          
          <AnimatePresence>
            {sameDay.enabled && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
                  className="p-4 rounded-lg bg-white border border-gray-200 space-y-4"
              >
                <div>
                  <Label htmlFor="same-day-price">Same Day Price Per Pound</Label>
                  <div className="flex mt-1">
                    <div className="flex items-center px-3 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                      <span className="text-gray-500">$</span>
                    </div>
                    <Input
                      id="same-day-price"
                      type="number"
                      min="0"
                      step="0.01"
                      value={rawInputValues.sameDayPrice || sameDay.pricePerPound || ''}
                      onChange={(e) => {
                        const value = e.target.value;
                        setRawInputValues(prev => ({ ...prev, sameDayPrice: value }));
                        
                        if (value.trim() === '') return;
                        const numValue = parseFloat(value);
                        
                        if (!isNaN(numValue)) {
                          handleSameDayUpdate({ pricePerPound: numValue });
                        }
                      }}
                      className="rounded-l-none"
                      placeholder="2.99"
                    />
                  </div>
                    <p className="text-xs text-gray-500 mt-1">
                      Premium price per pound for expedited same-day service
                    </p>
                </div>
                
                <div>
                  <Label htmlFor="same-day-cutoff">Cutoff Time for Same Day Service (Optional)</Label>
                  <div className="relative mt-1">
                    <Input
                      id="same-day-cutoff"
                      type="time"
                      value={sameDay.cutoff || ''}
                      onChange={(e) => handleSameDayUpdate({ cutoff: e.target.value })}
                      className="w-full"
                      aria-label="Same day cutoff time"
                      placeholder="17:00"
                    />
                  </div>
                  <p className="text-xs text-gray-500 mt-1">
                      Orders must be received before this time to qualify for same day service (leave empty if not applicable)
                  </p>
                </div>
              </motion.div>
            )}
          </AnimatePresence>

            {/* Delivery Fee */}
            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
              <div className="flex items-center gap-3">
                <Truck className="w-5 h-5 text-gray-400" />
                <div>
                  <span className="text-sm font-medium text-gray-700">Delivery Fee (Optional)</span>
                  <p className="text-xs text-gray-500 mt-1">
                    Charge a fee for pickup and delivery service
                  </p>
        </div>
              </div>
              <div className="flex items-center gap-2">
                <div className="flex mt-1">
                  <div className="flex items-center px-3 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                    <span className="text-gray-500">$</span>
                  </div>
                  <Input
                    id="delivery-fee"
                    type="number"
                    min="0"
                    step="0.01"
                    value={rawInputValues.deliveryFee || delivery.fee || ''}
                    onChange={(e) => {
                      const value = e.target.value;
                      setRawInputValues(prev => ({ ...prev, deliveryFee: value }));
                      
                      if (value.trim() === '') return;
                      const numValue = parseFloat(value);
                      
                      if (!isNaN(numValue)) {
                        handleDeliveryUpdate({ fee: numValue });
                      }
                    }}
                    className="rounded-l-none w-24"
                    placeholder="5.99"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Zone-specific Pricing */}
        {zones.length > 1 && (
          <div className="bg-white rounded-lg border border-gray-200 p-6 space-y-4">
            <h3 className="text-lg font-medium text-gray-900">Zone-Specific Pricing</h3>
            <p className="text-sm text-gray-500">
              Configure pricing for specific service zones. If no values are set, default pricing will be used.
            </p>
            
            <Accordion
              type="single"
              collapsible
              className="w-full space-y-3"
            >
              {zones.map((zone) => (
                <AccordionItem 
                  key={`price-${zone.id}`}
                  value={zone.id}
                  className="border border-gray-200 rounded-lg overflow-hidden shadow-sm px-0 py-0 border-b-0 mb-3"
                >
                  <AccordionTrigger className="px-4 py-3 hover:bg-gray-50 hover:no-underline w-full [&[data-state=open]>svg]:rotate-180">
                    <div className="flex justify-between items-center w-full">
                      <div className="flex items-center">
                        <DollarSign className="w-5 h-5 text-gray-500 mr-2" />
                        <span className="font-medium text-gray-900">{zone.name} Pricing</span>
                        {((zone.pricing?.regularPerPound && zone.pricing?.regularPerPound !== pricing.regularPerPound) || 
                          (zone.pricing?.deliveryFee && zone.pricing?.deliveryFee !== delivery.fee) || 
                          (zone.pricing?.minimumOrder && zone.pricing?.minimumOrder !== delivery.minimumOrder?.amount)) && (
                          <span className="ml-2 px-2 py-1 text-xs bg-blue-100 text-blue-800 rounded-full">
                            Custom Pricing
                          </span>
                        )}
                      </div>
                      <div
                        role="button"
                        tabIndex={0}
                        className="p-1 h-8 text-gray-500 hover:text-blue-600 hover:bg-gray-100 mr-2 rounded-md flex items-center justify-center cursor-pointer text-xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          copyGlobalPricingToZone(zone.id);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault();
                            e.stopPropagation();
                            copyGlobalPricingToZone(zone.id);
                          }
                        }}
                      >
                        <span className="text-xs">Copy Default</span>
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="px-4 pb-4 pt-0 bg-gray-50 border-t border-gray-200">
                    <div className="pt-3 space-y-5">
                      <div>
                        <Label htmlFor={`zone-${zone.id}-price-per-pound`}>Regular Price Per Pound</Label>
                        <div className="flex mt-1">
                          <div className="flex items-center px-3 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                            <span className="text-gray-500">$</span>
                          </div>
                          <Input
                            id={`zone-${zone.id}-price-per-pound`}
                            type="number"
                            min="0"
                            step="0.01"
                            value={rawInputValues.zoneRegularPerPound?.[zone.id] || zone.pricing?.regularPerPound || ''}
                            onChange={(e) => {
                              const value = e.target.value;
                              setRawInputValues(prev => ({
                                ...prev,
                                zoneRegularPerPound: {
                                  ...prev.zoneRegularPerPound,
                                  [zone.id]: value
                                }
                              }));
                              
                              if (value.trim() === '') {
                                handleZonePricingUpdate(zone.id, { regularPerPound: '' });
                                return;
                              }
                              
                              const numValue = parseFloat(value);
                              if (!isNaN(numValue)) {
                                handleZonePricingUpdate(zone.id, { regularPerPound: numValue });
                              }
                            }}
                            className="rounded-l-none"
                            placeholder={pricing.regularPerPound || "Default"}
                          />
                        </div>
                        <p className="text-xs text-gray-500 mt-1">
                          Leave blank to use default price ({pricing.regularPerPound ? `$${pricing.regularPerPound}` : 'not set'})
                        </p>
                      </div>
                      
                      <div>
                        <Label htmlFor={`zone-${zone.id}-delivery-fee`}>Delivery Fee (Optional)</Label>
                        <div className="flex mt-1">
                          <div className="flex items-center px-3 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                            <span className="text-gray-500">$</span>
                          </div>
                          <Input
                            id={`zone-${zone.id}-delivery-fee`}
                            type="number"
                            min="0"
                            step="0.01"
                            value={rawInputValues.zoneDeliveryFee?.[zone.id] || zone.pricing?.deliveryFee || ''}
                            onChange={(e) => {
                              const value = e.target.value;
                              setRawInputValues(prev => ({
                                ...prev,
                                zoneDeliveryFee: {
                                  ...prev.zoneDeliveryFee,
                                  [zone.id]: value
                                }
                              }));
                              
                              if (value.trim() === '') {
                                handleZonePricingUpdate(zone.id, { deliveryFee: '' });
                                return;
                              }
                              
                              const numValue = parseFloat(value);
                              if (!isNaN(numValue)) {
                                handleZonePricingUpdate(zone.id, { deliveryFee: numValue });
                              }
                            }}
                            className="rounded-l-none"
                            placeholder={delivery.fee || "Default"}
                          />
                        </div>
                        <p className="text-xs text-gray-500 mt-1">
                          Leave blank to use default fee ({delivery.fee ? `$${delivery.fee}` : 'not set'})
                        </p>
                      </div>
                      
                      <div>
                        <Label htmlFor={`zone-${zone.id}-minimum-order`}>Minimum Order Amount</Label>
                        <div className="flex mt-1">
                          <div className="flex items-center px-3 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                            <span className="text-gray-500">$</span>
                          </div>
                          <Input
                            id={`zone-${zone.id}-minimum-order`}
                            type="number"
                            min="0"
                            step="0.01"
                            value={rawInputValues.zoneMinimumOrder?.[zone.id] || zone.pricing?.minimumOrder || ''}
                            onChange={(e) => {
                              const value = e.target.value;
                              setRawInputValues(prev => ({
                                ...prev,
                                zoneMinimumOrder: {
                                  ...prev.zoneMinimumOrder,
                                  [zone.id]: value
                                }
                              }));
                              
                              if (value.trim() === '') {
                                handleZonePricingUpdate(zone.id, { minimumOrder: '' });
                                return;
                              }
                              
                              const numValue = parseFloat(value);
                              if (!isNaN(numValue)) {
                                handleZonePricingUpdate(zone.id, { minimumOrder: numValue });
                              }
                            }}
                            className="rounded-l-none"
                            placeholder={delivery.minimumOrder?.amount || "Default"}
                          />
                        </div>
                        <p className="text-xs text-gray-500 mt-1">
                          Leave blank to use default minimum order amount
                        </p>
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        )}
      </div>
    );
  };

  const renderTurnaroundContent = () => {
    // Get global turnaround data
    const turnaround = pudData.turnaround || {};
    const sameDay = pudData.pricing?.sameDay || {};
    // Get zones
    const zones = pudData.serviceAreas?.zones || [];

    return (
      <div className="space-y-6">
        {/* Global Turnaround Time Settings */}
        <div className="bg-white rounded-lg border border-gray-200 p-6 space-y-4">
          <h3 className="text-lg font-medium text-gray-900">Default Turnaround Times</h3>
          <p className="text-sm text-gray-500">
            Set the standard turnaround time for all orders. This will apply to all zones unless overridden.
          </p>

          <div className="space-y-4">
            {/* Standard Turnaround Time */}
            <div>
              <Label htmlFor="standard-turnaround">Standard Turnaround Time (days)</Label>
              <Input
                id="standard-turnaround"
                type="number"
                min="1"
                step="1"
                value={turnaround.standardHours || ''}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.trim() === '') return;
                  const numValue = parseInt(value);
                  
                  if (!isNaN(numValue) && numValue > 0) {
                    handleTurnaroundUpdate({ standardHours: numValue });
                  }
                }}
                className="mt-1"
              />
              <p className="text-xs text-gray-500 mt-1">
                The standard number of days it takes to complete laundry orders.
              </p>
            </div>

            {/* Express Turnaround */}
            <div className="mt-6">
              {/* Show Same Day service details if it's enabled */}
              {sameDay.enabled && (
                <div className="space-y-4">
                  <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
                    <div className="flex items-start">
                      <Calendar className="w-5 h-5 text-gray-400 mr-3 flex-shrink-0 mt-0.5" />
                      <div>
                        <h3 className="text-sm font-medium text-gray-700">Same Day Service</h3>
                        <p className="text-xs text-gray-500 mt-1">
                          Configure how quickly same day orders will be processed.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="space-y-4 p-4 rounded-lg bg-white border border-gray-200">
                    <div>
                      <Label htmlFor="same-day-cutoff">Cutoff Time for Same Day Service</Label>
                      <div className="relative mt-1">
                        <Input
                          id="same-day-cutoff"
                          type="time"
                          value={sameDay.cutoff || ''}
                          onChange={(e) => handleSameDayUpdate({ cutoff: e.target.value })}
                          className="w-full"
                          aria-label="Same day cutoff time"
                          placeholder="17:00"
                        />
                      </div>
                      <p className="text-xs text-gray-500 mt-1">
                        Orders must be received before this time to qualify for same day service.
                      </p>
                    </div>

                    <div>
                      <Label htmlFor="same-day-turnaround">Same Day Turnaround Time (hours)</Label>
                      <Input
                        id="same-day-turnaround"
                        type="number"
                        min="1"
                        step="1"
                        value={sameDay.turnaroundHours || ''}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.trim() === '') return;
                          const numValue = parseInt(value);
                          
                          if (!isNaN(numValue) && numValue > 0) {
                            handleSameDayUpdate({ turnaroundHours: numValue });
                          }
                        }}
                        className="mt-1"
                        placeholder="4"
                      />
                      <p className="text-xs text-gray-500 mt-1">
                        How quickly same day orders will be completed (in hours).
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        
        {/* Zone-specific Turnaround Times */}
        {zones.length > 1 && (
          <div className="bg-white rounded-lg border border-gray-200 p-6 space-y-4">
            <h3 className="text-lg font-medium text-gray-900">Zone-Specific Turnaround Times</h3>
            <p className="text-sm text-gray-500">
              Configure turnaround times for specific service zones. If no turnaround times are set, the default times will be used.
            </p>
            
            <Accordion
              type="single"
              collapsible
              className="w-full space-y-3"
            >
              {zones.map((zone) => (
                <AccordionItem 
                  key={`turnaround-${zone.id}`}
                  value={zone.id}
                  className="border border-gray-200 rounded-lg overflow-hidden shadow-sm px-0 py-0 border-b-0 mb-3"
                >
                  <AccordionTrigger className="px-4 py-3 hover:bg-gray-50 hover:no-underline w-full [&[data-state=open]>svg]:rotate-180">
                    <div className="flex justify-between items-center w-full">
                      <div className="flex items-center">
                        <Clock className="w-5 h-5 text-gray-500 mr-2" />
                        <span className="font-medium text-gray-900">{zone.name} Turnaround</span>
                        {zone.turnaround?.standardHours && zone.turnaround?.standardHours !== turnaround?.standardHours && (
                          <span className="ml-2 px-2 py-1 text-xs bg-blue-100 text-blue-800 rounded-full">
                            Custom Turnaround
                          </span>
                        )}
                      </div>
                      <div
                        role="button"
                        tabIndex={0}
                        className="p-1 h-8 text-gray-500 hover:text-blue-600 hover:bg-gray-100 mr-2 rounded-md flex items-center justify-center cursor-pointer text-xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (turnaround) {
                            handleZoneTurnaroundUpdate(zone.id, {
                              standardHours: turnaround.standardHours,
                              expressTurnaround: turnaround.expressTurnaround
                            });
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault();
                            e.stopPropagation();
                            if (turnaround) {
                              handleZoneTurnaroundUpdate(zone.id, {
                                standardHours: turnaround.standardHours,
                                expressTurnaround: turnaround.expressTurnaround
                              });
                            }
                          }
                        }}
                      >
                        <span className="text-xs">Copy Default</span>
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="px-4 pb-3 pt-0">
                    <div className="space-y-4 border-t border-gray-100 pt-3">
                      {/* Zone-specific standard turnaround */}
                      <div>
                        <Label htmlFor={`zone-${zone.id}-standard-turnaround`}>Standard Turnaround Time (days)</Label>
                        <Input
                          id={`zone-${zone.id}-standard-turnaround`}
                          type="number"
                          min="1"
                          step="1"
                          value={zone.turnaround?.standardHours || ''}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value.trim() === '') return;
                            const numValue = parseInt(value);
                            
                            if (!isNaN(numValue) && numValue > 0) {
                              handleZoneTurnaroundUpdate(zone.id, { standardHours: numValue });
                            }
                          }}
                          className="mt-1"
                        />
                        <p className="text-xs text-gray-500 mt-1">
                          The standard number of days it takes to complete laundry orders for this zone.
                        </p>
                      </div>

                      {/* Same day service for zone if enabled */}
                      {zone.pricing?.sameDay?.enabled && (
                        <div className="mt-4">
                          <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
                            <div className="flex items-start">
                              <Calendar className="w-5 h-5 text-gray-400 mr-3 flex-shrink-0 mt-0.5" />
                              <div>
                                <h3 className="text-sm font-medium text-gray-700">Same Day Service for {zone.name}</h3>
                                <p className="text-xs text-gray-500 mt-1">
                                  Configure how quickly same day orders will be processed in this zone.
                                </p>
                              </div>
                            </div>
                          </div>
                          
                          <div className="space-y-4 p-4 rounded-lg bg-white border border-gray-200 mt-3">
                            <div>
                              <Label htmlFor={`zone-${zone.id}-same-day-cutoff`}>Cutoff Time for Same Day Service (Optional)</Label>
                              <div className="relative mt-1">
                                <Input
                                  id={`zone-${zone.id}-same-day-cutoff`}
                                  type="time"
                                  value={zone.pricing?.sameDay?.cutoff || ''}
                                  onChange={(e) => handleZoneSameDayUpdate(zone.id, { cutoff: e.target.value })}
                                  className="w-full"
                                  aria-label="Same day cutoff time"
                                  placeholder="17:00"
                                />
                              </div>
                              <p className="text-xs text-gray-500 mt-1">
                                Orders must be received before this time to qualify for same day service in this zone (leave empty if not applicable).
                              </p>
                            </div>

                            {/* Same Day Turnaround Time field has been removed for zones */}
                          </div>
                        </div>
                      )}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        )}
      </div>
    );
  };

  const renderScheduleContent = () => {
    const schedule = pudData.schedule || {};
    const timeWindows = schedule.timeWindows || {};
    const serviceAreas = pudData.serviceAreas || {};
    const zones = serviceAreas.zones || [];
    const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const renderTimeWindowForm = (onAdd, zoneId = null) => (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        <div>
          <Label htmlFor={zoneId ? `day-${zoneId}` : "day"}>Day</Label>
          <select
            id={zoneId ? `day-${zoneId}` : "day"}
            className="w-full mt-1 rounded-md border border-gray-300 shadow-sm px-3 py-2 text-sm"
            value={newWindow.day}
            onChange={(e) => setNewWindow({...newWindow, day: e.target.value})}
          >
            <option value="">Select day</option>
            {weekdays.map(day => (
              <option key={day} value={day}>{day}</option>
            ))}
          </select>
        </div>
        
        <div>
          <Label htmlFor={zoneId ? `start-time-${zoneId}` : "start-time"}>Start Time</Label>
          <Input
            id={zoneId ? `start-time-${zoneId}` : "start-time"}
            type="time"
            value={newWindow.startTime}
            onChange={(e) => setNewWindow({...newWindow, startTime: e.target.value})}
            className="mt-1"
          />
          </div>
          
                <div>
          <Label htmlFor={zoneId ? `end-time-${zoneId}` : "end-time"}>End Time</Label>
          <div className="flex items-center gap-2">
            <Input
              id={zoneId ? `end-time-${zoneId}` : "end-time"}
              type="time"
              value={newWindow.endTime}
              onChange={(e) => setNewWindow({...newWindow, endTime: e.target.value})}
              className="mt-1 flex-1"
            />
                      <Button
                        type="button"
              onClick={onAdd}
              disabled={!newWindow.day || !newWindow.startTime || !newWindow.endTime}
              className="mt-1 bg-gray-900 hover:bg-gray-800 text-white"
            >
              <Plus className="w-4 h-4" />
                      </Button>
                  </div>
                </div>
      </div>
    );

    const renderTimeWindowsList = (windows, onRemove) => {
      if (Object.keys(windows).length === 0) {
        return (
          <div className="text-center p-4 border border-dashed border-gray-300 rounded-md">
            <p className="text-sm text-gray-500">No time windows added yet</p>
          </div>
        );
      }
      
      return (
        <div className="space-y-4">
          {Object.entries(windows).map(([day, dayWindows]) => (
            <div key={`time-${day}`} className="border border-gray-200 rounded-md p-3">
              <h5 className="font-medium text-sm mb-2">{day}</h5>
              <div className="space-y-2">
                {dayWindows.map((window, index) => (
                  <div key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded">
                    <span className="text-sm">
                      {formatTimeDisplay(window.startTime)} - {formatTimeDisplay(window.endTime)}
                    </span>
                    <button
                        type="button"
                      onClick={() => onRemove(day, index)}
                      className="text-gray-500 hover:text-red-500"
                    >
                      <Trash className="w-4 h-4" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      );
    };

    return (
      <div className="space-y-6">
        {/* Default Schedule */}
        <div className="bg-white rounded-lg border border-gray-200 p-6">
          <div className="mb-4">
            <h3 className="text-lg font-medium text-gray-900">Default Pickup & Delivery Schedule</h3>
            <p className="text-sm text-gray-500 mt-1">
              Define the default schedule for when customers can request pickup and delivery services
            </p>
          </div>
          
          <div className="space-y-4">
            <h4 className="text-sm font-medium text-gray-700">Time Windows</h4>
            <p className="text-xs text-gray-500">
              Add time windows when customers can schedule pickups and deliveries. A window is a period of time when laundry will be picked up or delivered (e.g., 8am-12pm).
            </p>
            
            {renderTimeWindowForm(() => handleAddTimeWindow(newWindow.day, newWindow.startTime, newWindow.endTime))}
            
            {/* Display existing time windows */}
            <div className="mt-4">
              {renderTimeWindowsList(timeWindows, handleRemoveTimeWindow)}
            </div>
          </div>
        </div>

        {/* Zone-specific Schedules */}
        {zones.length > 1 && (
          <div className="bg-white rounded-lg border border-gray-200 p-6 space-y-4">
            <h3 className="text-lg font-medium text-gray-900">Zone-Specific Schedules</h3>
            <p className="text-sm text-gray-500">
              Configure schedules for specific service zones. If no schedule is set, the default schedule will be used.
            </p>
            
            <Accordion
              type="single"
              collapsible
              className="w-full space-y-3"
            >
              {zones.map((zone) => (
                <AccordionItem 
                  key={`schedule-${zone.id}`}
                  value={zone.id}
                  className="border border-gray-200 rounded-lg overflow-hidden shadow-sm px-0 py-0 border-b-0 mb-3"
                >
                  <AccordionTrigger className="px-4 py-3 hover:bg-gray-50 hover:no-underline w-full [&[data-state=open]>svg]:rotate-180">
                    <div className="flex justify-between items-center w-full">
                      <div className="flex items-center">
                        <Clock className="w-5 h-5 text-gray-500 mr-2" />
                        <span className="font-medium text-gray-900">{zone.name} Schedule</span>
                        {zone.schedule?.timeWindows && 
                        Object.keys(zone.schedule.timeWindows).length > 0 && 
                        JSON.stringify(zone.schedule.timeWindows) !== JSON.stringify(schedule.timeWindows) && (
                          <span className="ml-2 px-2 py-1 text-xs bg-blue-100 text-blue-800 rounded-full">
                            Custom Schedule
                          </span>
                        )}
                      </div>
                      <div
                        role="button"
                        tabIndex={0}
                        className="p-1 h-8 text-gray-500 hover:text-blue-600 hover:bg-gray-100 mr-2 rounded-md flex items-center justify-center cursor-pointer text-xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          copyGlobalScheduleToZone(zone.id);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault();
                            e.stopPropagation();
                            copyGlobalScheduleToZone(zone.id);
                          }
                        }}
                      >
                        <span className="text-xs">Copy Default</span>
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="px-4 pb-4 pt-0 bg-gray-50 border-t border-gray-200">
                    <div className="pt-3 space-y-4">
                      <p className="text-sm text-gray-700">
                        Add time windows specific to the {zone.name} service zone
                      </p>
                      
                      {renderTimeWindowForm(
                        () => handleAddZoneTimeWindow(zone.id, newWindow.day, newWindow.startTime, newWindow.endTime),
                        zone.id
                      )}
                      
                      <div className="mt-4">
                        {renderTimeWindowsList(
                          zone.schedule?.timeWindows || {},
                          (day, index) => handleRemoveZoneTimeWindow(zone.id, day, index)
                        )}
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        )}
      </div>
    );
  };

  const renderInstructionsContent = () => {
    const instructions = pudData.instructions || {};
    
    return (
      <div className="space-y-4">
        <div className="bg-white rounded-lg border border-gray-200 p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">First Pickup Instructions</h3>
          
          <div>
            <Label htmlFor="first-pickup-instructions">
              Instructions for the first pickup
            </Label>
            <Textarea
              id="first-pickup-instructions"
              value={instructions.firstPickup || ''}
              onChange={(e) => handleInstructionsUpdate({ firstPickup: e.target.value })}
              placeholder="Enter any special instructions for the first pickup..."
              className="mt-1 min-h-[150px] w-full"
            />
            <p className="text-xs text-gray-500 mt-1">
              Provide any special instructions or notes for the driver during the first pickup
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderOtherContent = () => {
    const other = pudData.other || {};
    
    return (
      <div className="space-y-4">
        <div className="bg-white rounded-lg border border-gray-200 p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Additional Information</h3>
          
          <div>
            <Label htmlFor="special-instructions">Special instructions or notes</Label>
            <Textarea
              id="special-instructions"
              value={other.notes || ''}
              onChange={(e) => handleOtherUpdate({ notes: e.target.value })}
              placeholder="Any additional information about your Pickup & Delivery service..."
              className="mt-1 h-32"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderDiscountsContent = () => {
    // Get discounts data safely
    const discounts = pudData.discounts || {};
    const recurring = discounts.recurring || {};
    const weekly = recurring.weekly || { enabled: false, rate: '' };
    const biweekly = recurring.biweekly || { enabled: false, rate: '' };
    const monthly = recurring.monthly || { enabled: false, rate: '' };
    
    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <Percent className="w-5 h-5 text-gray-400" />
            <div>
              <span className="text-sm font-medium text-gray-700">Different Discounts than Drop Off?</span>
              <p className="text-xs text-gray-500 mt-1">
                Set special pricing options for pickup & delivery that differ from your drop-off service.
              </p>
            </div>
          </div>
          <BlackSwitch 
            checked={discounts.enabled || false} 
            onCheckedChange={(checked) => handleDiscountsUpdate({ enabled: checked })}
          />
        </div>

        <AnimatePresence>
          {discounts.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
              className="space-y-4"
            >
              <div className="p-4 bg-white rounded-lg border border-gray-200">
                <h4 className="text-sm font-medium text-gray-900 mb-2">Recurring Service Discounts</h4>
                <p className="text-xs text-gray-500 mb-4">
                  Offer special pricing for customers who sign up for recurring wash & fold service.
                </p>
                      
                <div className="overflow-hidden border border-gray-200 rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Frequency
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Offer
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Rate ($ per lb)
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {/* Weekly Row */}
                      <tr>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">Weekly</div>
                          <div className="text-xs text-gray-500">Every week</div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <BlackSwitch
                            checked={weekly.enabled}
                            onCheckedChange={(checked) => handleRecurringDiscountUpdate('weekly', { enabled: checked })}
                          />
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex items-center px-2 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                              <span className="text-gray-500">$</span>
                            </div>
                            <Input
                              type="number"
                              min="0"
                              step="0.01"
                              value={rawInputValues.weeklyRate || weekly.rate || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setRawInputValues(prev => ({ ...prev, weeklyRate: value }));
                                
                                if (value.trim() === '') return;
                                const numValue = parseFloat(value);
                                
                                if (!isNaN(numValue)) {
                                  handleRecurringDiscountUpdate('weekly', { rate: numValue });
                                }
                              }}
                              className="rounded-l-none w-20"
                              placeholder="0.00"
                              disabled={!weekly.enabled}
                            />
                          </div>
                        </td>
                      </tr>

                      {/* Bi-weekly Row */}
                      <tr>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">Bi-weekly</div>
                          <div className="text-xs text-gray-500">Every two weeks</div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <BlackSwitch
                            checked={biweekly.enabled}
                            onCheckedChange={(checked) => handleRecurringDiscountUpdate('biweekly', { enabled: checked })}
                          />
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex items-center px-2 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                              <span className="text-gray-500">$</span>
                            </div>
                            <Input
                              type="number"
                              min="0"
                              step="0.01"
                              value={rawInputValues.biweeklyRate || biweekly.rate || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setRawInputValues(prev => ({ ...prev, biweeklyRate: value }));
                                
                                if (value.trim() === '') return;
                                const numValue = parseFloat(value);
                                
                                if (!isNaN(numValue)) {
                                  handleRecurringDiscountUpdate('biweekly', { rate: numValue });
                                }
                              }}
                              className="rounded-l-none w-20"
                              placeholder="0.00"
                              disabled={!biweekly.enabled}
                            />
                          </div>
                        </td>
                      </tr>

                      {/* Monthly Row */}
                      <tr>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">Monthly</div>
                          <div className="text-xs text-gray-500">Once a month</div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <BlackSwitch
                            checked={monthly.enabled}
                            onCheckedChange={(checked) => handleRecurringDiscountUpdate('monthly', { enabled: checked })}
                          />
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex items-center px-2 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                              <span className="text-gray-500">$</span>
                            </div>
                            <Input
                              type="number"
                              min="0"
                              step="0.01"
                              value={rawInputValues.monthlyRate || monthly.rate || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setRawInputValues(prev => ({ ...prev, monthlyRate: value }));
                                
                                if (value.trim() === '') return;
                                const numValue = parseFloat(value);
                                
                                if (!isNaN(numValue)) {
                                  handleRecurringDiscountUpdate('monthly', { rate: numValue });
                                }
                              }}
                              className="rounded-l-none w-20"
                              placeholder="0.00"
                              disabled={!monthly.enabled}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                <div className="mt-3 text-sm text-gray-500 flex items-start gap-2">
                  <CheckCircle className="w-4 h-4 text-gray-400 mt-0.5 flex-shrink-0" />
                  <span>These rates will override the regular price per pound when a customer selects a recurring service.</span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  const renderExtrasContent = () => {
    // Get extras data safely
    const extras = pudData.extras || {};
    const specialtyItems = extras.specialtyItems || { enabled: false, items: [] };
    const addons = extras.addons || { enabled: false, items: [] };
    
    const openExtrasModal = (type) => {
      setExtrasModalType(type);
      setShowExtrasModal(true);
    };
    
    const closeExtrasModal = () => {
      setShowExtrasModal(false);
    };
    
    const handleSaveExtrasItems = (items) => {
      if (extrasModalType === 'specialty') {
        handleExtrasUpdate('specialtyItems', { items });
      } else if (extrasModalType === 'addon') {
        handleExtrasUpdate('addons', { items });
      }
    };
    
    return (
      <div className="space-y-4">
        {/* Specialty Items Section */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <ShoppingBag className="w-5 h-5 text-gray-400" />
            <div>
              <span className="text-sm font-medium text-gray-700">Different Than Drop Off?</span>
              <p className="text-xs text-gray-500 mt-1">
                Configure specialty items specifically for pickup & delivery service.
              </p>
            </div>
          </div>
          <BlackSwitch 
            checked={specialtyItems.enabled || false} 
            onCheckedChange={(checked) => handleExtrasUpdate('specialtyItems', { enabled: checked })}
          />
        </div>

        <AnimatePresence>
          {specialtyItems.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
            >
              <div className="p-4 rounded-lg bg-white border border-gray-200">
                {specialtyItems.items && specialtyItems.items.length > 0 ? (
                  <div className="space-y-3">
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-sm font-medium">Specialty Items ({specialtyItems.items.length})</span>
                      <Button 
                        variant="outline" 
                        size="sm"
                        onClick={() => openExtrasModal('specialty')}
                        className="flex items-center gap-1"
                      >
                        <Edit className="w-3 h-3" />
                        <span>Edit Items</span>
                      </Button>
                    </div>
                    <div className="max-h-36 overflow-y-auto pr-2">
                      {specialtyItems.items.map((item, index) => (
                        <div key={index} className="flex justify-between items-center py-2 border-b border-gray-100 last:border-0">
                          <span className="text-sm">{item.name}</span>
                          <div className="flex items-center gap-3">
                            <span className="text-sm font-medium">${item.price}</span>
                            {item.chargeByWeight && (
                              <span className="text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded">+Weight</span>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center border border-dashed border-gray-300 rounded-md p-6">
                    <button 
                      type="button"
                      className="flex items-center gap-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                      onClick={() => openExtrasModal('specialty')}
                    >
                      <Plus className="w-4 h-4" />
                      <span>Add Specialty Item</span>
                    </button>
                  </div>
                )}
                {!specialtyItems.items || specialtyItems.items.length === 0 && (
                  <p className="text-xs text-gray-500 text-center mt-3">
                    Click to configure specialty items and their pricing
                  </p>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Service Add-ons Section (Separate) */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <Plus className="w-5 h-5 text-gray-400" />
            <div>
              <span className="text-sm font-medium text-gray-700">Different Than Drop Off?</span>
              <p className="text-xs text-gray-500 mt-1">
                Configure service add-ons specifically for pickup & delivery service.
              </p>
            </div>
          </div>
          <BlackSwitch 
            checked={addons.enabled || false} 
            onCheckedChange={(checked) => handleExtrasUpdate('addons', { enabled: checked })}
          />
        </div>
        
        <AnimatePresence>
          {addons.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
            >
              <div className="p-4 rounded-lg bg-white border border-gray-200">
                {addons.items && addons.items.length > 0 ? (
                  <div className="space-y-3">
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-sm font-medium">Service Add-ons ({addons.items.length})</span>
                      <Button 
                        variant="outline" 
                        size="sm"
                        onClick={() => openExtrasModal('addon')}
                        className="flex items-center gap-1"
                      >
                        <Edit className="w-3 h-3" />
                        <span>Edit Add-ons</span>
                      </Button>
                    </div>
                    <div className="max-h-36 overflow-y-auto pr-2">
                      {addons.items.map((item, index) => (
                        <div key={index} className="flex justify-between items-center py-2 border-b border-gray-100 last:border-0">
                          <span className="text-sm">{item.name}</span>
                          <div className="flex items-center">
                            <span className="text-sm font-medium">${item.price}</span>
                            <span className="text-xs text-gray-500 ml-1">/ {item.unit}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center border border-dashed border-gray-300 rounded-md p-6">
                    <button 
                      type="button"
                      className="flex items-center gap-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                      onClick={() => openExtrasModal('addon')}
                    >
                      <Plus className="w-4 h-4" />
                      <span>Add Add-on Service</span>
                    </button>
                  </div>
                )}
                {!addons.items || addons.items.length === 0 && (
                  <p className="text-xs text-gray-500 text-center mt-3">
                    Click to configure additional services customers can select
                  </p>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        
        {/* Modal for editing extras */}
        {showExtrasModal && (
          <ExtrasModal 
            open={showExtrasModal}
            onClose={closeExtrasModal}
            type={extrasModalType === 'specialty' ? 'specialty' : 'addon'}
            items={extrasModalType === 'specialty' ? specialtyItems.items || [] : addons.items || []}
            onSave={handleSaveExtrasItems}
          />
        )}
      </div>
    );
  };

  // Flag to track if data has been fetched already
  const dataFetchedRef = useRef(false);

  // Effect to fetch initial data from database on mount
  useEffect(() => {
    // Only run this effect once
    const fetchInitialData = async () => {
      // Only proceed if we have locationData with an id, the data prop is relatively empty, 
      // and we haven't already fetched data
      if (!locationData?.id || 
         (data && Object.keys(data).length > 1 && data.metadata) ||
         dataFetchedRef.current) {
        // If data is already provided, no location ID, or we've already fetched, don't fetch
        return;
      }
      
      // Set flag to true BEFORE async operation
      dataFetchedRef.current = true;
      
      try {
        const businessType = locationData.business_type || 'laundromat';
        const supabase = getSupabaseClient(businessType);
        
        // Get the location settings from database
        const { data: locationSettings, error } = await supabase
          .from('vapi_location_settings')
          .select('metadata')
          .eq('id', locationData.id)
          .maybeSingle();
          
        if (error) {
          return;
        }
        
        if (locationSettings?.metadata?.services?.[2]?.pickupDelivery) {
          // Extract the pickupDelivery metadata
          const pickupDeliveryData = locationSettings.metadata.services[2].pickupDelivery;
          
          // Build component data from metadata
          const newPudData = {
            ...pudData,
            enabled: pickupDeliveryData.enabled !== false,
            
            // Pricing
            pricing: {
              ...(pudData.pricing || {}),
              regularPerPound: pickupDeliveryData.pricing?.regular_price_per_pound || '',
              sameDay: {
                ...(pudData.pricing?.sameDay || {}),
                enabled: pickupDeliveryData.same_day?.enabled || false,
                pricePerPound: pickupDeliveryData.same_day?.price_per_pound || '',
                cutoff: pickupDeliveryData.same_day?.cutoff_time || '',
                turnaroundHours: pickupDeliveryData.same_day?.turnaround_hours || ''
              }
            },
            
            // Delivery
            delivery: {
              ...(pudData.delivery || {}),
              fee: pickupDeliveryData.pricing?.delivery_fee || '',
              minimumOrder: {
                ...(pudData.delivery?.minimumOrder || {}),
                enabled: pickupDeliveryData.pricing?.minimum_order?.enabled || false,
                isDollarBased: pickupDeliveryData.pricing?.minimum_order?.is_dollar_based || true,
                amount: pickupDeliveryData.pricing?.minimum_order?.amount || ''
              }
            },
            
            // Turnaround
            turnaround: {
              ...(pudData.turnaround || {}),
              standardHours: pickupDeliveryData.turnaround?.standard_hours || ''
            },
            
            // Schedule
            schedule: {
              ...(pudData.schedule || {}),
              timeWindows: pickupDeliveryData.schedule?.time_windows ? 
                Object.entries(pickupDeliveryData.schedule.time_windows).reduce((acc, [day, windows]) => {
                  const capitalizedDay = day.charAt(0).toUpperCase() + day.slice(1);
                  acc[capitalizedDay] = windows.map(window => ({
                    startTime: window.start_time,
                    endTime: window.end_time
                  }));
                  return acc;
                }, {}) : {}
            },
            
            // Instructions
            instructions: {
              ...(pudData.instructions || {}),
              firstPickup: pickupDeliveryData.instructions?.first_pickup || ''
            },
            
            // Discounts
            discounts: {
              ...(pudData.discounts || {}),
              enabled: pickupDeliveryData.discounts?.enabled || false,
              recurring: {
                ...(pudData.discounts?.recurring || {}),
                weekly: {
                  ...(pudData.discounts?.recurring?.weekly || {}),
                  enabled: pickupDeliveryData.discounts?.recurring?.weekly?.enabled || false,
                  rate: pickupDeliveryData.discounts?.recurring?.weekly?.rate || ''
                },
                biweekly: {
                  ...(pudData.discounts?.recurring?.biweekly || {}),
                  enabled: pickupDeliveryData.discounts?.recurring?.biweekly?.enabled || false,
                  rate: pickupDeliveryData.discounts?.recurring?.biweekly?.rate || ''
                },
                monthly: {
                  ...(pudData.discounts?.recurring?.monthly || {}),
                  enabled: pickupDeliveryData.discounts?.recurring?.monthly?.enabled || false,
                  rate: pickupDeliveryData.discounts?.recurring?.monthly?.rate || ''
                }
              }
            },
            
            // Extras
            extras: {
              ...(pudData.extras || {}),
              specialtyItems: {
                ...(pudData.extras?.specialtyItems || {}),
                enabled: pickupDeliveryData.extras?.specialty_items?.enabled || false,
                items: pickupDeliveryData.extras?.specialty_items?.items?.map(item => ({
                  name: item.name || '',
                  price: item.price || '',
                  chargeByWeight: item.charge_by_weight || false
                })) || []
              },
              addons: {
                ...(pudData.extras?.addons || {}),
                enabled: pickupDeliveryData.extras?.addons?.enabled || false,
                items: pickupDeliveryData.extras?.addons?.items?.map(item => ({
                  name: item.name || '',
                  price: item.price || '',
                  unit: item.unit || ''
                })) || []
              }
            },
            
            // Service Areas
            serviceAreas: {
              ...(pudData.serviceAreas || {}),
              zones: pickupDeliveryData.service_areas?.zones?.map(zone => ({
                id: zone.id,
                name: zone.name || '',
                zipcodes: zone.zipcodes || [],
                pricing: zone.pricing ? {
                  regularPerPound: zone.pricing.regular_price_per_pound || '',
                  deliveryFee: zone.pricing.delivery_fee || '',
                  minimumOrder: zone.pricing.minimum_order || '',
                  sameDay: zone.pricing.same_day ? {
                    enabled: zone.pricing.same_day.enabled || false,
                    pricePerPound: zone.pricing.same_day.price_per_pound || '',
                    cutoff: zone.pricing.same_day.cutoff_time || '',
                    turnaroundHours: zone.pricing.same_day.turnaround_hours || ''
                  } : null
                } : null,
                turnaround: zone.turnaround ? {
                  standardHours: zone.turnaround.standard_hours || ''
                } : null,
                schedule: zone.schedule ? {
                  timeWindows: zone.schedule.time_windows ? 
                    Object.entries(zone.schedule.time_windows).reduce((acc, [day, windows]) => {
                      const capitalizedDay = day.charAt(0).toUpperCase() + day.slice(1);
                      acc[capitalizedDay] = windows.map(window => ({
                        startTime: window.start_time,
                        endTime: window.end_time
                      }));
                      return acc;
                    }, {}) : {}
                } : null
              })) || []
            },
            
            // Other
            other: {
              ...(pudData.other || {}),
              notes: pickupDeliveryData.notes || ''
            }
          };
          
          // Update state with the fetched data
          setPudData(newPudData);
          
          // Save as previous metadata to prevent immediate re-save
          prevMetadataRef.current = {
            services: {
              "2": {
                pickupDelivery: pickupDeliveryData
              }
            }
          };
        }
      } catch (error) {
        console.error("Error fetching pickup delivery data:", error);
      }
    };
    
    fetchInitialData();
    
    // Just depend on locationData.id and data properties, not pudData
  }, [locationData?.id, data]);

  // Clean up any existing turnaroundHours data
  useEffect(() => {
    setPudData(prev => {
      let updated = false;
      let newData = { ...prev };
      
      // Clean up global same day turnaround hours
      if (newData.pricing?.sameDay?.turnaroundHours !== undefined) {
        updated = true;
        const { turnaroundHours, ...restSameDay } = newData.pricing.sameDay;
        newData.pricing.sameDay = restSameDay;
      }
      
      // Clean up zone-specific same day turnaround hours
      if (newData.serviceAreas?.zones?.length > 0) {
        const updatedZones = newData.serviceAreas.zones.map(zone => {
          if (zone.pricing?.sameDay?.turnaroundHours !== undefined) {
            updated = true;
            const { turnaroundHours, ...restSameDay } = zone.pricing.sameDay;
            return {
              ...zone,
              pricing: {
                ...zone.pricing,
                sameDay: restSameDay
              }
            };
          }
          return zone;
        });
        
        if (updated) {
          newData.serviceAreas = {
            ...newData.serviceAreas,
            zones: updatedZones
          };
        }
      }
      
      // Only trigger an update if something changed
      if (updated) {
        // Use handleUpdate to ensure database gets updated
        setTimeout(() => {
          handleUpdate(newData);
        }, 0);
        return newData;
      }
      
      return prev;
    });
  }, []);

  return (
    <div className="space-y-6">
      <div className="border-b">
        <ScrollableTabs
          tabs={[
            {
              id: 'general',
              label: 'General',
              onClick: () => setActiveTab('general'),
              isActive: activeTab === 'general',
              indicator: null // Always set to null instead of the conditional
            },
            {
              id: 'serviceAreas',
              label: 'Service Areas',
              onClick: () => setActiveTab('serviceAreas'),
              isActive: activeTab === 'serviceAreas',
              indicator: pudData.enabled && !requiredFieldsStatus.serviceAreas ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'pricing',
              label: 'Pricing',
              onClick: () => setActiveTab('pricing'),
              isActive: activeTab === 'pricing',
              indicator: pudData.enabled && !requiredFieldsStatus.pricing ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'turnaround',
              label: 'Turnaround',
              onClick: () => {
                setActiveTab('turnaround');
                setActiveZoneId(null);
              },
              isActive: activeTab === 'turnaround',
              indicator: pudData.enabled && !requiredFieldsStatus.turnaround ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'schedule',
              label: 'Schedule',
              onClick: () => setActiveTab('schedule'),
              isActive: activeTab === 'schedule',
              indicator: pudData.enabled && !requiredFieldsStatus.schedule ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'instructions',
              label: 'Instructions',
              onClick: () => setActiveTab('instructions'),
              isActive: activeTab === 'instructions',
              indicator: pudData.enabled && !requiredFieldsStatus.instructions ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'discounts',
              label: 'Discounts',
              onClick: () => setActiveTab('discounts'),
              isActive: activeTab === 'discounts',
              indicator: pudData.enabled && !requiredFieldsStatus.discounts ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'extras',
              label: 'Extras',
              onClick: () => setActiveTab('extras'),
              isActive: activeTab === 'extras',
              indicator: pudData.enabled && !requiredFieldsStatus.extras ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'other',
              label: 'Other',
              onClick: () => setActiveTab('other'),
              isActive: activeTab === 'other'
            }
          ]}
          className="px-2 py-2"
        />
      </div>

      <div className="p-2 sm:p-4">
        {activeTab === 'general' && renderGeneralContent()}
        {activeTab === 'serviceAreas' && renderServiceAreasContent()}
        {activeTab === 'pricing' && renderPricingContent()}
        {activeTab === 'turnaround' && renderTurnaroundContent()}
        {activeTab === 'schedule' && renderScheduleContent()}
        {activeTab === 'instructions' && renderInstructionsContent()}
        {activeTab === 'discounts' && renderDiscountsContent()}
        {activeTab === 'extras' && renderExtrasContent()}
        {activeTab === 'other' && renderOtherContent()}
      </div>

      {/* Delete confirmation modal */}
      <Dialog open={showDeleteConfirmModal} onOpenChange={setShowDeleteConfirmModal}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Confirm Deletion</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p className="text-gray-700">
              Are you sure you want to delete the service zone "{zoneToDelete?.name}"?
            </p>
            <p className="text-sm text-gray-500 mt-2">
              This action cannot be undone. All zipcodes and settings associated with this zone will be removed.
            </p>
          </div>
          <DialogFooter className="flex items-center justify-end gap-2 mt-4">
            <Button 
              variant="outline" 
              onClick={cancelDeleteZone}
            >
              Cancel
            </Button>
            <Button 
              variant="destructive" 
              onClick={confirmDeleteZone}
              className="bg-red-600 hover:bg-red-700 text-white"
            >
              Delete Zone
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SimpleWDFPickupDelivery; 