import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { DollarSign, Clock, Calendar, AlertCircle, Settings, Info, Scale, Percent, CheckCircle, ShoppingBag, Plus, Edit } from 'lucide-react';
import { Switch } from '../ui/switch';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Checkbox } from '../ui/checkbox';
import { Button } from '../ui/button';
import { cn } from '../../lib/utils';
import ScrollableTabs from './ScrollableTabs';
import ExtrasModal from './ExtrasModal';
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle, 
  DialogFooter,
  DialogClose
} from '../ui/dialog';
import { getSupabaseClient } from '../../supabaseClient';

// Custom black Switch component
export const BlackSwitch = (props) => (
  <Switch 
    {...props}
    className={cn(
      "data-[state=checked]:bg-gray-900",
      props.className
    )}
  />
);

// ToggleGroup component for consistent UI
const ToggleGroup = ({
  options = [],
  value,
  onChange,
  className,
  fullWidth = false,
}) => {
  return (
    <div 
      className={cn(
        "flex flex-wrap gap-1",
        fullWidth && "grid grid-cols-3 gap-1",
        className
      )}
    >
      {options.map((option) => (
        <Button
          key={option.value}
          type="button"
          variant="outline"
          className={cn(
            "flex-1",
            "justify-center",
            "whitespace-nowrap",
            value === option.value && "border-gray-900 text-gray-900 font-medium"
          )}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
};

// Function to convert metadata format to component state format
const buildStateFromMetadata = (metadata, defaultState = {}) => {
  if (!metadata) return defaultState;
  
  // Start with default state
  const stateData = { ...defaultState };
  
  // Set enabled status
  stateData.enabled = metadata.enabled !== false;
  
  // Set general/other notes
  if (metadata.notes) {
    stateData.other = stateData.other || {};
    stateData.other.notes = metadata.notes;
  }
  
  // Service Areas - Add this section to handle service areas from metadata
  if (metadata.service_areas) {
    stateData.serviceAreas = {
      enabled: metadata.service_areas.enabled !== false,
      zipcodes: metadata.service_areas.zipcodes || [],
      zones: metadata.service_areas.zones?.map(zone => ({
        id: zone.id || String(Date.now()),
        name: zone.name || '',
        zipcodes: zone.zipcodes || [],
        fee: zone.fee,
        
        // Map zone-specific pricing, schedule, and turnaround if available
        ...(zone.pricing ? {
          pricing: {
            ...zone.pricing,
            // Handle minimum order specifically
            ...(zone.pricing.minimumOrder ? {
              minimumOrder: zone.pricing.minimumOrder
            } : {})
          },
          customPricing: true
        } : {}),
        
        ...(zone.schedule ? {
          schedule: {
            ...zone.schedule,
            // Ensure timeWindows has the correct structure
            timeWindows: zone.schedule.timeWindows || {}
          },
          customSchedule: true
        } : {}),
        
        ...(zone.turnaround ? {
          turnaround: {
            ...zone.turnaround,
            standardHours: zone.turnaround.standardHours,
            standardDays: zone.turnaround.standardDays || null
          },
          customTurnaround: true
        } : {})
      })) || []
    };
  } else {
    // Default empty service areas structure if none provided
    stateData.serviceAreas = { enabled: false, zipcodes: [], zones: [] };
  }
  
  // Pricing
  if (metadata.pricing) {
    stateData.pricing = stateData.pricing || {};
    
    // Price items
    if (metadata.pricing.items) {
      stateData.pricing.items = metadata.pricing.items.map(item => ({
        name: item.name,
        price: item.price
      }));
    }
    
    // Minimum order
    if (metadata.pricing.minimum_order) {
      stateData.pricing.minimumOrder = {
        enabled: metadata.pricing.minimum_order.enabled !== false,
        amount: metadata.pricing.minimum_order.amount
      };
    }
  }
  
  // Turnaround times
  if (metadata.turnaround) {
    stateData.turnaround = stateData.turnaround || {};
    
    // Standard turnaround days (handle null - convert to empty string)
    if ('standard_days' in metadata.turnaround) {
      stateData.turnaround.standard_days = metadata.turnaround.standard_days === null ? '' : metadata.turnaround.standard_days;
    }
    
    // Express turnaround
    if (metadata.turnaround.express_enabled) {
      stateData.turnaround.expressEnabled = true;
      stateData.turnaround.expressHours = metadata.turnaround.express_hours;
      stateData.turnaround.expressFee = metadata.turnaround.express_fee;
    } else {
      // Ensure expressEnabled is explicitly set to false when not enabled
      stateData.turnaround.expressEnabled = false;
    }
    
    // Same day service
    if (metadata.turnaround.same_day && metadata.turnaround.same_day.enabled) {
      stateData.pricing = stateData.pricing || {};
      stateData.pricing.sameDay = {
        enabled: true,
        cutoff: metadata.turnaround.same_day.cutoff,
        turnaroundHours: metadata.turnaround.same_day.turnaround_hours
      };
    } else if (metadata.turnaround.same_day) {
      // Ensure sameDay.enabled is explicitly set to false when present but not enabled
      stateData.pricing = stateData.pricing || {};
      stateData.pricing.sameDay = {
        enabled: false,
        cutoff: metadata.turnaround.same_day.cutoff || '',
        turnaroundHours: metadata.turnaround.same_day.turnaround_hours || ''
      };
    }
  }
  
  // Schedule
  if (metadata.schedule) {
    metadata['schedule'] = {};
    metadata['schedule']['mode'] = metadata.schedule.mode || 'same_every_day';
    
    if (metadata.schedule.mode === 'same_every_day' && metadata.schedule.sameTimeEveryday) {
      metadata['schedule']['open_time'] = metadata.schedule.sameTimeEveryday.open_time;
      metadata['schedule']['close_time'] = metadata.schedule.sameTimeEveryday.close_time;
    } else if (metadata.schedule.mode === 'custom_by_day') {
      if (metadata.schedule.business_hours && Object.keys(metadata.schedule.business_hours).length > 0) {
        metadata['schedule']['business_hours'] = {};
        
        Object.entries(metadata.schedule.business_hours).forEach(([day, dayData]) => {
          metadata['schedule']['business_hours'][day] = {
            is_open: dayData.is_open,
            open_time: dayData.open_time,
            close_time: dayData.close_time
          };
        });
      } 
      else if (metadata.schedule.days && metadata.schedule.days.length > 0) {
        metadata['schedule']['days'] = metadata.schedule.days.map(day => ({
          day_of_week: day.dayOfWeek,
          enabled: day.enabled !== false,
          open_time: day.openTime,
          close_time: day.closeTime
        }));
      }
    }
  }
  
  // Discounts
  if (metadata.discounts) {
    stateData.discounts = stateData.discounts || {};
    stateData.discounts.enabled = metadata.discounts.enabled !== false;
    
    if (metadata.discounts.recurring) {
      stateData.discounts.recurring = stateData.discounts.recurring || {};
      
      // Weekly discount
      if (metadata.discounts.recurring.weekly) {
        stateData.discounts.recurring.weekly = {
          enabled: metadata.discounts.recurring.weekly.enabled !== false,
          rate: metadata.discounts.recurring.weekly.rate
        };
      }
      
      // Biweekly discount
      if (metadata.discounts.recurring.biweekly) {
        stateData.discounts.recurring.biweekly = {
          enabled: metadata.discounts.recurring.biweekly.enabled !== false,
          rate: metadata.discounts.recurring.biweekly.rate
        };
      }
      
      // Monthly discount
      if (metadata.discounts.recurring.monthly) {
        stateData.discounts.recurring.monthly = {
          enabled: metadata.discounts.recurring.monthly.enabled !== false,
          rate: metadata.discounts.recurring.monthly.rate
        };
      }
    }
  }
  
  // Extras
  if (metadata.extras) {
    stateData.extras = stateData.extras || {};
    
    // Addons
    if (metadata.extras.addons) {
      stateData.extras.addons = {
        enabled: metadata.extras.addons.enabled !== false,
        items: metadata.extras.addons.items?.map(item => ({
          name: item.name,
          price: item.price,
          unit: item.unit || 'item'
        })) || []
      };
    }
  }
  
  return stateData;
};

/**
 * A simplified Dry Cleaning Drop-Off component
 */
const SimpleDCDropOff = ({ data = {}, onChange, locationData, fieldVisibility = {} }) => {
  // Extract existing properties from data
  const { 
    enabled = true, 
    pricing, 
    turnaround, 
    schedule, 
    sameDay, 
    discounts, 
    extras, 
    other, 
    instructions,
    serviceAreas 
  } = data;

  // Set default enabled value to true
  const defaultData = { ...data };
  if (defaultData.enabled === undefined) {
    defaultData.enabled = true;
  }
  
  // Set default schedule if not present
  if (!defaultData.schedule) {
    defaultData.schedule = {
      mode: 'same_every_day',
      sameTimeEveryday: {
        open_time: '',
        close_time: ''
      }
    };
  } else if (!defaultData.schedule.mode) {
    // If schedule exists but mode is not set, default to same_every_day
    defaultData.schedule.mode = 'same_every_day';
    // Initialize sameTimeEveryday if needed
    if (!defaultData.schedule.sameTimeEveryday) {
      defaultData.schedule.sameTimeEveryday = {
        open_time: '',
        close_time: ''
      };
    }
  }
  
  // Set default service areas if not present
  if (!defaultData.serviceAreas) {
    defaultData.serviceAreas = {
      enabled: false,
      zipcodes: [],
      zones: []
    };
  }

  // Set up state with minimal initialization
  const [dcData, setDcData] = useState(defaultData || {
    enabled: true
  });
  
  // Add loading state to prevent showing indicators until data is fully loaded
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  
  // Previous metadata ref to prevent unnecessary database updates
  const prevMetadataRef = useRef(null);
  
  // Debounce function to prevent too many database calls
  const debounce = (func, delay) => {
    let timer;
    return function(...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };
  
  // Function to update the database with the latest metadata
  const updateDatabase = async (metadata) => {
    try {
      // Only proceed if we have locationData with an id
      if (!locationData || !locationData.id) {
        return;
      }
      
      // Get the Supabase client - use business type from location data if available
      const businessType = locationData.business_type || 'laundromat';
      const supabase = getSupabaseClient(businessType);
      
      // First check if the record exists
      const { data: existingSettings, error: fetchError } = await supabase
        .from('vapi_location_settings')
        .select('id, metadata')
        .eq('id', locationData.id)
        .maybeSingle();
      
      if (fetchError) {
        return;
      }
      
      let result;
      
      // If record exists, update it
      if (existingSettings) {
        // Merge with existing metadata to preserve other services
        const existingMetadata = existingSettings.metadata || {};
        if (!existingMetadata.services) existingMetadata.services = {};
        
        // Prepare merged metadata preserving other services
        const mergedMetadata = {
          ...existingMetadata,
          services: {
            ...existingMetadata.services,
            "3": {
              ...(existingMetadata.services["3"] || {}),
              ...metadata.services["3"]
            }
          }
        };
        
        result = await supabase
          .from('vapi_location_settings')
          .update({ 
            metadata: mergedMetadata
          })
          .eq('id', locationData.id);
      } else {
        // If record doesn't exist, create it
        result = await supabase
          .from('vapi_location_settings')
          .insert({ 
            id: locationData.id,
            metadata: metadata
          });
      }
    } catch (error) {
      // Silent error handling
    }
  };
  
  // Create a debounced version of updateDatabase
  const debouncedUpdateDatabase = useCallback(
    debounce(updateDatabase, 1000), 
    []
  );
  
  // Function to transform dcData to metadata format
  const transformToMetadata = (data) => {
    // Start with an empty metadata object
    const metadata = {};
    
    // Only populate enabled field if service is disabled
    if (!data.enabled) {
      metadata['enabled'] = false;
      return metadata;
    }
    
    // General tab
    metadata['enabled'] = data.enabled;
    if (data.other?.notes) {
      metadata['notes'] = data.other.notes;
    }
    
    // Pricing tab
    if (data.pricing) {
      metadata['pricing'] = metadata['pricing'] || {};
      
      // Minimum order
      if (data.pricing.minimumOrder) {
        metadata['pricing']['minimum_order'] = {
          enabled: !!data.pricing.minimumOrder.enabled,
          amount: data.pricing.minimumOrder.amount || null
        };
      }
      
      // Pricing items
      if (data.pricing.items && data.pricing.items.length > 0) {
        metadata['pricing']['items'] = data.pricing.items.map(item => ({
          name: item.name,
          price: item.price
        }));
      }
    }
    
    // Turnaround tab
    if (data.turnaround) {
      metadata['turnaround'] = {};
      
      if (data.turnaround.standardHours) {
        metadata['turnaround']['standard_hours'] = data.turnaround.standardHours;
      }
      
      // Handle standard_days specifically - including empty string case
      if (data.turnaround.standard_days !== undefined) {
        metadata['turnaround']['standard_days'] = 
          data.turnaround.standard_days === '' ? null : data.turnaround.standard_days;
      }
      
      if (data.turnaround.expressEnabled) {
        metadata['turnaround']['express_enabled'] = data.turnaround.expressEnabled;
        metadata['turnaround']['express_hours'] = data.turnaround.expressHours;
        metadata['turnaround']['express_fee'] = data.turnaround.expressFee;
      }
      
      // Same day service
      if (data.turnaround.sameDay && data.turnaround.sameDay.enabled) {
        metadata['turnaround']['same_day'] = {
          enabled: true,
          cutoff: data.turnaround.sameDay.cutoff,
          turnaround_hours: data.turnaround.sameDay.turnaroundHours
        };
      }
    }
    
    // Schedule tab
    if (data.schedule) {
      metadata['schedule'] = {};
      metadata['schedule']['mode'] = data.schedule.mode || 'same_every_day';
      
      if (data.schedule.mode === 'same_every_day' && data.schedule.sameTimeEveryday) {
        // Only add open_time and close_time at the schedule level 
        metadata['schedule']['open_time'] = data.schedule.sameTimeEveryday.open_time;
        metadata['schedule']['close_time'] = data.schedule.sameTimeEveryday.close_time;
        
        // Remove this nested object to avoid duplication
        // metadata['schedule']['same_time_everyday'] = {
        //   open_time: data.schedule.sameTimeEveryday.open_time,
        //   close_time: data.schedule.sameTimeEveryday.close_time
        // };
      } else if (data.schedule.mode === 'custom_by_day') {
        // Check if we have business_hours property (new format)
        if (data.schedule.business_hours && Object.keys(data.schedule.business_hours).length > 0) {
          metadata['schedule']['business_hours'] = {};
          
          // Convert business_hours to the correct format
          Object.entries(data.schedule.business_hours).forEach(([day, dayData]) => {
            metadata['schedule']['business_hours'][day] = {
              is_open: dayData.is_open,
              open_time: dayData.open_time,
              close_time: dayData.close_time
            };
          });
        } 
        // Fallback to checking days array (old format)
        else if (data.schedule.days && data.schedule.days.length > 0) {
          metadata['schedule']['days'] = data.schedule.days.map(day => ({
            day_of_week: day.dayOfWeek,
            enabled: day.enabled !== false,
            open_time: day.openTime,
            close_time: day.closeTime
          }));
        }
      }
    }
    
    // Discounts tab
    if (data.discounts) {
      metadata['discounts'] = {
        enabled: !!data.discounts.enabled
      };
      
      if (data.discounts.recurring) {
        metadata['discounts']['recurring'] = {};
        const recurring = data.discounts.recurring;
        
        // Weekly discount
        if (recurring.weekly && recurring.weekly.enabled) {
          metadata['discounts']['recurring']['weekly'] = {
            enabled: true,
            rate: recurring.weekly.rate
          };
        }
        
        // Biweekly discount
        if (recurring.biweekly && recurring.biweekly.enabled) {
          metadata['discounts']['recurring']['biweekly'] = {
            enabled: true,
            rate: recurring.biweekly.rate
          };
        }
        
        // Monthly discount
        if (recurring.monthly && recurring.monthly.enabled) {
          metadata['discounts']['recurring']['monthly'] = {
            enabled: true,
            rate: recurring.monthly.rate
          };
        }
      }
    }
    
    // Extras tab
    if (data.extras) {
      metadata['extras'] = {};
      
      // Add-ons
      if (data.extras.addons) {
        metadata['extras']['addons'] = {
          enabled: !!data.extras.addons.enabled
        };
        
        if (data.extras.addons.items && data.extras.addons.items.length > 0) {
          metadata['extras']['addons']['items'] = data.extras.addons.items.map(item => ({
            name: item.name,
            price: item.price,
            unit: item.unit || 'item'
          }));
        }
      }
    }
    
    return metadata;
  };
  
  // State for modals
  const [extrasModalOpen, setExtrasModalOpen] = useState(false);
  const [extrasModalType, setExtrasModalType] = useState(null);
  const [priceListModalOpen, setPriceListModalOpen] = useState(false);

  const [activeTab, setActiveTab] = useState('general');
  
  const [rawInputValues, setRawInputValues] = useState({
    regularPerPound: '',
    sameDayPrice: '',
    sameDayTurnaroundHours: '',
    minimumAmount: '',
    turnaroundHours: '',
    weeklyRate: '',
    biweeklyRate: '',
    monthlyRate: ''
  });

  // Required fields tracking
  const [requiredFieldsStatus, setRequiredFieldsStatus] = useState({
    general: true,    // Service is enabled by default
    pricing: false,
    turnaround: true, // START AS TRUE to prevent blue dot
    schedule: true,   // START AS TRUE to prevent blue dot
    serviceAreas: true,
    discounts: true,  // Optional
    extras: true      // Optional
  });

  // Move openExtrasModal to component level so it's accessible to all render functions
  const openExtrasModal = (type) => {
    setExtrasModalType(type);
    setExtrasModalOpen(true);
  };

  // Add a useEffect to initialize data from metadata when component mounts
  useEffect(() => {
    // Only run on initial mount
    if (data && data.metadata?.services?.[3]?.dropOff) {
      const metadataDropOff = data.metadata.services[3].dropOff;
      
      // Use the buildStateFromMetadata function to create state data from metadata
      const initialStateFromMetadata = buildStateFromMetadata(metadataDropOff, dcData);
      
      // Update state with the initialized data
      setDcData(initialStateFromMetadata);
      
      // Set required fields status based on loaded data
      updateRequiredFieldsStatus(initialStateFromMetadata);
      
      // Mark data as loaded to allow showing indicators
      setIsDataLoaded(true);
    } else if (locationData?.id) {
      fetchMetadataFromDatabase();
    } else {
      // Even if no metadata is available, mark as loaded after a slight delay
      setTimeout(() => setIsDataLoaded(true), 100);
    }
  }, []);  // Empty dependency array ensures this only runs once on mount
  
  // Function to fetch metadata directly from database
  const fetchMetadataFromDatabase = async () => {
    if (!locationData?.id) {
      // Mark as loaded even if fetch fails
      setIsDataLoaded(true);
      return;
    }
    
    try {
      // Get Supabase client
      const businessType = locationData.business_type || 'laundromat';
      const supabase = getSupabaseClient(businessType);
      
      // Query for location settings
      const { data: settings, error } = await supabase
        .from('vapi_location_settings')
        .select('id, metadata')
        .eq('id', locationData.id)
        .maybeSingle();
      
      if (error) {
        // Mark as loaded even if there's an error
        setIsDataLoaded(true);
        return;
      }
      
      if (settings?.metadata?.services?.[3]?.dropOff) {
        // Use buildStateFromMetadata to transform the metadata to component state
        const metadataDropOff = settings.metadata.services[3].dropOff;
        const initialStateFromMetadata = buildStateFromMetadata(metadataDropOff, dcData);
        
        // Update state with the initialized data
        setDcData(initialStateFromMetadata);
        
        // Update required fields status
        updateRequiredFieldsStatus(initialStateFromMetadata);
        
        // Store metadata to prevent immediate update
        prevMetadataRef.current = {
          services: {
            "3": {
              dropOff: metadataDropOff
            }
          }
        };
      }
      
      // Mark as loaded after data is processed
      setIsDataLoaded(true);
    } catch (error) {
      // Mark as loaded even if there's an error
      setIsDataLoaded(true);
    }
  };
  
  // Helper to update required fields status
  const updateRequiredFieldsStatus = (stateData) => {
    const pricing = stateData.pricing || {};
    const turnaround = stateData.turnaround || {};
    const schedule = stateData.schedule || {};
    const serviceAreas = stateData.serviceAreas || {};
    
    // Check if service areas is valid
    const hasZipcodes = serviceAreas.zipcodes && serviceAreas.zipcodes.length > 0;
    const hasZones = serviceAreas.zones && serviceAreas.zones.length > 0;
    const isServiceAreasValid = serviceAreas.enabled ? (hasZipcodes || hasZones) : true;
    
    // Check if schedule is valid - handle both metadata formats
    let isScheduleValid = false;
    if (schedule.mode === 'self_serve') {
      // If using self-serve hours, it's always valid
      isScheduleValid = true;
    }
    else if (schedule.mode === 'same_every_day') {
      // Check if times are directly in schedule object
      if (schedule.open_time && schedule.close_time) {
        isScheduleValid = true;
      }
      // Also check if times are in the same_time_everyday or sameTimeEveryday object (handle both naming conventions)
      else if ((schedule.same_time_everyday?.open_time && schedule.same_time_everyday?.close_time) ||
               (schedule.sameTimeEveryday?.open_time && schedule.sameTimeEveryday?.close_time)) {
        isScheduleValid = true;
      }
    }
    else if (schedule.mode === 'custom_by_day') {
      // For custom_by_day mode, check if at least one day is open with both times set
      const businessHours = schedule.business_hours || schedule.days || {};
      isScheduleValid = Object.values(businessHours).some(day => 
        day.is_open && day.open_time && day.close_time
      );
    }
    else {
      // If it has a mode but doesn't match known modes, just consider it valid
      isScheduleValid = !!schedule.mode;
    }
    
    setRequiredFieldsStatus(prev => ({
      ...prev,
      pricing: !!pricing.items?.length,
      turnaround: !!turnaround.standardHours,
      schedule: isScheduleValid,
      serviceAreas: isServiceAreasValid,
      discounts: true, // Optional
      extras: true // Optional
    }));
  };

  // Make sure schedule mode is set on first render
  useEffect(() => {
    // If schedule doesn't exist yet, initialize it with defaults
    setDcData(prev => {
      // If we already have a proper schedule with mode, no need to update
      if (prev.schedule?.mode) return prev;
      
      // Create a default schedule
      const updatedData = {
        ...prev,
        schedule: {
          ...(prev.schedule || {}),
          mode: 'same_every_day',
          sameTimeEveryday: {
            ...(prev.schedule?.sameTimeEveryday || {}),
            open_time: prev.schedule?.sameTimeEveryday?.open_time || '',
            close_time: prev.schedule?.sameTimeEveryday?.close_time || ''
          }
        }
      };
      
      // Call onUpdate to propagate initial default values to parent
      if (typeof onChange === 'function') {
        onChange({
          ...updatedData,
          requirementsToAdvance: true // Always allow advancement since this is informational
        });
      }
      
      return updatedData;
    });
  }, []);

  // Effect to update parent component when data changes
  useEffect(() => {
    // Calculate if all required fields are complete
    const allRequiredFieldsComplete = Object.entries(requiredFieldsStatus)
      .filter(([field, isComplete]) => field !== 'other') // Exclude optional fields
      .every(([_, isComplete]) => isComplete === true);

    // Transform the dcData to metadata format
    const metadataFields = transformToMetadata(dcData);
    
    // Create the full metadata object
    const fullMetadata = {
      services: {
        "3": {
          dropOff: metadataFields
        }
      }
    };
    
    // Update the parent with both the full dcData and the metadata
    onChange({
      ...dcData,
      requirementsToAdvance: allRequiredFieldsComplete,
      metadata: fullMetadata
    });
    
    // Compare the new metadata with the previous one to avoid unnecessary updates
    if (prevMetadataRef.current === null || 
        JSON.stringify(prevMetadataRef.current) !== JSON.stringify(fullMetadata)) {
      
      // Save the new metadata as the previous one for future comparison
      prevMetadataRef.current = fullMetadata;
      
      // Only update the database if we have location data
      if (locationData?.id) {
        debouncedUpdateDatabase(fullMetadata);
      }
    }
  }, [dcData, requiredFieldsStatus, onChange, locationData, debouncedUpdateDatabase]);

  // Effect to update required fields status
  useEffect(() => {
    // Only validate if the service is enabled
    if (!dcData.enabled) {
      // If service is disabled, all requirements are considered met
      setRequiredFieldsStatus({
        general: true,
        pricing: true,
        turnaround: true,
        schedule: true,
        discounts: true,
        extras: true
      });
      return;
    }

    // Get references to each section's data safely
    const pricing = dcData.pricing || {};
    const sameDay = pricing.sameDay || {};
    const minimumOrder = pricing.minimumOrder || {};
    const turnaround = dcData.turnaround || {};
    const schedule = dcData.schedule || {};
    const discounts = dcData.discounts || {};
    const recurring = discounts.recurring || {};
    const extras = dcData.extras || {};

    // Check pricing completion requirements:
    // a) Regular price per pound must have a value
    // b) If minimum order is enabled, must have a value
    // c) If same day service is enabled, must have a price value
    const hasPricing = !!pricing.regularPerPound;
    const hasValidMinOrder = !minimumOrder.enabled || !!minimumOrder.amount;
    const hasValidSameDay = !sameDay.enabled || !!sameDay.pricePerPound;
    const hasAtLeastOnePricingItem = Array.isArray(pricing.items) && pricing.items.length > 0;
    
    // If minimum order is not enabled, require at least one pricing item
    // If minimum order is enabled, require both minimum amount and at least one pricing item
    let isPricingComplete = hasPricing && 
                          hasValidMinOrder && 
                          hasValidSameDay && 
                          hasAtLeastOnePricingItem;
                          
    // OVERRIDE: If we have at least one pricing item, consider pricing tab complete
    if (hasAtLeastOnePricingItem) {
      isPricingComplete = true;
    }
    
    // Check turnaround completion requirements:
    // a) Standard turnaround hours must have a value
    // b) If same day is enabled, cutoff time and hours must have values
    const hasTurnaround = !!turnaround.standard_days;
    const hasValidSameDayTurnaround = !sameDay.enabled || 
      (!!sameDay.cutoff && !!sameDay.turnaroundHours);
    const isTurnaroundComplete = hasTurnaround && hasValidSameDayTurnaround;
    
    // Check schedule completion based on mode
    // a) One of the 3 options must be selected
    // b) Same Every Day: open and close times must have values
    // c) Custom By Day: all enabled days must have open and close times
    let hasSchedule = false;
    if (schedule.mode === 'self_serve') {
      // If using self-serve hours, it's always valid
      hasSchedule = true;
    } else if (schedule.mode === 'same_every_day') {
      // If same every day, check that open and close times exist
      const sameTimeEveryday = schedule.sameTimeEveryday || {};
      hasSchedule = !!sameTimeEveryday.open_time && !!sameTimeEveryday.close_time;
    } else if (schedule.mode === 'custom_by_day') {
      // If custom by day, all open days must have valid hours
      const businessHours = schedule.business_hours || {};
      const allDaysValid = Object.values(businessHours).every(day => 
        !day.is_open || (!!day.open_time && !!day.close_time)
      );
      // At least one day must be open
      const atLeastOneDayOpen = Object.values(businessHours).some(day => 
        day.is_open
      );
      hasSchedule = allDaysValid && atLeastOneDayOpen;
    }
    
    // Check discounts requirements
    // Only validate if discounts are enabled
    let hasValidDiscounts = true;
    if (discounts.enabled) {
      // Check if all enabled recurring discount types have rates
      const weekly = recurring.weekly || {};
      const biweekly = recurring.biweekly || {};
      const monthly = recurring.monthly || {};
      
      const weeklyValid = !weekly.enabled || !!weekly.rate;
      const biweeklyValid = !biweekly.enabled || !!biweekly.rate;
      const monthlyValid = !monthly.enabled || !!monthly.rate;
      
      hasValidDiscounts = weeklyValid && biweeklyValid && monthlyValid;
    }
    
    // Check extras requirements
    // Only validate if extras are enabled
    let hasValidExtras = true;
    const specialtyItems = extras.specialtyItems || {};
    const addons = extras.addons || {};
    
    if ((specialtyItems.enabled && (!specialtyItems.items || specialtyItems.items.length === 0)) || 
        (addons.enabled && (!addons.items || addons.items.length === 0))) {
      // Mark as invalid only when a specific toggle is on but no items are added for that toggle
      hasValidExtras = false;
    }
    
    setRequiredFieldsStatus({
      general: true,
      pricing: isPricingComplete,
      turnaround: isTurnaroundComplete,
      schedule: hasSchedule,
      discounts: hasValidDiscounts,
      extras: hasValidExtras
    });
  }, [dcData]);

  // Add this constant at the top of the file, right after imports
  const DAY_ORDER = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  // Update the initializeBusinessHours function to use ordered days
  const initializeBusinessHours = () => {
    const businessHours = {};
    DAY_ORDER.forEach(day => {
      businessHours[day] = {
        is_open: true,
        open_time: '',
        close_time: ''
      };
    });
    return businessHours;
  };

  const handleUpdate = (updates) => {
    setDcData(prev => ({
      ...prev,
      ...updates
    }));
  };

  const handlePricingUpdate = (updates) => {
    setDcData(prev => {
      const pricing = prev.pricing || {};
      const updatedData = {
        ...prev,
        pricing: {
          ...pricing,
          ...updates
        }
      };
      
      // Store Drop-off data in localStorage when prices are updated
      localStorage.setItem('dcDropOffData', JSON.stringify(updatedData));
      
      // Explicitly calculate the validation status for pricing
      // after applying the updates
      if (updates.items !== undefined) {
        const sameDay = updatedData.pricing.sameDay || {};
        const minimumOrder = updatedData.pricing.minimumOrder || {};
        
        // Check all pricing requirements
        const hasPricing = !!updatedData.pricing.regularPerPound;
        const hasValidMinOrder = !minimumOrder.enabled || !!minimumOrder.amount;
        const hasValidSameDay = !sameDay.enabled || !!sameDay.pricePerPound;
        const hasAtLeastOnePricingItem = Array.isArray(updatedData.pricing.items) && 
                                         updatedData.pricing.items.length > 0;
        
        // Update the pricing requirement status
        const isPricingComplete = hasPricing && 
                                hasValidMinOrder && 
                                hasValidSameDay && 
                                hasAtLeastOnePricingItem;
        
        // Update the required fields status
        setRequiredFieldsStatus(prev => ({
          ...prev,
          pricing: isPricingComplete
        }));
      }
      
      // Notify parent of the update
      if (onChange) {
        // Use setTimeout to ensure state is updated first
        setTimeout(() => {
          onChange(updatedData);
        }, 0);
      }
      
      return updatedData;
    });
  };

  const handleSameDayUpdate = (updates) => {
    setDcData(prev => {
      const pricing = prev.pricing || {};
      const sameDay = pricing.sameDay || {};
      return {
        ...prev,
        pricing: {
          ...pricing,
          sameDay: {
            ...sameDay,
            ...updates
          }
        }
      };
    });
  };

  const handleMinimumOrderUpdate = (updates) => {
    setDcData(prev => {
      const pricing = prev.pricing || {};
      const minimumOrder = pricing.minimumOrder || {};
      return {
        ...prev,
        pricing: {
          ...pricing,
          minimumOrder: {
            ...minimumOrder,
            ...updates
          }
        }
      };
    });
  };

  const handleTurnaroundUpdate = (updates) => {
    setDcData(prev => {
      const turnaround = prev.turnaround || {};
      return {
        ...prev,
        turnaround: {
          ...turnaround,
          ...updates
        }
      };
    });
  };

  const handleExpressTurnaroundUpdate = (updates) => {
    setDcData(prev => ({
      ...prev,
      turnaround: {
        ...prev.turnaround,
        expressTurnaround: {
          ...prev.turnaround.expressTurnaround,
          ...updates
        }
      }
    }));
  };

  const handleScheduleUpdate = (updates) => {
    setDcData(prev => {
      const schedule = prev.schedule || {};
      
      // Create a base schedule object with only the mode
      let newSchedule = {
        mode: updates.mode
      };
      
      // If we're switching to same_every_day mode, make sure open_time and close_time are present
      if (updates.mode === 'same_every_day') {
        newSchedule.sameTimeEveryday = {
          ...(schedule.sameTimeEveryday || {}),
          open_time: schedule.sameTimeEveryday?.open_time || '',
          close_time: schedule.sameTimeEveryday?.close_time || ''
        };
      }
      
      // If we're switching to custom_by_day mode, ensure each day is properly initialized
      if (updates.mode === 'custom_by_day') {
        // Initialize business hours if not already present
        if (!schedule.business_hours) {
          newSchedule.business_hours = initializeBusinessHours();
        } else {
          newSchedule.business_hours = schedule.business_hours;
        }
      }
      
      // Include any other updates provided
      if (updates.business_hours && updates.mode === 'custom_by_day') {
        newSchedule.business_hours = updates.business_hours;
      }
      
      if (updates.sameTimeEveryday && updates.mode === 'same_every_day') {
        newSchedule.sameTimeEveryday = updates.sameTimeEveryday;
      }
      
      // Create updated data with new schedule
      const updatedData = {
        ...prev,
        schedule: newSchedule
      };
      
      // Prepare metadata for database update
      const metadata = prev.metadata || {};
      const dropOffMetadata = metadata.dropOff || { enabled: true };
      
      // Apply schedule updates to metadata
      dropOffMetadata.schedule = {
        ...(dropOffMetadata.schedule || {}),
        mode: newSchedule.mode === 'self_serve' ? 'same_as_self_serve' : newSchedule.mode
      };
      
      // Add the appropriate data based on mode
      if (newSchedule.mode === 'same_every_day' && newSchedule.sameTimeEveryday) {
        dropOffMetadata.schedule.open_time = newSchedule.sameTimeEveryday.open_time;
        dropOffMetadata.schedule.close_time = newSchedule.sameTimeEveryday.close_time;
      }
      
      if (newSchedule.mode === 'custom_by_day' && newSchedule.business_hours) {
        dropOffMetadata.schedule.business_hours = {};
        
        // Convert each day to snake_case for metadata
        Object.entries(newSchedule.business_hours).forEach(([day, dayData]) => {
          dropOffMetadata.schedule.business_hours[day] = {
            is_open: dayData.is_open,
            open_time: dayData.open_time,
            close_time: dayData.close_time
          };
        });
      }
      
      // Update the metadata with our changes
      const finalData = {
        ...updatedData,
        metadata: {
          ...metadata,
          dropOff: dropOffMetadata
        }
      };
      
      // Send update to parent with requirements check
      if (typeof onChange === 'function') {
        // Check if requirements to advance are met
        const requirementsCheck = prev.requirementsToAdvance !== undefined 
          ? prev.requirementsToAdvance 
          : Object.values(requiredFieldsStatus || {}).every(Boolean);
        
        const dataToSend = {
          ...finalData,
          requirementsToAdvance: requirementsCheck
        };
        
        // Use setTimeout to ensure this runs after state update
        setTimeout(() => onChange(dataToSend), 0);
      }
      
      return finalData;
    });
  };

  const handleSameDayTimeUpdate = (field, value) => {
    setDcData(prev => {
      const schedule = prev.schedule || {};
      const sameTimeEveryday = schedule.sameTimeEveryday || {};
      
      // Create updated schedule object
      const updatedSchedule = {
        ...schedule,
        // Update time at both levels - in sameTimeEveryday and at the top level
        [field]: value, // Add at top level too
        sameTimeEveryday: {
          ...sameTimeEveryday,
          [field]: value
        }
      };
      
      // Update data with new schedule
      const updatedData = {
        ...prev,
        schedule: updatedSchedule
      };
      
      // Update metadata for database
      const metadata = prev.metadata || {};
      const dropOffMetadata = metadata.dropOff || { enabled: true };
      
      // Ensure schedule structure exists in metadata
      if (!dropOffMetadata.schedule) {
        dropOffMetadata.schedule = { mode: 'same_every_day' };
      }
      
      // Update the schedule field in metadata
      dropOffMetadata.schedule[field] = value;
      
      // Also update the nested sameTimeEveryday if it exists
      if (dropOffMetadata.schedule.same_time_everyday) {
        const fieldKey = field === 'open_time' ? 'open_time' : 'close_time';
        dropOffMetadata.schedule.same_time_everyday[fieldKey] = value;
      }
      
      // Create final data with updated metadata
      const finalData = {
        ...updatedData,
        metadata: {
          ...metadata,
          dropOff: dropOffMetadata
        }
      };
      
      // Send update to parent with requirements check
      if (typeof onChange === 'function') {
        // Check if requirements to advance are met
        const requirementsCheck = prev.requirementsToAdvance !== undefined 
          ? prev.requirementsToAdvance 
          : Object.values(requiredFieldsStatus || {}).every(Boolean);
        
        const dataToSend = {
          ...finalData,
          requirementsToAdvance: requirementsCheck
        };
        
        // Use setTimeout to ensure this runs after state update
        setTimeout(() => onChange(dataToSend), 0);
      }
      
      return finalData;
    });
  };

  const handleOtherUpdate = (updates) => {
    setDcData(prev => {
      const other = prev.other || {};
      return {
        ...prev,
        other: {
          ...other,
          ...updates
        }
      };
    });
  };

  // Handler for discounts
  const handleDiscountsUpdate = (updates) => {
    setDcData(prev => {
      const newState = {
        ...prev,
        discounts: {
          ...(prev.discounts || {}),
          ...updates
        }
      };
      return newState;
    });
  };

  // Handler for recurring discount updates
  const handleRecurringDiscountUpdate = (type, updates) => {
    setDcData(prev => {
      const discounts = prev.discounts || {};
      const recurring = discounts.recurring || {};
      const typeData = recurring[type] || { enabled: false, rate: '' };
      
      return {
        ...prev,
        discounts: {
          ...discounts,
          recurring: {
            ...recurring,
            [type]: {
              ...typeData,
              ...updates
            }
          }
        }
      };
    });
  };

  const updateDay = (dayKey, update) => {
    setDcData(prev => {
      const schedule = prev.schedule || {};
      const businessHours = schedule.business_hours || initializeBusinessHours();
      const dayData = businessHours[dayKey] || { is_open: true, open_time: '', close_time: '' };
      
      // Create updated business_hours with the day changes
      const updatedBusinessHours = {
        ...businessHours,
        [dayKey]: {
          ...dayData,
          ...update
        }
      };
      
      // Create updated data with new schedule
      const updatedData = {
        ...prev,
        schedule: {
          ...schedule,
          business_hours: updatedBusinessHours
        }
      };
      
      // Update metadata for database
      const metadata = prev.metadata || {};
      const dropOffMetadata = metadata.dropOff || { enabled: true };
      
      // Ensure schedule structure exists in metadata
      if (!dropOffMetadata.schedule) {
        dropOffMetadata.schedule = { mode: 'custom_by_day' };
      }
      
      // Ensure business_hours exists in metadata
      if (!dropOffMetadata.schedule.business_hours) {
        dropOffMetadata.schedule.business_hours = {};
      }
      
      // Update the specific day in metadata
      dropOffMetadata.schedule.business_hours[dayKey] = {
        is_open: updatedBusinessHours[dayKey].is_open,
        open_time: updatedBusinessHours[dayKey].open_time,
        close_time: updatedBusinessHours[dayKey].close_time
      };
      
      // Create final data with updated metadata
      const finalData = {
        ...updatedData,
        metadata: {
          ...metadata,
          dropOff: dropOffMetadata
        }
      };
      
      // Send update to parent with requirements check
      if (typeof onChange === 'function') {
        // Check if requirements to advance are met
        const requirementsCheck = prev.requirementsToAdvance !== undefined 
          ? prev.requirementsToAdvance 
          : Object.values(requiredFieldsStatus || {}).every(Boolean);
        
        const dataToSend = {
          ...finalData,
          requirementsToAdvance: requirementsCheck
        };
        
        // Use setTimeout to ensure this runs after state update
        setTimeout(() => onChange(dataToSend), 0);
      }
      
      return finalData;
    });
  };

  // Format time for display
  const formatTimeDisplay = (time24) => {
    if (!time24) return '';
    
    try {
      const [hours, minutes] = time24.split(':');
      const hour = parseInt(hours, 10);
      const minute = parseInt(minutes, 10);
      
      const period = hour >= 12 ? 'PM' : 'AM';
      const hour12 = hour % 12 || 12;
      
      return `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
    } catch (e) {
      return time24;
    }
  };

  // Handler for extras updates
  const handleExtrasUpdate = (type, updates) => {
    setDcData(prev => {
      const extras = prev.extras || {};
      const typeData = extras[type] || { enabled: false };
      
      return {
        ...prev,
        extras: {
          ...extras,
          [type]: {
            ...typeData,
            ...updates
          }
        }
      };
    });
  };

  // Simple helper function to check if pricing tab should show a blue dot
  const shouldShowPricingBlueDot = () => {
    // If service is not enabled, no blue dot
    if (!dcData.enabled) return false;
    
    // If pricing items exist and have length > 0, no blue dot
    if (dcData.pricing?.items && dcData.pricing.items.length > 0) {
      return false;
    }
    
    // Otherwise use existing validation logic
    return !requiredFieldsStatus.pricing;
  };

  // Render General tab content
  const renderGeneralContent = () => (
    <div className="space-y-6">
      <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
        <div className="flex items-center gap-3">
          <Settings className="w-5 h-5 text-gray-400" />
          <div>
            <span className="text-sm font-medium text-gray-700">Dry Cleaning Drop-Off Service</span>
            <p className="text-xs text-gray-500 mt-1">
              Enable this service to allow customers to drop off dry cleaning.
            </p>
          </div>
        </div>
        <BlackSwitch 
          checked={dcData.enabled} 
          onCheckedChange={(checked) => handleUpdate({ enabled: checked })}
        />
      </div>
        
      <AnimatePresence>
        {dcData.enabled && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="flex items-center p-4 rounded-lg bg-blue-50 border border-blue-100">
              <Info className="w-5 h-5 text-blue-500 mr-3 flex-shrink-0" />
              <p className="text-sm text-blue-700">
                Configure the details of your Dry Cleaning Drop-Off service in the tabs below. You'll need to set up pricing, turnaround times, and schedule to complete this section.
              </p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );

  const renderPricingContent = () => {
    // Get pricing data safely with empty objects as fallbacks
    const pricing = dcData.pricing || {};
    const minimumOrder = pricing.minimumOrder || {};
    const items = pricing.items || [];
    
    return (
      <div className="space-y-4">
        {/* Minimum order section */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <DollarSign className="w-5 h-5 text-gray-400" />
            <span className="text-sm font-medium text-gray-700">Minimum Order Required</span>
          </div>
          <BlackSwitch 
            checked={minimumOrder.enabled || false} 
            onCheckedChange={(checked) => handleMinimumOrderUpdate({ enabled: checked })}
          />
        </div>

        <AnimatePresence>
          {minimumOrder.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
            >
              <div className="p-4 rounded-lg bg-white border border-gray-200">
                <div className="space-y-3">
                  <Label htmlFor="min-order-amount">Minimum Order Amount ($)</Label>
                  <div className="flex">
                    <div className="flex items-center px-3 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                      <span className="text-gray-500">$</span>
                    </div>
                    <Input
                      id="min-order-amount"
                      type="number"
                      min="0"
                      step="0.01"
                      value={minimumOrder.amount || ''}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.trim() === '') return;
                        const numValue = parseFloat(value);
                        
                        if (!isNaN(numValue)) {
                          handleMinimumOrderUpdate({ amount: numValue });
                        }
                      }}
                      className="rounded-l-none"
                      placeholder="20.00"
                    />
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        
        {/* Pricing Items Section */}
        <div className="p-4 rounded-lg border border-gray-200">
          <div className="flex justify-between items-center mb-3">
            <h3 className="font-medium">Dry Cleaning Price List</h3>
            <div className="flex gap-2">
              {items.length > 0 && (
                <Button 
                  variant="outline" 
                  size="sm"
                  onClick={() => openExtrasModal('pricing')}
                  className="flex items-center gap-1"
                >
                  <Edit className="w-3 h-3" />
                  <span>Edit Items</span>
                </Button>
              )}
            </div>
          </div>
          
          {items.length > 0 ? (
            <div className="space-y-2 max-h-80 overflow-y-auto pr-2">
              {items.map((item, index) => (
                <div 
                  key={index} 
                  className="flex justify-between items-center py-2 border-b border-gray-100 last:border-0"
                >
                  <span className="text-sm">{item.name}</span>
                  <span className="text-sm font-medium">${item.price}</span>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center border border-dashed border-gray-300 rounded-md p-6">
              <button 
                type="button"
                className="flex items-center gap-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                onClick={() => openExtrasModal('pricing')}
              >
                <Plus className="w-4 h-4" />
                <span>Add Price Items</span>
              </button>
            </div>
          )}
          
          {items.length === 0 && (
            <p className="text-xs text-gray-500 text-center mt-3">
              Click to add dress shirts, pants, suits, etc. with their prices
            </p>
          )}
        </div>
      </div>
    );
  };

  const renderScheduleContent = () => {
    // Get schedule data safely
    const schedule = dcData.schedule || {};
    const mode = schedule.mode || 'same_every_day';
    const sameTimeEveryday = schedule.sameTimeEveryday || {};
    
    // Initialize business hours if not present
    const businessHours = schedule.business_hours || initializeBusinessHours();
    
    return (
      <div className="space-y-6">
        <div className="mb-4">
          <Label className="text-sm mb-2 block">Schedule Type</Label>
          <ToggleGroup
            options={[
              { label: 'Same as Self-Serve', value: 'self_serve' },
              { label: 'Same Every Day', value: 'same_every_day' },
              { label: 'Custom by Day', value: 'custom_by_day' }
            ]}
            value={mode}
            onChange={(value) => handleScheduleUpdate({ mode: value })}
            className="w-full"
            fullWidth
          />
        </div>
        
        {mode === 'self_serve' && (
          <div className="bg-gray-50 rounded-md p-4 text-center text-gray-600">
            <p className="text-center">
              This service will use the same operating hours as your Self-Serve laundry.
            </p>
          </div>
        )}
        
        {mode === 'same_every_day' && (
          <div className="space-y-4">
            <div>
              <Label className="text-sm mb-2 block">Hours (Open - Close)</Label>
              <div className="flex items-center gap-2">
                <Input
                  type="time"
                  value={sameTimeEveryday.open_time || ''}
                  onChange={(e) => handleSameDayTimeUpdate('open_time', e.target.value)}
                  className="flex-1"
                  placeholder="09:00"
                />
                <span>to</span>
                <Input
                  type="time"
                  value={sameTimeEveryday.close_time || ''}
                  onChange={(e) => handleSameDayTimeUpdate('close_time', e.target.value)}
                  className="flex-1"
                  placeholder="17:00"
                />
              </div>
            </div>
            
            <div className="text-sm bg-gray-50 rounded-md p-3">
              {sameTimeEveryday.open_time && sameTimeEveryday.close_time ? 
                `Hours: ${formatTimeDisplay(sameTimeEveryday.open_time)} – ${formatTimeDisplay(sameTimeEveryday.close_time)} every day` : 
                'Please set your opening and closing hours'
              }
            </div>
          </div>
        )}
        
        {mode === 'custom_by_day' && (
          <div className="border rounded-md overflow-hidden overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" style={{ width: '120px' }}>
                    Day
                  </th>
                  <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Hours
                  </th>
                  <th scope="col" className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider text-center" style={{ width: '80px' }}>
                    Open
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {Object.entries(businessHours)
                  .sort(([dayA], [dayB]) => {
                    return DAY_ORDER.indexOf(dayA) - DAY_ORDER.indexOf(dayB);
                  })
                  .map(([day, dayData]) => (
                    <tr key={day} className={cn(!dayData.is_open && "bg-gray-100 text-gray-400")}>
                      <td className={cn(
                        "px-4 py-2 whitespace-nowrap text-sm font-medium", 
                        dayData.is_open ? "text-gray-900" : "text-gray-400"
                      )}>
                        {day}
                      </td>
                      <td className="px-4 py-2 text-sm text-gray-500">
                        {dayData.is_open ? (
                          <div className="flex items-center gap-2 flex-wrap sm:flex-nowrap">
                            <Input
                              type="time"
                              value={dayData.open_time || ''}
                              onChange={(e) => updateDay(day, { open_time: e.target.value })}
                              disabled={!dayData.is_open}
                              className="min-w-[120px] flex-1"
                              placeholder="09:00"
                            />
                            <span className="mx-1">to</span>
                            <Input
                              type="time"
                              value={dayData.close_time || ''}
                              onChange={(e) => updateDay(day, { close_time: e.target.value })}
                              disabled={!dayData.is_open}
                              className="min-w-[120px] flex-1"
                              placeholder="17:00"
                            />
                          </div>
                        ) : (
                          <span className="text-gray-400">Closed</span>
                        )}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-center">
                        <BlackSwitch
                          checked={dayData.is_open}
                          onCheckedChange={(checked) => updateDay(day, { is_open: checked })}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  const renderTurnaroundContent = () => {
    // Get turnaround data safely
    const turnaround = dcData.turnaround || {};
    const standardDays = turnaround.standard_days;
    const hasStandardDays = standardDays !== undefined && standardDays !== null && standardDays !== '';
    
    // Express turnaround
    const expressEnabled = turnaround.expressEnabled || false;
    const expressHours = turnaround.expressHours || '';
    const expressFee = turnaround.expressFee || '';
    
    return (
      <div className="space-y-6">
        {/* Standard turnaround */}
        <div className="bg-white rounded-lg border border-gray-200 p-4 space-y-4">
          <div className="flex items-start">
            <Clock className="w-5 h-5 text-gray-400 mr-3 flex-shrink-0 mt-0.5" />
            <div>
              <h3 className="text-sm font-medium text-gray-700">Standard Turnaround</h3>
              <p className="text-xs text-gray-500 mt-1">
                Set the normal turnaround time for dry cleaning orders.
              </p>
            </div>
          </div>
          
          <div className="space-y-3 mt-2">
            <Label htmlFor="standard-days" className="text-sm">Standard Days (leave empty for same day)</Label>
            <Input
              id="standard-days"
              type="number"
              min="1"
              value={standardDays === null ? '' : standardDays}
              onChange={(e) => {
                const value = e.target.value.trim();
                handleTurnaroundUpdate({
                  standard_days: value === '' ? null : parseInt(value)
                });
              }}
              placeholder="1"
              className="w-full"
            />
            <p className="text-xs text-gray-500">
              The normal number of days needed to complete a customer's order.
            </p>
          </div>
        </div>
  
        {/* Express Service */}
        <div className="bg-white rounded-lg border border-gray-200 p-4 space-y-4">
          <div className="flex items-center justify-between">
            <div className="flex items-start">
              <AlertCircle className="w-5 h-5 text-gray-400 mr-3 flex-shrink-0 mt-0.5" />
              <div>
                <h3 className="text-sm font-medium text-gray-700">Express Service</h3>
                <p className="text-xs text-gray-500 mt-1">
                  Offer customers the option to expedite their order for an additional fee.
                </p>
              </div>
            </div>
            <BlackSwitch 
              checked={expressEnabled} 
              onCheckedChange={(checked) => handleTurnaroundUpdate({ expressEnabled: checked })}
            />
          </div>
          
          <AnimatePresence>
            {expressEnabled && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="space-y-4"
              >
                <div className="space-y-3">
                  <Label htmlFor="express-hours">Express Turnaround Time (hours)</Label>
                  <Input
                    id="express-hours"
                    type="number"
                    min="1"
                    value={expressHours}
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      const numValue = value === '' ? '' : parseInt(value);
                      handleTurnaroundUpdate({ expressHours: numValue });
                    }}
                    className="w-full"
                    placeholder="2"
                  />
                  <p className="text-xs text-gray-500">
                    How quickly express orders will be completed (in hours).
                  </p>
                </div>
  
                <div className="space-y-3">
                  <Label htmlFor="express-fee">Express Fee ($)</Label>
                  <Input
                    id="express-fee"
                    type="number"
                    min="0"
                    step="0.01"
                    value={expressFee}
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      const numValue = value === '' ? '' : parseFloat(value);
                      handleTurnaroundUpdate({ expressFee: numValue });
                    }}
                    className="w-full"
                    placeholder="5.00"
                  />
                  <p className="text-xs text-gray-500">
                    Additional fee charged for express service.
                  </p>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    );
  };

  const renderDiscountsContent = () => {
    // Get discounts data safely
    const discounts = dcData.discounts || {};
    const recurring = discounts.recurring || {};
    const weekly = recurring.weekly || { enabled: false, rate: '' };
    const biweekly = recurring.biweekly || { enabled: false, rate: '' };
    const monthly = recurring.monthly || { enabled: false, rate: '' };
    
    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <Percent className="w-5 h-5 text-gray-400" />
            <span className="text-sm font-medium text-gray-700">Offer Discounts</span>
          </div>
          <BlackSwitch 
            checked={discounts?.enabled} 
            onCheckedChange={(checked) => handleDiscountsUpdate({ enabled: checked })}
          />
        </div>

        <AnimatePresence>
          {discounts?.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
              className="space-y-4"
            >
              <div className="p-4 bg-white rounded-lg border border-gray-200">
                <h3 className="text-sm font-medium text-gray-700 mb-3">Recurring Service Discounts</h3>
                <p className="text-xs text-gray-500 mb-4">
                  Offer special pricing for customers who sign up for recurring dry cleaning service.
                </p>
                
                <div className="overflow-hidden border border-gray-200 rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Frequency
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Offer
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Discount (%)
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {/* Weekly Row */}
                      <tr>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">Weekly</div>
                          <div className="text-xs text-gray-500">Every week</div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <BlackSwitch
                            checked={weekly.enabled}
                            onCheckedChange={(checked) => handleRecurringDiscountUpdate('weekly', { enabled: checked })}
                          />
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="flex items-center">
                            <Input
                              type="number"
                              min="0"
                              max="100"
                              step="1"
                              value={rawInputValues.weeklyRate || weekly.rate || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setRawInputValues(prev => ({ ...prev, weeklyRate: value }));
                                
                                if (value.trim() === '') return;
                                const numValue = parseFloat(value);
                                
                                if (!isNaN(numValue) && numValue >= 0 && numValue <= 100) {
                                  handleRecurringDiscountUpdate('weekly', { rate: numValue });
                                }
                              }}
                              onBlur={() => {
                                if (rawInputValues.weeklyRate === '') {
                                  setRawInputValues(prev => ({ ...prev, weeklyRate: '' }));
                                }
                              }}
                              className="w-20 rounded-md"
                              disabled={!weekly.enabled}
                            />
                            <span className="ml-2 text-gray-500">%</span>
                          </div>
                        </td>
                      </tr>
                      
                      {/* Biweekly Row */}
                      <tr>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">Biweekly</div>
                          <div className="text-xs text-gray-500">Every two weeks</div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <BlackSwitch
                            checked={biweekly.enabled}
                            onCheckedChange={(checked) => handleRecurringDiscountUpdate('biweekly', { enabled: checked })}
                          />
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="flex items-center">
                            <Input
                              type="number"
                              min="0"
                              max="100"
                              step="1"
                              value={rawInputValues.biweeklyRate || biweekly.rate || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setRawInputValues(prev => ({ ...prev, biweeklyRate: value }));
                                
                                if (value.trim() === '') return;
                                const numValue = parseFloat(value);
                                
                                if (!isNaN(numValue) && numValue >= 0 && numValue <= 100) {
                                  handleRecurringDiscountUpdate('biweekly', { rate: numValue });
                                }
                              }}
                              onBlur={() => {
                                if (rawInputValues.biweeklyRate === '') {
                                  setRawInputValues(prev => ({ ...prev, biweeklyRate: '' }));
                                }
                              }}
                              className="w-20 rounded-md"
                              disabled={!biweekly.enabled}
                            />
                            <span className="ml-2 text-gray-500">%</span>
                          </div>
                        </td>
                      </tr>
                      
                      {/* Monthly Row */}
                      <tr>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">Monthly</div>
                          <div className="text-xs text-gray-500">Every month</div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <BlackSwitch
                            checked={monthly.enabled}
                            onCheckedChange={(checked) => handleRecurringDiscountUpdate('monthly', { enabled: checked })}
                          />
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="flex items-center">
                            <Input
                              type="number"
                              min="0"
                              max="100"
                              step="1"
                              value={rawInputValues.monthlyRate || monthly.rate || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setRawInputValues(prev => ({ ...prev, monthlyRate: value }));
                                
                                if (value.trim() === '') return;
                                const numValue = parseFloat(value);
                                
                                if (!isNaN(numValue) && numValue >= 0 && numValue <= 100) {
                                  handleRecurringDiscountUpdate('monthly', { rate: numValue });
                                }
                              }}
                              onBlur={() => {
                                if (rawInputValues.monthlyRate === '') {
                                  setRawInputValues(prev => ({ ...prev, monthlyRate: '' }));
                                }
                              }}
                              className="w-20 rounded-md"
                              disabled={!monthly.enabled}
                            />
                            <span className="ml-2 text-gray-500">%</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  // Add service areas content renderer
  const renderServiceAreasContent = () => {
    // Get service areas data safely
    const serviceAreas = dcData.serviceAreas || { 
      enabled: false, 
      zipcodes: [], 
      zones: [] 
    };
    
    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <AlertCircle className="w-5 h-5 text-gray-400" />
            <span className="text-sm font-medium text-gray-700">Enable Service Areas</span>
          </div>
          <BlackSwitch 
            checked={serviceAreas.enabled || false} 
            onCheckedChange={(checked) => handleServiceAreasUpdate({ enabled: checked })}
          />
        </div>
        
        <AnimatePresence>
          {serviceAreas.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
            >
              <div className="p-4 rounded-lg bg-white border border-gray-200 space-y-4">
                <div className="flex items-center p-4 rounded-lg bg-blue-50 border border-blue-100">
                  <Info className="w-5 h-5 text-blue-500 mr-3 flex-shrink-0" />
                  <p className="text-sm text-blue-700">
                    Configure service areas for your Drop-Off service. You can add zones with different pricing and schedules.
                  </p>
                </div>
                
                {serviceAreas.zones.length === 0 ? (
                  <div className="flex flex-col items-center justify-center py-6 border-2 border-dashed border-gray-200 rounded-lg bg-gray-50">
                    <p className="text-sm text-gray-500 mb-3">No service areas defined yet</p>
                    <Button
                      onClick={() => {
                        // Create a new zone with a unique ID
                        const newZone = {
                          id: String(Date.now()),
                          name: 'New Zone',
                          zipcodes: []
                        };
                        
                        // Add the new zone to the service areas
                        handleServiceAreasUpdate({
                          zones: [...serviceAreas.zones, newZone]
                        });
                      }}
                      className="bg-black hover:bg-gray-800 text-white"
                    >
                      Add Zone
                    </Button>
                  </div>
                ) : (
                  <div className="space-y-2">
                    <div className="flex justify-between items-center mb-3">
                      <h3 className="font-medium">Service Zones</h3>
                      <Button
                        onClick={() => {
                          // Create a new zone with a unique ID
                          const newZone = {
                            id: String(Date.now()),
                            name: 'New Zone',
                            zipcodes: []
                          };
                          
                          // Add the new zone to the service areas
                          handleServiceAreasUpdate({
                            zones: [...serviceAreas.zones, newZone]
                          });
                        }}
                        className="bg-black hover:bg-gray-800 text-white text-sm px-3 py-1 h-8"
                      >
                        Add Zone
                      </Button>
                    </div>
                    
                    {serviceAreas.zones.map((zone) => (
                      <div key={zone.id} className="border border-gray-200 rounded-lg p-3">
                        <div className="flex justify-between items-center">
                          <h3 className="font-medium">{zone.name}</h3>
                          <div className="flex gap-2">
                            <Button
                              variant="outline"
                              size="sm"
                              className="text-xs h-7 px-2"
                              onClick={() => {
                                // Update the zone name
                                const updatedZones = serviceAreas.zones.map(z => 
                                  z.id === zone.id ? { ...z, name: prompt('Enter zone name:', z.name) || z.name } : z
                                );
                                handleServiceAreasUpdate({ zones: updatedZones });
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="outline"
                              size="sm"
                              className="text-xs h-7 px-2 text-red-500 border-red-200 hover:bg-red-50"
                              onClick={() => {
                                // Confirm deletion
                                if (confirm(`Are you sure you want to delete the zone "${zone.name}"?`)) {
                                  const updatedZones = serviceAreas.zones.filter(z => z.id !== zone.id);
                                  handleServiceAreasUpdate({ zones: updatedZones });
                                }
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                        <div className="text-sm text-gray-500 mt-1">
                          {zone.zipcodes?.length || 0} zipcodes
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  const renderExtrasContent = () => {
    // Get extras data safely
    const extras = dcData.extras || {};
    const addons = extras.addons || { enabled: false, items: [] };
    
    return (
      <div className="space-y-4">
        {/* Service Add-ons Section */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <Plus className="w-5 h-5 text-gray-400" />
            <div>
              <span className="text-sm font-medium text-gray-700">Service Add-ons</span>
              <p className="text-xs text-gray-500 mt-1">
                Additional services customers can add to their order (e.g. stain treatment, rush service)
              </p>
            </div>
          </div>
          <BlackSwitch 
            checked={addons.enabled || false} 
            onCheckedChange={(checked) => handleExtrasUpdate('addons', { enabled: checked })}
          />
        </div>
        
        <AnimatePresence>
          {addons.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
              className="p-4 rounded-lg bg-white border border-gray-200"
            >
              {addons.items && addons.items.length > 0 ? (
                <div className="space-y-3">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-sm font-medium">Service Add-ons ({addons.items.length})</span>
                    <Button 
                      variant="outline" 
                      size="sm"
                      onClick={() => openExtrasModal('addon')}
                      className="flex items-center gap-1"
                    >
                      <Edit className="w-3 h-3" />
                      <span>Edit Add-ons</span>
                    </Button>
                  </div>
                  <div className="max-h-36 overflow-y-auto pr-2">
                    {addons.items.map((item, index) => (
                      <div key={index} className="flex justify-between items-center py-2 border-b border-gray-100 last:border-0">
                        <span className="text-sm">{item.name}</span>
                        <div className="flex items-center">
                          <span className="text-sm font-medium">${item.price}</span>
                          <span className="text-xs text-gray-500 ml-1">/ {item.unit}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center border border-dashed border-gray-300 rounded-md p-6">
                  <button 
                    type="button"
                    className="flex items-center gap-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                    onClick={() => openExtrasModal('addon')}
                  >
                    <Plus className="w-4 h-4" />
                    <span>Add Add-on Service</span>
                  </button>
                </div>
              )}
              {!addons.items || addons.items.length === 0 && (
                <p className="text-xs text-gray-500 text-center mt-3">
                  Click to configure additional services customers can select
                </p>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  const renderOtherContent = () => (
    <div className="space-y-4">
      <div>
        <Label htmlFor="special-instructions">Special instructions or notes</Label>
        <textarea
          id="special-instructions"
          value={dcData.other?.notes || ''}
          onChange={(e) => handleOtherUpdate({ notes: e.target.value })}
          placeholder="Any additional information about your Dry Cleaning service..."
          className="w-full mt-1 p-2 min-h-[120px] border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      <div className="border-b">
        <ScrollableTabs
          tabs={[
            {
              id: 'general',
              label: 'General',
              onClick: () => setActiveTab('general'),
              isActive: activeTab === 'general'
            },
            {
              id: 'pricing',
              label: 'Pricing',
              onClick: () => setActiveTab('pricing'),
              isActive: activeTab === 'pricing',
              indicator: isDataLoaded && dcData.enabled && !(dcData.pricing?.items?.length > 0) ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'turnaround',
              label: 'Turnaround Time',
              onClick: () => setActiveTab('turnaround'),
              isActive: activeTab === 'turnaround',
              indicator: isDataLoaded && dcData.enabled && !requiredFieldsStatus.turnaround ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'schedule',
              label: 'Schedule',
              onClick: () => setActiveTab('schedule'),
              isActive: activeTab === 'schedule',
              indicator: isDataLoaded && dcData.enabled && !requiredFieldsStatus.schedule ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'discounts',
              label: 'Discounts',
              onClick: () => setActiveTab('discounts'),
              isActive: activeTab === 'discounts',
              indicator: isDataLoaded && dcData.enabled && !requiredFieldsStatus.discounts ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'extras',
              label: 'Extras',
              onClick: () => setActiveTab('extras'),
              isActive: activeTab === 'extras',
              indicator: isDataLoaded && dcData.enabled && !requiredFieldsStatus.extras ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'other',
              label: 'Other',
              onClick: () => setActiveTab('other'),
              isActive: activeTab === 'other'
            }
          ]}
          className="px-2 py-2"
        />
      </div>

      <div className="p-2 sm:p-4">
        {activeTab === 'general' && renderGeneralContent()}
        {activeTab === 'pricing' && renderPricingContent()}
        {activeTab === 'turnaround' && renderTurnaroundContent()}
        {activeTab === 'schedule' && renderScheduleContent()}
        {activeTab === 'discounts' && renderDiscountsContent()}
        {activeTab === 'extras' && renderExtrasContent()}
        {activeTab === 'other' && renderOtherContent()}
      </div>
      
      {/* Move the ExtrasModal here so it's always available */}
      {extrasModalOpen && (
        <ExtrasModal 
          open={extrasModalOpen}
          onClose={() => setExtrasModalOpen(false)}
          type={extrasModalType === 'pricing' ? 'pricing' : 'addon'}
          items={extrasModalType === 'pricing' ? dcData.pricing?.items || [] : dcData.extras?.addons?.items || []}
          onSave={(items) => {
            if (extrasModalType === 'pricing') {
              // For pricing items, only keep the name and price fields
              // This filters out any irrelevant fields like unit/per unit that might be coming from the ExtrasModal
              const cleanPricingItems = items.map(item => ({
                name: item.name || '',
                price: item.price || 0
                // Explicitly NOT including other fields that aren't needed for pricing items
              }));
              
              // Create a copy of the current state to work with
              const updatedData = {
                ...dcData,
                pricing: {
                  ...dcData.pricing,
                  items: cleanPricingItems
                }
              };
              
              // Update local state with clean pricing items
              setDcData(updatedData);
              localStorage.setItem('dcDropOffData', JSON.stringify(updatedData));
              
              // Check if we have valid pricing now
              const hasItems = cleanPricingItems.length > 0;
              const pricing = updatedData.pricing;
              const sameDay = pricing?.sameDay || {};
              const minimumOrder = pricing?.minimumOrder || {};
              
              // Calculate if pricing is now valid
              const hasPricing = !!pricing?.regularPerPound;
              const hasValidMinOrder = !minimumOrder.enabled || !!minimumOrder.amount;
              const hasValidSameDay = !sameDay.enabled || !!sameDay.pricePerPound;
              const isPricingValid = hasPricing && hasValidMinOrder && hasValidSameDay && hasItems;
              
              // Update the required fields status
              setRequiredFieldsStatus(prev => ({
                ...prev,
                pricing: isPricingValid
              }));
              
              // Make sure to update the parent component
              if (onChange) {
                // Use setTimeout to ensure our state updates first
                setTimeout(() => {
                  // Transform the data to proper metadata format
                  const metadataFields = transformToMetadata(updatedData);
                  const fullMetadata = {
                    services: {
                      "3": {
                        dropOff: metadataFields
                      }
                    }
                  };
                  
                  // Calculate if all required fields are valid for advancement
                  const allValid = Object.entries(requiredFieldsStatus)
                    .filter(([field]) => field !== 'other')
                    .every(([field, isValid]) => field === 'pricing' ? isPricingValid : isValid);
                  
                  // Send the updated data to parent
                  onChange({
                    ...updatedData,
                    requirementsToAdvance: allValid,
                    metadata: fullMetadata
                  });
                }, 0);
              }
            } else if (extrasModalType === 'addon') {
              handleExtrasUpdate('addons', { items });
            }
            setExtrasModalOpen(false);
          }}
        />
      )}
      
      {/* Dry Cleaning Price List Modal */}
      <Dialog open={priceListModalOpen} onOpenChange={setPriceListModalOpen}>
        <DialogContent className="sm:max-w-md" style={{ zIndex: 10000 }}>
          <DialogHeader>
            <DialogTitle>Dry Cleaning Price List</DialogTitle>
          </DialogHeader>
          <div className="max-h-[70vh] overflow-y-auto">
            <div className="space-y-4 py-4">
              {dcData.pricing?.items?.length > 0 ? (
                <div className="grid grid-cols-2 gap-4">
                  {dcData.pricing.items.map((item, index) => (
                    <div key={index} className="flex justify-between border-b pb-2">
                      <span className="font-medium">{item.name}</span>
                      <span>${parseFloat(item.price).toFixed(2)}</span>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-8 text-gray-500">
                  <p>No items added yet.</p>
                  <p className="text-sm mt-2">Click "Edit Items" to add items to your price list.</p>
                </div>
              )}
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setPriceListModalOpen(false)}>Close</Button>
            <Button onClick={() => {
              setPriceListModalOpen(false);
              openExtrasModal('pricing');
            }}>Edit Items</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SimpleDCDropOff; 