// src/components/PageHeader.js

import React from 'react';
import { motion } from 'framer-motion';
import { Plus, Upload, Search, X } from 'lucide-react';

const PageHeader = ({
  title,
  description,
  icon: Icon,
  searchQuery = '',
  onSearchChange,
  primaryAction,
  secondaryAction,
  customActions,
  additionalActions, // New prop added
}) => {
  return (
    <div className="border-b border-gray-100">
      <div className="max-w-[1400px] mx-auto pl-16 pr-8 sm:px-8 py-6">
        <div className="flex items-center justify-between">
          {/* Left section - Title */}
          <div className="flex items-center gap-4 flex-1">
            {Icon && <Icon className="w-6 h-6 text-gray-400" />}
            <div>
              <h1 className="text-2xl text-gray-900 font-normal tracking-normal">
                {title}
              </h1>
              {description && (
                <p className="text-sm text-gray-500 mt-0.5">{description}</p>
              )}
            </div>
          </div>

          {/* Right section - Interactive elements */}
          {customActions ? (
            customActions
          ) : (
            <div className="flex items-center gap-6">
              {onSearchChange && (
                <div className="relative">
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => onSearchChange(e.target.value)}
                    className="w-44 pl-9 pr-4 py-2 text-sm bg-gray-50 border-0 rounded-lg 
                      focus:w-72 focus:bg-gray-100 focus:outline-none focus:ring-0
                      transition-all duration-200 ease-in-out"
                    placeholder="Search..."
                  />
                  <Search className="absolute left-2.5 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
                  {searchQuery && (
                    <button
                      onClick={() => onSearchChange('')}
                      className="absolute right-2.5 top-1/2 transform -translate-y-1/2 
                        text-gray-400 hover:text-gray-600 transition-colors"
                    >
                      <X className="w-4 h-4" />
                    </button>
                  )}
                </div>
              )}

              {/* Action buttons */}
              <div className="flex flex-col sm:flex-row items-center gap-3">
                {secondaryAction && (
                  <motion.button
                    onClick={secondaryAction.onClick}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="w-full sm:w-auto p-2.5 rounded-lg bg-gray-50 hover:bg-gray-100 
                      text-gray-600 transition-all duration-200 ease-in-out"
                  >
                    <div className="flex items-center justify-center gap-2">
                      {secondaryAction.icon || <Upload className="w-5 h-5" />}
                      {secondaryAction.label && <span>{secondaryAction.label}</span>}
                    </div>
                  </motion.button>
                )}

                {primaryAction && (
                  <motion.button
                    onClick={primaryAction.onClick}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="w-full sm:w-auto p-2.5 rounded-lg bg-gray-900 text-white 
                      hover:bg-gray-800 transition-all duration-200 ease-in-out"
                  >
                    <div className="flex items-center justify-center gap-2">
                      {primaryAction.icon || <Plus className="w-5 h-5" />}
                      {primaryAction.label && <span>{primaryAction.label}</span>}
                    </div>
                  </motion.button>
                )}

                {/* Additional Custom Actions */}
                {additionalActions && (
                  <div className="w-full sm:w-auto">
                    {additionalActions}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
