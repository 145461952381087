import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { getSupabaseClient, BUSINESS_TYPES } from '../supabaseClient';
import { useBusinessType } from '../context/BusinessContext';
import {
  Home,
  Users,
  Settings,
  Phone,
  FileText,
  ChevronLeft,
  ChevronRight,
  LogOut,
  ChevronDown,
  MessageSquare,
  Building2,
  Store,
  X
} from 'lucide-react';
import { isRouteAccessible } from '../config/routeAccess';
import { motion, AnimatePresence } from 'framer-motion';

const SideNav = ({ forceExpanded = false, dashboardMobile = false }) => {
  const { businessType } = useBusinessType();
  const supabase = getSupabaseClient(businessType);

  const [isExpanded, setIsExpanded] = useState(forceExpanded);
  const [isFullyExpanded, setIsFullyExpanded] = useState(false);
  const [userData, setUserData] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const [openSubmenu, setOpenSubmenu] = useState('');
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [locationCount, setLocationCount] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const profileMenuRef = useRef(null);
  const hideMenuTimer = useRef(null);

  // Fetch location count when component mounts
  useEffect(() => {
    const fetchLocationCount = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session) {
          // Get the current user
          const { data: { user }, error: userError } = await supabase.auth.getUser();
          if (userError) {
            console.error('User error:', userError);
            return;
          }
          
          // Add account_id filter to only count user's locations
          const { data, error, count } = await supabase
            .from('vapi_location_settings')
            .select('id', { count: 'exact' })
            .eq('account_id', user.id);
            
          if (!error) {
            setLocationCount(count || 0);
          }
        }
      } catch (error) {
        console.error('Error fetching location count:', error);
      }
    };
    
    fetchLocationCount();
  }, [supabase]);

  // Define base navigation structure with dynamic Call Location Order
  const baseNavItems = [
    {
      icon: Home,
      label: 'Home',
      path: '/home',
    },
    {
      icon: Users,
      label: 'Activity',
      submenu: [
        { label: 'Calls', path: '/activity/calls' },
        { label: 'Bookings', path: '/activity/bookings' },
        { label: 'Tasks', path: '/activity/tasks' },
        { label: 'Contacts', path: '/activity/contacts' },
      ],
    },
    {
      icon: Store,
      label: 'Business Settings',
      submenu: [
        { label: 'Knowledge', path: '/business/knowledge' },
        { label: 'Onboarding Knowledge', path: '/business/onboarding-knowledge' },
        { label: 'FAQs', path: '/business/faqs' },
        { label: 'Services', path: '/business/services' },
        // { label: 'Orders', path: '/business/orders' },  // Comment out this line
        { label: 'Locations', path: '/business/locations' },
        { label: 'Call Settings', path: '/call-settings' },
        { label: 'Integrations', path: '/integrations' },
      ],
    },
    {
      icon: Phone,
      label: 'Caller Settings',
      submenu: [
        { label: 'Voice', path: '/caller/voice' },
        { label: 'Integrations', path: '/caller/integrations' },
        { label: 'Email Notifications', path: '/caller/notifications' },
        // Only show Call Location Order if there are 2+ locations
        ...(locationCount >= 2 ? [{ label: 'Call Location Order', path: '/caller/call-location-order' }] : []),
      ],
    },
  ];

  // Filter nav items based on business type and route access
  const navItems = baseNavItems
    .map((item) => {
      if (item.submenu) {
        return {
          ...item,
          submenu: item.submenu.filter((subItem) =>
            isRouteAccessible(businessType, subItem.path)
          ),
        };
      }
      return item;
    })
    .filter((item) =>
      item.path
        ? isRouteAccessible(businessType, item.path)
        : item.submenu?.length > 0 // Only keep submenu items if they have accessible routes
    );

  useEffect(() => {
    fetchUserData();

    const handleClickOutside = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setShowProfileMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    // On mobile, when menu is open, ensure it's fully expanded
    // But respect dashboardMobile prop for the dashboard page
    if (isMobile && isMobileMenuOpen) {
      setIsExpanded(true);
      setIsFullyExpanded(true);
    } else if (dashboardMobile) {
      // When on dashboard in mobile view, maintain collapsed state unless explicitly opened
      setIsExpanded(isMobileMenuOpen);
      setIsFullyExpanded(isMobileMenuOpen);
    } else {
      // Normal desktop behavior
      setIsExpanded(forceExpanded);
      setIsFullyExpanded(forceExpanded);
    }
    
    // Close submenus when closing the menu
    if (!forceExpanded && !isMobileMenuOpen && !dashboardMobile) {
      setOpenSubmenu('');
    }
  }, [forceExpanded, isMobileMenuOpen, isMobile, dashboardMobile]);

  const fetchUserData = async () => {
    try {
      // Get the stored business type, check all possible storage keys
      const storedBusinessType = localStorage.getItem('business_type') || 
                               localStorage.getItem('businessType') || 
                               "laundromat"; // Fallback to laundromat

      const supabase = getSupabaseClient(storedBusinessType);

      // First check if we have a session
      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();
      
      if (sessionError) {
        console.error('Session error:', sessionError);
        // Try to handle session error gracefully without redirecting immediately
        localStorage.setItem('business_type', 'laundromat');
        return;
      }

      if (!session) {
        console.log('No session found, redirecting to login');
        // If no session, redirect to login
        navigate('/login');
        return;
      }

      // Get user data
      const {
        data: { user },
        error: userError,
      } = await supabase.auth.getUser();
      
      if (userError) {
        console.error('User error:', userError);
        return;
      }

      if (user) {
        setUserData(user);
        
        // Ensure business_type is saved in user metadata
        if (user.user_metadata && user.user_metadata.business_type) {
          localStorage.setItem('business_type', user.user_metadata.business_type);
        }

        // Get account data
        const { data: accountData, error: accountError } = await supabase
          .from('accounts')
          .select('*')
          .eq('id', user.id)
          .single();

        if (accountError) {
          console.error('Account error:', accountError);
          return;
        }
        
        setAccountData(accountData);
        
        // Update localStorage with the correct business type from the account data
        if (accountData.business_type) {
          localStorage.setItem('business_type', accountData.business_type);
          localStorage.setItem('businessType', accountData.business_type);
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      // Don't redirect on error, just log it
    }
  };

  const handleLogout = async () => {
    try {
      // First sign out from both supabase instances
      await Promise.all([
        getSupabaseClient(BUSINESS_TYPES.CLEANING).auth.signOut(),
        getSupabaseClient(BUSINESS_TYPES.LAUNDROMAT).auth.signOut(),
      ]);

      // Only after successful signout, clear storage and state
      localStorage.clear();

      // Force a full page refresh to /login
      window.location.replace('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      // Even on error, force a clean logout
      localStorage.clear();
      window.location.replace('/login');
    }
  };

  const getInitials = () => {
    return (
      accountData?.full_name
        ?.split(' ')
        .map((n) => n[0])
        .join('') || 'U'
    );
  };

  const isSubmenuActive = (submenu) => {
    return submenu.some((item) => location.pathname.startsWith(item.path));
  };

  const handleMouseEnterProfile = () => {
    if (hideMenuTimer.current) {
      clearTimeout(hideMenuTimer.current);
      hideMenuTimer.current = null;
    }
    setShowProfileMenu(true);
  };

  const handleMouseLeaveProfile = () => {
    hideMenuTimer.current = setTimeout(() => {
      setShowProfileMenu(false);
    }, 200);
  };

  // Animation variants
  const sideNavVariants = {
    expanded: { width: 256 },
    collapsed: { width: 64 },
  };

  const menuItemVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <>
      {/* Mobile Hamburger Button (Fixed at top) */}
      {isMobile && (
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="fixed top-4 left-4 p-2 rounded-md bg-white shadow-md z-[9999]"
        >
          <div className="w-6 h-6 flex flex-col justify-center space-y-1.5">
            <span className="block w-6 h-0.5 bg-gray-600 rounded-full"></span>
            <span className="block w-6 h-0.5 bg-gray-600 rounded-full"></span>
            <span className="block w-6 h-0.5 bg-gray-600 rounded-full"></span>
          </div>
        </button>
      )}

      <motion.div
        initial={false}
        animate={isMobile ? 
          (isMobileMenuOpen ? { x: 0, y: 0 } : { x: '-100%', y: 0 }) : 
          (isExpanded ? 'expanded' : 'collapsed')}
        variants={!isMobile ? sideNavVariants : {}}
        onMouseEnter={() => !forceExpanded && !isMobile && setIsExpanded(true)}
        onMouseLeave={() => !forceExpanded && !isMobile && setIsExpanded(false)}
        onTransitionEnd={() => {
          setIsFullyExpanded(isExpanded || forceExpanded);
        }}
        className={`bg-white shadow-lg transition-all duration-300 ease-in-out z-[9999] flex flex-col ${
          isMobile ? 
          'fixed top-0 left-0 w-[80%] max-w-[300px] h-screen' : 
          'fixed left-0 top-0 h-screen'
        }`}
      >
        {/* Close button for mobile menu */}
        {isMobile && isMobileMenuOpen && (
          <button
            onClick={() => setIsMobileMenuOpen(false)}
            className="absolute top-4 right-4 p-2 text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        )}

        {/* Logo Section */}
        <div className="h-16 border-b border-gray-100 flex flex-col justify-center px-4 overflow-hidden">
          <span className={`text-xl font-semibold text-gray-800 whitespace-nowrap px-3 ${
            isExpanded || isMobileMenuOpen ? 'block' : 'hidden'
          }`}>
            AI For Laundromats
          </span>
          <span className={`text-xs text-gray-400 whitespace-nowrap px-3 mt-1 tracking-widest uppercase ${
            isExpanded || isMobileMenuOpen ? 'block' : 'hidden'
          }`}>
            by savant
          </span>
        </div>

        {/* Navigation Items */}
        <div className="flex-1 overflow-y-auto px-2 py-4 space-y-2">
          {navItems.map((item) => (
            <div key={item.label} className="relative">
              {item.submenu ? (
                // Submenu Item
                <div>
                  <button
                    onClick={() =>
                      setOpenSubmenu(
                        openSubmenu === item.label ? '' : item.label
                      )
                    }
                    className={`w-full h-9 flex items-center px-3 rounded-lg transition-colors
                      ${
                        isSubmenuActive(item.submenu)
                          ? 'bg-gray-50 text-gray-800'
                          : 'text-gray-600 hover:bg-gray-50'
                      }
                    `}
                  >
                    <div className="w-5 h-5">
                      <item.icon className="w-5 h-5" />
                    </div>
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{ opacity: (isExpanded || isMobileMenuOpen) ? 1 : 0 }}
                      transition={{ duration: 0.2 }}
                      className={`ml-3 whitespace-nowrap truncate ${
                        isExpanded || isMobileMenuOpen ? 'block' : 'hidden'
                      }`}
                    >
                      {item.label}
                    </motion.span>
                    {(isExpanded || isMobileMenuOpen) && (
                      <ChevronDown
                        className={`w-4 h-4 transition-transform ml-auto ${
                          openSubmenu === item.label ? 'rotate-180' : ''
                        }`}
                      />
                    )}
                  </button>
                  <AnimatePresence>
                    {((isExpanded || isMobileMenuOpen) && openSubmenu === item.label) && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className={`overflow-hidden ${isExpanded || isMobileMenuOpen ? 'ml-8 mt-1' : 'w-0 h-0 p-0'}`}
                      >
                        {item.submenu.map((subItem) => (
                        <NavLink
                          key={subItem.path}
                          to={subItem.path}
                          onClick={() => isMobile && setIsMobileMenuOpen(false)} // added for mobile
                          className={({ isActive }) => `
                            w-full flex items-center px-3 py-2 rounded-lg text-sm
                            ${isActive ? 'bg-gray-50 text-gray-800' : 'text-gray-600 hover:bg-gray-50'}
                          `}
                        >
                          <span className="whitespace-nowrap truncate">
                            {subItem.label}
                          </span>
                        </NavLink>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ) : (
              // Regular Item
              <NavLink
                to={item.path}
                onClick={() => isMobile && setIsMobileMenuOpen(false)} // added for mobile
                className={({ isActive }) => `w-full h-9 flex items-center px-3 rounded-lg transition-colors
                  ${isActive ? 'bg-gray-50 text-gray-800' : 'text-gray-600 hover:bg-gray-50'}
                `}
              >
                <div className="w-5 h-5">
                  <item.icon className="w-5 h-5" />
                </div>
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: (isExpanded || isMobileMenuOpen) ? 1 : 0 }}
                  transition={{ duration: 0.2 }}
                  className={`ml-3 whitespace-nowrap truncate ${
                    isExpanded || isMobileMenuOpen ? 'block' : 'hidden'
                  }`}
                >
                  {item.label}
                </motion.span>
              </NavLink>
              )}
            </div>
          ))}
        </div>

        {/* Profile Section */}
        <div 
         className="relative p-4 border-t border-gray-100 bg-white"
         onMouseEnter={handleMouseEnterProfile}
         onMouseLeave={handleMouseLeaveProfile}
        >
         {/* Profile Menu */}
         <motion.div
           ref={profileMenuRef}
           style={{ 
             opacity: (isExpanded || isMobileMenuOpen || forceExpanded) && showProfileMenu ? 1 : 0,
             transform: `translateY(${(isExpanded || isMobileMenuOpen || forceExpanded) && showProfileMenu ? 0 : 10}px)`,
             pointerEvents: (isExpanded || isMobileMenuOpen || forceExpanded) && showProfileMenu ? 'auto' : 'none'
           }}
           transition={{ duration: 0.2 }}
           className="absolute left-0 bottom-full mb-2 w-full bg-white rounded-lg shadow-lg border border-gray-100 z-10"
         >
           <div className="p-2 space-y-1">
             <NavLink
               to="/account/general"
               className={({ isActive }) => `
                 flex items-center px-3 py-2 rounded-lg text-sm w-full
                 ${isActive ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'}
               `}
             >
               <Settings className="w-4 h-4 flex-shrink-0" />
               <span className="ml-2 truncate">Settings</span>
             </NavLink>
             <button
               onClick={handleLogout}
               className="w-full flex items-center px-3 py-2 text-sm text-red-600 hover:bg-red-50 rounded-lg"
             >
               <LogOut className="w-4 h-4 flex-shrink-0" />
               <span className="ml-2 truncate">Logout</span>
             </button>
           </div>
         </motion.div>

         {/* Profile Content */}
         <div className="flex items-center cursor-pointer">
           <div
             className="w-9 h-9 rounded-full bg-gray-50 flex items-center justify-center
                     text-gray-800 font-medium flex-shrink-0"
           >
             {getInitials()}
           </div>
           <motion.div
             initial="hidden"
             animate={(isExpanded || isMobileMenuOpen) ? "visible" : "hidden"}
             variants={{
               visible: { 
                 width: 'auto',
                 marginLeft: 12,
                 opacity: 1,
                 transition: {
                   duration: 0.2,
                 }
               },
               hidden: { 
                 width: 0,
                 marginLeft: 0,
                 opacity: 0,
                 transition: {
                   duration: 0.2,
                 }
               }
             }}
             className="overflow-hidden"
           >
             <p className="text-sm font-medium text-gray-700 truncate whitespace-nowrap">
               {accountData?.full_name}
             </p>
             <p className="text-xs text-gray-500 truncate whitespace-nowrap">
               {userData?.email}
             </p>
           </motion.div>
         </div>
        </div>
      </motion.div>

      {/* Mobile Backdrop */}
      {isMobile && isMobileMenuOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setIsMobileMenuOpen(false)}
          className="fixed inset-0 bg-black/50 z-[9998]" // z-index slightly lower than the nav
        />
      )}
    </>
  );
};

export default SideNav;