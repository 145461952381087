import React from 'react';
import { cn } from '../../utils';

// This component wraps content with a custom scrollbar styling
export const CustomScrollArea = ({ 
  children, 
  className, 
  viewportClassName,
  hideScrollbar = false, // Option to completely hide scrollbar but keep functionality
}) => {
  return (
    <div className={cn("relative", className)}>
      <div 
        className={cn(
          "h-full w-full overflow-y-auto custom-scrollbar", 
          hideScrollbar && "scrollbar-hide",
          viewportClassName
        )}
      >
        {children}
      </div>
    </div>
  );
};

// This function adds the custom scrollbar styles to the global stylesheet
export function injectScrollbarStyles() {
  // Add this function to your main component (App.js) or include in a global CSS file
  const style = document.createElement('style');
  style.textContent = `
    /* Custom scrollbar styling */
    .custom-scrollbar::-webkit-scrollbar {
      width: 8px; /* Increased from 4px to 8px for better visibility */
    }
    
    .custom-scrollbar::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 20px;
    }
    
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background: #c1c1c1; /* Darker color for better visibility */
      border-radius: 20px;
      transition: background 0.2s ease;
    }
    
    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
      background: #888; /* Even darker on hover */
    }
    
    /* Hide scrollbar but keep functionality */
    .scrollbar-hide {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
    
    .scrollbar-hide::-webkit-scrollbar {
      display: none;  /* Chrome, Safari and Opera */
    }
    
    /* Prevent horizontal scrollbar from appearing */
    .prevent-horizontal-scroll {
      overflow-x: hidden !important;
    }

    /* Apply custom scrollbar to all scrollable elements by default */
    * {
      scrollbar-width: thin; /* For Firefox */
      scrollbar-color: #c1c1c1 #f1f1f1; /* For Firefox */
    }

    /* Standard scrollbar styling for all DOM elements */
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 20px;
    }
    
    ::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 20px;
    }
    
    ::-webkit-scrollbar-thumb:hover {
      background: #888;
    }
  `;
  document.head.appendChild(style);
}

// Export CSS class names for direct use in className props
export const scrollbarClasses = {
  custom: "custom-scrollbar",
  hide: "scrollbar-hide",
  preventHorizontal: "prevent-horizontal-scroll"
};

export default CustomScrollArea; 