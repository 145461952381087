import { Toaster as SonnerToaster } from "sonner";
import { toast as sonnerToast } from "sonner";

// Create a wrapper around sonner toast to handle old toast format
const toast = (options) => {
  // If options is an object with title/description, convert to sonner format
  if (options && typeof options === 'object' && (options.title || options.description)) {
    const { title, description, variant } = options;
    
    if (variant === 'destructive') {
      return sonnerToast.error(title || description, { description: title ? description : undefined });
    } else if (variant === 'warning') {
      return sonnerToast.warning(title || description, { description: title ? description : undefined });
    } else {
      return sonnerToast.success(title || description, { description: title ? description : undefined });
    }
  }
  
  // Otherwise, pass through to sonner
  return sonnerToast(options);
};

// Add all the sonner methods to our wrapper
Object.keys(sonnerToast).forEach(key => {
  toast[key] = sonnerToast[key];
});

export function Toaster({ ...props }) {
  return (
    <SonnerToaster
      className="toaster group"
      toastOptions={{
        classNames: {
          toast:
            "group toast group-[.toaster]:bg-white group-[.toaster]:text-gray-950 group-[.toaster]:border-gray-200 group-[.toaster]:shadow-lg",
          description: "group-[.toast]:text-gray-500",
          actionButton:
            "group-[.toast]:bg-gray-900 group-[.toast]:text-gray-50",
          cancelButton:
            "group-[.toast]:bg-gray-100 group-[.toast]:text-gray-500",
        },
      }}
      {...props}
    />
  );
}

export { toast }; 