import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Building2, ExternalLink } from 'lucide-react';
import PageHeader from '../components/PageHeader';

const Integrations = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  // List of available integrations
  const integrations = [
    {
      id: 'curbside',
      name: 'Curbside',
      description: 'Integrate with Curbside POS for laundry services',
      path: '/integrations/curbside',
      icon: Building2,
    },
    // Add more integrations here as they become available
  ];
  
  // Animation variants
  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.3,
        ease: [0.16, 1, 0.3, 1],
        staggerChildren: 0.15
      }
    }
  };

  const cardVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.2,
        ease: [0, 0, 0.2, 1]
      }
    }
  };
  
  // If we're at the base route, show the list of integrations
  if (location.pathname === '/integrations') {
    return (
      <motion.div 
        className="min-h-screen bg-gray-50 flex flex-col items-center"
        variants={pageVariants}
        initial="initial"
        animate="animate"
      >
        <div className="w-full max-w-4xl px-4">
          <PageHeader
            title="Integrations"
            description="Connect your account with various third-party services"
            icon={ExternalLink}
          />

          <main className="w-full mx-auto py-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {integrations.map((integration) => (
                <motion.div
                  key={integration.id}
                  variants={cardVariants}
                  whileHover={{ y: -4, transition: { duration: 0.2 } }}
                  className="bg-white rounded-xl border border-gray-200 shadow-sm hover:shadow-md transition-all duration-200"
                >
                  <Link 
                    to={integration.path} 
                    className="block p-6"
                  >
                    <div className="flex items-start">
                      <div className="mr-4 mt-1">
                        <integration.icon className="w-6 h-6 text-gray-600" />
                      </div>
                      <div>
                        <h3 className="text-lg font-medium text-gray-900 mb-2">{integration.name}</h3>
                        <p className="text-gray-600 text-sm">{integration.description}</p>
                      </div>
                    </div>
                  </Link>
                </motion.div>
              ))}
            </div>
          </main>
        </div>
      </motion.div>
    );
  }
  
  // Otherwise, render the specific integration view through Outlet
  return <Outlet />;
};

export default Integrations; 