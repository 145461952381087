import React, { useState, useEffect, useRef } from 'react';
import { 
  Clock, 
  CreditCard, 
  HelpCircle, 
  Info, 
  Plus, 
  Search, 
  Settings, 
  Shield, 
  ShoppingBag, 
  ShoppingCart, 
  Trash, 
  Trash2,
  Users, 
  Wind,
  Wrench,
  X,
  Check,
  AlarmClock,
  WashingMachine,
  Timer,
  Wallet,
  ChevronLeft,
  ChevronRight,
  ChevronDown,
  ChevronRight as ArrowRight,
  Upload
} from 'lucide-react';
import { TimePicker } from 'antd';
// Ant Design styles should be imported at the app level
import dayjs from 'dayjs';
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../components/ui/tabs";
import { Switch } from "../../components/ui/switch";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { Button } from "../../components/ui/button";
import { cn } from "../../utils";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "../../components/ui/tooltip";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Checkbox } from "../../components/ui/checkbox";
import ScrollableTabs from './ScrollableTabs';
import { getSupabaseClient } from '../../supabaseClient';
import Textarea from "../../components/ui/text-area";
import Papa from 'papaparse';

// Add a debounce utility at the top of the file
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

/**
 * A simplified version of SelfServicePanel that works independently
 * for use within the ServiceDetailsManager component
 */
const SimpleSelfServicePanel = ({ 
  locationData, 
  serviceData = {},
  onUpdate 
}) => {
  // Set up state for the self-service data
  const [selfServiceData, setSelfServiceData] = useState(serviceData || {});
  
  // Initialize products state
  const [products, setProducts] = useState([]);
  
  // Track if this is the initial render
  const isInitialRender = useRef(true);
  
  // Track previous metadata for comparison
  const prevMetadataRef = useRef(null);
  
  // Add loading state to prevent showing indicators until data is fully loaded
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  
  // Update products state when selfServiceData changes
  useEffect(() => {
    // Set products from selfServiceData if available, otherwise use empty array
    setProducts(selfServiceData.products || []);
  }, [selfServiceData]);
  
  // Initialize from serviceData metadata effect
  useEffect(() => {
    if (!locationData?.id) return;

    
    let initialData = { ...selfServiceData };
    let metadata = null;
    
    // Try multiple paths to find the Self-Service metadata
    // 1. Try with numeric ID
    if (serviceData?.metadata?.services?.[1]) {
      metadata = serviceData.metadata.services[1];
    } 
    // 2. Try with string ID
    else if (serviceData?.metadata?.services?.["1"]) {
      metadata = serviceData.metadata.services["1"];
    }
    // 3. Look for any service data as a fallback
    else if (serviceData?.metadata?.services) {
      const serviceKeys = Object.keys(serviceData.metadata.services);
      if (serviceKeys.length > 0) {
        const firstKey = serviceKeys[0];
        metadata = serviceData.metadata.services[firstKey];
      }
    }
    
    // If we found metadata, process it
    if (metadata) {
      
      // Initialize products from metadata if available
      if (metadata.products) {
        setProducts(metadata.products);
      }
      
      try {
        // Add all service data from metadata to the component state
        initialData = {
          ...initialData,
          // Extract specific fields from metadata if available and add to root level
          ...(metadata.hours ? { operatingHours: initializeFromMetadata('operatingHours', metadata.hours) } : {}),
          ...(metadata.attendantHours ? { attendantHours: initializeFromMetadata('attendantHours', metadata.attendantHours) } : {}),
          
          // Handle different possible field names for last load schedule in metadata
          ...((metadata.lastLoadSchedule || metadata.last_load_schedule) ? 
              { lastLoadSchedule: initializeFromMetadata('lastLoadSchedule', metadata.lastLoadSchedule || metadata.last_load_schedule) } 
              : {}),
              
          ...(metadata.security ? { security: metadata.security } : {}),
          ...(metadata.payment_methods ? { 
            acceptsCoins: metadata.payment_methods.acceptsCoins,
            acceptsCash: metadata.payment_methods.acceptsCash,
            acceptsCards: metadata.payment_methods.acceptsCards,
            acceptsMobilePayments: metadata.payment_methods.acceptsMobilePayments,
            acceptsCrypto: metadata.payment_methods.acceptsCrypto,
            hasLoyaltyProgram: metadata.payment_methods.hasLoyaltyProgram,
            // Initialize card options
            ...(metadata.payment_methods.card_options ? {
              acceptsCredit: metadata.payment_methods.card_options.acceptsCredit,
              acceptsDebit: metadata.payment_methods.card_options.acceptsDebit,
              cardHoldInfo: metadata.payment_methods.card_options.cardHoldInfo
            } : {}),
            // Initialize loyalty options
            ...(metadata.payment_methods.loyalty_options ? {
              loyaltyProgramDetails: metadata.payment_methods.loyalty_options.loyaltyProgramDetails
            } : {}),
            // Initialize mobile options
            ...(metadata.payment_methods.mobile_options ? {
              mobilePaymentOptions: metadata.payment_methods.mobile_options.mobilePaymentOptions
            } : {})
          } : {}),
          // Initialize payment options
          ...(metadata.payment_options ? {
            hasCoinMachine: metadata.payment_options.hasCoinMachine,
            hasATM: metadata.payment_options.hasATM,
            hasCardReader: metadata.payment_options.hasCardReader,
            hasMobileApp: metadata.payment_options.hasMobileApp,
            hasBitcoinATM: metadata.payment_options.hasBitcoinATM
          } : {}),
          ...(metadata.washers ? { washers: metadata.washers } : {}),
          ...(metadata.dryers ? { dryers: metadata.dryers } : {}),
          ...(metadata.products ? { products: metadata.products } : {}),
          // Initialize other info
          ...(metadata.other_info ? {
            additionalInfo: metadata.other_info.additionalInfo || metadata.other_info.notes || ''
          } : {}),
          // Keep the original metadata structure
          metadata: serviceData.metadata
        };
      } catch (error) {
        console.error("SimpleSelfServicePanel - Error processing metadata:", error);
        // Continue with whatever data we could extract
      }
      
      
      // Update the state with the initialized data
      setSelfServiceData(initialData);
      
      // Store the metadata for later comparison (using deep copy)
      prevMetadataRef.current = JSON.parse(JSON.stringify(serviceData.metadata));
      
      // Mark initialization complete
      isInitialRender.current = false;
      
      // Mark data as loaded after a slight delay to allow for validation
      setTimeout(() => {
        setIsDataLoaded(true);
      }, 100);
    } else {
      
      // Consider creating empty metadata structure here instead of waiting for another effect
      if (!selfServiceData.metadata) {
        const initialMetadata = {
          services: {
            "1": { enabled: true }
          }
        };
        
        initialData = {
          ...initialData,
          metadata: initialMetadata
        };
        
        setSelfServiceData(initialData);
      }
      
      // Mark data as loaded even if no metadata is found
      setTimeout(() => {
        setIsDataLoaded(true);
      }, 100);
    }
  }, [locationData?.id, serviceData]);
  
  // Add debug logging for the initial serviceData
  useEffect(() => {

    if (serviceData?.metadata?.services) {

      
      if (serviceData.metadata.services["1"]) {

      } else {

      }
    } else {

    }
  }, []);
  
  // Helper function to initialize schedule data from metadata
  const initializeFromMetadata = (scheduleType, metadataSchedule) => {
    
    // If metadataSchedule is null or undefined, return a default schedule
    if (!metadataSchedule) {
      return initializeSchedule(scheduleType);
    }
    
    let schedule = {};
    
    // Handle the special case for attendantHours with unattended type
    if (scheduleType === 'attendantHours' && (metadataSchedule.type === 'unattended' || metadataSchedule.mode === 'unattended')) {
      return {
        mode: 'unattended',
        type: 'unattended'
      };
    }
    
    // Different initialization based on schedule type in metadata
    if (metadataSchedule.type === '24/7') {
      schedule = {
        mode: 'always_open',
        is24Hours: true
      };
    } else if (metadataSchedule.type === 'no_last_load') {
      schedule = {
        mode: 'no_last_load',
        sameTimeForWashAndDry: true // Default value, not used but maintain for consistency
      };
      
      // Initialize the days array for consistent data structure
      schedule.days = daysOfWeek.map(day => ({
        day,
        isOpen: true,
        openTime: "",
        closeTime: "",
        lastWashTime: "",
        lastDryTime: ""
      }));
    } else if (metadataSchedule.type === 'same_every_day') {
      // Base structure for same_every_day
      schedule = {
        mode: 'same_every_day',
        sameHoursOpen: metadataSchedule.opening_time || "",
        sameHoursClose: metadataSchedule.closing_time || ""
      };
      
      // For different schedule types, use different field names
      if (scheduleType === 'lastLoadSchedule') {
        // Check if we have separate wash/dry times or a single last load time
        const hasLastWashTime = !!metadataSchedule.last_wash_time;
        const hasLastDryTime = !!metadataSchedule.last_dry_time;
        const hasLastLoadTime = !!metadataSchedule.last_load_time;
        
        // If we have both wash and dry times, use separate times mode
        // Otherwise, default to same time mode
        schedule.sameTimeForWashAndDry = !(hasLastWashTime && hasLastDryTime);
        
        // If metadata explicitly sets same_time_for_wash_and_dry, respect that
        if (metadataSchedule.same_time_for_wash_and_dry !== undefined) {
          schedule.sameTimeForWashAndDry = metadataSchedule.same_time_for_wash_and_dry;
        }
        
        // Set appropriate time fields based on the sameTimeForWashAndDry setting
        if (schedule.sameTimeForWashAndDry) {
          // For same time mode, use last_load_time for sameHoursOpen
          schedule.sameHoursOpen = metadataSchedule.last_load_time || "";
        } else {
          // For separate times mode, use last_wash_time and last_dry_time
          schedule.lastWashTime = metadataSchedule.last_wash_time || "";
          schedule.lastDryTime = metadataSchedule.last_dry_time || "";
          
          // If we don't have separate times but we do have a last_load_time,
          // use that for both wash and dry as a fallback
          if ((!schedule.lastWashTime || !schedule.lastDryTime) && hasLastLoadTime) {
            schedule.lastWashTime = schedule.lastWashTime || metadataSchedule.last_load_time || "";
            schedule.lastDryTime = schedule.lastDryTime || metadataSchedule.last_load_time || "";
          }
        }
        
        // Log what we're doing for debugging
        if (schedule.sameTimeForWashAndDry) {
        } else {
        }
      }
    } else if (metadataSchedule.type === 'custom_by_day') {
      schedule = {
        mode: 'custom_by_day',
        days: []
      };
      
      // For lastLoadSchedule, determine if we should use same time for wash and dry
      if (scheduleType === 'lastLoadSchedule') {
        // Default to true unless explicitly set to false
        schedule.sameTimeForWashAndDry = metadataSchedule.same_time_for_wash_and_dry !== false;
        
        // But if we have both wash and dry times for any day, switch to separate times mode
        // unless explicitly set to use same time
        if (metadataSchedule.same_time_for_wash_and_dry === undefined) {
          const hasSeparateTimes = metadataSchedule.days && metadataSchedule.days.some(day => 
            day.last_wash_time && day.last_dry_time);
          
          if (hasSeparateTimes) {
            schedule.sameTimeForWashAndDry = false;
          }
        }
      }
      
      // Map days from metadata to our internal format
      if (metadataSchedule.days && Array.isArray(metadataSchedule.days)) {
        schedule.days = metadataSchedule.days.map(day => {
          // Create clean day object with proper field names
          const dayObj = {
            day: day.day,
            isOpen: day.isOpen !== undefined ? day.isOpen : true,
            openTime: day.openTime || day.opening_time || "",
            closeTime: day.closeTime || day.closing_time || ""
          };
          
          // Add last load specific fields if needed
          if (scheduleType === 'lastLoadSchedule') {
            if (schedule.sameTimeForWashAndDry) {
              // When using same time, use last_load_time for openTime
              dayObj.openTime = day.last_load_time || "";
              // Still initialize these fields for UI consistency
              dayObj.lastWashTime = day.last_wash_time || day.last_load_time || "";
              dayObj.lastDryTime = day.last_dry_time || day.last_load_time || "";
            } else {
              // When using different times, use specific wash/dry times
              dayObj.lastWashTime = day.last_wash_time || "";
              dayObj.lastDryTime = day.last_dry_time || "";
              
              // If we have a last_load_time but no specific wash/dry times,
              // use last_load_time as a fallback for both
              if ((!dayObj.lastWashTime || !dayObj.lastDryTime) && day.last_load_time) {
                dayObj.lastWashTime = dayObj.lastWashTime || day.last_load_time;
                dayObj.lastDryTime = dayObj.lastDryTime || day.last_load_time;
              }
            }
          }
          
          return dayObj;
        });
      } else {
        // If no days in metadata, initialize with default days
        schedule.days = daysOfWeek.map(day => {
          const dayObj = {
            day,
            isOpen: true,
            openTime: "",
            closeTime: ""
          };
          
          if (scheduleType === 'lastLoadSchedule') {
            dayObj.lastWashTime = "";
            dayObj.lastDryTime = "";
          }
          
          return dayObj;
        });
      }
    } else {
      // If type is not recognized, initialize with default schedule
      console.warn(`Unrecognized schedule type in metadata: ${metadataSchedule.type}`);
      return initializeSchedule(scheduleType);
    }
    
    // Add days array for all schedule types if not already present
    // This ensures UI components have the data structure they expect
    if (!schedule.days) {
      schedule.days = daysOfWeek.map(day => {
        const dayObj = {
          day,
          isOpen: true,
          openTime: "",
          closeTime: ""
        };
        
        if (scheduleType === 'lastLoadSchedule') {
          dayObj.lastWashTime = "";
          dayObj.lastDryTime = "";
        }
        
        return dayObj;
      });
    }

    return schedule;
  };
  
  // Original metadata initialization effect - only for cases when metadata is completely missing
  useEffect(() => {
    if (!selfServiceData.metadata && !isInitialRender.current) {
      const initialMetadata = {
        // Initial empty structure to ensure JSONB field exists
        services: {
          "1": {}
        }
      };
      
      setSelfServiceData(prev => ({
        ...prev,
        metadata: initialMetadata
      }));
      
      if (onUpdate) {
        onUpdate({
          ...selfServiceData,
          metadata: initialMetadata
        });
      }
    }
  }, []);
  
  // Check if metadata has actually changed
  const hasMetadataChanged = (currentMetadata, previousMetadata) => {
    if (!previousMetadata) return true;
    
    try {
      // For other_info specifically, compare only that section to prevent unnecessary updates
      const currentOtherInfo = currentMetadata?.services?.["1"]?.other_info;
      const previousOtherInfo = previousMetadata?.services?.["1"]?.other_info;
      
      // If we're looking at changes in the other_info section
      if (currentOtherInfo || previousOtherInfo) {
        // If one exists and the other doesn't, they've changed
        if (!currentOtherInfo || !previousOtherInfo) return true;
        
        // Compare the stringified versions of just the other_info section
        return JSON.stringify(currentOtherInfo) !== JSON.stringify(previousOtherInfo);
      }
      
      // For other sections, use the regular full comparison
      return JSON.stringify(currentMetadata) !== JSON.stringify(previousMetadata);
    } catch (error) {
      console.error("Error comparing metadata:", error);
      return false; // Default to no change to prevent infinite update loops
    }
  };
  
  // Function to update the database
  const updateDatabase = async (updatedData) => {
    try {
      // Only proceed if we have locationData with an id
      if (!locationData || !locationData.id) {
        console.warn('Cannot update database: missing location ID');
        return;
      }
      
      // Get the Supabase client - use business type from location data if available
      const businessType = locationData.business_type || 'laundromat';
      const supabase = getSupabaseClient(businessType);
      
      // First check if the record exists
      const { data: existingSettings, error: fetchError } = await supabase
        .from('vapi_location_settings')
        .select('id, metadata')
        .eq('id', locationData.id)
        .maybeSingle();
      
      if (fetchError) {
        console.error('Error checking location settings:', fetchError);
        return;
      }
      
      let result;
      
      // If record exists, update it
      if (existingSettings) {
        // Merge with existing metadata to preserve other services
        const existingMetadata = existingSettings.metadata || {};
        if (!existingMetadata.services) existingMetadata.services = {};
        
        // Create a deep copy of the updated data to avoid reference issues
        const metadataToSave = updatedData.metadata ? JSON.parse(JSON.stringify(updatedData.metadata)) : { services: {} };
      
        
        // Prepare merged metadata preserving other services
        const mergedMetadata = {
          ...existingMetadata,
          services: {
            ...existingMetadata.services,
            "1": {
              ...(existingMetadata.services["1"] || {}),
              ...(metadataToSave.services["1"] || {})
            }
          }
        };
        
        result = await supabase
          .from('vapi_location_settings')
          .update({
            metadata: mergedMetadata
          })
          .eq('id', locationData.id);
      }
      // If record doesn't exist, create it
      else {
        // Initialize metadata structure
        const metadataToSave = {
          services: {
            "1": updatedData.metadata?.services?.["1"] || {}
          }
        };
        
        result = await supabase
          .from('vapi_location_settings')
          .insert({
            id: locationData.id,
            metadata: metadataToSave
          });
      }
      
      if (result.error) {
        console.error('Error updating location settings:', result.error);
      }
    } catch (err) {
      console.error('Failed to update location settings:', err);
    }
  };
  
  // Watch for changes to selfServiceData and sync with DB
  useEffect(() => {
    // Ensure metadata exists before trying to update anything
    if (!selfServiceData || !selfServiceData.metadata) return;
    
    // Check if metadata has actually changed
    if (hasMetadataChanged(selfServiceData.metadata, prevMetadataRef.current)) {
      // Update the previous metadata reference
      prevMetadataRef.current = JSON.parse(JSON.stringify(selfServiceData.metadata));
      
      // First, notify the parent component
      if (onUpdate) {
        onUpdate(selfServiceData);
      }
      
      // Then update the database
      updateDatabase(selfServiceData);
    }
  }, [selfServiceData?.metadata, onUpdate]);
  
  const [activeTab, setActiveTab] = useState('hours');
  const [currentDayIndex, setCurrentDayIndex] = useState(0);
  const [activeMachineType, setActiveMachineType] = useState('washers');
  const [activePaymentType, setActivePaymentType] = useState('cash');
  
  // Required fields tracking
  const [requiredFieldsStatus, setRequiredFieldsStatus] = useState({
    hours: false,
    machines: false,
    payment: false,
    staff: false, // Initialize as false, will be updated in useEffect
    'last-load': false,
    products: true, // Optional
    security: true, // Optional
    'other-info': true // Optional
  });
  const [allRequiredFieldsComplete, setAllRequiredFieldsComplete] = useState(false);
  
  // Expose requirements status to parent component
  useEffect(() => {
    // Only update when the completion status actually changes
    if (onUpdate && selfServiceData) {
      // Store the current requirements status directly in the service data
      // to avoid constant re-renders
      const updatedData = {
        ...selfServiceData
      };
      
      // Only set this property if it's different
      if (updatedData.requirementsToAdvance !== allRequiredFieldsComplete) {
        updatedData.requirementsToAdvance = allRequiredFieldsComplete;
        onUpdate(updatedData);
      }
    }
  }, [allRequiredFieldsComplete, onUpdate, selfServiceData]);
  
  // Product management state
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [productDropdownOpen, setProductDropdownOpen] = useState(false);
  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);
  const productDropdownRef = useRef(null);
  const locationDropdownRef = useRef(null);
  const [newProduct, setNewProduct] = useState({
    type: 'Laundry Detergent/Soap', // Set a default
    customType: '',
    brand: '',
    cost: '',
    purchaseLocation: 'Vending Machine', // Set a default
    customLocation: ''
  });
  
  // Close product dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (productDropdownRef.current && !productDropdownRef.current.contains(event.target)) {
        setProductDropdownOpen(false);
      }
      if (locationDropdownRef.current && !locationDropdownRef.current.contains(event.target)) {
        setLocationDropdownOpen(false);
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  // File upload state
  const [csvFile, setCsvFile] = useState(null);
  const [csvError, setCsvError] = useState('');
  const fileInputRef = useRef(null);
  
  // Handle CSV file upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setCsvFile(file);
    setCsvError('');
  };
  
  // Process the CSV file
  const handleBulkUpload = () => {
    if (!csvFile) {
      setCsvError('Please select a CSV file');
      return;
    }
    
    Papa.parse(csvFile, {
      header: true,
      complete: (results) => {
        if (results.errors.length > 0) {
          setCsvError('Error parsing CSV file');
          return;
        }
        
        const validProducts = [];
        const errors = [];
        
        results.data.forEach((row, index) => {
          // Skip empty rows
          if (!row.type && !row.brand && !row.cost && !row.purchaseLocation) {
            return;
          }
          
          // Validate required fields
          if (!row.type) {
            errors.push(`Row ${index + 1}: Missing product type`);
            return;
          }
          if (!row.cost) {
            errors.push(`Row ${index + 1}: Missing cost`);
            return;
          }
          if (!row.purchaseLocation) {
            errors.push(`Row ${index + 1}: Missing purchase location`);
            return;
          }
          
          // Create the product object
          validProducts.push({
            id: Date.now() + index, // Simple way to create a unique ID
            type: row.type,
            brand: row.brand || 'N/A',
            cost: row.cost,
            purchaseLocation: row.purchaseLocation
          });
        });
        
        if (errors.length > 0) {
          setCsvError(`Errors in CSV file: ${errors.join('; ')}`);
          return;
        }
        
        if (validProducts.length === 0) {
          setCsvError('No valid products found in CSV file');
          return;
        }
        
        // Add to products array
        const updatedProducts = [...products, ...validProducts];
        
        // Update products state directly
        setProducts(updatedProducts);
        
        // Update local state with new product data and updated metadata
        setSelfServiceData(prevData => {
          // Ensure metadata structure exists
          const currentMetadata = prevData.metadata || { services: { "1": {} } };
          
          // Create the updated metadata
          const updatedMetadata = {
            ...currentMetadata,
            services: {
              ...currentMetadata.services,
              "1": {
                ...currentMetadata.services["1"],
                products: updatedProducts,
              }
            }
          };
          
          // Check if metadata has changed
          if (hasMetadataChanged(updatedMetadata, currentMetadata)) {
            // Set unsaved changes flag
            setUnsavedChanges(true);
            
            // Return updated data
            return {
              ...prevData,
              metadata: updatedMetadata
            };
          }
          
          return prevData;
        });
        
        // Reset the file input and close the modal
        setCsvFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        setIsAddProductModalOpen(false);
      }
    });
  };
  
  // Machine management state
  const [isAddMachineModalOpen, setIsAddMachineModalOpen] = useState(false);
  const [newMachine, setNewMachine] = useState({
    type: 'washer',
    loadType: 'front',
    capacity: 20,
    cost: '',
    minutes: '' // Only for dryers
  });
  
  // Days of the week for hours configuration
  const daysOfWeek = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
  ];
  
  // Product type options
  const productTypes = [
    'Laundry Detergent/Soap',
    'Dryer Sheets',
    'Fabric Softener',
    'Bleach',
    'Laundry Bags',
    'Other'
  ];
  
  // Purchase location options
  const purchaseLocations = [
    'Vending Machine',
    'Attendant',
    'Other'
  ];
  
  // Helper function to format time from 24h format to AM/PM format
  const formatTime = (time24h) => {
    if (!time24h) return '';
    
    const [hours, minutes] = time24h.split(':');
    const hour = parseInt(hours, 10);
    
    if (isNaN(hour)) return time24h;
    
    const period = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12; // Convert 0 to 12 for 12 AM
    
    return `${hour12}:${minutes} ${period}`;
  };
  
  // Initialize schedule data if it doesn't exist
  const initializeSchedule = (scheduleType) => {
    if (!selfServiceData[scheduleType]) {
      // Base initial schedule structure
      const initialSchedule = {
        mode: 'same_every_day', // 'always_open', 'same_every_day', or 'custom_by_day'
        is24Hours: false,
        sameHoursOpen: "",
        sameHoursClose: ""
      };
      
      // Add schedule-specific properties
      if (scheduleType === 'lastLoadSchedule') {
        initialSchedule.sameTimeForWashAndDry = true;
        initialSchedule.lastWashTime = "";
        initialSchedule.lastDryTime = "";
        
        // For last load schedule, include isOpen for UI purposes even though it's not used in the metadata
        initialSchedule.days = daysOfWeek.map(day => ({
          day,
          isOpen: true, // Include isOpen for UI interactions
          openTime: "", // For single time mode
          closeTime: "", // For consistency
          lastWashTime: "",
          lastDryTime: ""
        }));
      } else if (scheduleType === 'attendantHours') {
        // For attendant hours, days need day, isOpen, openTime, closeTime
        initialSchedule.days = metadataSchedule.days.map(day => {
          // Create clean day object with proper field names
          return {
            day: day.day,
            isOpen: day.isOpen !== undefined ? day.isOpen : true,
            openTime: day.openTime || day.attendantStartTime || "",
            closeTime: day.closeTime || day.attendantEndTime || ""
          };
        });
      } else {
        // For operating hours, days need day, isOpen, openTime, closeTime
        initialSchedule.days = daysOfWeek.map(day => ({
          day,
          isOpen: true,
          openTime: "",
          closeTime: ""
        }));
      }
      
      return initialSchedule;
    }
    
    return selfServiceData[scheduleType];
  };
  
  
  // Get initialized schedule for the given type
  // Get initialized schedule for the given type
  const getSchedule = (scheduleType) => {
  return selfServiceData[scheduleType] || initializeSchedule(scheduleType);
};

  // Add this function to your component
const getScheduleData = (scheduleType) => {
  // Special case for attendant hours which should always be accessed directly
    if (scheduleType === 'attendantHours') {
    return selfServiceData.attendantHours || {
      mode: 'unattended',
      days: daysOfWeek.map(day => ({
        day,
        isOpen: true,
        openTime: "",
        closeTime: ""
      })),
      sameHoursOpen: "",
      sameHoursClose: ""
    };
  }
  
  // For other schedule types
    return selfServiceData[scheduleType] || initializeSchedule(scheduleType);
  };
  
  // Check if payment tab requirements are met (at least 1 payment method enabled)
  const isPaymentTabComplete = () => {
    // Check all possible payment methods - only ONE needs to be true
    return (
      selfServiceData.acceptsCoins === true ||
      selfServiceData.acceptsCash === true ||
      selfServiceData.acceptsCards === true ||
      selfServiceData.acceptsMobilePayments === true ||
      selfServiceData.hasLoyaltyProgram === true ||
      selfServiceData.acceptsCrypto === true
    );
  };
  
  // Check if attendant tab requirements are met
  // Fixed isAttendantTabComplete function that directly accesses attendantHours
const isAttendantTabComplete = () => {
   // Access attendant hours DIRECTLY from selfServiceData
   const attendantHours = selfServiceData.attendantHours || {};
   
   // Case 1: If unattended mode is selected, tab is complete
   if (attendantHours.mode === 'unattended' || attendantHours.type === 'unattended') {
     return true;
   }
   
   // Case 2: If same_every_day mode is selected, check for start and end times
   if (attendantHours.mode === 'same_every_day') {
     return Boolean(attendantHours.sameHoursOpen && attendantHours.sameHoursClose);
   }
   
   // Case 3: If custom_by_day mode is selected, check each day that is set to open
   if (attendantHours.mode === 'custom_by_day' && attendantHours.days) {
     // Check that each open day has both start and end times
     return attendantHours.days.every(day => 
       !day.isOpen || (day.isOpen && Boolean(day.openTime) && Boolean(day.closeTime))
     );
   }
   
   // Default to incomplete if no valid mode is selected
   return false;
};
  
  
  // Check if last load tab requirements are met
  // Check if last load tab requirements are met
  const isLastLoadTabComplete = () => {
    const lastLoadSchedule = getSchedule('lastLoadSchedule');
    
    // If no last load is selected, the tab is complete
    if (lastLoadSchedule.mode === 'no_last_load') {
      return true;
    }
    
    if (lastLoadSchedule.mode === 'same_every_day') {
      if (lastLoadSchedule.sameTimeForWashAndDry) {
        // For same time mode, check if last load time is set
        return !!lastLoadSchedule.sameHoursOpen;
      } else {
        // For separate times mode, check if both wash and dry times are set
        return !!lastLoadSchedule.lastWashTime && !!lastLoadSchedule.lastDryTime;
      }
    }
    
    if (lastLoadSchedule.mode === 'custom_by_day') {
      return lastLoadSchedule.days.every(day => {
        if (!day.isOpen) return true; // Skip closed days
        
        if (lastLoadSchedule.sameTimeForWashAndDry) {
          // For same time mode, check if last load time (openTime) is set
          return !!day.openTime;
        } else {
          // For separate times mode, check if both wash and dry times are set
          return !!day.lastWashTime && !!day.lastDryTime;
        }
      });
    }
    
    // If we get here, the tab is incomplete
    return false;
  };
  
  
  // Effect to ensure schedules are initialized and validation status is updated
// Effect to ensure schedules are initialized and validation status is updated
useEffect(() => {
  // Only initialize non-attendant schedules here
  const scheduleTypes = ['operatingHours', 'lastLoadSchedule', 'securitySchedule'];
  
  let hasUpdates = false;
  const updatedData = {...selfServiceData};
  
  scheduleTypes.forEach(type => {
    if (!updatedData[type]) {
      updatedData[type] = initializeSchedule(type);
      hasUpdates = true;
    }
  });
  
  // Special handling for attendantHours initialization - directly access it
  if (!updatedData.attendantHours) {
    updatedData.attendantHours = {
      mode: 'unattended',
      days: [
        { day: 'Monday', isOpen: true, openTime: "", closeTime: "" },
        { day: 'Tuesday', isOpen: true, openTime: "", closeTime: "" },
        { day: 'Wednesday', isOpen: true, openTime: "", closeTime: "" },
        { day: 'Thursday', isOpen: true, openTime: "", closeTime: "" },
        { day: 'Friday', isOpen: true, openTime: "", closeTime: "" },
        { day: 'Saturday', isOpen: true, openTime: "", closeTime: "" },
        { day: 'Sunday', isOpen: true, openTime: "", closeTime: "" }
      ],
      sameHoursOpen: "",
      sameHoursClose: ""
    };
    hasUpdates = true;
  }
  
  if (hasUpdates) {
    setSelfServiceData(updatedData);
    if (onUpdate) {
      onUpdate(updatedData);
    }
  }
  
  // Check required fields completion status
  const updatedStatus = {
    hours: isHoursTabComplete(),
    machines: isMachinesTabComplete(),
    payment: isPaymentTabComplete(),
    staff: isAttendantTabComplete(), // This now uses direct access
    'last-load': isLastLoadTabComplete(),
    products: true, // Optional
    security: true, // Optional
    'other-info': true // Optional
  };
  
  setRequiredFieldsStatus(updatedStatus);
  
  // Check if all required fields are complete
  const allComplete = updatedStatus.hours && 
                      updatedStatus.machines && 
                      updatedStatus.payment &&
                      updatedStatus.staff &&
                      updatedStatus['last-load'];
  
  setAllRequiredFieldsComplete(allComplete);
}, [
  // More specific dependencies
  selfServiceData?.operatingHours,
  selfServiceData?.lastLoadSchedule,
  selfServiceData?.attendantHours,
  selfServiceData?.washers,
  selfServiceData?.dryers,
  selfServiceData?.acceptsCoins,
  selfServiceData?.acceptsCash,
  selfServiceData?.acceptsCards,
  selfServiceData?.acceptsMobilePayments,
  selfServiceData?.hasLoyaltyProgram,
  selfServiceData?.acceptsCrypto,
  onUpdate
]);
  
  // Add a new useEffect to update the staff tab status whenever attendantHours changes
  useEffect(() => {
    setRequiredFieldsStatus(prevStatus => ({
      ...prevStatus,
      staff: isAttendantTabComplete()
    }));
  }, [selfServiceData.attendantHours]);
  
  // Add a one-time effect that runs immediately after component mount
  // This ensures the staff status is correct on initial render
  useEffect(() => {
    // Check if attendantHours is unattended and update the status accordingly
    const attendantHours = selfServiceData.attendantHours || {};
    if (attendantHours.mode === 'unattended' || attendantHours.type === 'unattended') {
      setRequiredFieldsStatus(prevStatus => ({
        ...prevStatus,
        staff: true
      }));
    }
  }, []); // Empty dependency array means this runs once on mount
  
  // FINAL SOLUTION - This useEffect will ALWAYS run AFTER any other useEffects or
  // handler functions have completed. Using a 3-second delay ensures we always
  // save the FINAL state after all updates are finished.
  useEffect(() => {
    if (selfServiceData.attendantHours) {
      const finalTimer = setTimeout(() => {
        // Create a deep copy to ensure we're using the final state
        const finalData = JSON.parse(JSON.stringify(selfServiceData));
        updateDatabase(finalData);
      }, 3000); // 3-second delay - this is the key to ensuring we save after everything else
      
      return () => clearTimeout(finalTimer);
    }
  }, [JSON.stringify(selfServiceData.attendantHours)]);
  
  // Add simple useEffect to update the database whenever attendantHours changes
  useEffect(() => {
    if (selfServiceData.attendantHours) {
      // Update the database with latest data - with sufficient delay
      const timer = setTimeout(() => {
        updateDatabase(selfServiceData);
      }, 1000);
      
      return () => clearTimeout(timer);
    }
  }, [JSON.stringify(selfServiceData.attendantHours)]);
  
  // Replace with a more reliable implementation
  useEffect(() => {
    if (!selfServiceData.attendantHours) return;
    
    // Create a debounced function that runs after changes stop for 500ms
    const debouncedUpdate = setTimeout(() => {
      
      // Make a deep copy to ensure we're not getting stale data
      const dataCopy = JSON.parse(JSON.stringify(selfServiceData));
      updateDatabase(dataCopy);
    }, 500); // 500ms debounce to give React time to fully update state
    
    // Clear the timeout if the component unmounts or attendantHours changes again
    return () => clearTimeout(debouncedUpdate);
  }, [
    // Include ALL relevant properties to ensure we catch every change
    selfServiceData.attendantHours?.mode,
    selfServiceData.attendantHours?.sameHoursOpen,
    selfServiceData.attendantHours?.sameHoursClose,
    // If days exist, stringify them to detect any change to any day
    selfServiceData.attendantHours?.days ? JSON.stringify(selfServiceData.attendantHours.days) : null,
  ]);
  
  // THIS USEEFFECT IS THE CENTRAL DATABASE UPDATER FOR ATTENDANT HOURS
  // Instead of calling updateDatabase from individual handler functions,
  // this useEffect watches for ANY changes to attendantHours and updates
  // the database with a debounce to ensure all React state updates are complete.
  // 
  // Individual handler functions (handleAttendantModeChange, 
  // handleAttendantSameHoursChange, handleAttendantDayUpdate) should
  // ONLY update the local state and NOT call updateDatabase directly.
  useEffect(() => {
    if (!selfServiceData.attendantHours) return;
    
    // Create a debounced function that runs after changes stop for 500ms
    const debouncedUpdate = setTimeout(() => {
      
      // Make a deep copy to ensure we're not getting stale data
      const dataCopy = JSON.parse(JSON.stringify(selfServiceData));
      updateDatabase(dataCopy);
    }, 500); // 500ms debounce to give React time to fully update state
    
    // Clear the timeout if the component unmounts or attendantHours changes again
    return () => clearTimeout(debouncedUpdate);
  }, [
    // Include ALL relevant properties to ensure we catch every change
    selfServiceData.attendantHours?.mode,
    selfServiceData.attendantHours?.sameHoursOpen,
    selfServiceData.attendantHours?.sameHoursClose,
    // If days exist, stringify them to detect any change to any day
    selfServiceData.attendantHours?.days ? JSON.stringify(selfServiceData.attendantHours.days) : null,
  ]);
  
  // IMPROVED CENTRAL USEEFFECT FOR ATTENDANT HOURS DATABASE UPDATES
  // This is a more foolproof implementation that guarantees all metadata 
  // is correctly structured before saving to the database
  useEffect(() => {
    if (!selfServiceData.attendantHours) return;
    
    // Create a debounced function that runs after changes stop
    const debouncedUpdate = setTimeout(() => {
      
      // Create a complete deep copy of the current state
      const latestData = JSON.parse(JSON.stringify(selfServiceData));
      
      // Ensure all required metadata structure exists
      if (!latestData.metadata) latestData.metadata = { services: { "1": {} } };
      if (!latestData.metadata.services) latestData.metadata.services = { "1": {} };
      if (!latestData.metadata.services["1"]) latestData.metadata.services["1"] = {};
      
      // Manually rebuild the metadata based on the current mode to ensure consistency
      const mode = latestData.attendantHours.mode;
      
      if (mode === 'unattended') {
        // For unattended mode, just set the type
        latestData.metadata.services["1"].attendantHours = {
          type: 'unattended'
        };
      } 
      else if (mode === 'same_every_day') {
        // For same_every_day, ensure both opening and closing times are included
        latestData.metadata.services["1"].attendantHours = {
          type: 'same_every_day',
          opening_time: latestData.attendantHours.sameHoursOpen || "",
          closing_time: latestData.attendantHours.sameHoursClose || ""
        };
      } 
      else if (mode === 'custom_by_day' && latestData.attendantHours.days) {
        // For custom_by_day, create clean days array with all required properties
        const cleanedDays = latestData.attendantHours.days.map(day => ({
          day: day.day,
          isOpen: day.isOpen,
          openTime: day.openTime || "",
          closeTime: day.closeTime || ""
        }));
        
        latestData.metadata.services["1"].attendantHours = {
          type: 'custom_by_day',
          days: cleanedDays
        };
      }
      
      
      // Update the database with the consistent structure
      updateDatabase(latestData);
    }, 1000); // Increased to 1000ms (1 second) to ensure all state updates are complete
    
    // Clear the timeout if the component unmounts or attendantHours changes again
    return () => clearTimeout(debouncedUpdate);
  }, [
    // Dependencies to watch
    selfServiceData.attendantHours?.mode,
    selfServiceData.attendantHours?.sameHoursOpen,
    selfServiceData.attendantHours?.sameHoursClose,
    // Convert days array to string for comparison
    selfServiceData.attendantHours?.days ? JSON.stringify(selfServiceData.attendantHours.days) : null,
  ]);
  
  // Handle updating a specific day in a schedule
  const handleScheduleUpdate = (scheduleType, dayIndex, field, value) => {
    const schedule = { ...getSchedule(scheduleType) };
    
    // Handle special case for mode change
    if (field === 'mode') {
      schedule.mode = value;
      
      // Reset 24 hour flag when changing modes
      if (value !== 'always_open') {
        schedule.is24Hours = false;
      }
      
      if (value === 'always_open') {
        schedule.is24Hours = true;
      }
    }
    // Handle special case for 24 hours toggle
    else if (field === 'is24Hours') {
      schedule.is24Hours = value;
    }
    // Handle setting hours for "same every day" mode
    else if (field === 'sameHours') {
      const { open, close } = value;
      schedule.sameHoursOpen = open;
      schedule.sameHoursClose = close;
    }
    // Handle setting specific day's hours for "custom by day" mode
    else if (dayIndex >= 0) {
      const day = { ...schedule.days[dayIndex] };
      
      if (field === 'isOpen') {
        day.isOpen = value;
      } else if (field === 'times') {
        const { open, close } = value;
        day.openTime = open;
        day.closeTime = close;
      } else if (field === 'lastLoadTimes') {
        const { wash, dry } = value;
        day.lastWashTime = wash;
        day.lastDryTime = dry;
      }
      
      schedule.days[dayIndex] = day;
    }
    
    // Now update the state and metadata
    setSelfServiceData(prevData => {
      // Ensure metadata structure exists
      const currentMetadata = prevData.metadata || { services: { "1": {} } };
      
      // Determine the metadata field name
      const metadataField = scheduleType === 'operatingHours' ? 'hours' : scheduleType;
      
      // Format metadata based on schedule mode
      let scheduleMetadata = {};
      
      if (schedule.is24Hours || schedule.mode === 'always_open') {
        scheduleMetadata = {
          type: '24/7'
        };
      } else if (schedule.mode === 'same_every_day') {
        scheduleMetadata = {
          type: 'same_every_day',
          opening_time: schedule.sameHoursOpen,
          closing_time: schedule.sameHoursClose
        };
        } else {
        scheduleMetadata = {
          type: 'custom_by_day',
          days: schedule.days
        };
      }
      
      // Create the updated metadata
      const updatedMetadata = {
        ...currentMetadata,
        services: {
          ...currentMetadata.services,
          "1": {
            ...(currentMetadata.services?.["1"] || {}),
            [metadataField]: scheduleMetadata
          }
        }
      };
      
      // Return updated state
      return {
        ...prevData,
        [scheduleType]: schedule,
        metadata: updatedMetadata
      };
    });
  };
  
  // Handle updating operating hours schedule
  // Complete handleOperatingHoursUpdate function
  const handleOperatingHoursUpdate = (dayIndex, field, value) => {
  
  // Get a fresh copy of the current schedule
  const currentSchedule = getScheduleData('operatingHours');
  const schedule = JSON.parse(JSON.stringify(currentSchedule));
    
    // Handle special case for mode change
    if (field === 'mode') {
      schedule.mode = value;
      
    // Set is24Hours flag based on mode
    schedule.is24Hours = (value === 'always_open');
    
    // If switching to custom_by_day, ensure days array is initialized
    if (value === 'custom_by_day' && (!schedule.days || !schedule.days.length)) {
      schedule.days = daysOfWeek.map(day => ({
        day,
        isOpen: true,
        openTime: "",
        closeTime: ""
      }));
    }
    
    // If switching to same_every_day, ensure we have the required properties
    if (value === 'same_every_day') {
      schedule.sameHoursOpen = schedule.sameHoursOpen || "";
      schedule.sameHoursClose = schedule.sameHoursClose || "";
      }
    }
    // Handle special case for 24 hours toggle
    else if (field === 'is24Hours') {
      schedule.is24Hours = value;
    // If toggling on 24 hours, also set mode to always_open for consistency
    if (value === true) {
      schedule.mode = 'always_open';
    }
    }
    // Handle setting hours for "same every day" mode
    else if (field === 'sameHours') {
      const { open, close } = value;
      
    // Only update if values are provided
    if (open) schedule.sameHoursOpen = open;
    if (close) schedule.sameHoursClose = close;
    }
    // Handle setting specific day's hours for "custom by day" mode
  else if (dayIndex >= 0 && schedule.days && dayIndex < schedule.days.length) {
      const day = { ...schedule.days[dayIndex] };
      
      if (field === 'isOpen') {
        day.isOpen = value;
      } else if (field === 'times') {
        const { open, close } = value;
      
      // Only update if values are provided
      if (open) day.openTime = open;
      if (close) day.closeTime = close;
      }
      
      schedule.days[dayIndex] = day;
    }
    
  // Format metadata based on schedule mode for the parent component
  let hoursMetadata = {};
      
      if (schedule.is24Hours || schedule.mode === 'always_open') {
    hoursMetadata = {
          type: '24/7'
        };
      } else if (schedule.mode === 'same_every_day') {
    hoursMetadata = {
          type: 'same_every_day',
      opening_time: schedule.sameHoursOpen || "",
      closing_time: schedule.sameHoursClose || ""
        };
  } else if (schedule.mode === 'custom_by_day') {
        // Create a cleaned up version of days with only the necessary properties
        const cleanedDays = schedule.days.map(day => ({
          day: day.day,
          isOpen: day.isOpen,
      openTime: day.openTime || "",
      closeTime: day.closeTime || ""
        }));
        
    hoursMetadata = {
          type: 'custom_by_day',
          days: cleanedDays
        };
      }
      
  // Update state by creating a new object to ensure it triggers a re-render
  setSelfServiceData(prevData => {
    // Create a deep copy to avoid modifying the original state
    const updatedData = JSON.parse(JSON.stringify(prevData));
    
    // Update the operatingHours directly in the state
    updatedData.operatingHours = schedule;
    
    // Ensure metadata structure exists and update only the hours section
    if (!updatedData.metadata) {
      updatedData.metadata = { services: { "1": {} } };
    } else if (!updatedData.metadata.services) {
      updatedData.metadata.services = { "1": {} };
    } else if (!updatedData.metadata.services["1"]) {
      updatedData.metadata.services["1"] = {};
    }
    
    // Update ONLY the hours in the metadata structure
    updatedData.metadata.services["1"].hours = hoursMetadata;

    
    // Force the parent update to ensure changes are saved
    // The parent component will call the parent's updateDatabase function
    if (onUpdate) {
      // Use setTimeout to ensure this runs after the state update
        setTimeout(() => {
        onUpdate(updatedData);
      }, 0);
    }
    
    return updatedData;
    });
  };




  
  // Handle updating attendant hours schedule
  const handleAttendantHoursUpdate = (dayIndex, field, value) => {
    const scheduleType = 'attendantHours';
    const schedule = { ...getSchedule(scheduleType) };
    
    // Handle special case for mode change
    if (field === 'mode') {
      schedule.mode = value;
      
      // When switching to custom_by_day mode, initialize the days array
      if (value === 'custom_by_day') {
        // Create days array if it doesn't exist or is empty
        if (!schedule.days || !Array.isArray(schedule.days) || schedule.days.length === 0) {
          schedule.days = [
            { day: 'Monday', isOpen: true, openTime: "", closeTime: "" },
            { day: 'Tuesday', isOpen: true, openTime: "", closeTime: "" },
            { day: 'Wednesday', isOpen: true, openTime: "", closeTime: "" },
            { day: 'Thursday', isOpen: true, openTime: "", closeTime: "" },
            { day: 'Friday', isOpen: true, openTime: "", closeTime: "" },
            { day: 'Saturday', isOpen: true, openTime: "", closeTime: "" },
            { day: 'Sunday', isOpen: true, openTime: "", closeTime: "" }
          ];
        }
      }
    }
    // Handle setting hours for "same every day" mode
    else if (field === 'sameHours') {
      const { open, close } = value;
      schedule.sameHoursOpen = open;
      schedule.sameHoursClose = close;
    }
    // Handle setting specific day's hours for "custom by day" mode
    else if (dayIndex >= 0) {
      const day = { ...schedule.days[dayIndex] };
      
      if (field === 'isOpen') {
        day.isOpen = value;
      } else if (field === 'times') {
        const { open, close } = value;
        day.openTime = open;
        day.closeTime = close;
      }
      
      schedule.days[dayIndex] = day;
    }
    
    // Now update the state and metadata
    setSelfServiceData(prevData => {
      // Create a deep copy of the current metadata
      const currentMetadata = prevData.metadata 
        ? JSON.parse(JSON.stringify(prevData.metadata)) 
        : { services: { "1": {} } };
      
      // Ensure the proper nested structure exists
      if (!currentMetadata.services) currentMetadata.services = {};
      if (!currentMetadata.services["1"]) currentMetadata.services["1"] = {};
      
      // Format metadata based on schedule mode
      let attendantMetadata = {};
      
      if (schedule.mode === 'unattended') {
        attendantMetadata = {
          type: 'unattended'
        };
      } else if (schedule.mode === 'same_every_day') {
        attendantMetadata = {
          type: 'same_every_day',
          opening_time: schedule.sameHoursOpen,
          closing_time: schedule.sameHoursClose
        };
      } else {
        // Create a cleaned up version of days with exactly the right property names
        const cleanedDays = schedule.days.map(day => ({
          day: day.day,
          isOpen: day.isOpen,
          openTime: day.openTime,
          closeTime: day.closeTime
        }));
        
        attendantMetadata = {
          type: 'custom_by_day',
          days: cleanedDays
        };
      }
      
      // Update ONLY the attendantHours section of the metadata
      currentMetadata.services["1"].attendantHours = attendantMetadata;
      
      // Create updated state
      const updatedState = {
        ...prevData,
        [scheduleType]: schedule,
        metadata: currentMetadata
      };
      
      // Use a setTimeout to ensure the updateDatabase call happens after the state update
      setTimeout(() => {
        updateDatabase(updatedState);
      }, 100);
      
      // Return updated state
      return updatedState;
    });
    
    // Add an explicit updateDatabase call after state is updated
    // This ensures attendant hours changes are saved to the database
    setTimeout(() => {
      updateDatabase(selfServiceData);
    }, 100);
  };
  
  // Handle updating last load schedule
  // Handle updating last load schedule
  const handleLastLoadScheduleUpdate = (dayIndex, field, value) => {
    // Get a fresh copy of the current schedule
    const currentSchedule = getSchedule('lastLoadSchedule');
    const schedule = JSON.parse(JSON.stringify(currentSchedule));
    
    // Handle special case for mode change
    if (field === 'mode') {
      schedule.mode = value;
      
      // When switching to custom_by_day mode, ensure each day has all the necessary properties
      if (value === 'custom_by_day') {
        // If we don't have days array, create it with all days
        if (!schedule.days || !Array.isArray(schedule.days) || schedule.days.length === 0) {
          schedule.days = daysOfWeek.map(day => ({
            day,
            isOpen: true,
            openTime: "", // Used for lastLoadTime in same time mode
            closeTime: "",
            lastWashTime: "",
            lastDryTime: ""
          }));
        } else {
          // Ensure all properties exist on each day
          schedule.days = schedule.days.map(day => ({
            ...day,
            isOpen: day.isOpen !== undefined ? day.isOpen : true,
            openTime: day.openTime || "", // Used for lastLoadTime in same time mode
            closeTime: day.closeTime || "",
            lastWashTime: day.lastWashTime || "",
            lastDryTime: day.lastDryTime || ""
          }));
        }
      }
    }
    // Handle setting hours for "same every day" mode
    else if (field === 'sameHours') {
      const { open, close } = value;
      
      // Only update if values are provided
      if (open) schedule.sameHoursOpen = open;
      if (close) schedule.sameHoursClose = close;
      
      // If we're in same time mode, also update the lastWashTime and lastDryTime
      // This ensures we have consistent data for both UI modes
      if (schedule.sameTimeForWashAndDry && open) {
        schedule.lastWashTime = open;
        schedule.lastDryTime = open;
      }
    }
    // Handle setting wash/dry times for "same every day" mode
    else if (field === 'sameLoadTimes') {
      const { wash, dry } = value;
      
      // Only update if values are provided
      if (wash) schedule.lastWashTime = wash;
      if (dry) schedule.lastDryTime = dry;
      
      // If we're in same time mode and both times are the same, also update the sameHoursOpen
      // This ensures we have consistent data for both UI modes
      if (schedule.sameTimeForWashAndDry && wash && dry && wash === dry) {
        schedule.sameHoursOpen = wash;
      }
    }
    // Handle toggling same time for wash and dry
    else if (field === 'sameTimeForWashAndDry') {
      const previousValue = schedule.sameTimeForWashAndDry;
      schedule.sameTimeForWashAndDry = value;
      
      // When switching between modes, ensure we have appropriate values set
      if (value && !previousValue) {
        // Switching from separate times to same time
        // Use lastWashTime as the single time if available
        if (schedule.lastWashTime) {
          schedule.sameHoursOpen = schedule.lastWashTime;
        }
        
        // Update all days in custom mode
      if (schedule.mode === 'custom_by_day' && schedule.days) {
          schedule.days = schedule.days.map(day => ({
            ...day,
            openTime: day.lastWashTime || day.openTime || ""
          }));
        }
      } else if (!value && previousValue) {
        // Switching from same time to separate times
        // Use sameHoursOpen for both wash and dry times if they're not already set
        if (schedule.sameHoursOpen) {
          schedule.lastWashTime = schedule.lastWashTime || schedule.sameHoursOpen;
          schedule.lastDryTime = schedule.lastDryTime || schedule.sameHoursOpen;
        }
        
        // Update all days in custom mode
      if (schedule.mode === 'custom_by_day' && schedule.days) {
          schedule.days = schedule.days.map(day => ({
            ...day,
            lastWashTime: day.lastWashTime || day.openTime || "",
            lastDryTime: day.lastDryTime || day.openTime || ""
          }));
        }
      }
    }
    // Handle setting specific day's hours for "custom by day" mode
  else if (dayIndex >= 0 && schedule.days && dayIndex < schedule.days.length) {
      const day = { ...schedule.days[dayIndex] };
      
      if (field === 'isOpen') {
        day.isOpen = value;
      } else if (field === 'times') {
        const { open, close } = value;
        
        // Only update if values are provided
        if (open) day.openTime = open;
        if (close) day.closeTime = close;
        
        // If we're in same time mode, also update the lastWashTime and lastDryTime
        // This ensures we have consistent data for both UI modes
        if (schedule.sameTimeForWashAndDry && open) {
          day.lastWashTime = open;
          day.lastDryTime = open;
        }
      } else if (field === 'lastLoadTimes') {
        const { wash, dry } = value;
        
        // Only update if values are provided
        if (wash) day.lastWashTime = wash;
        if (dry) day.lastDryTime = dry;
        
        // If we're in same time mode and both times are the same, also update the openTime
        // This ensures we have consistent data for both UI modes
        if (schedule.sameTimeForWashAndDry && wash && dry && wash === dry) {
          day.openTime = wash;
        }
      }
      
      schedule.days[dayIndex] = day;
    }
    
  // Update the state and metadata
    setSelfServiceData(prevData => {
      // Create a deep copy of the current metadata to avoid reference issues
      const currentMetadata = prevData.metadata 
        ? JSON.parse(JSON.stringify(prevData.metadata)) 
        : { services: { "1": {} } };
      
      // Ensure the proper nested structure exists
      if (!currentMetadata.services) currentMetadata.services = {};
      if (!currentMetadata.services["1"]) currentMetadata.services["1"] = {};
      
      // Create a clean last_load metadata object with ONLY the necessary fields
      let lastLoadMetadata = {};
      
      if (schedule.mode === 'no_last_load') {
        // For no last load, we only need to specify the type
        lastLoadMetadata = {
          type: 'no_last_load'
        };
      } else if (schedule.mode === 'same_every_day') {
        if (schedule.sameTimeForWashAndDry) {
          // When using same time, only include last_load_time
          lastLoadMetadata = {
            type: 'same_every_day',
            same_time_for_wash_and_dry: true,
            last_load_time: schedule.sameHoursOpen || ""
          };
        } else {
          // When using different times, include last_wash_time and last_dry_time
          lastLoadMetadata = {
            type: 'same_every_day',
            same_time_for_wash_and_dry: false,
            last_wash_time: schedule.lastWashTime || "",
            last_dry_time: schedule.lastDryTime || ""
          };
        }
      } else if (schedule.mode === 'custom_by_day') {
        // Create a cleaned up version of days with only the necessary properties
        const cleanedDays = schedule.days.map(day => {
          if (schedule.sameTimeForWashAndDry) {
            // When using same time, only include last_load_time (from openTime)
            return {
              day: day.day,
              isOpen: day.isOpen !== undefined ? day.isOpen : true,
              last_load_time: day.openTime || ""
            };
          } else {
            // When using different times, include last_wash_time and last_dry_time
            return {
              day: day.day,
              isOpen: day.isOpen !== undefined ? day.isOpen : true,
              last_wash_time: day.lastWashTime || "",
              last_dry_time: day.lastDryTime || ""
            };
          }
        });
        
        lastLoadMetadata = {
          type: 'custom_by_day',
          same_time_for_wash_and_dry: schedule.sameTimeForWashAndDry,
          days: cleanedDays
        };
      }
      
      // Update the metadata with the new last_load structure
      currentMetadata.services["1"].lastLoadSchedule = lastLoadMetadata;
      
      // Return the updated state
      return {
        ...prevData,
        lastLoadSchedule: schedule,
        metadata: currentMetadata
      };
    });
  };
  
  // Reusable Schedule Table Component
  // Full updated ScheduleTable component
// Fixed ScheduleTable component
const ScheduleTable = ({ title, scheduleType, helpText }) => {
  const schedule = getSchedule(scheduleType);
  const timeFormat = 'h:mm a'; // 12-hour format with AM/PM
  
  // Get the appropriate handler based on schedule type
  const getHandlerForScheduleType = () => {
    switch(scheduleType) {
      case 'operatingHours':
        return handleOperatingHoursUpdate;
      case 'attendantHours':
        return handleAttendantHoursUpdate;
      case 'lastLoadSchedule':
        return handleLastLoadScheduleUpdate;
      default:
        console.error(`No handler defined for schedule type: ${scheduleType}`);
        return () => {}; // Empty function as fallback
    }
  };
  
  const scheduleHandler = getHandlerForScheduleType();
  
  // We'll use these handlers to avoid direct state updates in render
  // Fix for the ScheduleTable handleModeChange function
// The main issue is that once you switch to 24/7 mode, switching back to other modes doesn't work correctly.
// This is likely due to how the is24Hours flag and mode are synchronized.

// Replace the entire ScheduleTable component's handleModeChange function with this:
  const handleModeChange = (mode) => {
  // Create a simple object with the new values
  const updates = {
        mode: mode,
    is24Hours: mode === 'always_open'
  };
  
  // Apply all changes in a single update
  if (scheduleType === 'operatingHours') {
    // Update both properties at once for operating hours
    handleOperatingHoursUpdate(null, 'modeAndFlags', updates);
    } else {
    // Update both properties at once for other schedule types
    scheduleHandler(null, 'modeAndFlags', updates);
    }
  };
  
  const handleSameHoursChange = (times) => {
    // Modified to handle both antd TimePicker and HTML time input formats
    let open = '';
    let close = '';

    if (Array.isArray(times) && times.length === 2) {
      // Handle time inputs
      if (typeof times[0] === 'string') {
        open = times[0];
      } else if (times[0] && typeof times[0].format === 'function') {
        open = times[0].format('HH:mm');
      }

      if (typeof times[1] === 'string') {
        close = times[1];
      } else if (times[1] && typeof times[1].format === 'function') {
        close = times[1].format('HH:mm');
      }
      
      // Call the appropriate handler based on schedule type
      if (scheduleType === 'operatingHours') {
        handleOperatingHoursUpdate(null, 'sameHours', { open, close });
      } else if (scheduleType === 'attendantHours') {
        handleAttendantHoursUpdate(null, 'sameHours', { open, close });
      } else if (scheduleType === 'lastLoadSchedule') {
        handleLastLoadScheduleUpdate(null, 'sameHours', { open, close });
      } else {
        // Direct update to state to ensure UI reflects changes immediately
        setSelfServiceData(prevData => {
          // Create a deep copy to avoid reference issues
          const updatedData = JSON.parse(JSON.stringify(prevData));
          
          // Update the schedule directly
          if (!updatedData[scheduleType]) {
            updatedData[scheduleType] = {};
          }
          
          updatedData[scheduleType].sameHoursOpen = open;
          updatedData[scheduleType].sameHoursClose = close;
          updatedData[scheduleType].mode = 'same_every_day';
          
          // Update metadata
          if (!updatedData.metadata) {
            updatedData.metadata = { services: { "1": {} } };
          }
          
          if (!updatedData.metadata.services) {
            updatedData.metadata.services = { "1": {} };
          }
          
          // Determine which metadata field to update based on schedule type
          const metadataField = scheduleType === 'operatingHours' ? 'hours' : 
                              scheduleType === 'attendantHours' ? 'attendantHours' : 
                              scheduleType;
          
          // Update the metadata
          updatedData.metadata.services["1"][metadataField] = {
            type: 'same_every_day',
            opening_time: open,
            closing_time: close
          };
          
          return updatedData;
        });
        
        // Force immediate database update
        setTimeout(() => {
          updateDatabase(selfServiceData);
        }, 100);
      }
    }
  };
  
  const handleDayTimeChange = (index, times) => {
    // Modified to handle both antd TimePicker and HTML time input formats
    let open = '';
    let close = '';

    if (Array.isArray(times) && times.length === 2) {
      // Handle time inputs
      if (typeof times[0] === 'string') {
        open = times[0];
      } else if (times[0] && typeof times[0].format === 'function') {
        open = times[0].format('HH:mm');
      }

      if (typeof times[1] === 'string') {
        close = times[1];
      } else if (times[1] && typeof times[1].format === 'function') {
        close = times[1].format('HH:mm');
      }
      
      // Use the appropriate handler based on the schedule type
      if (scheduleType === 'operatingHours') {
        handleOperatingHoursUpdate(index, 'times', { open, close });
      } else if (scheduleType === 'attendantHours') {
        handleAttendantHoursUpdate(index, 'times', { open, close });
      } else if (scheduleType === 'lastLoadSchedule') {
        handleLastLoadScheduleUpdate(index, 'times', { open, close });
      }
    }
  };
  
  const handleDayOpenChange = (index, checked) => {
    scheduleHandler(index, 'isOpen', checked);
  };

  // Allowed minute options: 00, 15, 30, 45
  const disabledMinutes = () => {
    const minutes = [];
    for (let i = 0; i < 60; i++) {
      if (![0, 15, 30, 45].includes(i)) {
        minutes.push(i);
      }
    }
    return minutes;
  };
  
  if (!schedule) {
    return <div>Loading...</div>;
  }
  
  // Ensure days are initialized for display
  const safeSchedule = {
    ...schedule,
    days: schedule.days || [
      { day: 'Sunday', isOpen: true, openTime: "", closeTime: "" },
      { day: 'Monday', isOpen: true, openTime: "", closeTime: "" },
      { day: 'Tuesday', isOpen: true, openTime: "", closeTime: "" },
      { day: 'Wednesday', isOpen: true, openTime: "", closeTime: "" },
      { day: 'Thursday', isOpen: true, openTime: "", closeTime: "" },
      { day: 'Friday', isOpen: true, openTime: "", closeTime: "" },
      { day: 'Saturday', isOpen: true, openTime: "", closeTime: "" }
    ]
  };
  
  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2">
        <h3 className="text-lg font-semibold">{title}</h3>
        {helpText && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <HelpCircle size={16} className="text-gray-400" />
              </TooltipTrigger>
              <TooltipContent className="w-80 p-2">
                <p>{helpText}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      
      <div className="mb-4">
        {/* Replace the existing button group with these completely independent buttons */}
        <div className="inline-flex items-center rounded-md border border-gray-200 w-full">
          <button
            type="button"
            onClick={() => {
              // Direct state update with no dependencies
              setSelfServiceData(prevData => {
                const updatedData = JSON.parse(JSON.stringify(prevData));
                
                // Set schedule properties directly
                if (!updatedData[scheduleType]) {
                  updatedData[scheduleType] = {};
                }
                
                updatedData[scheduleType].mode = 'always_open';
                updatedData[scheduleType].is24Hours = true;
                
                // Update metadata
                if (!updatedData.metadata) {
                  updatedData.metadata = { services: { "1": {} } };
                }
                
                if (!updatedData.metadata.services) {
                  updatedData.metadata.services = { "1": {} };
                }
                
                // Update metadata field based on schedule type
                const metadataField = scheduleType === 'operatingHours' ? 'hours' : scheduleType;
                updatedData.metadata.services["1"][metadataField] = { type: '24/7' };
                
                if (onUpdate) {
                  setTimeout(() => onUpdate(updatedData), 0);
                }
                
                return updatedData;
              });
            }}
            className={cn(
              "flex-1 px-4 py-2 text-sm font-medium focus:outline-none focus:z-10 rounded-l-md",
              getSchedule(scheduleType).mode === 'always_open' 
                ? "bg-gray-900 text-white" 
                : "bg-white text-gray-700 hover:bg-gray-50"
            )}
          >
            24/7
          </button>
          
          <button
            type="button"
            onClick={() => {
              // Direct state update with no dependencies
              setSelfServiceData(prevData => {
                const updatedData = JSON.parse(JSON.stringify(prevData));
                
                // Set schedule properties directly
                if (!updatedData[scheduleType]) {
                  updatedData[scheduleType] = {};
                }
                
                updatedData[scheduleType].mode = 'same_every_day';
                updatedData[scheduleType].is24Hours = false;
                
                // Ensure we have the properties needed for this mode
                if (!updatedData[scheduleType].sameHoursOpen) {
                  updatedData[scheduleType].sameHoursOpen = "";
                }
                
                if (!updatedData[scheduleType].sameHoursClose) {
                  updatedData[scheduleType].sameHoursClose = "";
                }
                
                // Update metadata
                if (!updatedData.metadata) {
                  updatedData.metadata = { services: { "1": {} } };
                }
                
                if (!updatedData.metadata.services) {
                  updatedData.metadata.services = { "1": {} };
                }
                
                // Update metadata field based on schedule type
                const metadataField = scheduleType === 'operatingHours' ? 'hours' : scheduleType;
                updatedData.metadata.services["1"][metadataField] = {
                  type: 'same_every_day',
                  opening_time: updatedData[scheduleType].sameHoursOpen,
                  closing_time: updatedData[scheduleType].sameHoursClose
                };
                
                if (onUpdate) {
                  setTimeout(() => onUpdate(updatedData), 0);
                }
                
                return updatedData;
              });
            }}
            className={cn(
              "flex-1 px-4 py-2 text-sm font-medium focus:outline-none focus:z-10",
              getSchedule(scheduleType).mode === 'same_every_day' 
                ? "bg-gray-900 text-white" 
                : "bg-white text-gray-700 hover:bg-gray-50",
              "border-x border-gray-200"
            )}
          >
            Same Every Day
          </button>
          
          <button
            type="button"
            onClick={() => {
              // Direct state update with no dependencies
              setSelfServiceData(prevData => {
                const updatedData = JSON.parse(JSON.stringify(prevData));
                
                // Set schedule properties directly
                if (!updatedData[scheduleType]) {
                  updatedData[scheduleType] = {};
                }
                
                updatedData[scheduleType].mode = 'custom_by_day';
                updatedData[scheduleType].is24Hours = false;
                
                // Ensure we have days array for this mode
                if (!updatedData[scheduleType].days || !Array.isArray(updatedData[scheduleType].days) || updatedData[scheduleType].days.length === 0) {
                  updatedData[scheduleType].days = [
                    { day: 'Monday', isOpen: true, openTime: "", closeTime: "" },
                    { day: 'Tuesday', isOpen: true, openTime: "", closeTime: "" },
                    { day: 'Wednesday', isOpen: true, openTime: "", closeTime: "" },
                    { day: 'Thursday', isOpen: true, openTime: "", closeTime: "" },
                    { day: 'Friday', isOpen: true, openTime: "", closeTime: "" },
                    { day: 'Saturday', isOpen: true, openTime: "", closeTime: "" },
                    { day: 'Sunday', isOpen: true, openTime: "", closeTime: "" }
                  ];
                }
                
                // Update metadata
                if (!updatedData.metadata) {
                  updatedData.metadata = { services: { "1": {} } };
                }
                
                if (!updatedData.metadata.services) {
                  updatedData.metadata.services = { "1": {} };
                }
                
                // Update metadata field based on schedule type
                const metadataField = scheduleType === 'operatingHours' ? 'hours' : scheduleType;
                
                // Create a cleaned up version of days for metadata
                const cleanedDays = updatedData[scheduleType].days.map(day => ({
                  day: day.day,
                  isOpen: day.isOpen !== undefined ? day.isOpen : true,
                  openTime: day.openTime || "",
                  closeTime: day.closeTime || ""
                }));
                
                updatedData.metadata.services["1"][metadataField] = {
                  type: 'custom_by_day',
                  days: cleanedDays
                };
                
                if (onUpdate) {
                  setTimeout(() => onUpdate(updatedData), 0);
                }
                
                return updatedData;
              });
            }}
            className={cn(
              "flex-1 px-4 py-2 text-sm font-medium focus:outline-none focus:z-10 rounded-r-md",
              getSchedule(scheduleType).mode === 'custom_by_day' 
                ? "bg-gray-900 text-white" 
                : "bg-white text-gray-700 hover:bg-gray-50"
            )}
          >
            Custom by Day
          </button>
        </div>
      </div>
      
      {safeSchedule.mode === 'always_open' && (
        <div className="bg-gray-50 rounded-md p-4 text-center text-gray-600">
          This service is always open — no specific opening/closing times needed.
        </div>
      )}
      
      {safeSchedule.mode === 'same_every_day' && (
        <div className="space-y-4">
          <div>
            <label className="text-sm mb-2 block">Hours (Open - Close)</label>
            <div className="flex items-center gap-2">
              <Input
                type="time"
                value={safeSchedule.sameHoursOpen || ''}
                onChange={(e) => {
                  if (e.target.value) {
                    const open = e.target.value;
                    const close = safeSchedule.sameHoursClose || '';
                    handleSameHoursChange([open, close]);
                  }
                }}
                className="flex-1"
                placeholder="09:00"
              />
              <span>to</span>
              <Input
                type="time"
                value={safeSchedule.sameHoursClose || ''}
                onChange={(e) => {
                  if (e.target.value) {
                    const open = safeSchedule.sameHoursOpen || '';
                    const close = e.target.value;
                    handleSameHoursChange([open, close]);
                  }
                }}
                className="flex-1"
                placeholder="17:00"
              />
            </div>
          </div>
          
          <div className="text-sm bg-gray-50 rounded-md p-3">
            {safeSchedule.sameHoursOpen && safeSchedule.sameHoursClose ? 
              `Hours: ${formatTime(safeSchedule.sameHoursOpen)} – ${formatTime(safeSchedule.sameHoursClose)} every day` : 
              'Please set your opening and closing hours'
            }
          </div>
        </div>
      )}
      
      {safeSchedule.mode === 'custom_by_day' && (
        <div className="border rounded-md overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[100px]">
                  Day
                </th>
                <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[65%]">
                  Hours
                </th>
                <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[80px] text-center">
                  Open
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {safeSchedule.days.map((day, index) => (
                <tr key={day.day} className={cn(!day.isOpen && "bg-gray-100 text-gray-400")}>
                  <td className={cn(
                    "px-4 py-2 whitespace-nowrap text-sm font-medium", 
                    day.isOpen ? "text-gray-900" : "text-gray-400"
                  )}>
                    {day.day}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-500">
                    <div className="flex items-center gap-2">
                      <Input
                        type="time"
                        value={day.openTime || ''}
                        onChange={(e) => {
                          if (e.target.value) {
                            const open = e.target.value;
                            const close = day.closeTime || '';
                            handleDayTimeChange(index, [open, close]);
                          }
                        }}
                      disabled={!day.isOpen}
                        className="flex-1"
                        placeholder="09:00"
                      />
                      <span>to</span>
                      <Input
                        type="time"
                        value={day.closeTime || ''}
                        onChange={(e) => {
                          if (e.target.value) {
                            const open = day.openTime || '';
                            const close = e.target.value;
                            handleDayTimeChange(index, [open, close]);
                          }
                        }}
                        disabled={!day.isOpen}
                        className="flex-1"
                        placeholder="17:00"
                      />
                    </div>
                  </td>
                  <td className={cn(
                    "px-4 py-2 whitespace-nowrap text-sm text-center", 
                    day.isOpen ? "text-gray-500" : "text-gray-400"
                  )}>
                    <CustomSwitch
                      checked={day.isOpen}
                      onCheckedChange={(checked) => handleDayOpenChange(index, checked)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
  
  // Tab button for custom tab styling if needed
  const TabButton = ({ active, icon: Icon, label, onClick }) => (
    <button
      className={cn(
        "flex items-center gap-2 px-3 py-2 rounded-md transition-colors shrink-0",
        active 
          ? "bg-gray-900 text-white" 
          : "bg-gray-100 text-gray-600 hover:bg-gray-200"
      )}
      onClick={onClick}
    >
      <Icon className="w-4 h-4" />
      {label && <span className="text-sm font-medium whitespace-nowrap">{label}</span>}
    </button>
  );
  
  // REVISED APPROACH
// Remove the automatic sync effect entirely

// Instead, add a sync option to the Hours tab UI
// Complete renderHoursContent function
const renderHoursContent = () => {
  return (
    <div>
      <ScheduleTable 
        title="Operating Hours" 
        scheduleType="operatingHours"
        helpText="Set when this self-service location is open and available to customers."
      />
      
      {getSchedule('operatingHours').mode === 'custom_by_day' && (
        <div className="mt-4 p-3 bg-gray-50 rounded-md border border-gray-200">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Info className="h-4 w-4 text-gray-500" />
              <span className="text-sm text-gray-700">Apply closed days to other schedules</span>
            </div>
            <Button 
              variant="outline" 
              size="sm" 
              onClick={syncClosedDaysToOtherSchedules}
            >
              Sync Closed Days
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const syncClosedDaysToOtherSchedules = () => {
  const operatingHours = getScheduleData('operatingHours');
  
  // Only proceed if in custom_by_day mode
  if (operatingHours.mode !== 'custom_by_day') return;
  
  // Create a single state update that handles both attendant and last load updates
  setSelfServiceData(prevData => {
    // Create a deep copy to avoid reference issues
    const newData = JSON.parse(JSON.stringify(prevData));
    
    // Create a deep copy of the current metadata to avoid reference issues
    const currentMetadata = newData.metadata 
      ? JSON.parse(JSON.stringify(newData.metadata))
      : { services: { "1": {} } };
    
    // Ensure the proper nested structure exists
    if (!currentMetadata.services) currentMetadata.services = {};
    if (!currentMetadata.services["1"]) currentMetadata.services["1"] = {};
    
    // Update attendant hours if needed
    if (prevData.attendantHours?.mode === 'custom_by_day') {
      const updatedAttendantDays = [...prevData.attendantHours.days];
      let hasAttendantChanges = false;
      
      operatingHours.days.forEach((day, index) => {
        if (index < updatedAttendantDays.length && !day.isOpen && updatedAttendantDays[index].isOpen) {
          updatedAttendantDays[index].isOpen = false;
          hasAttendantChanges = true;
        }
      });
      
      if (hasAttendantChanges) {
        newData.attendantHours = {
          ...prevData.attendantHours,
          days: updatedAttendantDays
        };
        
        // Create cleaned days array for metadata
        const cleanedDays = updatedAttendantDays.map(day => ({
          day: day.day,
          isOpen: day.isOpen,
          openTime: day.openTime || "",
          closeTime: day.closeTime || ""
        }));
        
        // Update attendantHours in metadata
        currentMetadata.services["1"].attendantHours = {
          type: 'custom_by_day',
          days: cleanedDays
        };
      }
    }
    
    // Update last load schedule if needed
    if (prevData.lastLoadSchedule?.mode === 'custom_by_day') {
      const updatedLastLoadDays = [...prevData.lastLoadSchedule.days];
      let hasLastLoadChanges = false;
      
      operatingHours.days.forEach((day, index) => {
        if (index < updatedLastLoadDays.length && !day.isOpen && updatedLastLoadDays[index].isOpen) {
          updatedLastLoadDays[index].isOpen = false;
          hasLastLoadChanges = true;
        }
      });
      
      if (hasLastLoadChanges) {
        newData.lastLoadSchedule = {
          ...prevData.lastLoadSchedule,
          days: updatedLastLoadDays
        };
        
        // Format last load metadata properly based on sameTimeForWashAndDry setting
        const sameTimeForWashAndDry = prevData.lastLoadSchedule.sameTimeForWashAndDry !== false;
        
        // Create a cleaned up version of days with only the necessary properties
        const cleanedDays = updatedLastLoadDays.map(day => {
          if (sameTimeForWashAndDry) {
            // When using same time, only include last_load_time (from openTime)
            return {
              day: day.day,
              isOpen: day.isOpen !== undefined ? day.isOpen : true,
              last_load_time: day.openTime || ""
            };
          } else {
            // When using different times, include last_wash_time and last_dry_time
            return {
              day: day.day,
              isOpen: day.isOpen !== undefined ? day.isOpen : true,
              last_wash_time: day.lastWashTime || "",
              last_dry_time: day.lastDryTime || ""
            };
          }
        });
        
        // Update lastLoadSchedule in metadata
        currentMetadata.services["1"].lastLoadSchedule = {
          type: 'custom_by_day',
          same_time_for_wash_and_dry: sameTimeForWashAndDry,
          days: cleanedDays
        };
      }
    }
    
    // Update metadata
    newData.metadata = currentMetadata;
    
    // Notify parent component of changes
    if (onUpdate && (newData.attendantHours !== prevData.attendantHours || 
                     newData.lastLoadSchedule !== prevData.lastLoadSchedule)) {
      // Use setTimeout to ensure this executes after state update
      setTimeout(() => {
        onUpdate(newData);
      }, 0);
    }
    
    return newData;
  });
};
  
  const renderMachinesContent = () => (
    <div className="space-y-6">
      <div className="border rounded-lg">
        <div className="p-4 bg-gray-50 border-b flex items-center justify-between">
          <div className="flex items-center">
            <h3 className="text-md font-medium">Machines</h3>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <span className="ml-2 cursor-help">
                    <HelpCircle className="h-4 w-4 text-gray-400" />
                  </span>
                </TooltipTrigger>
                <TooltipContent side="right" className="max-w-sm">
                  <p className="text-sm">Configure your self-service machines. This helps customers understand what equipment is available.</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          
          <Tabs value={activeMachineType} onValueChange={setActiveMachineType} className="w-auto">
            <TabsList className="grid w-48 grid-cols-2">
              <TabsTrigger value="washers">Washers</TabsTrigger>
              <TabsTrigger value="dryers">Dryers</TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
        
        <div className="p-4">
          <div className="space-y-4">
            <Button 
              variant="outline" 
              className="w-full flex items-center justify-center gap-2 mb-4"
              onClick={() => setIsAddMachineModalOpen(true)}
            >
              <Plus className="w-4 h-4" />
              Add {activeMachineType === 'washers' ? 'Washer' : 'Dryer'}
            </Button>
            
            {machines[activeMachineType].map(machine => (
              <div key={machine.id} className="p-3 border rounded-md flex justify-between items-center">
                <div>
                  <h4 className="font-medium">{machine.name}</h4>
                  <p className="text-sm text-gray-500">Capacity: {machine.capacity}</p>
                  <p className="text-sm text-gray-500">Price: {machine.price}</p>
                </div>
                <button 
                  className="text-red-500 hover:text-red-700"
                  onClick={() => handleDeleteMachine(machine.id)}
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
  
  // Add a state to track which payment toggles are currently updating
  const [updatingToggles, setUpdatingToggles] = useState({});
  
  // Simplify the CustomSwitch component to just be a direct wrapper around Switch
  const CustomSwitch = ({ checked, onCheckedChange, disabled }) => {
    return (
      <Switch 
        checked={checked} 
        onCheckedChange={onCheckedChange} 
        disabled={disabled}
        className="data-[state=checked]:bg-gray-900"
      />
    );
  };
  
  // Simplify the payment method update function to be as direct as possible
  const handlePaymentMethodUpdate = (field, value) => {
    // Create a simple update function that just sets the state directly
    setSelfServiceData(prevData => {
      // Create a copy of the current data
      const updatedData = { ...prevData, [field]: value };
      
      // Ensure metadata structure exists
      if (!updatedData.metadata) updatedData.metadata = { services: { "1": {} } };
      if (!updatedData.metadata.services) updatedData.metadata.services = { "1": {} };
      if (!updatedData.metadata.services["1"]) updatedData.metadata.services["1"] = {};
      
      // Update metadata based on field type
      if (['acceptsCoins', 'acceptsCash', 'acceptsCards', 'acceptsMobilePayments', 'acceptsCrypto', 'hasLoyaltyProgram'].includes(field)) {
        if (!updatedData.metadata.services["1"].payment_methods) {
          updatedData.metadata.services["1"].payment_methods = {};
        }
        updatedData.metadata.services["1"].payment_methods[field] = value;
      }
      else if (['hasCoinMachine', 'hasATM', 'hasCardReader', 'hasMobileApp', 'hasBitcoinATM'].includes(field)) {
        if (!updatedData.metadata.services["1"].payment_options) {
          updatedData.metadata.services["1"].payment_options = {};
        }
        updatedData.metadata.services["1"].payment_options[field] = value;
      }
      else if (['acceptsCredit', 'acceptsDebit', 'cardHoldInfo'].includes(field)) {
        if (!updatedData.metadata.services["1"].payment_methods) {
          updatedData.metadata.services["1"].payment_methods = {};
        }
        if (!updatedData.metadata.services["1"].payment_methods.card_options) {
          updatedData.metadata.services["1"].payment_methods.card_options = {};
        }
        updatedData.metadata.services["1"].payment_methods.card_options[field] = value;
      }
      
      // Directly update the database with the new data
      updateDatabase(updatedData);
      
      return updatedData;
    });
  };
  
  const renderPaymentContent = () => (
    <div className="space-y-6">
      <div className="border rounded-lg">
        <div className="p-4 bg-gray-50 border-b">
          <div className="flex items-center">
            <h3 className="text-md font-medium">Payment Methods</h3>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <span className="ml-2 cursor-help">
                    <HelpCircle className="h-4 w-4 text-gray-400" />
                  </span>
                </TooltipTrigger>
                <TooltipContent side="right" className="max-w-sm">
                  <p className="text-sm">Configure payment methods and options for your self-service location.</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
        
        <div className="p-4">
          <Tabs defaultValue="coin" className="w-full">
            <TabsList className="flex flex-wrap gap-1 mb-6">
              <TabsTrigger value="coin" className="flex-1 min-w-[80px]">Coin</TabsTrigger>
              <TabsTrigger value="cash" className="flex-1 min-w-[80px]">Cash</TabsTrigger>
              <TabsTrigger value="card" className="flex-1 min-w-[80px]">Card</TabsTrigger>
              <TabsTrigger value="rewards" className="flex-1 min-w-[80px]">Rewards</TabsTrigger>
              <TabsTrigger value="mobile" className="flex-1 min-w-[80px]">Mobile</TabsTrigger>
              <TabsTrigger value="crypto" className="flex-1 min-w-[80px]">Crypto</TabsTrigger>
            </TabsList>
            
            <TabsContent value="coin" className="space-y-4">
              <div className="flex items-center gap-2">
                <Switch 
                  checked={selfServiceData.acceptsCoins} 
                  onCheckedChange={(checked) => handlePaymentMethodUpdate('acceptsCoins', checked)}
                  className="data-[state=checked]:bg-gray-900"
                />
                <span className="text-sm font-medium">Accept Coin Payments</span>
              </div>
              
              <div className="border-t pt-4 space-y-4">
                <div className="flex items-center">
                  <h4 className="font-medium text-gray-700">Coin Options</h4>
                </div>
                
                <div className="flex items-center gap-2">
                  <Switch 
                    checked={selfServiceData.hasCoinMachine} 
                    onCheckedChange={(checked) => handlePaymentMethodUpdate('hasCoinMachine', checked)}
                    className="data-[state=checked]:bg-gray-900"
                  />
                  <span className="text-sm font-medium">Does this location have a coin change machine?</span>
                </div>
              </div>
            </TabsContent>
            
            <TabsContent value="cash" className="space-y-4">
              <div className="flex items-center gap-2">
                <Switch 
                  checked={selfServiceData.acceptsCash} 
                  onCheckedChange={(checked) => handlePaymentMethodUpdate('acceptsCash', checked)}
                  className="data-[state=checked]:bg-gray-900"
                />
                <span className="text-sm font-medium">Accept Cash Payments</span>
              </div>
              
              <div className="border-t pt-4 space-y-4">
                <div className="flex items-center">
                  <h4 className="font-medium text-gray-700">Cash Options</h4>
                </div>
                
                <div className="flex items-center gap-2">
                  <Switch 
                    checked={selfServiceData.hasATM} 
                    onCheckedChange={(checked) => handlePaymentMethodUpdate('hasATM', checked)}
                    className="data-[state=checked]:bg-gray-900"
                  />
                  <span className="text-sm font-medium">Does this location have an ATM?</span>
                </div>
              </div>
            </TabsContent>
            
            <TabsContent value="card" className="space-y-4">
              <div className="flex items-center gap-2">
                <Switch 
                  checked={selfServiceData.acceptsCards} 
                  onCheckedChange={(checked) => handlePaymentMethodUpdate('acceptsCards', checked)}
                  className="data-[state=checked]:bg-gray-900"
                />
                <span className="text-sm font-medium">Accept Card Payments</span>
              </div>
              
              <div className="border-t pt-4 space-y-4">
                <div className="flex items-center">
                  <h4 className="font-medium text-gray-700">Card Options</h4>
                </div>
                
                <div className="flex items-center gap-2">
                  <Switch 
                    checked={selfServiceData.acceptsCredit} 
                    onCheckedChange={(checked) => handlePaymentMethodUpdate('acceptsCredit', checked)}
                    className="data-[state=checked]:bg-gray-900"
                  />
                  <span className="text-sm font-medium">Credit Cards</span>
                </div>
                
                <div className="flex items-center gap-2">
                  <Switch 
                    checked={selfServiceData.acceptsDebit} 
                    onCheckedChange={(checked) => handlePaymentMethodUpdate('acceptsDebit', checked)}
                    className="data-[state=checked]:bg-gray-900"
                  />
                  <span className="text-sm font-medium">Debit Cards</span>
                </div>
                
                {selfServiceData.acceptsCards && (
                  <div className="space-y-2 mt-4">
                    <label className="text-sm font-medium text-gray-700">Card Hold Information (Optional)</label>
                    <textarea
                      value={selfServiceData.cardHoldInfo || ''}
                      onChange={(e) => handlePaymentMethodUpdate('cardHoldInfo', e.target.value)}
                      placeholder="Enter information about any holds placed on customer cards when using machines..."
                      className="w-full h-24 px-3 py-2 text-sm bg-white border border-gray-200 rounded-md focus:outline-none focus:border-gray-500 resize-none"
                    />
                    <p className="text-xs text-gray-500">
                      Provide details about any temporary holds or authorizations placed on customer cards.
                    </p>
                  </div>
                )}
              </div>
            </TabsContent>
            
            <TabsContent value="mobile" className="space-y-4">
              <div className="flex items-center gap-2">
                <Switch 
                  checked={selfServiceData.acceptsMobilePayments} 
                  onCheckedChange={(checked) => handlePaymentMethodUpdate('acceptsMobilePayments', checked)}
                  className="data-[state=checked]:bg-gray-900"
                />
                <span className="text-sm font-medium">Accept Mobile Payments</span>
              </div>
              
              <div className="border-t pt-4 space-y-4">
                <div className="flex items-center">
                  <h4 className="font-medium text-gray-700">Mobile Payment Options</h4>
                </div>
                
                {selfServiceData.acceptsMobilePayments && (
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">Available Mobile Payment Methods</label>
                    <textarea
                      value={selfServiceData.mobilePaymentOptions || ''}
                      onChange={(e) => handlePaymentMethodUpdate('mobilePaymentOptions', e.target.value)}
                      placeholder="Describe which mobile payment options are available (e.g., Apple Pay, Google Pay, Samsung Pay, etc.)..."
                      className="w-full h-24 px-3 py-2 text-sm bg-white border border-gray-200 rounded-md focus:outline-none focus:border-gray-500 resize-none"
                    />
                    <p className="text-xs text-gray-500">
                      Provide details about all mobile payment methods accepted at your location.
                    </p>
                </div>
                )}
              </div>
            </TabsContent>
            
            <TabsContent value="rewards" className="space-y-4">
              <div className="flex items-center gap-2">
                <Switch 
                  checked={selfServiceData.hasLoyaltyProgram} 
                  onCheckedChange={(checked) => handlePaymentMethodUpdate('hasLoyaltyProgram', checked)}
                  className="data-[state=checked]:bg-gray-900"
                />
                <span className="text-sm font-medium">Has Loyalty Program</span>
              </div>
              
              {selfServiceData.hasLoyaltyProgram && (
              <div className="border-t pt-4 space-y-4">
                <div className="flex items-center">
                    <h4 className="font-medium text-gray-700">Loyalty Program Details</h4>
                    <span className="ml-2 text-xs text-red-500">*Required</span>
                </div>
                
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">Loyalty Program Information</label>
                    <textarea
                      value={selfServiceData.loyaltyProgramDetails || ''}
                      onChange={(e) => handlePaymentMethodUpdate('loyaltyProgramDetails', e.target.value)}
                      placeholder="Describe your loyalty program details including: cost to signup/get a card, whether it's physical or online, available rewards, and how to refill it..."
                      required
                      className="w-full h-32 px-3 py-2 text-sm bg-white border border-gray-200 rounded-md focus:outline-none focus:border-gray-500 resize-none"
                    />
                    <p className="text-xs text-gray-500">
                      Include all information customers need to understand and use your loyalty program.
                    </p>
                </div>
                </div>
              )}
            </TabsContent>
            
            <TabsContent value="crypto" className="space-y-4">
              <div className="flex items-center gap-2">
                <Switch 
                  checked={selfServiceData.acceptsBitcoin} 
                  onCheckedChange={(checked) => handlePaymentMethodUpdate('acceptsBitcoin', checked)}
                  className="data-[state=checked]:bg-gray-900"
                />
                <span className="text-sm font-medium">Accept Bitcoin</span>
              </div>
              
              <div className="border-t pt-4 space-y-4">
                <div className="flex items-center">
                  <h4 className="font-medium text-gray-700">Bitcoin Options</h4>
                </div>
                
                <div className="flex items-center gap-2">
                  <Switch 
                    checked={selfServiceData.hasBitcoinATM} 
                    onCheckedChange={(checked) => handlePaymentMethodUpdate('hasBitcoinATM', checked)}
                    className="data-[state=checked]:bg-gray-900"
                  />
                  <span className="text-sm font-medium">Does this location have a Bitcoin ATM?</span>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </div>
  );
  
// Completely Separated renderAttendantContent function
const renderAttendantContent = () => {
  // Access attendant hours DIRECTLY from selfServiceData instead of using getSchedule
  const attendantHours = selfServiceData.attendantHours || {
    mode: 'unattended',
    days: [
      { day: 'Monday', isOpen: true, openTime: "", closeTime: "" },
      { day: 'Tuesday', isOpen: true, openTime: "", closeTime: "" },
      { day: 'Wednesday', isOpen: true, openTime: "", closeTime: "" },
      { day: 'Thursday', isOpen: true, openTime: "", closeTime: "" },
      { day: 'Friday', isOpen: true, openTime: "", closeTime: "" },
      { day: 'Saturday', isOpen: true, openTime: "", closeTime: "" },
      { day: 'Sunday', isOpen: true, openTime: "", closeTime: "" }
    ],
    sameHoursOpen: "",
    sameHoursClose: ""
  };
  
  // Get the current mode directly from attendantHours
  const currentMode = attendantHours.mode || 'unattended';
  
  // Custom handler for attendant hours mode changes
  const handleAttendantModeChange = (newMode) => {
    // Create a new object with the updated mode
    const updatedAttendantHours = {
      ...attendantHours,
      mode: newMode,
    };
    
    // If switching to custom_by_day, ensure days array is initialized
    if (newMode === 'custom_by_day' && (!attendantHours.days || !attendantHours.days.length)) {
      updatedAttendantHours.days = [
        { day: 'Monday', isOpen: true, openTime: "", closeTime: "" },
        { day: 'Tuesday', isOpen: true, openTime: "", closeTime: "" },
        { day: 'Wednesday', isOpen: true, openTime: "", closeTime: "" },
        { day: 'Thursday', isOpen: true, openTime: "", closeTime: "" },
        { day: 'Friday', isOpen: true, openTime: "", closeTime: "" },
        { day: 'Saturday', isOpen: true, openTime: "", closeTime: "" },
        { day: 'Sunday', isOpen: true, openTime: "", closeTime: "" }
      ];
    }
    
    // Update state directly
    setSelfServiceData(prevData => {
      // Create a deep copy of the current metadata to avoid reference issues
      const currentMetadata = prevData.metadata 
        ? JSON.parse(JSON.stringify(prevData.metadata)) 
        : { services: { "1": {} } };
      
      // Ensure the proper nested structure exists
      if (!currentMetadata.services) currentMetadata.services = {};
      if (!currentMetadata.services["1"]) currentMetadata.services["1"] = {};
      
      // Format metadata based on the new mode
      let attendantMetadata = {};
      
      if (newMode === 'unattended') {
        attendantMetadata = {
          type: 'unattended'
        };
        
        // Immediately update the required fields status to remove the blue dot
        // This ensures the UI updates immediately when switching to unattended mode
        setTimeout(() => {
          setRequiredFieldsStatus(prevStatus => ({
            ...prevStatus,
            staff: true
          }));
        }, 0);
      } else if (newMode === 'same_every_day') {
        attendantMetadata = {
          type: 'same_every_day',
          opening_time: updatedAttendantHours.sameHoursOpen || "",
          closing_time: updatedAttendantHours.sameHoursClose || ""
        };
      } else {
        // Create a cleaned up version of days with exactly the right property names
        const cleanedDays = (updatedAttendantHours.days || []).map(day => ({
          day: day.day,
          isOpen: day.isOpen,
          openTime: day.openTime || "",
          closeTime: day.closeTime || ""
        }));
        
        attendantMetadata = {
          type: 'custom_by_day',
          days: cleanedDays
        };
      }
      
      // Update ONLY the attendantHours section of the metadata
      currentMetadata.services["1"].attendantHours = attendantMetadata;
      
      // Create updated state
      const updatedState = {
        ...prevData,
        attendantHours: updatedAttendantHours,
        metadata: currentMetadata
      };
      
      // Use a setTimeout to ensure the updateDatabase call happens after the state update
      setTimeout(() => {
        updateDatabase(updatedState);
      }, 100);
      
      // Return updated state
      return updatedState;
    });
    
    // Remove the old setTimeout call as we now have it inside the setState callback
    // setTimeout(() => {
    //   updateDatabase(selfServiceData);
    // }, 100);
  };
  
  // Custom handler for "same every day" time changes
  const handleAttendantSameHoursChange = (times) => {
    // Modified to handle both antd TimePicker and HTML time input formats
    let open = '';
    let close = '';

    if (Array.isArray(times) && times.length === 2) {
      // Handle time inputs
      if (typeof times[0] === 'string') {
        open = times[0];
      } else if (times[0] && typeof times[0].format === 'function') {
        open = times[0].format('HH:mm');
      }

      if (typeof times[1] === 'string') {
        close = times[1];
      } else if (times[1] && typeof times[1].format === 'function') {
        close = times[1].format('HH:mm');
      }
      
      // Update state with the new times
      setSelfServiceData(prevData => {
        // Create updated attendant hours object
        const updatedAttendantHours = {
          ...prevData.attendantHours,
          sameHoursOpen: open,
          sameHoursClose: close
        };
        
        // Create a deep copy of the current metadata
        const currentMetadata = prevData.metadata 
          ? JSON.parse(JSON.stringify(prevData.metadata)) 
          : { services: { "1": {} } };
        
        // Ensure the proper nested structure exists
        if (!currentMetadata.services) currentMetadata.services = {};
        if (!currentMetadata.services["1"]) currentMetadata.services["1"] = {};
        
        // Update metadata with new times
        if (!currentMetadata.services["1"].attendantHours) {
          currentMetadata.services["1"].attendantHours = { type: 'same_every_day' };
        }
        
        currentMetadata.services["1"].attendantHours.opening_time = open;
        currentMetadata.services["1"].attendantHours.closing_time = close;
        
        // Create updated state
        const updatedState = {
          ...prevData,
          attendantHours: updatedAttendantHours,
          metadata: currentMetadata
        };
        
        // Use a setTimeout to ensure the updateDatabase call happens after the state update
        setTimeout(() => {
          updateDatabase(updatedState);
        }, 100);
        
        // Return updated state
        return updatedState;
      });
    }
    
    // Add an explicit updateDatabase call after state is updated
    // This ensures attendant hours changes are saved to the database
    setTimeout(() => {
      updateDatabase(selfServiceData);
    }, 100);
  };
  
  // Custom handler for updating a specific day in custom_by_day mode
  const handleAttendantDayUpdate = (index, field, value) => {
    // Make sure we have days array
    if (!attendantHours.days || !Array.isArray(attendantHours.days)) {
      return;
    }
    
    // Create a copy of the days array
    const updatedDays = [...attendantHours.days];
    
    // Update the specific field for the day
    if (field === 'isOpen') {
      updatedDays[index] = {
        ...updatedDays[index],
        isOpen: value
      };
    } else if (field === 'times') {
      updatedDays[index] = {
        ...updatedDays[index],
        openTime: value.open,
        closeTime: value.close
      };
    }
    
    // Update state with the new days array
    setSelfServiceData(prevData => {
      // Create updated attendant hours object
      const updatedAttendantHours = {
        ...prevData.attendantHours,
        days: updatedDays
      };
      
      // Create a deep copy of the current metadata
      const currentMetadata = prevData.metadata 
        ? JSON.parse(JSON.stringify(prevData.metadata)) 
        : { services: { "1": {} } };
      
      // Ensure the proper nested structure exists
      if (!currentMetadata.services) currentMetadata.services = {};
      if (!currentMetadata.services["1"]) currentMetadata.services["1"] = {};
      
      // Create cleaned days array for metadata
      const cleanedDays = updatedDays.map(day => ({
        day: day.day,
        isOpen: day.isOpen,
        openTime: day.openTime || "",
        closeTime: day.closeTime || ""
      }));
      
      // Update attendantHours in metadata
      currentMetadata.services["1"].attendantHours = {
        type: 'custom_by_day',
        days: cleanedDays
      };
      
      // Create updated state object
      const updatedState = {
        ...prevData,
        attendantHours: updatedAttendantHours,
        metadata: currentMetadata
      };
      
      // Call updateDatabase with the updated state
      setTimeout(() => {
        updateDatabase(updatedState);
      }, 100);
      
      // Return the updated state
      return updatedState;
    });
  };
  
  // Make sure the days array exists for rendering
  const safeDays = attendantHours.days || [
    { day: 'Sunday', isOpen: true, openTime: "", closeTime: "" },
    { day: 'Monday', isOpen: true, openTime: "", closeTime: "" },
    { day: 'Tuesday', isOpen: true, openTime: "", closeTime: "" },
    { day: 'Wednesday', isOpen: true, openTime: "", closeTime: "" },
    { day: 'Thursday', isOpen: true, openTime: "", closeTime: "" },
    { day: 'Friday', isOpen: true, openTime: "", closeTime: "" },
    { day: 'Saturday', isOpen: true, openTime: "", closeTime: "" }
  ];
  
  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <h3 className="text-lg font-semibold">Attendant Hours</h3>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <HelpCircle size={16} className="text-gray-400" />
              </TooltipTrigger>
              <TooltipContent className="w-80 p-2">
                <p>Set when staff or attendants are available on-site.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        
        {/* Improved button group with consistent styling */}
        <div className="inline-flex items-center rounded-md border border-gray-200 w-full">
          <button 
            type="button"
            onClick={() => handleAttendantModeChange('unattended')}
            className={cn(
              "flex-1 px-4 py-2 text-sm font-medium focus:outline-none focus:z-10 rounded-l-md",
              currentMode === 'unattended' 
                ? "bg-gray-900 text-white" 
                : "bg-white text-gray-700 hover:bg-gray-50"
            )}
          >
            Unattended
          </button>
          <button 
            type="button"
            onClick={() => handleAttendantModeChange('same_every_day')}
            className={cn(
              "flex-1 px-4 py-2 text-sm font-medium focus:outline-none focus:z-10",
              currentMode === 'same_every_day' 
                ? "bg-gray-900 text-white" 
                : "bg-white text-gray-700 hover:bg-gray-50",
              "border-x border-gray-200"
            )}
          >
            Same Every Day
          </button>
          <button 
            type="button"
            onClick={() => handleAttendantModeChange('custom_by_day')}
            className={cn(
              "flex-1 px-4 py-2 text-sm font-medium focus:outline-none focus:z-10 rounded-r-md",
              currentMode === 'custom_by_day' 
                ? "bg-gray-900 text-white" 
                : "bg-white text-gray-700 hover:bg-gray-50"
            )}
          >
            Custom by Day
          </button>
        </div>
        
        {currentMode === 'unattended' && (
          <div className="bg-gray-50 rounded-md p-4 text-center text-gray-600">
            This location is unattended — no staff or attendants are available on site.
          </div>
        )}
        
        {currentMode === 'same_every_day' && (
          <div className="space-y-4">
            <div>
              <label className="text-sm mb-2 block">Attendant Hours (Start - End)</label>
              <div className="flex items-center gap-2">
                <Input
                  type="time"
                  value={attendantHours.sameHoursOpen || ''}
                  onChange={(e) => {
                    if (e.target.value) {
                      const open = e.target.value;
                      const close = attendantHours.sameHoursClose || '';
                      handleAttendantSameHoursChange([open, close]);
                    }
                  }}
                  className="flex-1"
                  placeholder="09:00"
                />
                <span>to</span>
                <Input
                  type="time"
                  value={attendantHours.sameHoursClose || ''}
                  onChange={(e) => {
                    if (e.target.value) {
                      const open = attendantHours.sameHoursOpen || '';
                      const close = e.target.value;
                      handleAttendantSameHoursChange([open, close]);
                    }
                  }}
                  className="flex-1"
                  placeholder="17:00"
                />
              </div>
            </div>
            
            <div className="text-sm bg-gray-50 rounded-md p-3">
              {attendantHours.sameHoursOpen && attendantHours.sameHoursClose ? 
                `Attendant Hours: ${formatTime(attendantHours.sameHoursOpen)} – ${formatTime(attendantHours.sameHoursClose)} every day` : 
                'Please set attendant hours'
              }
            </div>
          </div>
        )}
        
        {currentMode === 'custom_by_day' && (
          <div className="border rounded-md overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[100px]">
                    Day
                  </th>
                  <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Hours
                  </th>
                  <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[80px] text-center">
                    Available
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {safeDays.map((day, index) => (
                  <tr key={day.day || `day-${index}`} className={cn(!day.isOpen && "bg-gray-100 text-gray-400")}>
                    <td className={cn(
                      "px-4 py-2 whitespace-nowrap text-sm font-medium", 
                      day.isOpen ? "text-gray-900" : "text-gray-400"
                    )}>
                      {day.day}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-500">
                      <div className="flex items-center gap-2">
                        <Input
                          type="time"
                          value={day.openTime || ''}
                          onChange={(e) => {
                            if (e.target.value) {
                              const open = e.target.value;
                              const close = day.closeTime || '';
                            handleAttendantDayUpdate(index, 'times', { open, close });
                          }
                        }}
                        disabled={!day.isOpen}
                          className="flex-1"
                          placeholder="09:00"
                        />
                        <span>to</span>
                        <Input
                          type="time"
                          value={day.closeTime || ''}
                          onChange={(e) => {
                            if (e.target.value) {
                              const open = day.openTime || '';
                              const close = e.target.value;
                              handleAttendantDayUpdate(index, 'times', { open, close });
                            }
                          }}
                          disabled={!day.isOpen}
                          className="flex-1"
                          placeholder="17:00"
                        />
                      </div>
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-center">
                      <CustomSwitch
                        checked={day.isOpen}
                        onCheckedChange={(checked) => handleAttendantDayUpdate(index, 'isOpen', checked)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
  
const renderLastLoadContent = () => {
  // Get the Last Load schedule
  const lastLoadSchedule = getSchedule('lastLoadSchedule');

  return (
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <h3 className="text-lg font-semibold">Last Load Times</h3>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <HelpCircle size={16} className="text-gray-400" />
              </TooltipTrigger>
              <TooltipContent className="w-80 p-2">
                <p>Set the last wash and dry start times. This helps customers know when they can start their final loads.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        
        <div className="mb-4">
          <div className="inline-flex items-center rounded-md border border-gray-200 w-full">
            <button
              type="button"
              onClick={() => handleLastLoadScheduleUpdate(null, 'mode', 'no_last_load')}
              className={cn(
                "flex-1 px-4 py-2 text-sm font-medium focus:outline-none focus:z-10 rounded-l-md",
                lastLoadSchedule.mode === 'no_last_load' 
                  ? "bg-gray-900 text-white" 
                  : "bg-white text-gray-700 hover:bg-gray-50"
              )}
            >
              No Last Load
            </button>
            <button
              type="button"
              onClick={() => handleLastLoadScheduleUpdate(null, 'mode', 'same_every_day')}
              className={cn(
                "flex-1 px-4 py-2 text-sm font-medium focus:outline-none focus:z-10",
                lastLoadSchedule.mode === 'same_every_day' 
                  ? "bg-gray-900 text-white" 
                  : "bg-white text-gray-700 hover:bg-gray-50"
              )}
            >
              Same Every Day
            </button>
            <button
              type="button"
              onClick={() => handleLastLoadScheduleUpdate(null, 'mode', 'custom_by_day')}
              className={cn(
                "flex-1 px-4 py-2 text-sm font-medium focus:outline-none focus:z-10 rounded-r-md",
                lastLoadSchedule.mode === 'custom_by_day' 
                  ? "bg-gray-900 text-white" 
                  : "bg-white text-gray-700 hover:bg-gray-50"
              )}
            >
              Custom by Day
            </button>
          </div>
        </div>
        
        {lastLoadSchedule.mode !== 'no_last_load' && (
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200 mb-4">
            <div className="flex items-center gap-3">
              <AlarmClock className="w-5 h-5 text-gray-400" />
              <span className="text-sm font-medium text-gray-700">Same time for wash and dry</span>
            </div>
            <CustomSwitch
              checked={lastLoadSchedule.sameTimeForWashAndDry}
              onCheckedChange={(checked) => handleLastLoadScheduleUpdate(null, 'sameTimeForWashAndDry', checked)}
            />
          </div>
        )}
        
        {lastLoadSchedule.mode === 'no_last_load' && (
          <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
            <p className="text-sm text-gray-600">
              No last load time will be displayed to customers.
            </p>
          </div>
        )}
        
        {lastLoadSchedule.mode === 'same_every_day' && (
          <div className="space-y-4">
            {lastLoadSchedule.sameTimeForWashAndDry ? (
              // Single time picker when Same time for wash and dry is ON
              <div>
                <label className="text-sm mb-2 block">Last Load Time</label>
                <Input
                  type="time"
                  value={lastLoadSchedule.sameHoursOpen || ''}
                  onChange={(e) => {
                    if (e.target.value) {
                      const formattedTime = e.target.value;
                        
                        // Update with the new time, but keep the existing close time
                        handleLastLoadScheduleUpdate(null, 'sameHours', { 
                          open: formattedTime,
                          close: lastLoadSchedule.sameHoursClose || formattedTime
                        });
                      }
                    }}
                    className="w-full"
                  placeholder="17:00"
                  />
              </div>
            ) : (
              // Two time pickers when Same time for wash and dry is OFF
              <div className="space-y-4">
                <div>
                  <label className="text-sm mb-2 block">Last Wash Time</label>
                  <Input
                    type="time"
                    value={lastLoadSchedule.lastWashTime || ''}
                    onChange={(e) => {
                      if (e.target.value) {
                        const formattedTime = e.target.value;
                          
                          // Keep the existing dry time
                        const dryTime = lastLoadSchedule.lastDryTime || '';
                          
                          // Update with the new wash time
                          handleLastLoadScheduleUpdate(null, 'sameLoadTimes', {
                            wash: formattedTime,
                            dry: dryTime
                          });
                        }
                      }}
                      className="w-full"
                    placeholder="17:00"
                    />
                </div>
                
                <div>
                  <label className="text-sm mb-2 block">Last Dry Time</label>
                  <Input
                    type="time"
                    value={lastLoadSchedule.lastDryTime || ''}
                    onChange={(e) => {
                      if (e.target.value) {
                        const formattedTime = e.target.value;
                          
                          // Keep the existing wash time
                        const washTime = lastLoadSchedule.lastWashTime || '';
                          
                          // Update with the new dry time
                          handleLastLoadScheduleUpdate(null, 'sameLoadTimes', {
                            wash: washTime,
                            dry: formattedTime
                          });
                        }
                      }}
                      className="w-full"
                    placeholder="17:00"
                    />
                </div>
              </div>
            )}
            
            <div className="text-sm bg-gray-50 rounded-md p-3">
              {lastLoadSchedule.sameTimeForWashAndDry ? 
                (lastLoadSchedule.sameHoursOpen ? 
                  `Last Load: ${formatTime(lastLoadSchedule.sameHoursOpen)} every day` : 
                    'Please set your last load time') :
                (lastLoadSchedule.lastWashTime && lastLoadSchedule.lastDryTime ? 
                  `Last Wash: ${formatTime(lastLoadSchedule.lastWashTime)}, Last Dry: ${formatTime(lastLoadSchedule.lastDryTime)} every day` : 
                    'Please set your last wash and dry times')
                }
            </div>
          </div>
        )}
        
        {lastLoadSchedule.mode === 'custom_by_day' && (
          <div className="border rounded-md overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" style={{ width: '100px' }}>
                    Day
                  </th>
                  {lastLoadSchedule.sameTimeForWashAndDry ? (
                    <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Last Load Time
                    </th>
                  ) : (
                    <>
                      <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Last Wash Time
                      </th>
                      <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Last Dry Time
                      </th>
                    </>
                  )}
                  <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[80px] text-center">
                    Open
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {lastLoadSchedule.days.map((day, index) => (
                  <tr key={day.day} className={cn(!day.isOpen && "bg-gray-100 text-gray-400")}>
                    <td className={cn(
                      "px-4 py-2 whitespace-nowrap text-sm font-medium", 
                      day.isOpen ? "text-gray-900" : "text-gray-400"
                    )}>
                      {day.day}
                    </td>
                    
                    {lastLoadSchedule.sameTimeForWashAndDry ? (
                      <td className="px-4 py-2 text-sm text-gray-500">
                        <Input
                          type="time"
                          value={day.openTime || ''}
                          onChange={(e) => {
                            if (e.target.value) {
                              const formattedTime = e.target.value;
                                  
                                  // Update with the new time
                                  handleLastLoadScheduleUpdate(index, 'times', {
                                    open: formattedTime,
                                    close: day.closeTime || formattedTime
                                  });
                                }
                              }}
                          disabled={!day.isOpen}
                              className="w-full"
                          placeholder="17:00"
                            />
                      </td>
                    ) : (
                      <>
                        <td className="px-4 py-2 text-sm text-gray-500">
                          <Input
                            type="time"
                            value={day.lastWashTime || ''}
                            onChange={(e) => {
                              if (e.target.value) {
                                const formattedTime = e.target.value;
                                  
                                  // Keep the existing dry time
                                  const dryTime = day.lastDryTime || '';
                                  
                                  // Update with the new wash time
                                  handleLastLoadScheduleUpdate(index, 'lastLoadTimes', {
                                    wash: formattedTime,
                                    dry: dryTime
                                  });
                                }
                              }}
                            disabled={!day.isOpen}
                                className="w-full"
                            placeholder="17:00"
                              />
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-500">
                          <Input
                            type="time"
                            value={day.lastDryTime || ''}
                            onChange={(e) => {
                              if (e.target.value) {
                                const formattedTime = e.target.value;
                                  
                                  // Keep the existing wash time
                                  const washTime = day.lastWashTime || '';
                                  
                                  // Update with the new dry time
                                  handleLastLoadScheduleUpdate(index, 'lastLoadTimes', {
                                    wash: washTime,
                                    dry: formattedTime
                                  });
                                }
                              }}
                            disabled={!day.isOpen}
                                className="w-full"
                            placeholder="17:00"
                              />
                        </td>
                      </>
                    )}
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-center">
                      <CustomSwitch
                        checked={day.isOpen}
                        onCheckedChange={(checked) => handleLastLoadScheduleUpdate(index, 'isOpen', checked)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
  );
};
  
  // Handle security settings updates
  const handleSecurityUpdate = (securityData) => {
    // Update state and metadata properly
    setSelfServiceData(prevData => {
      // Create a deep copy of the current metadata to avoid reference issues
      const currentMetadata = prevData.metadata 
        ? JSON.parse(JSON.stringify(prevData.metadata)) 
        : { services: { "1": {} } };
      
      // Ensure the proper nested structure exists
      if (!currentMetadata.services) currentMetadata.services = {};
      if (!currentMetadata.services["1"]) currentMetadata.services["1"] = {};
      if (!currentMetadata.services["1"].security) currentMetadata.services["1"].security = {};
      
      // Update ONLY the security section of the metadata
      currentMetadata.services["1"].security = securityData;
      
      // Return updated state
      return {
        ...prevData,
        security: securityData,
        metadata: currentMetadata
      };
    });
  };
  
  const renderSecurityContent = () => {
    // Initialize security settings if not present
    const security = selfServiceData.security || {
      autoLock: false,
      lockTime: '22:00',
      details: ''
    };

    return (
          <div className="space-y-6">
            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
              <div className="flex items-center gap-3">
                <Shield className="w-5 h-5 text-gray-400" />
                <span className="text-sm font-medium text-gray-700">Doors Lock When Closed</span>
              </div>
              <CustomSwitch
                checked={security.autoLock}
                onCheckedChange={(checked) => {
                  handleSecurityUpdate({
                    ...security,
                    autoLock: checked
                  });
                }}
              />
            </div>

            {security.autoLock && (
              <div className="space-y-6">
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">Lock Time</label>
                  <Select
                    value={security.lockTime}
                    onValueChange={(value) => {
                      handleSecurityUpdate({
                        ...security,
                        lockTime: value
                      });
                    }}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue>
                        {security.lockTime ? formatTime(security.lockTime) : 'Select time'}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent className="max-h-[300px] overflow-y-auto">
                      {Array.from({ length: 24 }).map((_, hour) => (
                        <SelectItem key={hour} value={`${hour.toString().padStart(2, '0')}:00`}>
                          {formatTime(`${hour.toString().padStart(2, '0')}:00`)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">How Doors Lock</label>
                  <textarea
                    value={security.details || ''}
                    onChange={(e) => {
                      handleSecurityUpdate({
                        ...security,
                        details: e.target.value
                      });
                    }}
                    placeholder="Do you lock automatically or by hand? Any other info..."
                    className="w-full h-24 px-3 py-2 text-sm bg-gray-50 border border-gray-200 rounded-lg focus:ring-1 focus:ring-gray-200 resize-none transition-colors duration-200 placeholder:text-gray-400"
                  />
                </div>
              </div>
            )}
          </div>
    );
  };
  
  const renderProductsContent = () => (
    // Initialize products array if it doesn't exist
    <div className="space-y-6">
      <div className="border rounded-lg">
        <div className="p-4 bg-gray-50 border-b">
          <div className="flex items-center">
            <h3 className="text-md font-medium">Products for Sale</h3>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <span className="ml-2 cursor-help">
                    <HelpCircle className="h-4 w-4 text-gray-400" />
                  </span>
                </TooltipTrigger>
                <TooltipContent side="right" className="max-w-sm">
                  <p className="text-sm">Add products that are available for purchase at your laundromat.</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
        
        <div className="p-4">
          <div className="space-y-4">
            {products.length === 0 ? (
              <div className="text-center py-8 text-gray-500">
                <ShoppingCart className="w-12 h-12 mx-auto mb-2 text-gray-400" />
                <p>No products added yet</p>
                <p className="text-sm">Click the button below to add products that are sold at your location.</p>
              </div>
            ) : (
              <div className="overflow-hidden rounded-md border">
                <div className="bg-white">
                  <div className="grid grid-cols-5 border-b bg-slate-50 p-3 text-sm font-medium text-slate-600">
                    <div>Product Type</div>
                    <div>Brand</div>
                    <div>Cost</div>
                    <div>Purchase Location</div>
                    <div className="text-right">Actions</div>
                  </div>
                  {products.map((product) => (
                    <div key={product.id} className="grid grid-cols-5 border-b p-3 text-sm">
                      <div className="font-medium text-slate-900">{product.type}</div>
                      <div className="text-slate-700">{product.brand}</div>
                      <div className="text-slate-700">${product.cost}</div>
                      <div className="text-slate-700">{product.purchaseLocation}</div>
                      <div className="text-right">
                        <button
                          onClick={() => handleDeleteProduct(product.id)}
                          className="inline-flex h-8 w-8 items-center justify-center rounded-md border border-transparent hover:bg-slate-100 text-red-500"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            
            <Button
              onClick={() => setIsAddProductModalOpen(true)}
              className="mt-4 w-full flex items-center justify-center gap-2"
            >
              <Plus className="h-4 w-4" />
              Add Product
            </Button>
          </div>
        </div>
      </div>
      
      {/* Add Product Modal */}
      <div className={`fixed inset-0 bg-black/50 z-[10002] flex items-start justify-center pt-[10vh] ${isAddProductModalOpen ? 'block' : 'hidden'}`}>
        <div className="bg-white rounded-xl shadow-xl w-full max-w-md mx-auto flex flex-col max-h-[85vh] overflow-hidden">
          {/* Fixed Header */}
          <div className="p-6 border-b border-gray-200 bg-white rounded-t-xl">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-medium text-gray-900">
                Add New Product
              </h2>
              <button 
                onClick={() => {
                  setIsAddProductModalOpen(false);
                  setProductDropdownOpen(false);
                  setLocationDropdownOpen(false);
                  setCsvFile(null);
                  setCsvError('');
                  if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                  }
                }}
                className="text-gray-400 hover:text-gray-500 transition-colors text-xl leading-none"
              >
                &times;
              </button>
            </div>
            <p className="text-sm text-gray-500 mt-1">
              Add products that are available for purchase at your laundromat
            </p>
          </div>
          
          {/* Scrollable Content Area */}
          <div className="overflow-y-auto flex-1 p-6">
            <Tabs defaultValue="single-entry" className="w-full">
              <TabsList className="mb-4 w-full flex">
                <TabsTrigger value="single-entry" className="flex-1">Single Entry</TabsTrigger>
                <TabsTrigger value="bulk-upload" className="flex-1">Bulk Upload</TabsTrigger>
              </TabsList>
              
              <TabsContent value="single-entry" className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="productType" className="text-sm font-medium text-gray-700">Product Type *</Label>
                  
                  <div className="relative" ref={productDropdownRef}>
                    <button
                      type="button"
                      onClick={() => setProductDropdownOpen(!productDropdownOpen)}
                      className="flex w-full items-center justify-between rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-gray-400"
                    >
                      <span>{newProduct.type || 'Select a product type'}</span>
                      <ChevronDown className="ml-2 h-4 w-4 text-gray-500" />
                    </button>
                    
                    {productDropdownOpen && (
                      <div className="fixed min-w-[200px] mt-1 rounded-md border bg-white shadow-lg z-[10001]" 
                           style={{
                             top: productDropdownRef?.current?.getBoundingClientRect().bottom || 0,
                             left: productDropdownRef?.current?.getBoundingClientRect().left || 0,
                             maxHeight: '300px',
                             overflowY: 'auto'
                           }}>
                        <div className="py-1">
                          {productTypes.map((type) => (
                            <div
                              key={type}
                              className="px-3 py-2 text-sm cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setNewProduct({ ...newProduct, type });
                                setProductDropdownOpen(false);
                              }}
                            >
                              {type}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  
                  {newProduct.type === 'Other' && (
                    <div className="mt-2">
                      <Label htmlFor="customType" className="text-sm font-medium text-gray-700">Specify Product Type *</Label>
                      <input
                        id="customType"
                        type="text"
                        value={newProduct.customType}
                        onChange={(e) => setNewProduct({ ...newProduct, customType: e.target.value })}
                        className="mt-1 block w-full rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-gray-400"
                        placeholder="Enter product type"
                      />
                    </div>
                  )}
                </div>
                
                <div>
                  <Label htmlFor="brand" className="text-sm font-medium text-gray-700">Brand (Optional)</Label>
                  <input
                    id="brand"
                    type="text"
                    value={newProduct.brand}
                    onChange={(e) => setNewProduct({ ...newProduct, brand: e.target.value })}
                    className="mt-1 block w-full rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-gray-400"
                    placeholder="Enter brand name"
                  />
                </div>
                
                <div>
                  <Label htmlFor="cost" className="text-sm font-medium text-gray-700">Cost *</Label>
                  <div className="mt-1 relative rounded-md">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      id="cost"
                      type="text"
                      value={newProduct.cost}
                      onChange={(e) => setNewProduct({ ...newProduct, cost: e.target.value })}
                      className="block w-full pl-7 rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-gray-400"
                      placeholder="0.00"
                    />
                  </div>
                </div>
                
                <div className="space-y-2">
                  <Label htmlFor="purchaseLocation" className="text-sm font-medium text-gray-700">Purchase Location *</Label>
                  
                  <div className="relative" ref={locationDropdownRef}>
                    <button
                      type="button"
                      onClick={() => setLocationDropdownOpen(!locationDropdownOpen)}
                      className="flex w-full items-center justify-between rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-gray-400"
                    >
                      <span>{newProduct.purchaseLocation || 'Select a purchase location'}</span>
                      <ChevronDown className="ml-2 h-4 w-4 text-gray-500" />
                    </button>
                    
                    {locationDropdownOpen && (
                      <div className="fixed min-w-[200px] mt-1 rounded-md border bg-white shadow-lg z-[10001]" 
                           style={{
                             top: locationDropdownRef?.current?.getBoundingClientRect().bottom || 0,
                             left: locationDropdownRef?.current?.getBoundingClientRect().left || 0,
                             maxHeight: '300px',
                             overflowY: 'auto'
                           }}>
                        <div className="py-1">
                          {purchaseLocations.map((location) => (
                            <div
                              key={location}
                              className="px-3 py-2 text-sm cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setNewProduct({ ...newProduct, purchaseLocation: location });
                                setLocationDropdownOpen(false);
                              }}
                            >
                              {location}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  
                  {newProduct.purchaseLocation === 'Other' && (
                    <div className="mt-2">
                      <Label htmlFor="customLocation" className="text-sm font-medium text-gray-700">Specify Purchase Location *</Label>
                      <input
                        id="customLocation"
                        type="text"
                        value={newProduct.customLocation}
                        onChange={(e) => setNewProduct({ ...newProduct, customLocation: e.target.value })}
                        className="mt-1 block w-full rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-gray-400"
                        placeholder="Enter purchase location"
                      />
                    </div>
                  )}
                </div>
                
                <Button
                  onClick={handleAddProduct}
                  className="w-full mt-4"
                >
                  Add Product
                </Button>
              </TabsContent>
              
              <TabsContent value="bulk-upload" className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="csvFile" className="text-sm font-medium text-gray-700">Upload CSV File</Label>
                  <div className="mt-1">
                    <div className="flex items-center justify-center w-full">
                      <label
                        htmlFor="csvFile"
                        className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <Upload className="w-8 h-8 mb-2 text-gray-500" />
                          <p className="mb-2 text-sm text-gray-500">
                            <span className="font-semibold">Click to upload</span> or drag and drop
                          </p>
                          <p className="text-xs text-gray-500">CSV file with product data</p>
                        </div>
                        <input
                          id="csvFile"
                          ref={fileInputRef}
                          type="file"
                          accept=".csv"
                          className="hidden"
                          onChange={handleFileChange}
                        />
                      </label>
                    </div>
                  </div>
                  
                  {csvFile && (
                    <div className="mt-2 text-sm text-gray-600">
                      Selected file: {csvFile.name}
                    </div>
                  )}
                  
                  {csvError && (
                    <div className="mt-2 text-sm text-red-600">
                      {csvError}
                    </div>
                  )}
                  
                  <div className="mt-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">CSV Format Requirements:</h4>
                    <p className="text-xs text-gray-600 mb-2">Your CSV file should include the following columns:</p>
                    <ul className="list-disc list-inside text-xs text-gray-600 space-y-1">
                      <li><strong>type</strong>: Product type (required)</li>
                      <li><strong>brand</strong>: Brand name (optional)</li>
                      <li><strong>cost</strong>: Cost in dollars (required)</li>
                      <li><strong>purchaseLocation</strong>: Where products can be purchased (required)</li>
                    </ul>
                  </div>
                </div>
                
                <Button
                  onClick={handleBulkUpload}
                  className="w-full mt-4"
                  disabled={!csvFile}
                >
                  Upload Products
                </Button>
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );

  // Generic data change handler for simple fields
  const handleDataChange = (field, value) => {
    // Use a local variable to track if we've already updated this value
    // to prevent unnecessary re-renders
    const currentValue = selfServiceData[field];
    
    // Only update if the value has actually changed
    if (currentValue === value) {
      return; // No change, don't trigger a state update
    }
    
    setSelfServiceData(prevData => {
      // Create a deep copy of the current metadata to avoid reference issues
      const currentMetadata = prevData.metadata 
        ? JSON.parse(JSON.stringify(prevData.metadata)) 
        : { services: { "1": {} } };
      
      // Ensure the proper nested structure exists
      if (!currentMetadata.services) currentMetadata.services = {};
      if (!currentMetadata.services["1"]) currentMetadata.services["1"] = {};
      
      // Update metadata with the field value
      if (field === 'notes' || field === 'additionalInfo') {
        // Store notes in the other_info section of metadata
        if (!currentMetadata.services["1"].other_info) {
          currentMetadata.services["1"].other_info = {};
        }
        currentMetadata.services["1"].other_info[field] = value;
      }
      
      // Return updated state with the field and metadata updated
      return {
        ...prevData,
        [field]: value,
        metadata: currentMetadata
      };
    });
  };

  // Then update the renderOtherInfoContent function
  const renderOtherInfoContent = () => (
    <div className="space-y-4">
      <div>
        <Label htmlFor="additionalInfo">Special instructions or notes</Label>
        <textarea
          id="additionalInfo"
          value={selfServiceData.additionalInfo || ''}
          onChange={(e) => handleOtherUpdate({ additionalInfo: e.target.value })}
          placeholder="Any additional information about your self-service location..."
          className="w-full mt-1 p-2 min-h-[120px] border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
    </div>
  );
  
  // Check if hours tab requirements are met
  const isHoursTabComplete = () => {
  // Access operatingHours directly from selfServiceData
  const operatingHours = selfServiceData.operatingHours || {};
  
  if (operatingHours.mode === 'always_open' || operatingHours.is24Hours) {
    return true;
  }
  
  if (operatingHours.mode === 'same_every_day') {
    return !!operatingHours.sameHoursOpen && !!operatingHours.sameHoursClose;
  }
  
  if (operatingHours.mode === 'custom_by_day') {
    // Make sure we have days array
    if (!operatingHours.days || !Array.isArray(operatingHours.days)) {
      return false;
    }
    
    return operatingHours.days.every(day => 
      !day.isOpen || (!!day.openTime && !!day.closeTime)
    );
  }
  
  return false;
};
  
  // Check if machines tab requirements are met (at least 1 washer and 1 dryer)
  const isMachinesTabComplete = () => {
    const washers = selfServiceData.washers || [];
    const dryers = selfServiceData.dryers || [];
    
    return washers.length > 0 && dryers.length > 0;
  };
  
  // Handle adding a new product
  const handleAddProduct = () => {
    // Validate product data
    if (!newProduct.type) return;
    if (newProduct.type === 'Other' && !newProduct.customType) return;
    if (!newProduct.cost) return;
    if (!newProduct.purchaseLocation) return;
    if (newProduct.purchaseLocation === 'Other' && !newProduct.customLocation) return;
    
    // Create the product object
    const product = {
      id: Date.now(), // Simple way to create a unique ID
      type: newProduct.type === 'Other' ? newProduct.customType : newProduct.type,
      brand: newProduct.brand || 'N/A',
      cost: newProduct.cost,
      purchaseLocation: newProduct.purchaseLocation === 'Other' 
        ? newProduct.customLocation 
        : newProduct.purchaseLocation
    };
    
    // Add to products array
    const updatedProducts = [...products, product];
    
    // Update products state directly
    setProducts(updatedProducts);
    
    // Update local state with new product data and updated metadata
    setSelfServiceData(prevData => {
      // Ensure metadata structure exists
      const currentMetadata = prevData.metadata || { services: { "1": {} } };
      
      // Create the updated metadata
      const updatedMetadata = {
        ...currentMetadata,
        services: {
          ...currentMetadata.services,
          "1": {
            ...(currentMetadata.services?.["1"] || {}),
            products: updatedProducts
          }
        }
      };
      
      // Create updated data object
      const updatedData = {
        ...prevData,
        products: updatedProducts,
        metadata: updatedMetadata
      };
      
      // Explicitly call updateDatabase with the updated data
      setTimeout(() => {
        updateDatabase(updatedData);
      }, 100);
      
      // Return updated state
      return updatedData;
    });
    
    // Reset form
    setNewProduct({
      type: 'Laundry Detergent/Soap',
      customType: '',
      brand: '',
      cost: '',
      purchaseLocation: 'Vending Machine',
      customLocation: ''
    });
    
    // Close modal
    setIsAddProductModalOpen(false);
  };
  
  // Handle deleting a product
  const handleDeleteProduct = (productId) => {
    // Filter out the product with the given ID
    const updatedProducts = products.filter(product => product.id !== productId);
    
    // Update local state with new product data and updated metadata
    setSelfServiceData(prevData => {
      // Ensure metadata structure exists
      const currentMetadata = prevData.metadata || { services: { "1": {} } };
      
      // Create the updated metadata
      const updatedMetadata = {
        ...currentMetadata,
        services: {
          ...currentMetadata.services,
          "1": {
            ...(currentMetadata.services?.["1"] || {}),
            products: updatedProducts
          }
        }
      };
      
      // Create updated data object
      const updatedData = {
        ...prevData,
        products: updatedProducts,
        metadata: updatedMetadata
      };
      
      // Explicitly call updateDatabase with the updated data
      setTimeout(() => {
        updateDatabase(updatedData);
      }, 100);
      
      // Return updated state
      return updatedData;
    });
  };
  
  // Initialize machines array if it doesn't exist
  const washers = selfServiceData.washers || [];
  const dryers = selfServiceData.dryers || [];
  
  // Combined object for easier rendering
  const machines = {
    washers,
    dryers
  };
  
  // Function to update the form metadata
  const updateFormMetadata = (updatedData) => {
    // Ensure metadata structure exists
    if (!updatedData.metadata) {
      updatedData.metadata = { services: { "1": {} } };
    } else if (!updatedData.metadata.services) {
      updatedData.metadata.services = { "1": {} };
    } else if (!updatedData.metadata.services["1"]) {
      updatedData.metadata.services["1"] = {};
    }
    
    // Update the metadata with the current machines
    updatedData.metadata.services["1"].washers = updatedData.washers || [];
    updatedData.metadata.services["1"].dryers = updatedData.dryers || [];
    
    // Return the updated data
    return updatedData;
  };
  
  // Handle adding a new machine
  const handleAddMachine = () => {
    // Validate machine data
    if (!newMachine.cost) return;
    if (activeMachineType === 'dryers' && !newMachine.minutes) return;
    
    // Ensure capacity is a valid number
    const capacity = parseInt(newMachine.capacity) || 20;
    
    // Create the machine object
    const machine = {
      id: Date.now(), // Simple way to create a unique ID
      capacity: capacity,
      cost: parseFloat(newMachine.cost)
    };
    
    // Update specific machine properties based on type
    if (activeMachineType === 'washers') {
      machine.loadType = newMachine.loadType;
      machine.name = `${newMachine.loadType === 'front' ? 'Front' : 'Top'} Load ${capacity} lbs`;
      machine.price = `$${newMachine.cost}`;
    } else {
      machine.minutes = parseInt(newMachine.minutes);
      machine.name = `Dryer ${capacity} lbs`;
      machine.price = `$${newMachine.cost}/${newMachine.minutes} min`;
    }
    
    // Add the new machine to the appropriate array
    const updatedMachines = [...machines[activeMachineType], machine];
    
    // Update the self-service data with the new machine
    setSelfServiceData(prevData => {
      const updatedData = {
        ...prevData,
        [activeMachineType]: updatedMachines
      };
      
      // Update the metadata and save to the state
      const finalData = updateFormMetadata(updatedData);
      
      // Explicitly call updateDatabase with the updated data
      // This ensures the database is updated with the latest changes
      setTimeout(() => {
        updateDatabase(finalData);
      }, 100);
      
      return finalData;
    });
    
    // Reset the form
    setNewMachine({
      capacity: '20',
      cost: '',
      minutes: '',
      loadType: 'top'
    });
    
    // Close the modal after adding the machine
    setIsAddMachineModalOpen(false);
  };
  
  // Handle deleting a machine
  const handleDeleteMachine = (machineId) => {
    // Filter out the machine with the given ID
    const updatedMachines = machines[activeMachineType].filter(machine => machine.id !== machineId);
    
    // Update the self-service data with the filtered machines
    setSelfServiceData(prevData => {
      // Ensure metadata structure exists
      const currentMetadata = prevData.metadata || { services: { "1": {} } };
      
      // Create the updated metadata
      const updatedMetadata = {
        ...currentMetadata,
        services: {
          ...currentMetadata.services,
          "1": {
            ...(currentMetadata.services?.["1"] || {}),
            [activeMachineType]: updatedMachines
          }
        }
      };
      
      // Create updated data with both the direct state and metadata updates
      const updatedData = {
        ...prevData,
        [activeMachineType]: updatedMachines,
        metadata: updatedMetadata
      };
      
      // Notify parent component of the update
      if (onUpdate) {
        setTimeout(() => onUpdate(updatedData), 0);
      }
      
      // Explicitly call updateDatabase with the updated data
      // This ensures the database is updated with the latest changes
      setTimeout(() => {
        updateDatabase(updatedData);
      }, 100);
      
      return updatedData;
    });
  };
  
  // Function to handle updates to other information
  const handleOtherUpdate = (updates) => {
    setSelfServiceData(prev => {
      // Create a copy of the current other_info object or initialize it
      const otherInfo = prev.otherInfo || {};
      
      // Create the updated data with the new other info
      const updatedData = {
        ...prev,
        ...updates
      };
      
      // Ensure metadata structure exists
      const metadata = updatedData.metadata || { services: { "1": {} } };
      if (!metadata.services) metadata.services = { "1": {} };
      if (!metadata.services["1"]) metadata.services["1"] = {};
      if (!metadata.services["1"].other_info) metadata.services["1"].other_info = {};
      
      // Apply updates to metadata
      Object.keys(updates).forEach(key => {
        metadata.services["1"].other_info[key] = updates[key];
      });
      
      // Prepare the final updated data
      const finalData = {
        ...updatedData,
        metadata: metadata
      };
      
      // Update the database
      updateDatabase(finalData);
      
      // Notify parent component of changes
      if (onUpdate) {
        setTimeout(() => onUpdate(finalData), 0);
      }
      
      return finalData;
    });
  };
  
  return (
    <div className="flex w-full">
      <div className="flex-1 p-2 sm:p-4 space-y-4 overflow-hidden">
        <div className="flex flex-col w-full">
          <div className="border-b">
            <ScrollableTabs
              tabs={[
                {
                  id: 'hours',
                  label: 'Hours',
                  onClick: () => setActiveTab('hours'),
                  isActive: activeTab === 'hours',
                  indicator: isDataLoaded && !requiredFieldsStatus.hours ? (
                    <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
                  ) : null
                },
                {
                  id: 'last-load',
                  label: 'Last Load',
                  onClick: () => setActiveTab('last-load'),
                  isActive: activeTab === 'last-load',
                  indicator: isDataLoaded && !requiredFieldsStatus['last-load'] ? (
                    <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
                  ) : null
                },
                {
                  id: 'staff',
                  label: 'Attendant',
                  onClick: () => setActiveTab('staff'),
                  isActive: activeTab === 'staff',
                  indicator: isDataLoaded && !requiredFieldsStatus.staff ? (
                    <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
                  ) : null
                },
                {
                  id: 'machines',
                  label: 'Machines',
                  onClick: () => setActiveTab('machines'),
                  isActive: activeTab === 'machines',
                  indicator: isDataLoaded && !requiredFieldsStatus.machines ? (
                    <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
                  ) : null
                },
                {
                  id: 'payment',
                  label: 'Payment',
                  onClick: () => setActiveTab('payment'),
                  isActive: activeTab === 'payment',
                  indicator: isDataLoaded && !requiredFieldsStatus.payment ? (
                    <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
                  ) : null
                },
                {
                  id: 'products',
                  label: 'Products',
                  onClick: () => setActiveTab('products'),
                  isActive: activeTab === 'products',
                  indicator: isDataLoaded && !requiredFieldsStatus.products ? (
                    <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
                  ) : null
                },
                {
                  id: 'security',
                  label: 'Security',
                  onClick: () => setActiveTab('security'),
                  isActive: activeTab === 'security',
                  indicator: isDataLoaded && !requiredFieldsStatus.security ? (
                    <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
                  ) : null
                },
                {
                  id: 'other-info',
                  label: 'Other Info',
                  onClick: () => setActiveTab('other-info'),
                  isActive: activeTab === 'other-info'
                }
              ]}
              className="px-2 py-2"
            />
          </div>
          
          <div className="p-2 sm:p-4">
            {activeTab === 'hours' && renderHoursContent()}
            {activeTab === 'last-load' && renderLastLoadContent()}
            {activeTab === 'staff' && renderAttendantContent()}
            {activeTab === 'machines' && renderMachinesContent()}
            {activeTab === 'payment' && renderPaymentContent()}
            {activeTab === 'products' && renderProductsContent()}
            {activeTab === 'security' && renderSecurityContent()}
            {activeTab === 'other-info' && renderOtherInfoContent()}
          </div>

          <div className="p-2 sm:p-4 flex justify-end">
            {/* Next Service button removed */}
          </div>
        </div>
      </div>

      {/* Add Machine Modal */}
      <div className={`fixed inset-0 bg-black/50 z-[10002] flex items-start justify-center pt-[10vh] ${isAddMachineModalOpen ? 'block' : 'hidden'}`}>
        <div className="bg-white rounded-xl shadow-xl w-full max-w-md mx-auto flex flex-col max-h-[85vh] overflow-hidden">
          {/* Fixed Header */}
          <div className="p-6 border-b border-gray-200 bg-white rounded-t-xl">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-medium text-gray-900">
                Add New {activeMachineType === 'washers' ? 'Washer' : 'Dryer'}
              </h2>
              <button 
                onClick={() => setIsAddMachineModalOpen(false)}
                className="text-gray-400 hover:text-gray-500 transition-colors text-xl leading-none"
              >
                &times;
              </button>
            </div>
            <p className="text-sm text-gray-500 mt-1">
              Configure machine details and pricing
            </p>
          </div>
          
          {/* Scrollable Content Area */}
          <div className="overflow-y-auto flex-1 p-6 space-y-4">
            {activeMachineType === 'washers' && (
              <div className="space-y-2">
                <Label className="text-sm font-medium text-gray-700">Load Type</Label>
                <div className="flex space-x-2">
                  <Button
                    type="button"
                    variant={newMachine.loadType === 'front' ? 'default' : 'outline'}
                    onClick={() => setNewMachine({...newMachine, loadType: 'front'})}
                    className="flex-1"
                  >
                    Front Loader
                  </Button>
                  <Button
                    type="button"
                    variant={newMachine.loadType === 'top' ? 'default' : 'outline'}
                    onClick={() => setNewMachine({...newMachine, loadType: 'top'})}
                    className="flex-1"
                  >
                    Top Loader
                  </Button>
                </div>
              </div>
            )}
            
            <div className="space-y-2">
              <Label htmlFor="capacity" className="text-sm font-medium text-gray-700">Capacity (lbs)</Label>
              <Input
                id="capacity"
                type="number"
                min="1"
                value={newMachine.capacity}
                onChange={(e) => setNewMachine({...newMachine, capacity: parseInt(e.target.value) || 20})}
                placeholder="20"
                className="bg-gray-50 border border-gray-200"
              />
            </div>
            
            <div className="space-y-2">
              <Label htmlFor="cost" className="text-sm font-medium text-gray-700">Cost per {activeMachineType === 'washers' ? 'cycle' : 'use'} ($)</Label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">$</span>
                <Input
                  id="cost"
                  type="number"
                  step="0.01"
                  min="0"
                  value={newMachine.cost}
                  onChange={(e) => setNewMachine({...newMachine, cost: e.target.value})}
                  placeholder="0.00"
                  className="pl-7 bg-gray-50 border border-gray-200"
                />
              </div>
            </div>
            
            {activeMachineType === 'dryers' && (
              <div className="space-y-2">
                <Label htmlFor="minutes" className="text-sm font-medium text-gray-700">Minutes per cycle</Label>
                <Input
                  id="minutes"
                  type="number"
                  min="1"
                  value={newMachine.minutes}
                  onChange={(e) => setNewMachine({...newMachine, minutes: e.target.value})}
                  placeholder="30"
                  className="bg-gray-50 border border-gray-200"
                />
              </div>
            )}
          </div>
          
          {/* Fixed Footer */}
          <div className="p-6 border-t border-gray-200 bg-white rounded-b-xl">
            <div className="flex justify-end">
              <Button 
                type="button" 
                variant="outline" 
                onClick={() => setIsAddMachineModalOpen(false)}
                className="mr-2"
              >
                Cancel
              </Button>
              <Button 
                type="button" 
                onClick={handleAddMachine}
              >
                Add {activeMachineType === 'washers' ? 'Washer' : 'Dryer'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleSelfServicePanel;