// src/App.js
import React, { useEffect, Suspense, useState  } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useBusinessType, BusinessProvider } from './context/BusinessContext';
import ProtectedRoute from './components/ProtectedRoute';
import { isRouteAccessible } from './config/routeAccess';
import { MAPS_CONFIG } from './config/maps';
import { loadGoogleMapsScript } from './utils/mapUtils';
import { Toaster } from "./components/ui/sonner-toast";
import { getSupabaseClient } from './supabaseClient';
import { injectScrollbarStyles } from './components/ui/custom-scrollbar';
import "./styles/styles.css";
import SideNav from "./components/SideNav";
import OnboardingRoute, { getOnboardingRedirectPath } from './components/signup/OnboardingRoutes.js';
import BusinessDetailsStep from './components/signup/business-details/BusinessDetailsStep';
import 'mapbox-gl/dist/mapbox-gl.css';
import ServiceDetailsPage from './components/signup/business-details/ServiceDetailsPage';
import Integrations from './pages/Integrations';
import CurbsideIntegration from './integrations/curbsideIntegration';

// Page imports
const Login = React.lazy(() => import("./pages/Login"));
const Signup = React.lazy(() => import("./pages/Signup"));
const Dashboard = React.lazy(() => import("./pages/LaundryDashboard"));
const LeadsManagement = React.lazy(() => import("./pages/LeadsManagement"));
const LeadDetails = React.lazy(() => import("./components/contacts/LeadDetails"));
const BookingsPage = React.lazy(() => import("./pages/BookingsPage"));
const ToDoPage = React.lazy(() => import("./pages/ToDoPage"));
const QnAManagement = React.lazy(() => import("./pages/QnAManagement"));
const SOPManagement = React.lazy(() => import("./pages/SOPManagement"));
const PredefinedPathwaysManager = React.lazy(() => import("./pages/PredefinedPathwaysManager"));
const LivePathwaysManager = React.lazy(() => import("./pages/LivePathwaysManager"));
const Reports = React.lazy(() => import("./pages/Reports"));
const Settings = React.lazy(() => import("./pages/Settings"));
const ServicesPricingPage = React.lazy(() => import("./pages/ServicesPricingPage"));
const CallSettings = React.lazy(() => import("./pages/CallSettings"));
const LocationsManagement = React.lazy(() => import("./pages/LocationsManagement"));
const EmailPreferencesManager = React.lazy(() => import("./pages/EmailPreferencesManager"));
const AccountSetupFlow = React.lazy(() => import("./pages/onboarding/AccountSetupFlow"));
const OnboardingLayout = React.lazy(() => import("./pages/onboarding/OnboardingLayout"));
//const Integrations = React.lazy(() => import("./pages/Integrations"));
const CallsListPage = React.lazy(() => import("./pages/CallsListPage"));
const CallDetailsPage = React.lazy(() => import("./components/calls/CallDetailsPage"));
const LaundrySignup = React.lazy(() => import("./pages/LaundrySignup"));
const UnifiedLocationsQnA = React.lazy(() => import("./pages/UnifiedLocationsQnA"));
const IntroPage = React.lazy(() => import("./pages/IntroPage"));
const NotificationsPage = React.lazy(() => import("./pages/NotificationsPage"));
const PhoneIntegrationPage = React.lazy(() => import("./pages/PhoneIntegration"));
const OnboardingKnowledge = React.lazy(() => import("./pages/OnboardingKnowledge"));
const KnowledgeEditor = React.lazy(() => import("./pages/KnowledgeEditor")); // admin knowledge editor
const IVRLocationOrder = React.lazy(() => import("./pages/IVRLocationOrder"));
// Comment out the Orders import
// const Orders = React.lazy(() => import("./pages/Orders")); // Import Orders page
const Knowledge = React.lazy(() => import("./pages/Knowledge")); // Import Knowledge page
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));

const PageLoader = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
  </div>
);

// Coming Soon Component
const ComingSoon = ({ title = "Coming Soon", description = "This feature is currently under development." }) => (
  <div className="min-h-screen bg-gray-50 p-8">
    <div className="max-w-2xl mx-auto mt-20">
      <div className="bg-white rounded-lg p-8 shadow-lg border border-gray-100">
        <h1 className="text-3xl font-bold text-center text-gray-900 mb-4">{title}</h1>
        <p className="text-center text-gray-600">{description}</p>
      </div>
    </div>
  </div>
);

// In App.js AuthProvider
const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { businessType } = useBusinessType();
  const supabase = getSupabaseClient(businessType);
  const location = useLocation();

  useEffect(() => {
    const publicRoutes = ['/login', '/signup', '/join', '/reset-password'];
    const onboardingRoutes = ['/onboarding/business'];

    const checkSession = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();

        if (!session && !publicRoutes.includes(location.pathname)) {
          navigate('/login');
          return;
        }

        if (session) {
          const { data: onboarding, error: onboardingError } = await supabase
            .from('onboarding_data')
            .select('current_step')
            .eq('account_id', session.user.id)
            .single();

          if (onboardingError && onboardingError.code === '42P01') {
            if (!onboardingRoutes.includes(location.pathname)) {
              navigate('/onboarding/intro');
            }
            return;
          }

          if (onboarding?.current_step === 'not_started') {
            // Let Login.js handle the first-time login case
            return;
          }

          if (!onboarding?.current_step || onboarding.current_step !== 'complete') {
            if (!onboardingRoutes.includes(location.pathname)) {
              const correctPath = getOnboardingRedirectPath(onboarding?.current_step);
              navigate(correctPath);
            }
            return;
          }

          if (onboarding.current_step === 'complete' && 
              !publicRoutes.includes(location.pathname) && 
              !isRouteAccessible(businessType, location.pathname)) {
            navigate('/home');
          }
        }
      } catch (error) {
        console.error('Session check error:', error);
      }
    };

    checkSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_OUT') {
        navigate('/login');
      } else if (event === 'SIGNED_IN' && session) {
        // Let checkSession handle the routing
        checkSession();
      }
    });

    return () => subscription?.unsubscribe();
  }, [navigate, supabase, location.pathname, businessType]);

  return children;
};

const App = () => {
  const location = useLocation();
  const [mapsLoaded, setMapsLoaded] = useState(false);
  const [mapsLoading, setMapsLoading] = useState(true);

  // Load Google Maps on app initialization
  useEffect(() => {
    const initMaps = async () => {
      try {
        await loadGoogleMapsScript();
        setMapsLoaded(true);
      } catch (error) {
        console.error("Failed to load Google Maps:", error);
      } finally {
        setMapsLoading(false);
      }
    };

    initMaps();
  }, []);

  // Inject custom scrollbar styles when app loads
  useEffect(() => {
    injectScrollbarStyles();
  }, []);

  const noNavRoutes = [
    '/login', 
    '/signup', 
    '/setup', 
    '/onboarding', 
    '/onboarding/phone', 
    '/onboarding/pathway', 
    '/onboarding/details',
    '/join',
    '/onboarding/intro',
    '/onboarding/business',
    '/onboarding/business-knowledge',
    '/onboarding/business-knowledge/service-details',
    '/onboarding/workflows',
    '/onboarding/notifications',
    '/onboarding/integration',
    '/onboarding/payment',
    '/reset-password'
  ];

  const isDashboard = location.pathname === '/home';
  const shouldShowNav = !noNavRoutes.includes(location.pathname);

  // Show loading state while Maps is loading
  if (mapsLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <BusinessProvider>
      <AuthProvider>
        <div className="flex">
          {shouldShowNav && <SideNav forceExpanded={!isDashboard} />}
          <main className={`flex-1 min-h-screen overflow-x-hidden ${shouldShowNav && !location.pathname.includes('/home') ? 'sm:ml-16 md:ml-64' : ''}`}>
            <Suspense fallback={
              <div className="min-h-screen flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            }>
              <Routes>
                {/* Public Routes */}
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/join" element={<LaundrySignup />} />

                {/* Onboarding Routes */}
                <Route path="/onboarding" element={<OnboardingRoute />}>
                  <Route index element={<Navigate to="/onboarding/intro" />} />
                  <Route path="intro" element={
                    <div className="min-h-screen bg-gray-50">
                      <IntroPage isOnboarding={true} />
                    </div>
                  } />
                  <Route path="business-knowledge" element={
                    <div className="min-h-screen bg-gray-50">
                      <OnboardingKnowledge isOnboarding={true} />
                    </div>
                  } />
                  <Route path="business-knowledge/service-details" element={
                    <div className="min-h-screen bg-gray-50">
                      <ServiceDetailsPage />
                    </div>
                  } />
                  <Route path="business" element={
                    <div className="min-h-screen bg-gray-50">
                      <UnifiedLocationsQnA isOnboarding={true} />
                    </div>
                  } />
                  <Route path="workflows" element={
                    <div className="min-h-screen bg-gray-50">
                      <PredefinedPathwaysManager isOnboarding={true} />
                    </div>
                  } />
                  <Route path="notifications" element={
                    <div className="min-h-screen bg-gray-50">
                      <NotificationsPage />
                    </div>
                  } />
                  <Route path="integration" element={
                    <div className="min-h-screen bg-gray-50">
                      <Integrations />
                    </div>
                  } />
                </Route>

                {/* Protected Routes */}
                <Route path="/home" element={
                  <ProtectedRoute path="/home">
                    <Dashboard />
                  </ProtectedRoute>
                } />

                <Route path="/activity/contacts" element={
                  <ProtectedRoute path="/contacts">
                    <LeadsManagement />
                  </ProtectedRoute>
                } />

                <Route path="/activity/contacts/:id" element={
                  <ProtectedRoute path="/contacts/:id">
                    <LeadDetails />
                  </ProtectedRoute>
                } />

                <Route path="/activity/bookings" element={
                  <ProtectedRoute path="/leads/bookings">
                    <BookingsPage />
                  </ProtectedRoute>
                } />

                <Route path="/activity/tasks" element={
                  <ProtectedRoute path="/tasks">
                    <ToDoPage />
                  </ProtectedRoute>
                } />

                <Route path="/business/knowledge" element={
                  <ProtectedRoute path="/business/knowledge">
                    <Knowledge />
                  </ProtectedRoute>
                } />

                <Route path="/business/onboarding-knowledge" element={
                  <ProtectedRoute path="/business/onboarding-knowledge">
                    <OnboardingKnowledge />
                  </ProtectedRoute>
                } />

                {/* Admin Knowledge Editor - Hidden route */}
                <Route path="/admin/knowledge" element={
                  <ProtectedRoute path="/admin/knowledge">
                    <KnowledgeEditor />
                  </ProtectedRoute>
                } />

                <Route path="/business/triggers" element={
                  <ProtectedRoute path="/business/triggers">
                    <SOPManagement />
                  </ProtectedRoute>
                } />

                <Route path="/business/pathways" element={
                  <ProtectedRoute path="/business/pathways">
                    <PredefinedPathwaysManager />
                  </ProtectedRoute>
                } />

                <Route path="/call-settings" element={
                  <ProtectedRoute path="/call-settings">
                    <LivePathwaysManager />
                  </ProtectedRoute>
                } />

                <Route path="/business/faqs" element={
                  <ProtectedRoute path="/business/faqs">
                    <QnAManagement />
                  </ProtectedRoute>
                } />

                <Route path="/business/services" element={
                  <ProtectedRoute path="/business/services">
                    <ServicesPricingPage />
                  </ProtectedRoute>
                } />

                {/* Comment out the Orders route */}
                {/* <Route path="/business/orders" element={
                  <ProtectedRoute path="/business/orders">
                    <Orders />
                  </ProtectedRoute>
                } /> */}

                <Route path="/business/locations" element={
                  <ProtectedRoute path="/business/locations">
                    <LocationsManagement />
                  </ProtectedRoute>
                } />

                <Route path="/caller/voice" element={
                  <ProtectedRoute path="/caller/voice">
                    <CallSettings />
                  </ProtectedRoute>
                } />

                <Route path="/caller/notifications" element={
                  <ProtectedRoute path="/caller/notifications">
                    <EmailPreferencesManager />
                  </ProtectedRoute>
                } />

                <Route path="/caller/call-location-order" element={
                  <ProtectedRoute path="/caller/call-location-order">
                    <IVRLocationOrder />
                  </ProtectedRoute>
                } />

                <Route path="/reports/insights" element={
                  <ProtectedRoute path="/reports/insights">
                    <Reports />
                  </ProtectedRoute>
                } />

                <Route path="/account/general" element={
                  <ProtectedRoute path="/account/general">
                    <Settings />
                  </ProtectedRoute>
                } />

                <Route path="/account/payment" element={
                  <ProtectedRoute path="/account/payment">
                    <ComingSoon 
                      title="Payment Settings" 
                      description="Manage your billing and subscription."
                    />
                  </ProtectedRoute>
                } />

                <Route path="/integrations" element={
                  <ProtectedRoute path="/integrations">
                    <Integrations />
                  </ProtectedRoute>
                }>
                  <Route index element={<Integrations />} />
                  <Route path="curbside" element={<CurbsideIntegration />} />
                </Route>

                <Route path="/activity/calls" element={
                  <ProtectedRoute path="/calls">
                    <CallsListPage />
                  </ProtectedRoute>
                } />

                <Route 
                  path="/activity/calls/:id" 
                  element={
                    <ProtectedRoute>
                      <CallDetailsPage />
                    </ProtectedRoute>
                  } 
                />

                {/* Default Route */}
                <Route path="/" element={<Navigate to="/home" replace />} />

                {/* Catch-All Route for Undefined Paths */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Suspense>
          </main>
          <Toaster />
        </div>
      </AuthProvider>
    </BusinessProvider>
  );
};

export default App;