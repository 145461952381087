// src/components/signup/OnboardingRoutes.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { getSupabaseClient } from '../../supabaseClient';

export function getOnboardingRedirectPath(current_step) {
  switch (current_step) {
    case 'not_started':
      return '/onboarding/intro';
    case 'intro':
      return '/onboarding/intro';
    case 'business_locations_details':
      return '/onboarding/business-knowledge';
    case 'business_knowledge.service_details':
      return '/onboarding/business-knowledge/service-details';
    case 'business_details':
      return '/onboarding/business';
    case 'business_workflows':
      return '/onboarding/workflows';
    case 'notifications':  
      return '/onboarding/notifications';
    case 'integration': 
        return '/onboarding/integration';
    case 'complete':
      return '/home';
    default:
      return '/onboarding/intro';
  }
}

const OnboardingRoute = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const supabase = getSupabaseClient('laundromat');

  useEffect(() => {
    let isMounted = true;

    const checkOnboardingStatus = async () => {
      try {
        console.log('OnboardingRoutes: Checking status for path:', location.pathname);

        const { data: { session } } = await supabase.auth.getSession();
        if (!session) {
          navigate('/login');
          return;
        }

        // First, check if the onboarding record exists
        const { data: onboarding, error } = await supabase
          .from('onboarding_data')
          .select('current_step')
          .eq('account_id', session.user.id)
          .limit(1)
          .maybeSingle();

        if (!isMounted) return;

        if (error && error.code !== 'PGRST116') {
          console.error('OnboardingRoutes error:', error);
          setLoading(false);
          return;
        }

        // If no onboarding data exists, we're in a fresh state
        if (!onboarding) {
          // Since the records are created during signup with 'not_started',
          // this code path is unlikely but we leave it as a fallback
          const { error: insertError } = await supabase
            .from('onboarding_data')
            .insert([{ 
              account_id: session.user.id, 
              current_step: 'not_started' 
            }]);

          if (insertError) {
            console.error('Error creating onboarding record:', insertError);
          }

          navigate('/onboarding/intro');
          setLoading(false);
          return;
        }

        console.log('OnboardingRoutes: Current step:', onboarding?.current_step);

        // Handle user with not_started status
        if (onboarding?.current_step === 'not_started' && !location.pathname.includes('/intro')) {
          navigate('/onboarding/intro');
          setLoading(false);
          return;
        }

        const correctPath = getOnboardingRedirectPath(onboarding?.current_step);
        console.log('OnboardingRoutes: Correct path:', correctPath);

        // Don't redirect if already on correct path
        if (location.pathname === correctPath) {
          setLoading(false);
          return;
        }

        // Special handling for service-details to prevent redirect loops
        const isServiceDetailsStep = onboarding?.current_step === 'business_knowledge.service_details';
        const isAtServiceDetailsPath = location.pathname === '/onboarding/business-knowledge/service-details';
        const isAtBusinessKnowledgePath = location.pathname === '/onboarding/business-knowledge';

        // If at a valid path for the current step, don't redirect
        if (
          (isServiceDetailsStep && (isAtServiceDetailsPath || isAtBusinessKnowledgePath)) ||
          location.pathname.includes(correctPath)
        ) {
          setLoading(false);
          return;
        }

        // Otherwise redirect to the correct path
        navigate(correctPath);
      } catch (error) {
        console.error('OnboardingRoutes error:', error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    checkOnboardingStatus();

    return () => {
      isMounted = false;
    };
  }, [location.pathname, navigate]);

  if (loading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  return <Outlet />;
};

export default OnboardingRoute;