import React, { useState, useEffect, useCallback } from 'react';
import { AlertTriangle } from 'lucide-react';
import { getSupabaseClient } from '../../supabaseClient';

const CommercialLaundryPanel = ({ 
  locationData, 
  serviceData = {},
  onUpdate 
}) => {
  const [commercialData, setCommercialData] = useState(serviceData || {
    enabled: true,
    notes: ''
  });

  // Update parent when our state changes
  useEffect(() => {
    if (typeof onUpdate === 'function') {
      onUpdate({
        ...commercialData,
        requirementsToAdvance: true // Always allow advancement since this is informational
      });
    }
  }, [commercialData, onUpdate]);

  // Load data from database when component mounts
  useEffect(() => {
    const loadLocationSettings = async () => {
      if (locationData?.id) {
        try {
          const businessType = locationData.business_type || 'laundromat';
          const supabase = getSupabaseClient(businessType);
          
          const { data, error } = await supabase
            .from('vapi_location_settings')
            .select('metadata')
            .eq('id', locationData.id)
            .maybeSingle();
            
          if (error) {
            return;
          }
          
          if (data?.metadata?.services?.["4"]?.notes) {
            setCommercialData(prev => ({
              ...prev,
              notes: data.metadata.services["4"].notes
            }));
          }
        } catch (err) {
          // Error handling removed
        }
      }
    };
    
    loadLocationSettings();
  }, [locationData]);

  // Function to update database when data changes
  const updateDatabase = async (notes) => {
    if (!locationData?.id) {
      return;
    }
    
    try {
      const businessType = locationData.business_type || 'laundromat';
      const supabase = getSupabaseClient(businessType);
      
      // First check if the record exists
      const { data: existingSettings, error: fetchError } = await supabase
        .from('vapi_location_settings')
        .select('metadata')
        .eq('id', locationData.id)
        .maybeSingle();
      
      if (fetchError) {
        return;
      }
      
      let result;
      
      // If record exists, update it
      if (existingSettings) {
        // Merge with existing metadata to preserve other services
        const existingMetadata = existingSettings.metadata || {};
        if (!existingMetadata.services) existingMetadata.services = {};
        
        // Prepare merged metadata preserving other services
        const mergedMetadata = {
          ...existingMetadata,
          services: {
            ...existingMetadata.services,
            "4": {
              ...(existingMetadata.services["4"] || {}),
              notes: notes
            }
          }
        };
        
        result = await supabase
          .from('vapi_location_settings')
          .update({ 
            metadata: mergedMetadata 
          })
          .eq('id', locationData.id);
      } else {
        // If record doesn't exist, create it
        const newMetadata = {
          services: {
            "4": {
              notes: notes
            }
          }
        };
        
        result = await supabase
          .from('vapi_location_settings')
          .insert({ 
            id: locationData.id,
            metadata: newMetadata
          });
      }
      
      if (result?.error) {
        // Error handling removed
      } else {
        // Success logging removed
      }
    } catch (err) {
      // Error handling removed
    }
  };
  
  // Debounce function to prevent too many database calls
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };
  
  // Create a debounced version of updateDatabase
  const debouncedUpdateDatabase = useCallback(
    debounce((notes) => {
      if (locationData?.id) {
        updateDatabase(notes).catch(err => {
          // Error handling removed
        });
      }
    }, 1000), 
    [locationData]
  );

  const handleNotesChange = (e) => {
    const newNotes = e.target.value;
    setCommercialData(prev => ({
      ...prev,
      notes: newNotes
    }));
    
    // Update database with debounce
    debouncedUpdateDatabase(newNotes);
  };

  return (
    <div className="space-y-6 max-w-3xl mx-auto">
      {/* Disclaimer Banner */}
      <div className="p-4 bg-amber-50 border border-amber-200 rounded-lg">
        <div className="flex items-start">
          <AlertTriangle className="w-5 h-5 text-amber-500 mr-3 flex-shrink-0 mt-0.5" />
          <div>
            <h3 className="text-sm font-medium text-amber-800">Commercial Laundry Services Notice</h3>
            <p className="mt-2 text-sm text-amber-700">
              Commercial laundry services require specialized attention. We'll connect you with a dedicated commercial specialist 
              who can provide personalized support for your business needs.
            </p>
            <p className="mt-2 text-sm text-amber-700">
              Please provide details about your commercial laundry requirements below. This helps us understand your business 
              better and ensures you receive the most appropriate assistance.
            </p>
          </div>
        </div>
      </div>

      {/* Notes Text Area */}
      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Commercial Laundry Information</h3>
        
        <div className="space-y-3">
          <label htmlFor="commercial-notes" className="block text-sm font-medium text-gray-700">
            Enter details about your commercial laundry services
          </label>
          <textarea
            id="commercial-notes"
            className="w-full min-h-[200px] p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Please describe your commercial laundry services, target customers, pricing structure, volume expectations, etc."
            value={commercialData.notes || ''}
            onChange={handleNotesChange}
          />
          <p className="text-sm text-gray-500">
            This information will help us tailor our onboarding and service offerings to better meet your commercial needs.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CommercialLaundryPanel; 