import React, { useState } from 'react';
import { 
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
} from '../ui/dialog';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Switch } from '../ui/switch';
import { BlackSwitch } from './SimpleWDFDropOff';
import { X, Plus, Upload } from 'lucide-react';
import { cn } from '../../lib/utils';

// Custom DialogOverlay with higher z-index to appear above OnboardingSideNav
const CustomDialogOverlay = React.forwardRef(({ className, ...props }, ref) => (
  <DialogOverlay
    ref={ref}
    className={cn(
      "z-[10000] bg-black/50 backdrop-blur-sm",
      className
    )}
    {...props}
  />
));
CustomDialogOverlay.displayName = "CustomDialogOverlay";

// Custom DialogContent with higher z-index to appear above OnboardingSideNav
const CustomDialogContent = React.forwardRef(
  ({ className, children, ...props }, ref) => (
    <DialogPortal>
      <CustomDialogOverlay />
      <DialogContent
        ref={ref}
        className={cn(
          "z-[10001] bg-white",
          className
        )}
        {...props}
      >
        {children}
      </DialogContent>
    </DialogPortal>
  )
);
CustomDialogContent.displayName = "CustomDialogContent";

/**
 * ExtrasModal component to handle Specialty Items, Addons, and Pricing items
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.open - Whether the modal is open
 * @param {Function} props.onClose - Function to call when modal is closed
 * @param {string} props.type - Type of extras ('specialty', 'addon', or 'pricing')
 * @param {Array} props.items - Array of current items
 * @param {Function} props.onSave - Function to call when changes are saved
 */
const ExtrasModal = ({ open, onClose, type, items = [], onSave }) => {
  const [localItems, setLocalItems] = useState(items);
  const [csvFile, setCsvFile] = useState(null);
  const [csvError, setCsvError] = useState('');

  const isSpecialty = type === 'specialty';
  const isAddon = type === 'addon';
  const isPricing = type === 'pricing';
  
  let title = isSpecialty ? 'Specialty Items' : (isAddon ? 'Add-ons' : 'Price Items');
  
  const handleAddItem = () => {
    if (isSpecialty) {
      setLocalItems([
        ...localItems,
        { name: '', price: '', chargeByWeight: false }
      ]);
    } else if (isAddon) {
      setLocalItems([
        ...localItems,
        { name: '', price: '', unit: 'item' }
      ]);
    } else if (isPricing) {
      setLocalItems([
        ...localItems,
        { name: '', price: '' }
      ]);
    }
  };

  const handleRemoveItem = (index) => {
    const newItems = [...localItems];
    newItems.splice(index, 1);
    setLocalItems(newItems);
  };

  const handleItemChange = (index, field, value) => {
    const newItems = [...localItems];
    newItems[index] = { ...newItems[index], [field]: value };
    setLocalItems(newItems);
  };

  const handleCsvUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setCsvFile(file);
    
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const csvData = event.target.result;
        const lines = csvData.split('\n');
        
        // Skip header row if present
        const startRow = lines[0].toLowerCase().includes('name') ? 1 : 0;
        
        const newItems = [];
        
        for (let i = startRow; i < lines.length; i++) {
          const line = lines[i].trim();
          if (!line) continue;
          
          const values = line.split(',');
          
          if (isSpecialty) {
            if (values.length < 2) {
              setCsvError('CSV must have at least name and price columns');
              return;
            }
            
            newItems.push({
              name: values[0].trim(),
              price: values[1].trim(),
              chargeByWeight: values[2]?.toLowerCase().trim() === 'true' || false
            });
          } else if (isAddon) {
            if (values.length < 3) {
              setCsvError('CSV must have name, price, and unit columns');
              return;
            }
            
            newItems.push({
              name: values[0].trim(),
              price: values[1].trim(),
              unit: values[2].trim() || 'item'
            });
          } else if (isPricing) {
            if (values.length < 2) {
              setCsvError('CSV must have name and price columns');
              return;
            }
            
            newItems.push({
              name: values[0].trim(),
              price: values[1].trim()
            });
          }
        }
        
        setLocalItems(newItems);
        setCsvError('');
      } catch (err) {
        setCsvError('Error parsing CSV: ' + err.message);
      }
    };
    
    reader.onerror = () => {
      setCsvError('Error reading file');
    };
    
    reader.readAsText(file);
  };

  const handleSave = () => {
    // Validate items
    const invalidItems = localItems.filter(item => {
      if (isSpecialty) {
        return !item.name.trim() || !item.price.trim();
      } else if (isAddon) {
        return !item.name.trim() || !item.price.trim() || !item.unit.trim();
      } else if (isPricing) {
        return !item.name.trim() || !item.price.trim();
      }
      return true;
    });
    
    if (invalidItems.length > 0) {
      alert('Please fill in all fields for each item');
      return;
    }
    
    onSave(localItems);
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <CustomDialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold text-gray-800">Manage {title}</DialogTitle>
        </DialogHeader>

        <div className="py-4">
          <Card className="mb-4 border-gray-200 shadow-sm">
            <CardHeader className="pb-2 bg-gray-50 rounded-t-lg">
              <CardTitle className="text-base font-medium text-gray-700">Upload CSV</CardTitle>
              <CardDescription>
                {isSpecialty 
                  ? 'CSV should have columns: name, price, chargeByWeight(optional)'
                  : isAddon
                    ? 'CSV should have columns: name, price, unit'
                    : 'CSV should have columns: name, price'}
              </CardDescription>
            </CardHeader>
            <CardContent className="p-4">
              <div className="flex items-center gap-2">
                <Input
                  type="file"
                  accept=".csv"
                  onChange={handleCsvUpload}
                  className="flex-1"
                />
                <Button 
                  variant="outline" 
                  size="sm"
                  className="flex gap-1 items-center hover:bg-gray-50" 
                  onClick={() => document.getElementById('csv-upload').click()}
                >
                  <Upload className="h-4 w-4" />
                  Upload
                </Button>
              </div>
              {csvError && (
                <p className="text-red-500 text-sm mt-2">{csvError}</p>
              )}
              <input 
                id="csv-upload" 
                type="file" 
                accept=".csv" 
                className="hidden" 
                onChange={handleCsvUpload}
              />
            </CardContent>
          </Card>

          <Card className="border-gray-200 shadow-sm">
            <CardHeader className="pb-2 bg-gray-50 rounded-t-lg">
              <CardTitle className="flex justify-between items-center text-base font-medium text-gray-700">
                <span>{title} List</span>
                <Button 
                  variant="outline" 
                  size="sm" 
                  className="flex gap-1 items-center bg-white hover:bg-gray-50 border-gray-300"
                  onClick={handleAddItem}
                >
                  <Plus className="h-4 w-4" />
                  Add Item
                </Button>
              </CardTitle>
            </CardHeader>
            <CardContent className="p-4">
              <div className="space-y-3 max-h-[300px] overflow-y-auto pr-2 custom-scrollbar">
                {localItems.length === 0 ? (
                  <div className="text-center text-gray-500 py-4">
                    No items added yet. Click "Add Item" to get started.
                  </div>
                ) : (
                  localItems.map((item, index) => (
                    <div 
                      key={index} 
                      className="flex items-center gap-2 p-3 rounded-md border border-gray-200 hover:bg-gray-50 transition-colors"
                    >
                      <div className={`grid grid-cols-1 sm:grid-cols-${isAddon ? '3' : '2'} gap-2 flex-1`}>
                        <div>
                          <Label htmlFor={`name-${index}`} className="mb-1 block text-xs text-gray-600">
                            Name
                          </Label>
                          <Input
                            id={`name-${index}`}
                            value={item.name}
                            onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                            placeholder="Item name"
                            className="border-gray-300 focus:border-gray-500"
                          />
                        </div>
                        <div>
                          <Label htmlFor={`price-${index}`} className="mb-1 block text-xs text-gray-600">
                            Price
                          </Label>
                          <Input
                            id={`price-${index}`}
                            value={item.price}
                            onChange={(e) => handleItemChange(index, 'price', e.target.value)}
                            placeholder="0.00"
                            type="text"
                            inputMode="decimal"
                            className="border-gray-300 focus:border-gray-500"
                          />
                        </div>
                        {isSpecialty ? (
                          <div className="flex items-center justify-start sm:justify-center h-full pt-5">
                            <div className="flex items-center gap-2">
                              <BlackSwitch
                                checked={item.chargeByWeight}
                                onCheckedChange={(checked) => 
                                  handleItemChange(index, 'chargeByWeight', checked)
                                }
                              />
                              <Label className="text-sm text-gray-700">+Weight</Label>
                            </div>
                          </div>
                        ) : isAddon ? (
                          <div>
                            <Label htmlFor={`unit-${index}`} className="mb-1 block text-xs text-gray-600">
                              Per Unit
                            </Label>
                            <Input
                              id={`unit-${index}`}
                              value={item.unit}
                              onChange={(e) => handleItemChange(index, 'unit', e.target.value)}
                              placeholder="item/load/etc."
                              className="border-gray-300 focus:border-gray-500"
                            />
                          </div>
                        ) : null}
                      </div>
                      <Button
                        variant="ghost"
                        size="sm"
                        className="text-gray-500 hover:text-red-500"
                        onClick={() => handleRemoveItem(index)}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                  ))
                )}
              </div>
            </CardContent>
          </Card>
        </div>

        <DialogFooter className="mt-2 space-x-2">
          <Button variant="outline" onClick={onClose} className="border-gray-300 hover:bg-gray-50 text-gray-700">
            Cancel
          </Button>
          <Button onClick={handleSave} className="bg-gray-900 hover:bg-gray-800 text-white">
            Save Changes
          </Button>
        </DialogFooter>
      </CustomDialogContent>
    </Dialog>
  );
};

export default ExtrasModal; 