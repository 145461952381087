import React, { useState, useEffect, useCallback, useRef, createContext, useContext, memo, lazy, Suspense } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowLeft, ArrowRight, ChevronDown, ChevronRight, Copy, Check, Info } from 'lucide-react';
import { getSupabaseClient } from '../../supabaseClient';
import { useBusinessType } from '../../context/BusinessContext';
import SimpleSelfServicePanel from './SimpleSelfServicePanel';
import WashDryFoldPanel from './WashDryFoldPanel';
import DryCleaningPanel from './DryCleaningPanel';
import CommercialLaundryPanel from './CommercialLaundryPanel';
import CommercialDryCleaningPanel from './CommercialDryCleaningPanel';
import CustomServicePanel from './CustomServicePanel';
import { cn } from "../../utils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../components/ui/tooltip"

// Memoized components to prevent unnecessary re-renders
const Accordion = memo(({ children, className, ...props }) => {
  return (
    <div className={cn("space-y-2", className)} {...props}>
      {children}
    </div>
  );
});
Accordion.displayName = 'Accordion';

const AccordionItem = memo(({ children, className, ...props }) => {
  return (
    <div className={cn("border rounded-lg shadow-sm overflow-hidden mb-4", className)} {...props}>
      {children}
    </div>
  );
});
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = memo(({ 
  children, 
  expanded, 
  onClick, 
  className,
  ...props 
}) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        "flex items-center justify-between p-3 sm:p-4 cursor-pointer bg-white hover:bg-gray-50/80 transition-colors",
        expanded && "border-b",
        className
      )}
      {...props}
    >
      <div className="flex-1">
        {children}
      </div>
      <div className="flex items-center">
        <ChevronDown className={cn(
          "h-4 w-4 text-gray-500 transition-transform duration-300",
          expanded && "transform rotate-180"
        )} />
      </div>
    </div>
  );
});
AccordionTrigger.displayName = 'AccordionTrigger';

const AccordionContent = memo(({ children, expanded, className, ...props }) => {
  return (
    <div
      className={cn(
        "overflow-hidden transition-all",
        expanded ? "h-auto opacity-100" : "h-0 opacity-0",
        className
      )}
      data-state={expanded ? "open" : "closed"}
      {...props}
    >
      {expanded && (
        <div className="p-4 bg-white">
          {children}
        </div>
      )}
    </div>
  );
});
AccordionContent.displayName = 'AccordionContent';

// Helper function to get next service name
const getNextServiceName = (serviceId, allServices) => {
  if (!allServices || allServices.length === 0) return 'Complete';
  
  const currentIndex = allServices.findIndex(s => s.id.toString() === serviceId.toString());
  
  if (currentIndex === -1 || currentIndex >= allServices.length - 1) {
    return 'Complete';
  }
  
  return allServices[currentIndex + 1].name;
};

// Memoized ServiceItem component to prevent unnecessary re-renders
const ServiceItem = memo(({ 
  service, 
  index, 
  locationId, 
  isExpanded, 
  status, 
  onToggle, 
  onAnimationComplete, 
  renderContent,
  isFirstService,
  activeService,
  hasMovedPastFirstService,
  isExiting = false
}) => {
  // Track whether this service has already animated in
  const [hasAnimated, setHasAnimated] = useState(false);
  
  // Handle animation completion
  const handleAnimationComplete = () => {
    if (!hasAnimated) {
      setHasAnimated(true);
      onAnimationComplete(service.id);
    }
  };
  
  // Check if this is the currently active service
  const isActiveService = service.id.toString() === activeService?.toString();
  
  // Track service status for animation purposes
  const isFirstExpanded = isFirstService && isExpanded;
  
  // Define animation properties based on expanded state
  let animationProps = {};
  
  // If exiting, use exit animation
  if (isExiting) {
    animationProps = {
      opacity: 0,
      y: 100,
      scale: 0.98,
      transition: {
        duration: 0.5,
        ease: "easeIn",
        delay: index * 0.1, // Staggered timing for exit too
      }
    };
  }
  // Base animation on whether the service is expanded
  else if (isExpanded) {
    // Expanded service gets full visibility and emphasis
    animationProps = {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.4,
        ease: "easeOut"
      }
    };
  } else {
    // Collapsed services are de-emphasized
    animationProps = {
      opacity: 0.7,
      y: 0,
      scale: 0.98,
      transition: {
        duration: 0.4,
        ease: "easeOut"
      }
    };
  }
  
  // Add initial animation with staggered appearance
  if (!hasAnimated && !isExiting) {
    animationProps = {
      ...animationProps,
      transition: {
        ...animationProps.transition,
        delay: index * 0.3, // Staggered timing
      }
    };
  }
  
  return (
    <motion.div 
      key={`service-${service.id}-${locationId}`}
      initial={{ opacity: 0, y: 100 }}
      animate={animationProps}
      exit={{ 
        opacity: 0, 
        y: 100,
        transition: { 
          opacity: { duration: 0.8 },
          y: { duration: 1.5, ease: [0.33, 1, 0.68, 1] }
        } 
      }}
      onAnimationComplete={handleAnimationComplete}
      className={cn(
        "mb-4", // Add margin between services
        !isExpanded && "opacity-80" // Slight visual distinction for collapsed services
      )}
    >
      <AccordionItem className={cn(
        isExpanded ? "border-blue-200 shadow-md" : "border-gray-200"
      )}>
        <AccordionTrigger 
          expanded={isExpanded}
          onClick={() => onToggle(service.id, locationId)}
        >
          <div className="flex items-center gap-3">
            <div 
              className={cn(
                "flex h-5 w-5 rounded-full items-center justify-center",
                status === 'complete' ? "bg-green-500 text-white" : "bg-gray-200"
              )}
              title={status === 'complete' ? "Service setup complete" : "Service setup incomplete"}
            >
              {status === 'complete' ? (
                <Check className="h-3 w-3" />
              ) : (
                <span className="h-3 w-3"></span>
              )}
            </div>
            <h3 className="text-sm font-medium">{service.name}</h3>
          </div>
        </AccordionTrigger>
        
        <AccordionContent expanded={isExpanded}>
          {renderContent(service, locationId)}
        </AccordionContent>
      </AccordionItem>
    </motion.div>
  );
});
ServiceItem.displayName = 'ServiceItem';

// Component to detect if the header text is wrapping and adjust layout accordingly
const HeaderWithBreakDetection = ({ 
  locationName, 
  currentLocationIndex, 
  totalLocations, 
  dbLocations, 
  serviceCount, 
  onNextLocation,
  allServicesComplete
}) => {
  const headerRef = useRef(null);
  const [isWrapping, setIsWrapping] = useState(false);
  
  // Function to check if text is wrapping
  const checkWrapping = useCallback(() => {
    if (headerRef.current) {
      const element = headerRef.current;
      // If line-height is 1.2 and font-size is calculated to be around 24px
      // then single line height would be approximately 29px
      // We'll use 35px as a threshold to detect multi-line
      const singleLineHeight = 35;
      const isMultiLine = element.offsetHeight > singleLineHeight;
      setIsWrapping(isMultiLine);
    }
  }, []);
  
  // Check on mount and window resize
  useEffect(() => {
    checkWrapping();
    
    const handleResize = () => {
      checkWrapping();
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [checkWrapping]);
  
  // Determine text for the next button
  const hasMoreLocations = currentLocationIndex < totalLocations - 1;
  const buttonText = hasMoreLocations
    ? dbLocations[currentLocationIndex + 1]?.name || "Next Location"
    : "Next Section";
  
  // Determine text for description
  const nextEntityType = hasMoreLocations ? "location" : "section";
  
  // Get first location name for info box
  const isNotFirstLocation = currentLocationIndex > 0;
  const firstLocationName = dbLocations[0]?.name || "the first location";
  
  return (
    <>
      {isWrapping ? (
        <>
          <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-1">
            Service Details:
          </h2>
          <h3 className="text-lg sm:text-xl font-bold text-gray-900 mb-2">
            {locationName}
          </h3>
        </>
      ) : (
        <h2 ref={headerRef} className="text-xl sm:text-2xl font-bold text-gray-900 mb-2">
          Service Details: {locationName}
        </h2>
      )}
      <p className="text-sm text-gray-500 mb-4">
        Please train your AI on the details of the {serviceCount} services at {locationName}. 
        Once you've finished, click the '{buttonText}' button below to continue to the next {nextEntityType}.
      </p>
      
      {/* Info box for non-first locations */}
      {currentLocationIndex > 0 && (
        <div className="flex items-center p-4 rounded-lg bg-blue-50 border border-blue-100 mb-4">
          <Info className="w-5 h-5 text-blue-500 mr-3 flex-shrink-0" />
          <p className="text-sm text-blue-700">
            We've prefilled all of this location with the data from {firstLocationName} to save you time. 
            You can review and adjust any specific details as needed.
          </p>
        </div>
      )}
      
      <div className="flex justify-start mt-4 mb-2">
        <button
          onClick={onNextLocation}
          disabled={!allServicesComplete}
          className={cn(
            "inline-flex items-center justify-center px-5 py-2.5 font-medium rounded-lg transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 shadow-sm",
            allServicesComplete 
              ? "bg-gray-900 text-white hover:bg-gray-800 focus:ring-gray-500 transform hover:-translate-y-0.5 active:translate-y-0" 
              : "bg-gray-200 text-gray-400 cursor-not-allowed"
          )}
        >
          {buttonText}
          {hasMoreLocations && allServicesComplete && (
            <ArrowRight className="ml-2 h-4 w-4" />
          )}
        </button>
      </div>
    </>
  );
};

// First, add a placeholder function for metadataToKnowledgeBase
// Add this before the ServiceDetailsManager component
const metadataToKnowledgeBase = async (location) => {
  try {
    // Import the converter function with specific error handling
    let converter;
    try {
      const moduleImport = await import('../../utils/metadata-to-kb/index.js');
      
      converter = moduleImport.default || moduleImport.convertMetadataToKnowledgeBase;
      
      if (!converter) {
        throw new Error('Converter function not found in module');
      }
    } catch (importError) {
      throw importError;
    }
    
    // Call the converter function with the location data
    const success = await converter(location);
    
    return success;
  } catch (error) {
    return false;
  }
};

// Add this function to test the module import directly
const testMetadataToKBModule = async () => {
  try {
    // Test basic module import
    const moduleImport = await import('../../utils/metadata-to-kb/index.js');
    
    // Check if we have the exported functions
    const hasDefault = typeof moduleImport.default === 'function';
    const hasNamedExport = typeof moduleImport.convertMetadataToKnowledgeBase === 'function';
    
    // Test service converter imports
    const hasSelfService = typeof moduleImport.selfServiceConverter === 'function';
    const hasWDF = typeof moduleImport.washDryFoldConverter === 'function';
    const hasDryCleaning = typeof moduleImport.dryCleaningConverter === 'function';
    const hasCommercial = typeof moduleImport.commercialConverter === 'function';
    
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * ServiceDetailsManager component allows users to configure service details for each location.
 * 
 * @param {Object} props
 * @param {Array} props.locations - List of locations with their services (used as a fallback)
 * @param {Array} props.services - Combined list of selected and custom services
 * @param {Function} props.onComplete - Callback when service details are complete
 * @param {Function} props.onBack - Callback to go back to previous step
 */
const ServiceDetailsManager = ({ locations = [], services = [], onComplete, onBack }) => {
  const { businessType } = useBusinessType();
  const supabase = getSupabaseClient(businessType);
  const [isLoading, setIsLoading] = useState(true);
  const [locationsLoaded, setLocationsLoaded] = useState(false);
  const [dbLocations, setDbLocations] = useState([]);
  const [userId, setUserId] = useState(null); // Add state for user ID
  
  // Initialize with safe default values if locations is empty
  const [activeLocation, setActiveLocation] = useState(null);
  const [activeService, setActiveService] = useState(null);
  const [serviceDetails, setServiceDetails] = useState({});
  const [serviceComponents, setServiceComponents] = useState({});
  const [expandedServices, setExpandedServices] = useState({});
  const [orderedServices, setOrderedServices] = useState([]);
  const [currentServiceIndex, setCurrentServiceIndex] = useState(0);
  const [showApplyToAllModal, setShowApplyToAllModal] = useState(false);
  const [completedServices, setCompletedServices] = useState({});  
  const [animationsComplete, setAnimationsComplete] = useState(false);
  const [animatingServices, setAnimatingServices] = useState([]);
  
  // Animation sequence states
  const [headerAnimationPhase, setHeaderAnimationPhase] = useState('initial'); // 'initial', 'center', 'final'
  const [showServices, setShowServices] = useState(false);
  const [showHeader, setShowHeader] = useState(true); // New state to control header visibility
  
  // Add a new state to track if we've moved past the first service
  const [hasMovedPastFirstService, setHasMovedPastFirstService] = useState(false);
  
  // Add this state for exiting animation
  const [isExitingLocation, setIsExitingLocation] = useState(false);

  const fetchUserBusinessType = async (userId) => {
    try {
      if (!userId) return null;
      
      // Get the user's business type from onboarding_data
      const { data, error } = await supabase
        .from('onboarding_data')
        .select('business_type')
        .eq('account_id', userId)
        .single();
        
      if (error) throw error;
      
      if (data && data.business_type) {
        // Now get the actual type ID from the onboarding_types table
        const { data: typeData, error: typeError } = await supabase
          .from('onboarding_types')
          .select('id')
          .eq('name', data.business_type)
          .single();
          
        if (typeError) throw typeError;
        
        if (typeData && typeData.id) {
          console.log("Found business type ID:", typeData.id);
          return typeData.id;
        }
      }
      
      // Default to showing all services if no specific type found
      return null;
    } catch (error) {
      console.error("Error fetching user business type:", error);
      return null;
    }
  };

  // Add this function to filter services by business type
  const fetchServicesByBusinessType = async (businessTypeId) => {
    try {
      console.log("Fetching services for business type ID:", businessTypeId);
      
      const { data, error } = await supabase
        .from('onboarding_services_mappings')
        .select('service_id')
        .eq('type_id', businessTypeId);
        
      if (error) throw error;
      
      if (data && data.length > 0) {
        const serviceIds = data.map(item => item.service_id.toString());
        console.log("Found valid service IDs for business type:", serviceIds);
        return serviceIds;
      }
      
      return [];
    } catch (error) {
      console.error("Error fetching services by business type:", error);
      return [];
    }
  };
  
  // Memoize handlers to prevent re-creation on every render
  const toggleServiceExpanded = useCallback((serviceId, locationId) => {
    if (!serviceId || !locationId) return;
    
    const key = `${serviceId}-${locationId}`;
    
    setExpandedServices(prev => {
      // Check if we're opening or closing this accordion
      const isOpening = !prev[key];
      
      if (isOpening) {
        // EXCLUSIVE MODE: If opening, create a new object with all accordions closed except this one
        // This ensures only one accordion can be open at a time
        const result = {};
        // First, set all to false (close all accordions)
        Object.keys(prev).forEach(k => {
          result[k] = false;
        });
        // Then set only this one to true
        result[key] = true;
        return result;
      } else {
        // If closing, just close this one
        return {
          ...prev,
          [key]: false
        };
      }
    });
    
    // Set as active service
    setActiveService(serviceId);
  }, []);

  const isServiceExpanded = useCallback((serviceId, locationId) => {
    if (!serviceId || !locationId) return false;
    
    // Convert both to strings for consistent comparison
    const key = `${serviceId.toString()}-${locationId.toString()}`;
    return expandedServices[key] === true;
  }, [expandedServices]);

  const getServiceStatus = useCallback((locationId, serviceId) => {
    if (!locationId || !serviceId) return 'incomplete';
    
    // Convert both to strings for consistent comparison
    const locationKey = locationId.toString();
    const serviceKey = serviceId.toString();
    
    // Check if this service specifically has been completed
    if (completedServices[`${locationKey}-${serviceKey}`]) {
      console.log(`Service ${serviceKey} marked complete via completedServices`);
      return 'complete';
    }
    
    // Check if service data has requirementsToAdvance property
    const serviceData = serviceDetails[locationKey]?.[serviceKey];
    if (serviceData && serviceData.requirementsToAdvance === true) {
      console.log(`Service ${serviceKey} marked complete via requirementsToAdvance in serviceData`);
      return 'complete';
    }
    
    // Check if the service data is in the metadata format
    // and has requirementsToAdvance set to true
    if (serviceData && serviceData.metadata?.services?.[serviceKey]?.requirementsToAdvance === true) {
      console.log(`Service ${serviceKey} marked complete via metadata requirementsToAdvance`);
      return 'complete';
    }
    
    // Check if WDF panel (serviceId=2), also check if at least one delivery method is complete
    if (serviceId === "2" || serviceId === 2) {
      const wdfData = serviceData?.metadata?.services?.[2] || serviceData;
      
      // Check if dropOff is complete
      if (wdfData?.dropOff?.requirementsToAdvance === true) {
        console.log(`Service ${serviceKey} marked complete via dropOff requirementsToAdvance`);
        return 'complete';
      }
      
      // Check if pickupDelivery is complete
      if (wdfData?.pickupDelivery?.requirementsToAdvance === true) {
        console.log(`Service ${serviceKey} marked complete via pickupDelivery requirementsToAdvance`);
        return 'complete';
      }
    }
    
    return 'incomplete';
  }, [completedServices, serviceDetails]);
  
  const handleAnimationComplete = useCallback((serviceId) => {
    setAnimatingServices(prev => {
      if (!prev.includes(serviceId)) {
        return [...prev, serviceId];
      }
      return prev;
    });
  }, []);

  // Add a ref to store the moveToNextService function
  const moveToNextServiceRef = useRef();
  
  // Simplified moveToNextService function without complex transitions
  const moveToNextService = (serviceId, locationId) => {
    // Get all services for this location
    const location = locations.find(loc => loc.id === locationId);
    
    // Make sure we're handling the services array correctly
    const locationServices = Array.isArray(location?.services) 
      ? location.services 
      : typeof location?.services === 'string' 
        ? JSON.parse(location.services) 
        : [];
    
    // Convert all service IDs to strings for comparison
    const locationServiceIds = locationServices.map(id => id.toString());
    
    // Get ordered services filtered to this location with string comparison
    const locationOrderedServices = orderedServices.filter(service => 
      locationServiceIds.includes(service.id.toString())
    );
    
    // Find current index using string comparison
    const currentIndex = locationOrderedServices.findIndex(s => 
      s.id.toString() === serviceId.toString()
    );
    
    // Check if there's a next service
    if (currentIndex >= 0 && currentIndex < locationOrderedServices.length - 1) {
      const nextService = locationOrderedServices[currentIndex + 1];
      
      // If we're completing the first service, mark that we've moved past it
      if (currentIndex === 0) {
        setHasMovedPastFirstService(true);
      }
      
      // Close the current service
      const currentKey = `${serviceId}-${locationId}`;
      
      // Open the next service and set as active
      const nextKey = `${nextService.id}-${locationId}`;
      
      // Update expanded services state to close all accordions except the next one
      setExpandedServices(() => ({
        [nextKey]: true
      }));
      
      // Update active service
      setActiveService(nextService.id.toString());
    } else if (currentIndex === locationOrderedServices.length - 1) {
      // This was the last service in this location
      
      // Check if there's another location to move to
      const currentLocationIndex = dbLocations.findIndex(loc => loc.id === locationId);
      
      if (currentLocationIndex < dbLocations.length - 1) {
        // Move to the first service of the next location
        const nextLocation = dbLocations[currentLocationIndex + 1];
        
        // Close the current service
        const currentKey = `${serviceId}-${locationId}`;
        setExpandedServices(prev => ({
          ...prev,
          [currentKey]: false
        }));
        
        // Set the new location as active
        setActiveLocation(nextLocation.id);
        
        // Find the first service in the next location
        const nextLocationServices = Array.isArray(nextLocation.services) 
          ? nextLocation.services.map(id => parseInt(id)) 
          : [];
        
        if (nextLocationServices.length > 0) {
          const firstServiceInNextLocation = orderedServices.find(s => 
            nextLocationServices.includes(parseInt(s.id))
          );
          
          if (firstServiceInNextLocation) {
            // Set the first service in the next location as active and expanded
            setActiveService(firstServiceInNextLocation.id.toString());
            
            const nextKey = `${firstServiceInNextLocation.id}-${nextLocation.id}`;
            setTimeout(() => {
              // Set only this accordion as expanded
              setExpandedServices(() => ({
                [nextKey]: true
              }));
            }, 300); // Short delay to allow location change to process
          }
        }
      } else {
        // All locations completed
      }
    }
  };
  
  // Update the ref whenever moveToNextService changes
  useEffect(() => {
    moveToNextServiceRef.current = moveToNextService;
  }, [moveToNextService]);
  
  // Function to check if a service is "Self-Serve" by name
  const isSelfServeService = useCallback((service) => {
    return service && service.name === "Self-Serve";
  }, []);

  // Function to check if a service is "Wash & Fold" by name
  const isWashAndFoldService = useCallback((service) => {
    return service && service.name === "Wash & Fold";
  }, []);

  // Function to dynamically import a component based on file path
  const importComponent = (filePath) => {
    if (!filePath) return null;
    
    // Remove leading ./ or / if present
    const normalizedPath = filePath.replace(/^\.\/|^\//, '');
    try {
      // For components in the onboarding-knowledge directory
      if (normalizedPath.startsWith('onboarding-knowledge/')) {
        // Extract the component name without extension
        const componentName = normalizedPath.split('/').pop().replace(/\.(js|jsx)$/, '');
        
        // Map to known components
        switch (componentName) {
          case 'SimpleSelfServicePanel':
            return SimpleSelfServicePanel;
          case 'WashDryFoldPanel':
            return WashDryFoldPanel;
          case 'DryCleaningPanel':
            return DryCleaningPanel;
          case 'CommercialLaundryPanel':
            return CommercialLaundryPanel;
          case 'CommercialDryCleaningPanel':
            return CommercialDryCleaningPanel;
          // Add more component mappings here as needed
          default:
            // For components that aren't directly mapped but are in the same directory
            // This approach won't work at runtime but shows the intent - you'd need
            // a proper dynamic import system for this to work
            try {
              // This is an example - actual implementation would depend on your bundler/environment
              return require(`./${componentName}`).default;
            } catch (e) {
              return null;
            }
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  // Update location details when a service component (like SelfServicePanel) makes changes
  const handleUpdateLocation = useCallback((locationId, serviceId, updatedData) => {
    // Convert serviceId to string for consistent usage with metadata keys
    serviceId = serviceId.toString();
    
    // Check if requirements are met to update the completed status
    if (updatedData.requirementsToAdvance === true) {
      setCompletedServices(prev => ({
        ...prev,
        [`${locationId}-${serviceId}`]: true
      }));
    }
    
    // Handle metadata updates carefully
    setServiceDetails(prev => {
      // If there's no metadata in the updated data but there is in the previous state,
      // we need to preserve it
      let mergedData = { ...updatedData };
      
      // Check if we need to preserve existing metadata
      if (prev[locationId]?.[serviceId]?.metadata &&
          (!updatedData.metadata || Object.keys(updatedData.metadata).length === 0)) {
        mergedData.metadata = prev[locationId][serviceId].metadata;
      }
      
      // If we have new metadata, make sure it has the right structure
      if (updatedData.metadata?.services) {
        // Make sure we have the right serviceId if not already present
        if (!updatedData.metadata.services[serviceId] && 
            Object.keys(updatedData.metadata.services).length > 0) {
          
          // Get the first key, assuming it's the intended service
          const firstKey = Object.keys(updatedData.metadata.services)[0];
          
          // Restructure the metadata with the correct service ID
          mergedData.metadata = {
            services: {
              [serviceId]: updatedData.metadata.services[firstKey]
            }
          };
        }
      }
      
      // IMPORTANT: Make sure requirementsToAdvance is preserved in the merged data
      if (updatedData.requirementsToAdvance === true) {
        mergedData.requirementsToAdvance = true;
      }
      
      // Update the service data
      return {
        ...prev,
        [locationId]: {
          ...prev[locationId],
          [serviceId]: {
            ...prev[locationId]?.[serviceId],
            ...mergedData
          }
        }
      };
    });
  }, []);

  // Render the appropriate service component based on service type or component path
  const renderServiceSpecificComponent = useCallback((service, locationId) => {
    const location = dbLocations.find(loc => loc.id === locationId);
    
    // Convert serviceId to string for consistent usage with metadata keys
    const serviceId = service.id.toString();
    
    // Get service specific data from serviceDetails
    let currentDetails = serviceDetails[locationId]?.[serviceId] || {};
    
    // CRITICAL FIX: If metadata is missing or empty from the service details,
    // but exists in the location object, use that instead
    if ((!currentDetails.metadata || !currentDetails.metadata.services || 
         !currentDetails.metadata.services[serviceId]) && 
        location?.metadata?.services?.[serviceId]) {
      
      // Create a properly structured metadata object
      currentDetails = {
        ...currentDetails,
        metadata: {
          services: {
            [serviceId]: location.metadata.services[serviceId]
          }
        }
      };
      
      // If this is Self-Service (service 1), add specific fields to match component expectation
      if (serviceId === "1" && location.metadata.services[serviceId]) {
        const serviceMetadata = location.metadata.services[serviceId];
        
        // Map component-specific fields
        if (serviceMetadata.hours) {
          currentDetails.operatingHours = serviceMetadata.hours;
        }
        
        if (serviceMetadata.attendantHours) {
          currentDetails.attendantHours = serviceMetadata.attendantHours;
        }
        
        if (serviceMetadata.payment) {
          currentDetails.payment = serviceMetadata.payment;
        }
        
        if (serviceMetadata.machines) {
          currentDetails.machines = serviceMetadata.machines;
        }
        
        if (serviceMetadata.lastLoad) {
          currentDetails.lastLoadSchedule = serviceMetadata.lastLoad;
        }
        
        if (serviceMetadata.security) {
          currentDetails.security = serviceMetadata.security;
          
          if (serviceMetadata.security.schedule) {
            currentDetails.securitySchedule = serviceMetadata.security.schedule;
          }
        }
        
        if (serviceMetadata.products) {
          currentDetails.products = serviceMetadata.products;
        }
        
        // Set enabled state
        currentDetails.enabled = serviceMetadata.enabled !== false;
      }
      
      // Update state to ensure consistency in future renders
      setServiceDetails(prev => ({
        ...prev,
        [locationId]: {
          ...prev[locationId],
          [serviceId]: currentDetails
        }
      }));
    }
    
    // Find the next service for this location
    const locationServices = Array.isArray(location?.services) 
      ? location.services.map(id => id.toString())  // Convert all IDs to strings for consistency
      : [];
    const currentServiceIndex = locationServices.indexOf(serviceId);
    const nextServiceId = currentServiceIndex < locationServices.length - 1 
      ? locationServices[currentServiceIndex + 1] 
      : null;
    const nextService = nextServiceId 
      ? services.find(s => s.id.toString() === nextServiceId) 
      : null;
    const nextServiceName = nextService?.name || null;
    
    // Check if this is a custom service
    // Consider a service custom if:
    // 1. It has is_custom flag set to true AND account_id matches current user, or
    // 2. Its ID is a string that starts with "custom-"
    // Ensure service ID 10 (Commercial Dry Cleaning) is treated as standard
    const standardServiceIds = ["1", "2", "3", "4", "10"];
    const isCustomService = 
      (service.is_custom === true && service.account_id === userId) || 
      (typeof service.id === 'string' && service.id.startsWith('custom-') && !standardServiceIds.includes(serviceId));
    
    // If this is a custom service, use the CustomServicePanel
    if (isCustomService) {
      console.log("Using CustomServicePanel for service:", service);
      return (
        <CustomServicePanel
          locationData={location}
          serviceData={{...service, id: serviceId}}
          onUpdate={(updatedData) => {
            handleUpdateLocation(locationId, service.id, updatedData);
          }}
          nextServiceName={nextServiceName}
        />
      );
    }
    
    // Otherwise, continue with the normal component selection logic
    // Check if the service has a file_path in the serviceComponents data
    const serviceComponent = serviceComponents[service.id];
    
    // Use fallback mapping for services when no database mapping exists
    let componentPath = serviceComponent?.file_path;
    if (!componentPath) {
      // Map service names to component paths as fallback
      const fallbackMapping = {
        'Self-Serve': 'onboarding-knowledge/SimpleSelfServicePanel',
        'Wash & Fold': 'onboarding-knowledge/WashDryFoldPanel',
        'Dry Cleaning': 'onboarding-knowledge/DryCleaningPanel',
        'Commercial Laundry': 'onboarding-knowledge/CommercialLaundryPanel',
        'Commercial Dry Cleaning': 'onboarding-knowledge/CommercialDryCleaningPanel'
      };
      
      if (fallbackMapping[service.name]) {
        componentPath = fallbackMapping[service.name];
      }
    }
    
    if (componentPath) {
      const DynamicComponent = importComponent(componentPath);
      if (DynamicComponent) {
        return (
          <DynamicComponent
            locationData={location}
            serviceData={currentDetails}
            onUpdate={(updatedData) => {
              handleUpdateLocation(locationId, service.id, updatedData);
            }}
            nextServiceName={nextServiceName}
          />
        );
      }
    }
    
    // Simplified generic UI for services without a specific component
    return (
      <div className="py-4 bg-white rounded-lg shadow-sm p-6">
        <h3 className="text-lg font-semibold mb-4">Configure {service.name}</h3>
        <div className="mb-6 p-4 bg-gray-50 rounded-lg">
          <p className="text-gray-600 mb-2">This service doesn't have a specialized configuration UI yet.</p>
          <div className="flex justify-end">
            <button 
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 mr-2"
              onClick={() => {
                // Mark service as complete to enable the "Next" button
                setCompletedServices(prev => ({
                  ...prev,
                  [`${locationId}-${service.id}`]: true
                }));
              }}
            >
              Mark as Complete
            </button>
          </div>
        </div>
      </div>
    );
  }, [dbLocations, serviceDetails, serviceComponents, services, handleUpdateLocation, setCompletedServices]);

  // Fetch locations from vapi_location_settings
  useEffect(() => {
    let isMounted = true;
    
    const getLocations = async () => {
      setIsLoading(true);
      try {
        await fetchLocations();
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    
    getLocations();
    
    return () => {
      isMounted = false;
    };
  }, []); // Empty dependency array to run only once
  
  // Fetch service detail components after we have locations and services
  useEffect(() => {
    let isMounted = true;
    
    const getServiceComponents = async () => {
      if (dbLocations.length > 0 && services.length > 0) {
        try {
          await fetchServiceComponents();
        } finally {
          if (isMounted) {
            setIsLoading(false);
          }
        }
      } else {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    
    getServiceComponents();
    
    return () => {
      isMounted = false;
    };
  }, [dbLocations, services]); // Only run when dbLocations or services change
  
  // Set first active location when data is ready
  useEffect(() => {
    if (dbLocations.length > 0 && !activeLocation) {
      setActiveLocation(dbLocations[0].id);
    }
  }, [dbLocations, activeLocation]);
  
  // Order services by display_order
  useEffect(() => {
    if (services && services.length > 0) {
      // Sort services by display_order (if available)
      const sorted = [...services].sort((a, b) => {
        // If both have display_order, compare them
        if (a.display_order !== null && b.display_order !== null) {
          return a.display_order.localeCompare(b.display_order);
        }
        
        // If only a has display_order, a comes first
        if (a.display_order !== null) return -1;
        
        // If only b has display_order, b comes first
        if (b.display_order !== null) return 1;
        
        // If neither has display_order, sort by name
        return a.name.localeCompare(b.name);
      });
      
      setOrderedServices(sorted);
    } else {
      // Set empty array if services is empty
      setOrderedServices([]);
    }
  }, [services]);
  
  // Set first active service when data is ready
  useEffect(() => {
    if (!isLoading && activeLocation && !activeService && orderedServices.length > 0) {
      const location = dbLocations.find(loc => loc.id === activeLocation);
      if (location && location.services && location.services.length > 0) {
        // Find the first service for this location
        const firstServiceId = parseInt(location.services[0]);
        const service = services.find(s => s.id === firstServiceId);
        if (service) {
          setActiveService(service.id);
          toggleServiceExpanded(service.id, activeLocation);
        }
      }
    }
  }, [isLoading, activeLocation, activeService, orderedServices, dbLocations]);
  
  const fetchLocations = async () => {
    try {
      // Get the current user
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      
      if (userError) throw userError;
      
      if (user) {
        // Store user ID in state
        setUserId(user.id);
        
        // Fetch locations from vapi_location_settings
        const { data, error } = await supabase
          .from('vapi_location_settings')
          .select('*')
          .eq('account_id', user.id)
          .order('id', { ascending: true });
            
        if (error) throw error;
        
        if (data && data.length > 0) {
          // Improved sorting that works with both string and numeric IDs
          const sortedData = [...data].sort((a, b) => {
            // Parse IDs as numbers if possible
            const idA = typeof a.id === 'string' ? parseInt(a.id, 10) : a.id;
            const idB = typeof b.id === 'string' ? parseInt(b.id, 10) : b.id;
            
            // Use numeric comparison if both are valid numbers, otherwise string comparison
            if (!isNaN(idA) && !isNaN(idB)) {
              return idA - idB;
            } else {
              // Fallback to string comparison
              return String(a.id).localeCompare(String(b.id));
            }
          });
          
          // Map the sorted data to include metadata, services, and other fields
          const locationsWithMetadata = sortedData.map(loc => {
            // Extract services from the services JSONB column if available
            let servicesList = loc.services ? 
              (Array.isArray(loc.services) ? loc.services : Object.keys(loc.services)) : 
              [];
            
            // Also extract services from metadata if available
            if (loc.metadata && loc.metadata.services) {
              const metadataServiceIds = Object.keys(loc.metadata.services);
              
              // Add these service IDs to the services list if not already there
              metadataServiceIds.forEach(id => {
                if (!servicesList.includes(id)) {
                  servicesList.push(id);
                }
              });
            }
            
            // Remove duplicate service IDs by using a Set
            const uniqueServicesList = [...new Set(servicesList.map(id => id.toString()))];
            
            return {
              id: loc.id,
              name: loc.name || `Location ${loc.id}`,
              services: uniqueServicesList,
              metadata: loc.metadata || {}
            };
          });
          
          setDbLocations(locationsWithMetadata);
        } else {
          // Fallback to passed locations if no data from DB
          setDbLocations(locations.map(loc => ({
            id: loc.id,
            name: loc.name,
            services: loc.services.map(s => s.toString()),
            metadata: {}
          })));
        }
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
      
      // Fallback to passed locations if error
      setDbLocations(locations.map(loc => ({
        id: loc.id,
        name: loc.name,
        services: loc.services.map(s => s.toString()),
        metadata: {}
      })));
    }
  };
  
  const fetchServiceComponents = async () => {
  try {
    // If there are no services, exit early
    if (!services || services.length === 0) {
      return;
    }
    
    // Fetch all services without filtering by business type
    for (const service of services) {
      // For custom services belonging to this user, use custom component
      if (service.is_custom && service.account_id === userId) {
        setServiceComponents(prev => ({
          ...prev,
          [service.id]: { file_path: 'custom' }
        }));
        continue;
      }
      
      // For standard services, fetch from components table
      const { data, error } = await supabase
        .from('onboarding_services_details')
        .select('*')
        .eq('service_id', service.id);
        
      if (error) throw error;
      
      if (data && data.length > 0) {
        // Store the component details
        setServiceComponents(prev => ({
          ...prev,
          [service.id]: data[0]
        }));
      }
    }
  } catch (error) {
    console.error("Error in fetchServiceComponents:", error);
  }
};
  
  const initializeServiceDetails = () => {
    try {
      // Create a details object to store service details for each location
      const details = {};
      
      // Iterate through each location
      dbLocations.forEach(location => {
        // If this location doesn't exist in the details object, create it
        if (!details[location.id]) {
          details[location.id] = {};
        }
        
        // Get services for this location and ensure they're strings
        const locationServices = (location.services || []).map(id => id.toString());
        
        // Deep copy the location's metadata to avoid reference issues
        const locationMetadata = location.metadata ? JSON.parse(JSON.stringify(location.metadata)) : { services: {} };
        
        // Iterate through our available services
        services.forEach(service => {
          // Always convert serviceId to string for consistent usage with metadata keys
          const serviceId = service.id.toString();
          
          // If this service is used by this location, add it to the details
          if (locationServices.includes(serviceId)) {
            // Check if we have existing metadata for this service
            const hasExistingMetadata = 
              locationMetadata?.services && 
              locationMetadata.services[serviceId] !== undefined;
            
            if (hasExistingMetadata) {
              // Structure it correctly for the child component
              details[location.id][serviceId] = {
                metadata: {
                  services: {
                    [serviceId]: locationMetadata.services[serviceId]
                  }
                },
                requirementsToAdvance: false
              };
              
              // If this is WDFPanel (service 2), add specific fields to match component expectation
              if (serviceId === "2" && locationMetadata.services[serviceId]) {
                const serviceMetadata = locationMetadata.services[serviceId];
                if (serviceMetadata.dropOff) {
                  details[location.id][serviceId].dropOff = serviceMetadata.dropOff;
                  details[location.id][serviceId].enabled = serviceMetadata.enabled !== false;
                }
                if (serviceMetadata.pickupDelivery) {
                  details[location.id][serviceId].pickupDelivery = serviceMetadata.pickupDelivery;
                }
                // Set service type if drop-off is enabled
                if (serviceMetadata.dropOff?.enabled) {
                  details[location.id][serviceId].serviceType = 'drop-off';
                }
              }
              
              // If this is DryCleaningPanel (service 3), add specific fields to match component expectation
              if (serviceId === "3" && locationMetadata.services[serviceId]) {
                const serviceMetadata = locationMetadata.services[serviceId];
                
                if (serviceMetadata.dropOff) {
                  details[location.id][serviceId].dropOff = serviceMetadata.dropOff;
                  details[location.id][serviceId].enabled = serviceMetadata.enabled !== false;
                }
                if (serviceMetadata.pickupDelivery) {
                  details[location.id][serviceId].pickupDelivery = serviceMetadata.pickupDelivery;
                }
                // Set service type if drop-off is enabled
                if (serviceMetadata.dropOff?.enabled) {
                  details[location.id][serviceId].serviceType = 'drop-off';
                }
              }
              
              // If this is SelfServicePanel (service 1), add specific fields
              if (serviceId === "1" && locationMetadata.services[serviceId]) {
                const serviceMetadata = locationMetadata.services[serviceId];
                // Add specific fields like hours, attendant, etc.
                if (serviceMetadata.hours) {
                  details[location.id][serviceId].operatingHours = serviceMetadata.hours;
                }
                if (serviceMetadata.attendantHours) {
                  details[location.id][serviceId].attendantHours = serviceMetadata.attendantHours;
                }
              }
            } else {
              // Initialize with empty metadata structure
              details[location.id][serviceId] = {
                requirementsToAdvance: false,
                metadata: {
                  services: {
                    [serviceId]: {
                      enabled: true
                    }
                  }
                }
              };
            }
          }
        });
      });
      
      setServiceDetails(details);
    } catch (error) {
    }
  };
  
  // Set initial service details structure
  useEffect(() => {
    if (Object.keys(serviceComponents).length > 0 && dbLocations.length > 0) {
      initializeServiceDetails();
    }
  }, [serviceComponents, dbLocations, services]);

  const handleComponentChange = (locationId, serviceId, componentName, value) => {
    console.log(`ServiceDetailsManager: component changed for service ${serviceId}, component ${componentName}`);
    
    // Update the serviceDetails state
    setServiceDetails(prev => {
      const newDetails = {
        ...prev,
        [locationId]: {
          ...prev[locationId],
          [serviceId]: {
            ...prev[locationId]?.[serviceId],
            [componentName]: value
          }
        }
      };
      
      // If the value contains requirementsToAdvance, update the completedServices state
      if (value && typeof value === 'object' && value.requirementsToAdvance === true) {
        console.log(`Service ${serviceId} requirements met, updating completion status`);
        
        // Update completedServices in the next render cycle
        setTimeout(() => {
          setCompletedServices(prev => ({
            ...prev,
            [`${locationId}-${serviceId}`]: true
          }));
        }, 0);
      }
      
      return newDetails;
    });
  };
  
  const handleCopyToAllLocations = (fromLocationId, serviceId) => {
    if (!fromLocationId || !serviceId || !dbLocations || dbLocations.length <= 1) {
      setShowApplyToAllModal(false);
      moveToNextService(serviceId, activeLocation);
      return;
    }
    
    const sourceDetails = serviceDetails[fromLocationId]?.[serviceId] || {};
    
    setServiceDetails(prev => {
      const updated = { ...prev };
      
      // Copy to all other locations that offer this service
      dbLocations.forEach(location => {
        if (location && location.id !== fromLocationId && 
            Array.isArray(location.services) && 
            location.services.includes(serviceId.toString())) {
          
          if (!updated[location.id]) {
            updated[location.id] = {};
          }
          
          updated[location.id] = {
            ...updated[location.id],
            [serviceId]: { ...sourceDetails }
          };
        }
      });
      
      return updated;
    });
    
    // Mark as completed for all locations with this service
    const updatedCompleted = { ...completedServices };
    dbLocations.forEach(location => {
      if (location && Array.isArray(location.services) && location.services.includes(serviceId.toString())) {
        if (!updatedCompleted[location.id]) {
          updatedCompleted[location.id] = {};
        }
        updatedCompleted[location.id][serviceId] = true;
      }
    });
    setCompletedServices(updatedCompleted);
    
    setShowApplyToAllModal(false);
    
    // Move to the next service
    moveToNextService(serviceId, activeLocation);
  };
  
  const handleApplyToCurrentLocationOnly = (locationId, serviceId) => {
    if (!locationId || !serviceId) {
      setShowApplyToAllModal(false);
      return;
    }
    
    // Mark as completed for current location only
    setCompletedServices(prev => ({
      ...prev,
      [locationId]: {
        ...prev[locationId],
        [serviceId]: true
      }
    }));
    
    setShowApplyToAllModal(false);
    
    // Move to the next service
    moveToNextService(serviceId, locationId);
  };
  
  // Reset expanded services when active location changes
  useEffect(() => {
    // When location changes, collapse all services
    setExpandedServices({});
    setAnimationsComplete(false);
    setAnimatingServices([]);
  }, [activeLocation]);

  // Auto-expand the first service after animations complete with a delay
  useEffect(() => {
    if (animationsComplete && dbLocations.length > 0 && orderedServices.length > 0) {
      // Add a short delay before expanding the first service
      const timer = setTimeout(() => {
        const location = dbLocations.find(loc => loc.id === activeLocation);
        if (location) {
          const locationServices = Array.isArray(location.services) 
            ? location.services.map(id => id.toString())  // Convert ALL IDs to strings
            : [];
          
          // Find the first service in this location
          const firstServiceInLocation = orderedServices.find(s => 
            locationServices.includes(s.id.toString())
          );
          
          if (firstServiceInLocation) {
            // Set active service to the first one
            const serviceId = firstServiceInLocation.id.toString();
            setActiveService(serviceId);
            
            // Ensure the key format matches what's used in isServiceExpanded
            const expandedKey = `${serviceId}-${location.id}`;
            
            // Then set expanded state for first service - using a clean object to ensure no other services are expanded
            setExpandedServices({ [expandedKey]: true });
            
            // Mark that we've moved past first animation
            setHasMovedPastFirstService(true);
            
            console.log(`Auto-expanding first service: ${serviceId} for location ${location.id} with key ${expandedKey}`);
          }
        }
      }, 500); // Increased delay to ensure animations have fully completed
      
      return () => clearTimeout(timer);
    }
  }, [animationsComplete, dbLocations, activeLocation, orderedServices]);

  // Add this to the animatingServices effect to help debug
  useEffect(() => {
    if (activeLocation && dbLocations.length > 0 && orderedServices.length > 0) {
      const location = dbLocations.find(loc => loc.id === activeLocation);
      if (location) {
        const locationServices = Array.isArray(location.services) 
          ? location.services.map(id => id.toString())
          : [];
        
        // Filter ordered services to only include those in this location
        const locationOrderedServices = orderedServices.filter(s => 
          locationServices.includes(s.id.toString())
        );
        
        // Check if all services have been animated
        const allAnimated = locationOrderedServices.length > 0 && 
          locationOrderedServices.every(service => 
            animatingServices.includes(service.id)
          );
        
        if (allAnimated && !animationsComplete) {
          console.log('All services have completed animation, setting animationsComplete to true');
          setAnimationsComplete(true);
        }
      }
    }
  }, [animatingServices, activeLocation, dbLocations, orderedServices, animationsComplete]);

  // Define header animation variants with fixed positioning
  const headerVariants = {
    initial: { 
      opacity: 0, 
      y: 500, 
      transition: { 
        duration: 0.1 
      } 
    }, // Start from well below the viewport
    final: { 
      opacity: 1, 
      y: 0, 
      transition: { 
        duration: 1.5, // Changed to 1.5 seconds
        ease: [0.33, 1, 0.68, 1] // Smooth easing for natural motion
      } 
    },
    exit: {
      opacity: 0,
      y: 500,
      transition: {
        duration: 1.0,
        ease: [0.33, 1, 0.68, 1]
      }
    }
  };

  // Service item variants - these are for the initial fade in
  const serviceItemVariants = {
    hidden: { 
      opacity: 0, 
      y: 100 
    },
    visible: { 
      opacity: 1, 
      height: "auto", 
      y: 0,
      transition: { 
        duration: 0.8, 
        ease: "easeOut" 
      } 
    },
    fadeOut: { 
      opacity: 0,
      y: 100,
      transition: { 
        duration: 0.5, 
        ease: "easeIn" 
      } 
    }
  };

  // Header animation sequence
  useEffect(() => {
    if (locationsLoaded && headerAnimationPhase === 'initial' && showHeader) {
      // Start animation sequence with a short delay
      setTimeout(() => {
        setHeaderAnimationPhase('final');
        
        // After header reaches final position, wait before showing services
        setTimeout(() => {
          setShowServices(true);
        }, 1500); // 1.5 seconds delay
      }, 100); // Small initial delay
    }
  }, [locationsLoaded, headerAnimationPhase, showHeader]);

  // Auto-expand the first service after animations complete with a delay
  useEffect(() => {
    if (animationsComplete && dbLocations.length > 0 && orderedServices.length > 0) {
      // Add a short delay before expanding the first service
      const timer = setTimeout(() => {
        const location = dbLocations.find(loc => loc.id === activeLocation);
        if (location) {
          const locationServices = Array.isArray(location.services) 
            ? location.services.map(id => id.toString())  // Convert ALL IDs to strings
            : [];
          
          // Find the first service in this location
          const firstServiceInLocation = orderedServices.find(s => 
            locationServices.includes(s.id.toString())
          );
          
          if (firstServiceInLocation) {
            // Set active service to the first one
            const serviceId = firstServiceInLocation.id.toString();
            setActiveService(serviceId);
            
            // Ensure the key format matches what's used in isServiceExpanded
            const expandedKey = `${serviceId}-${location.id}`;
            
            // Then set expanded state for first service - using a clean object to ensure no other services are expanded
            setExpandedServices({ [expandedKey]: true });
            
            // Mark that we've moved past first animation
            setHasMovedPastFirstService(true);
            
            console.log(`Auto-expanding first service: ${serviceId} for location ${location.id} with key ${expandedKey}`);
          }
        }
      }, 500); // Increased delay to ensure animations have fully completed
      
      return () => clearTimeout(timer);
    }
  }, [animationsComplete, dbLocations, activeLocation, orderedServices]);
  
  // Function to handle exit animations and transition to next location
  const transitionToNextLocation = (nextLocationId) => {
    // First, hide all UI elements
    setShowServices(false);
    setIsExitingLocation(true);
    
    // After a short delay, hide the header with animation
    setTimeout(() => {
      setShowHeader(false);
      setHeaderAnimationPhase('exit');
      
      // After header exit animation completes, switch to new location
      setTimeout(() => {
        // Reset state for new location
        setIsExitingLocation(false);
        setHeaderAnimationPhase('initial');
        setAnimationsComplete(false);
        setAnimatingServices([]);
        
        // Change to next location
        setActiveLocation(nextLocationId);
        
        // Small delay before showing new location's header
        setTimeout(() => {
          // Show the header first
          setShowHeader(true);
          
          // Start header animation
          setTimeout(() => {
            setHeaderAnimationPhase('final');
            
            // After header animation completes, show services with a delay
            setTimeout(() => {
              setShowServices(true);
            }, 1500); // 1.5 seconds after header animation starts
          }, 100);
        }, 300);
      }, 1000); // Wait for header exit animation to complete
    }, 600); // Wait for services to animate out
  };
  
  const renderLocationServiceDetails = () => {
    if (!dbLocations || dbLocations.length === 0) {
      return (
        <div className="p-6 bg-white rounded-lg shadow-sm text-center">
          <p className="text-gray-500">No locations found. Please go back and add locations first.</p>
        </div>
      );
    }
    
    const location = dbLocations.find(loc => loc.id === activeLocation);
    if (!location) return null;
    
    console.log("Location found:", location);
    
    // Ensure location.services is an array of strings and deduplicate
    const locationServices = Array.isArray(location.services) 
      ? [...new Set(location.services.map(id => id.toString()))]  // Convert ALL IDs to strings and deduplicate
      : [];
      
    console.log("Location services (IDs):", locationServices);
    console.log("All available services:", orderedServices);
    
    // Check if we have any services for this location
    if (locationServices.length === 0) {
      return (
        <div className="p-6 bg-white rounded-lg shadow-sm text-center">
          <p className="text-gray-500">No services selected for this location. Please go back and select services.</p>
        </div>
      );
    }

    // Find current location index - must be defined BEFORE it's used
    const currentLocationIndex = dbLocations.findIndex(loc => loc.id === activeLocation);
    
    // Improved filtering logic:
    // 1. First try exact ID matching
    // 2. If that doesn't produce enough results, allow more flexible matching
    let locationOrderedServices = orderedServices.filter(service => 
      locationServices.includes(service.id.toString())
    );
    
    // If we didn't find enough services, try a more flexible approach
    if (locationOrderedServices.length < locationServices.length) {
      console.log("Using more flexible service matching...");
      // Find services by ID or by name (for custom services that might have different IDs)
      locationOrderedServices = orderedServices.filter(service => 
        locationServices.includes(service.id.toString()) ||
        (service.is_custom === true && service.account_id === userId) // Only include custom services for this user
      );
      
      // If we still don't have enough services, create placeholder services for missing IDs
      if (locationOrderedServices.length < locationServices.length) {
        const existingIds = locationOrderedServices.map(s => s.id.toString());
        const missingIds = locationServices.filter(id => !existingIds.includes(id));
        
        if (missingIds.length > 0) {
          console.log("Creating placeholder services for missing IDs:", missingIds);
          
          // Create initial placeholder services for smooth UI rendering
          const initialPlaceholders = missingIds.map(id => ({
            id: id,
            name: `Custom Service ${id}`,
            is_custom: true,
            description: "Custom service"
          }));
          
          // Add the initial placeholders to locationOrderedServices
          locationOrderedServices = [...locationOrderedServices, ...initialPlaceholders];
          
          // Then try to fetch actual service data in the background
          (async () => {
            try {
              const { data, error } = await supabase
                .from('onboarding_services')
                .select('*')
                .eq('account_id', userId)
                .in('id', missingIds);
                
              if (error) throw error;
              
              if (data && data.length > 0) {
                // Create services from the actual data
                const customServices = data.map(service => ({
                  id: service.id,
                  name: service.name || `Custom Service ${service.id}`,
                  is_custom: true,
                  description: service.description || "Custom service",
                  account_id: service.account_id
                }));
                
                // Update the ordered services with real data
                setOrderedServices(prev => {
                  // Replace placeholder services with real data
                  const updatedServices = [...prev];
                  customServices.forEach(service => {
                    const index = updatedServices.findIndex(s => s.id.toString() === service.id.toString());
                    if (index !== -1) {
                      updatedServices[index] = service;
                    } else {
                      updatedServices.push(service);
                    }
                  });
                  return updatedServices;
                });
              }
            } catch (err) {
              console.error("Error fetching custom services:", err);
            }
          })();
        }
      }
    }
    
    console.log("Filtered location services:", locationOrderedServices);

    if (locationOrderedServices.length === 0) {
      return (
        <div className="p-6 bg-white rounded-lg shadow-sm text-center">
          <p className="text-gray-500">No services found for this location.</p>
        </div>
      );
    }

    // Function to handle next location button click
    const handleNextLocationClick = () => {
      // Check if all services for this location are complete
      const allServicesComplete = locationServices.every(serviceId => 
        getServiceStatus(location.id, serviceId) === 'complete'
      );
      
      // Only proceed if all services are complete
      if (!allServicesComplete) {
        return;
      }
      
      // Check if this is the first location and copy metadata to all other locations
      const isFirstLocation = currentLocationIndex === 0;
      
      // Check if this is the last location
      const isLastLocation = currentLocationIndex === dbLocations.length - 1;
      
      if (isLastLocation) {
        // This is the last location and "Next Section" was clicked
        
        // Show loading state or disable button to prevent multiple clicks
        setIsLoading(true);
        
        // Process all locations through metadataToKnowledgeBase
        const processLocations = async () => {
          try {
            // First run a direct test of the module
            const moduleTestResult = await testMetadataToKBModule();
            
            if (!moduleTestResult) {
              alert("There was an issue with the metadata converter module. Please contact support.");
              setIsLoading(false);
              return;
            }
            
            // Track success for all locations
            const results = [];
            
            // Call metadataToKnowledgeBase for each location sequentially
            for (const loc of dbLocations) {
              const success = await metadataToKnowledgeBase(loc);
              results.push({ locationId: loc.id, success });
            }
            
            // Check if all conversions were successful
            const allSuccessful = results.every(result => result.success === true);
            
            if (!allSuccessful) {
              alert("There was an issue converting some metadata to knowledge base. Please try again.");
              setIsLoading(false);
              return;
            }
            
            // All locations processed successfully, now update the onboarding_data table
            const { data: { user }, error: userError } = await supabase.auth.getUser();
            
            if (userError) {
              setIsLoading(false);
              return;
            }
            
            // Update the onboarding_data table to set current_step to business_workflows
            const { error: updateError } = await supabase
              .from('onboarding_data')
              .update({ current_step: 'business_workflows' })
              .eq('account_id', user.id);
            
            if (updateError) {
              setIsLoading(false);
              return;
            }
            
            // Also call the onComplete prop, which may still be used by the parent
            if (onComplete) {
              setTimeout(() => {
                onComplete(serviceDetails);
              }, 100);
            }
          } catch (error) {
          } finally {
            setIsLoading(false);
          }
        };
        
        // Start the processing
        processLocations();
      } else if (isFirstLocation) {
        // Existing code for first location...
        const sourceLocationMetadata = location.metadata || {};
        
        // Update all other locations with this metadata
        const updatePromises = dbLocations
          .filter(loc => loc.id !== location.id) // Skip the current location
          .map(async (targetLocation) => {
            try {
              // Update the location_settings table with the metadata from the first location
              const { error } = await supabase
                .from('vapi_location_settings')
                .update({ metadata: sourceLocationMetadata })
                .eq('id', targetLocation.id);
                
              if (error) {
                return false;
              }
              
              // Also update the local state
              setDbLocations(prev => prev.map(loc => 
                loc.id === targetLocation.id 
                  ? { ...loc, metadata: { ...sourceLocationMetadata } } 
                  : loc
              ));
              
              return true;
            } catch (err) {
              return false;
            }
          });
        
        // Wait for all updates to complete before proceeding
        Promise.all(updatePromises)
          .then(results => {
            const allSuccessful = results.every(result => result === true);
            if (!allSuccessful) {
            }
            
            // Check if there's a next location to transition to
            if (currentLocationIndex < dbLocations.length - 1) {
              const nextLocation = dbLocations[currentLocationIndex + 1];
              transitionToNextLocation(nextLocation.id);
            }
          })
          .catch(err => {
            // Continue with navigation despite errors
            if (currentLocationIndex < dbLocations.length - 1) {
              const nextLocation = dbLocations[currentLocationIndex + 1];
              transitionToNextLocation(nextLocation.id);
            }
          });
      } else {
        // Not the first location or last location, use normal transition
        if (currentLocationIndex < dbLocations.length - 1) {
          const nextLocation = dbLocations[currentLocationIndex + 1];
          transitionToNextLocation(nextLocation.id);
        }
      }
    };
    
    // Check if all services for this location are complete
    const allServicesComplete = locationServices.every(serviceId => 
      getServiceStatus(location.id, serviceId) === 'complete'
    );
    
    return (
      <div>
        {/* Animated header card */}
        <AnimatePresence>
          {showHeader && (
            <motion.div 
              key={`header-${location.id}`}
              className="bg-white p-4 sm:p-6 rounded-lg shadow-md border border-gray-200 mb-6"
              initial="initial"
              animate={headerAnimationPhase}
              exit="exit"
              variants={headerVariants}
            >
              {/* Use ref to measure if header is wrapping */}
              <HeaderWithBreakDetection 
                locationName={location.name || `Location ${location.id}`}
                currentLocationIndex={currentLocationIndex}
                totalLocations={dbLocations.length}
                dbLocations={dbLocations}
                serviceCount={locationServices.length}
                onNextLocation={handleNextLocationClick}
                allServicesComplete={locationServices.every(serviceId => 
                  getServiceStatus(location.id, serviceId) === 'complete'
                )}
              />
            </motion.div>
          )}
        </AnimatePresence>
        
        {/* Services with AnimatePresence for proper exit animations */}
        <AnimatePresence>
          {showServices && (
            <motion.div 
              key="services-container"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.5 }}
              className="space-y-4"
            >
              <Accordion>
                <AnimatePresence>
                  {locationOrderedServices.map((service, index) => {
                    // Always render all services, regardless of showOnlyFirstService
                    return (
                      <ServiceItem
                        key={`service-item-${service.id}-${location.id}`}
                        service={service}
                        index={index}
                        locationId={location.id}
                        isExpanded={isServiceExpanded(service.id, location.id)}
                        status={getServiceStatus(location.id, service.id)}
                        onToggle={toggleServiceExpanded}
                        onAnimationComplete={handleAnimationComplete}
                        renderContent={renderServiceSpecificComponent}
                        isFirstService={index === 0}
                        activeService={activeService}
                        hasMovedPastFirstService={hasMovedPastFirstService}
                        isExiting={isExitingLocation}
                      />
                    );
                  })}
                </AnimatePresence>
              </Accordion>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };
  
  // Modal to ask if service details should apply to all locations
  const ApplyToAllModal = () => {
    if (!showApplyToAllModal || !activeService) return null;
    
    const service = services.find(s => s.id === activeService);
    if (!service) return null;
    
    const otherLocationsWithService = dbLocations.filter(
      loc => loc.id !== activeLocation && 
      Array.isArray(loc.services) && 
      loc.services.includes(activeService.toString())
    );
    
    if (otherLocationsWithService.length === 0) return null;
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-md w-full">
          <h3 className="text-xl font-bold mb-4">Apply to Other Locations?</h3>
          <p className="mb-6">
            Would you like to apply the same {service?.name} details to all other locations?
            ({otherLocationsWithService.map(l => l.name || `Location ${l.id}`).join(', ')})
          </p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => handleApplyToCurrentLocationOnly(activeLocation, activeService)}
              className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              No, Just This Location
            </button>
            <button
              onClick={() => handleCopyToAllLocations(activeLocation, activeService)}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Yes, Apply to All
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  useEffect(() => {
    if (dbLocations && dbLocations.length > 0) {
      setLocationsLoaded(true);
    }
  }, [dbLocations]);

  // Update loading condition to check if locations are loaded
  if (isLoading || !locationsLoaded) {
    // Return null instead of showing a loading spinner
    return null;
  }
  
  // Handle case where there are no services selected at all
  if (!services || services.length === 0) {
    return (
      <div className="max-w-4xl mx-auto">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">
            Service Details
          </h1>
          <p className="text-gray-600">
            Configure the details for each service at your locations.
          </p>
        </div>
        
        <div className="p-6 bg-white rounded-lg shadow-sm text-center">
          <p className="text-gray-500 mb-4">No services have been selected. Please go back and select services first.</p>
          
          <button
            onClick={onBack}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Go Back to Select Services
          </button>
        </div>
      </div>
    );
  }
  
  // Handle case where there are no locations
  if (!dbLocations || dbLocations.length === 0) {
    return (
      <div className="max-w-4xl mx-auto">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">
            Service Details
          </h1>
          <p className="text-gray-600">
            Configure the details for each service at your locations.
          </p>
        </div>
        
        <div className="p-6 bg-white rounded-lg shadow-sm text-center">
          <p className="text-gray-500 mb-4">No locations have been added. Please go back and add locations first.</p>
          
          <button
            onClick={onBack}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Go Back to Add Locations
          </button>
        </div>
      </div>
    );
  }
  
  // Normal render when we have data
  return (
    <>
      {renderLocationServiceDetails()}
      <ApplyToAllModal />
    </>
  );
};

// Helper function to check if a service is the first one
const isFirstService = (serviceId) => {
  return orderedServices.length > 0 && 
         orderedServices[0].id.toString() === serviceId.toString();
};

export default ServiceDetailsManager; 