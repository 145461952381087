import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { getSupabaseClient, BUSINESS_TYPES } from '../../supabaseClient';
import { useBusinessType } from '../../context/BusinessContext';
import {
  Home,
  FileText,
  Settings,
  ChevronDown,
  Book,
  Cog,
  Puzzle,
  CircleHelp,
  LogOut
} from 'lucide-react';

const OnboardingSideNav = ({ currentStep, onSelectStep }) => {
  const { businessType } = useBusinessType();
  const supabase = getSupabaseClient(businessType);
  
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFullyExpanded, setIsFullyExpanded] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState('Knowledge');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [userData, setUserData] = useState(null);
  const [accountData, setAccountData] = useState(null);
  
  const location = useLocation();
  const navigate = useNavigate();
  const profileMenuRef = useRef(null);
  const hideMenuTimer = useRef(null);

  // Set default active state to Intro if on the intro page
  useEffect(() => {
    if (location.pathname === '/onboarding/intro') {
      onSelectStep && onSelectStep('Intro');
    }
  }, [location.pathname, onSelectStep]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setShowProfileMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      // Get the stored business type, check all possible storage keys
      const storedBusinessType = localStorage.getItem('business_type') || 
                               localStorage.getItem('businessType') || 
                               "laundromat"; // Fallback to laundromat

      const supabase = getSupabaseClient(storedBusinessType);

      // First check if we have a session
      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();
      
      if (sessionError) {
        console.error('Session error:', sessionError);
        // Try to handle session error gracefully without redirecting immediately
        localStorage.setItem('business_type', 'laundromat');
        return;
      }

      if (!session) {
        console.log('No session found, redirecting to login');
        // If no session, redirect to login
        navigate('/login');
        return;
      }

      // Get user data
      const {
        data: { user },
        error: userError,
      } = await supabase.auth.getUser();
      
      if (userError) {
        console.error('User error:', userError);
        return;
      }

      if (user) {
        setUserData(user);
        
        // Ensure business_type is saved in user metadata
        if (user.user_metadata && user.user_metadata.business_type) {
          localStorage.setItem('business_type', user.user_metadata.business_type);
        }

        // Get account data
        const { data: accountData, error: accountError } = await supabase
          .from('accounts')
          .select('*')
          .eq('id', user.id)
          .single();

        if (accountError) {
          console.error('Account error:', accountError);
          return;
        }
        
        setAccountData(accountData);
        
        // Update localStorage with the correct business type from the account data
        if (accountData.business_type) {
          localStorage.setItem('business_type', accountData.business_type);
          localStorage.setItem('businessType', accountData.business_type);
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      // Don't redirect on error, just log it
    }
  };

  const handleLogout = async () => {
    try {
      // First sign out from both supabase instances
      await Promise.all([
        getSupabaseClient(BUSINESS_TYPES.CLEANING).auth.signOut(),
        getSupabaseClient(BUSINESS_TYPES.LAUNDROMAT).auth.signOut(),
      ]);

      // Only after successful signout, clear storage and state
      localStorage.clear();

      // Force a full page refresh to /login
      window.location.replace('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      // Even on error, force a clean logout
      localStorage.clear();
      window.location.replace('/login');
    }
  };

  const getInitials = () => {
    return (
      accountData?.company_name
        ?.split(' ')
        .map((n) => n[0])
        .join('') || 'U'
    );
  };

  const handleMouseEnterProfile = () => {
    if (hideMenuTimer.current) {
      clearTimeout(hideMenuTimer.current);
      hideMenuTimer.current = null;
    }
    setShowProfileMenu(true);
  };

  const handleMouseLeaveProfile = () => {
    hideMenuTimer.current = setTimeout(() => {
      setShowProfileMenu(false);
    }, 200);
  };

  // Define base navigation structure for onboarding
  const onboardingNavItems = [
    {
      icon: Home,
      label: 'Intro',
      path: '/onboarding/intro',
      // Show active indicator when on the intro route
      active: location.pathname.includes('/onboarding/intro')
    },
    {
      icon: Book,
      label: 'Knowledge',
      submenu: [
        { label: 'Business Type', step: 1, path: 'business-type' },
        { label: 'Services', step: 2, path: 'services' },
        { label: 'Locations', step: 3, path: 'locations' },
        { label: 'Service Details', step: 4, path: 'service-details' },
        { label: 'Review', step: 5, path: 'review' }
      ],
      // Show active indicator when on any business-knowledge route
      active: location.pathname.includes('/business-knowledge')
    },
    {
      icon: Cog,
      label: 'Settings',
      path: '/onboarding/settings',
      // Add active indicator when on the workflows route
      active: location.pathname.includes('/workflows')
    },
    {
      icon: Puzzle,
      label: 'Integration',
      submenu: [
        { label: 'Notifications', step: 1, path: '/notifications' },
        { label: 'Call Forwarding', step: 2, path: '/integration' }
      ],
      // Add active indicator for integration route
      active: location.pathname.includes('/integration') || location.pathname.includes('/notifications') || location.pathname.includes('/call-forwarding')
    },
  ];

  // Helper to determine which submenu item is active
  const getActiveSubmenuStep = () => {
    if (!location.pathname.includes('/business-knowledge') && 
        !location.pathname.includes('/notifications') && 
        !location.pathname.includes('/call-forwarding')) return null;
    
    // Extract the last part of the path for determining active step
    const pathParts = location.pathname.split('/');
    const lastPart = pathParts[pathParts.length - 1];
    
    // Map path segments to step numbers
    const pathToStep = {
      // Knowledge submenu
      'business-type': 1,
      'services': 2,
      'locations': 3,
      'service-details': 4,
      'review': 5,
      // Integration submenu
      'notifications': 1,
      'call-forwarding': 2
    };
    
    return pathToStep[lastPart] || null;
  };
  
  // Set the current active step based on the URL path
  useEffect(() => {
    const activeStep = getActiveSubmenuStep();
    if (activeStep && onSelectStep) {
      onSelectStep(activeStep);
    }
  }, [location.pathname, onSelectStep]);

  const isSubmenuActive = (submenu) => {
    // When on intro page, no submenu should be active
    if (location.pathname.includes('/onboarding/intro')) {
      return false;
    }
    
    // When on workflows page, no submenu should be active
    if (location.pathname.includes('/workflows')) {
      return false;
    }
    
    // Show Knowledge submenu as active when on business-knowledge pages
    if (submenu === "Knowledge" && location.pathname.includes('business-knowledge')) {
      return true;
    }
    
    // Show Integration submenu as active when on notifications or call-forwarding pages
    if (submenu === "Integration" && 
        (location.pathname.includes('/notifications') || 
         location.pathname.includes('/call-forwarding'))) {
      return true;
    }
    
    return false;
  };
  
  // Add effect to close Knowledge submenu when on workflows page
  useEffect(() => {
    if (location.pathname.includes('/workflows') && openSubmenu === 'Knowledge') {
      setOpenSubmenu('');
    }
    
    // Auto-expand Knowledge submenu when on business-knowledge routes
    if (location.pathname.includes('/business-knowledge') && openSubmenu !== 'Knowledge') {
      setOpenSubmenu('Knowledge');
    }
  }, [location.pathname]);

  // Animation variants
  const sideNavVariants = {
    expanded: { width: 256 },
    collapsed: { width: 64 },
  };

  return (
    <>
    {/* Desktop Sidebar - Vertical */}
    {!isMobile && (
      <motion.div
        initial={false}
        animate={isExpanded ? 'expanded' : 'collapsed'}
        variants={sideNavVariants}
        onMouseEnter={() => setIsExpanded(true)}
        onMouseLeave={() => setIsExpanded(false)}
        onTransitionEnd={() => {
          setIsFullyExpanded(isExpanded);
        }}
        className="fixed left-0 top-0 h-screen bg-white shadow-lg transition-all duration-300 ease-in-out z-[9999] flex flex-col"
      >
        {/* Logo Section */}
        <div className="h-16 border-b border-gray-100 flex flex-col justify-center px-4 overflow-hidden">
          <span className={`text-xl font-semibold text-gray-800 whitespace-nowrap px-3 ${
            isExpanded ? 'block' : 'hidden'
          }`}>
            Onboarding
          </span>
          <span className={`text-xs text-gray-400 whitespace-nowrap px-3 mt-1 tracking-widest uppercase ${
            isExpanded ? 'block' : 'hidden'
          }`}>
            {/* by the savants */}
          </span>
        </div>

        {/* Navigation Items */}
        <div className="flex-1 overflow-y-auto px-2 py-4 space-y-2 custom-scrollbar">
          {onboardingNavItems.map((item) => (
            <div key={item.label} className="relative">
              {item.submenu ? (
                // Submenu Item
                <div>
                  <button
                    onClick={() =>
                      setOpenSubmenu(
                        openSubmenu === item.label ? '' : item.label
                      )
                    }
                    className={`w-full h-9 flex items-center px-3 rounded-lg transition-colors
                      ${
                        isSubmenuActive(item.label)
                          ? 'bg-gray-50 text-gray-800'
                          : 'text-gray-600 hover:bg-gray-50'
                      }
                    `}
                  >
                    <div className="w-5 h-5 relative">
                      <item.icon className="w-5 h-5" />
                      {item.active && (
                        <div className="absolute -top-1 -right-1 w-2 h-2 bg-blue-500 rounded-full"></div>
                      )}
                    </div>
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{ opacity: isExpanded ? 1 : 0 }}
                      transition={{ duration: 0.2 }}
                      className={`ml-3 whitespace-nowrap truncate ${
                        isExpanded ? 'block' : 'hidden'
                      }`}
                    >
                      {item.label}
                    </motion.span>
                    {isExpanded && (
                      <ChevronDown
                        className={`w-4 h-4 transition-transform ml-auto ${
                          openSubmenu === item.label ? 'rotate-180' : ''
                        }`}
                      />
                    )}
                  </button>
                  <AnimatePresence>
                    {(isExpanded && openSubmenu === item.label) && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className={`overflow-hidden ${isExpanded ? 'ml-8 mt-1' : 'w-0 h-0 p-0'}`}
                      >
                        {item.submenu.map((subItem) => (
                          <div 
                            key={subItem.label}
                            onClick={() => onSelectStep(subItem.step)}
                            className={`
                              w-full flex items-center px-3 py-2 rounded-lg text-sm relative
                              ${
                                // Highlight the submenu item when its step matches current step
                                // OR when the URL path contains the submenu path
                                (currentStep === subItem.step || 
                                 location.pathname.includes(subItem.path)) && 
                                !location.pathname.includes('/onboarding/intro') 
                                ? 'bg-gray-50 text-gray-800' 
                                : 'text-gray-600 hover:bg-gray-50'
                              }
                            `}
                          >
                            <span className="whitespace-nowrap truncate">
                              {subItem.label}
                            </span>
                            
                            {/* Progress indication for steps - hide on intro page */}
                            {
                              (currentStep === subItem.step || 
                               location.pathname.includes(subItem.path)) && 
                              !location.pathname.includes('/onboarding/intro') && (
                                <div className="w-1.5 h-1.5 rounded-full bg-blue-600 ml-auto"></div>
                              )
                            }
                          </div>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ) : (
              // Regular Item
              <NavLink
                to={item.path}
                className={({ isActive }) => `w-full h-9 flex items-center px-3 rounded-lg transition-colors
                  ${
                    // Only highlight Intro if we're exactly on the intro path
                    (item.path.includes('/intro') && isActive && location.pathname.includes('/intro')) ||
                    // Highlight Settings when on workflows page
                    (item.label === 'Settings' && location.pathname.includes('/workflows')) ||
                    // Regular highlight for other items
                    (isActive && !item.path.includes('/intro'))
                      ? 'bg-gray-50 text-gray-800' 
                      : 'text-gray-600 hover:bg-gray-50'
                  }
                `}
              >
                <div className="w-5 h-5 relative">
                  <item.icon className="w-5 h-5" />
                  {item.active && (
                    <div className="absolute -top-1 -right-1 w-2 h-2 bg-blue-500 rounded-full"></div>
                  )}
                </div>
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isExpanded ? 1 : 0 }}
                  transition={{ duration: 0.2 }}
                  className={`ml-3 whitespace-nowrap truncate ${
                    isExpanded ? 'block' : 'hidden'
                  }`}
                >
                  {item.label}
                </motion.span>
              </NavLink>
              )}
            </div>
          ))}
        </div>

        {/* Profile Section */}
        <div 
        className="relative p-4 border-t border-gray-100 bg-white"
        onMouseEnter={handleMouseEnterProfile}
        onMouseLeave={handleMouseLeaveProfile}
        >
        {/* Profile Menu */}
        <motion.div
          ref={profileMenuRef}
          style={{ 
            opacity: isExpanded && showProfileMenu ? 1 : 0,
            transform: `translateY(${isExpanded && showProfileMenu ? 0 : 10}px)`,
            pointerEvents: isExpanded && showProfileMenu ? 'auto' : 'none'
          }}
          transition={{ duration: 0.2 }}
          className="absolute left-0 bottom-full mb-2 w-full bg-white rounded-lg shadow-lg border border-gray-100 z-10"
        >
          <div className="p-2 space-y-1">
            <button
              onClick={handleLogout}
              className="w-full flex items-center px-3 py-2 text-sm text-red-600 hover:bg-red-50 rounded-lg"
            >
              <LogOut className="w-4 h-4 flex-shrink-0" />
              <span className="ml-2 truncate">Logout</span>
            </button>
          </div>
        </motion.div>

        {/* Profile Content */}
        <div className="flex items-center cursor-pointer">
          <div
            className="w-9 h-9 rounded-full bg-gray-50 flex items-center justify-center
                    text-gray-800 font-medium flex-shrink-0"
          >
            {getInitials()}
          </div>
          <motion.div
            initial="hidden"
            animate={isExpanded ? "visible" : "hidden"}
            variants={{
              visible: { 
                width: 'auto',
                marginLeft: 12,
                opacity: 1,
                transition: {
                  duration: 0.2,
                }
              },
              hidden: { 
                width: 0,
                marginLeft: 0,
                opacity: 0,
                transition: {
                  duration: 0.2,
                }
              }
            }}
            className="overflow-hidden"
          >
            <p className="text-sm font-medium text-gray-700 truncate whitespace-nowrap">
              {accountData?.company_name || 'Company'}
            </p>
            <p className="text-xs text-gray-500 truncate whitespace-nowrap">
              {userData?.email || 'user@example.com'}
            </p>
          </motion.div>
        </div>
        </div>
      </motion.div>
    )}

    {/* Mobile Navigation - Horizontal */}
    {isMobile && (
      <div className="fixed top-0 left-0 right-0 bg-white shadow-md z-[9999]">
        {/* Mobile Header */}
        <div className="flex justify-between items-center px-4 py-3 border-b border-gray-100">
          <div className="flex items-center">
            <span className="text-lg font-semibold text-gray-800">Onboarding</span>
            <span className="text-xs text-gray-400 ml-2 tracking-widest uppercase">{/* by the savants */}</span>
          </div>
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="p-1 rounded-md bg-gray-50"
          >
            {isMobileMenuOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-600">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-600">
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="overflow-hidden border-b border-gray-100"
            >
              {/* Knowledge Submenu - Always show on mobile */}
              <div className="px-4 py-2">
                <div className="mb-2 font-medium text-gray-800">
                  <div className="flex items-center py-2">
                    <Book className="h-5 w-5 mr-2" />
                    <span>Knowledge</span>
                  </div>
                </div>
                <div className="flex overflow-x-auto pb-2 no-scrollbar">
                  {onboardingNavItems
                    .find(item => item.label === 'Knowledge')
                    ?.submenu.map((subItem) => (
                      <button
                        key={subItem.label}
                        onClick={() => {
                          onSelectStep(subItem.step);
                          setIsMobileMenuOpen(false);
                        }}
                        className={`
                          mr-3 px-4 py-2 rounded-full text-sm whitespace-nowrap flex items-center
                          ${currentStep === subItem.step 
                            ? 'bg-blue-600 text-white' 
                            : 'bg-gray-100 text-gray-800'
                          }
                        `}
                      >
                        {subItem.label}
                        {currentStep === subItem.step && (
                          <div className="h-2 w-2 rounded-full bg-white ml-2"></div>
                        )}
                      </button>
                    ))
                  }
                </div>
              </div>

              {/* Other Navigation Items */}
              <div className="grid grid-cols-3 gap-2 p-4">
                {onboardingNavItems
                  .filter(item => item.label !== 'Knowledge')
                  .map((item) => (
                    <NavLink
                      key={item.label}
                      to={item.path}
                      onClick={() => setIsMobileMenuOpen(false)}
                      className={({ isActive }) => `
                        flex flex-col items-center justify-center p-3 rounded-lg text-center
                        ${isActive ? 'bg-gray-50 text-gray-800' : 'text-gray-600'}
                      `}
                    >
                      <div className="w-6 h-6 mb-1">
                        <item.icon className="w-6 h-6" />
                      </div>
                      <span className="text-xs font-medium">{item.label}</span>
                    </NavLink>
                  ))
                }
              </div>

              {/* Mobile Profile Section */}
              <div className="border-t border-gray-100 p-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-9 h-9 rounded-full bg-gray-50 flex items-center justify-center text-gray-800 font-medium">
                      {getInitials()}
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-700">
                        {accountData?.company_name || 'Company'}
                      </p>
                      <p className="text-xs text-gray-500">
                        {userData?.email || 'user@example.com'}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={handleLogout}
                    className="flex items-center px-3 py-2 text-sm text-red-600 bg-red-50 rounded-lg"
                  >
                    <LogOut className="w-4 h-4 mr-2" />
                    <span>Logout</span>
                  </button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    )}

    {/* Mobile Backdrop when menu is open */}
    {isMobile && isMobileMenuOpen && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={() => setIsMobileMenuOpen(false)}
        className="fixed inset-0 bg-black/50 z-[50] mt-[97px]"
      />
    )}

    {/* Add mobile padding to the page when mobile navigation is present */}
    {isMobile && <div className="h-[60px]" />}
    </>
  );
};

export default OnboardingSideNav; 