// src/utils/mapUtils.js

// Using the provided API key
export const GOOGLE_MAPS_API_KEY = "AIzaSyDgzpOCpuZhMSkVWUNgmRpsKpYMA8O4Kyc";

// Global flag to track if Google Maps API is already loaded
let isLoading = false;
let isLoaded = false;

// Existing static map function
export const generateStaticMapUrl = ({ latitude, longitude, width = 400, height = 200, zoom = 14 }) => {
  const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap';
  const marker = `markers=color:red%7C${latitude},${longitude}`;
  const mapStyle = 'style=feature:poi%7Cvisibility:off'; // Hide POI markers
  return `${baseUrl}?center=${latitude},${longitude}&zoom=${zoom}&size=${width}x${height}&scale=2&${marker}&${mapStyle}&key=${GOOGLE_MAPS_API_KEY}`;
};

// Existing address formatting function
export const formatAddress = (location) => {
  const parts = [
    location.address,
    location.city,
    location.state,
    location.zipcode
  ].filter(Boolean);
  return parts.join(', ');
};

// Improved Google Maps script loading function with singleton pattern
export const loadGoogleMapsScript = () => {
  return new Promise((resolve, reject) => {
    // If script is already loaded, resolve immediately
    if (window.google && window.google.maps) {
      isLoaded = true;
      resolve(window.google);
      return;
    }

    // If script is loading, wait for it
    if (isLoading) {
      const checkLoaded = setInterval(() => {
        if (window.google && window.google.maps) {
          clearInterval(checkLoaded);
          isLoaded = true;
          resolve(window.google);
        }
      }, 100);
      return;
    }

    // Remove any existing Google Maps scripts to prevent duplicates
    const existingScripts = document.querySelectorAll('script[src*="maps.googleapis.com"]');
    existingScripts.forEach(script => script.remove());

    isLoading = true;

    // Disable analytics
    window.gm_global_options = {
      disableStats: true
    };

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&loading=async`;
    script.async = true;
    script.defer = true;

    script.addEventListener('load', () => {
      console.log("Google Maps API loaded successfully");
      isLoaded = true;
      isLoading = false;
      resolve(window.google);
    });

    script.addEventListener('error', (error) => {
      console.error("Error loading Google Maps API:", error);
      isLoading = false;
      reject(error);
    });

    document.head.appendChild(script);
  });
};

// New address component formatting function (for Places Autocomplete)
export const formatAddressFromPlace = (place) => {
  let streetNumber = '';
  let streetName = '';
  let city = '';
  let state = '';
  let zipcode = '';

  place.address_components.forEach(component => {
    if (component.types.includes('street_number')) {
      streetNumber = component.long_name;
    }
    if (component.types.includes('route')) {
      streetName = component.long_name
        .replace(/\bN\b/g, 'North')
        .replace(/\bS\b/g, 'South')
        .replace(/\bE\b/g, 'East')
        .replace(/\bW\b/g, 'West')
        .replace(/\bSt\b/g, 'Street')
        .replace(/\bAve\b/g, 'Avenue')
        .replace(/\bRd\b/g, 'Road')
        .replace(/\bDr\b/g, 'Drive')
        .replace(/\bBlvd\b/g, 'Boulevard');
    }
    if (component.types.includes('locality')) {
      city = component.long_name;
    }
    if (component.types.includes('administrative_area_level_1')) {
      state = component.short_name;
    }
    if (component.types.includes('postal_code')) {
      zipcode = component.long_name;
    }
  });

  const fullAddress = `${streetNumber} ${streetName}, ${city}, ${state} ${zipcode}, USA`.trim();
  const displayName = city 
    ? `${streetNumber} ${streetName} in ${city}`
    : `${streetNumber} ${streetName}`;

  return { fullAddress, displayName, city };
};