import React, { createContext, useContext, useState, useEffect } from 'react';
import { getSupabaseClient, BUSINESS_TYPES } from '../supabaseClient';

const BusinessContext = createContext();

export const BusinessProvider = ({ children }) => {
  const [businessType, setBusinessType] = useState(() => {
    // Initialize from localStorage, checking both possible keys
    return localStorage.getItem('business_type') || 
           localStorage.getItem('businessType') || 
           'laundromat'; // Default to laundromat
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializeBusinessType = async () => {
      try {
        // First check localStorage (both possible keys)
        const storedType = localStorage.getItem('business_type') || 
                          localStorage.getItem('businessType');
        
        if (storedType) {
          setBusinessType(storedType);
          // Ensure both localStorage keys are set
          localStorage.setItem('business_type', storedType);
          localStorage.setItem('businessType', storedType);
          setLoading(false);
          return;
        }

        // If not in localStorage, try to get from user session
        const client = getSupabaseClient('laundromat'); // Default to laundromat
        
        try {
          const { data: { user } } = await client.auth.getUser();
          
          if (user && user.user_metadata && user.user_metadata.business_type) {
            // Get from user metadata
            const type = user.user_metadata.business_type;
            setBusinessType(type);
            localStorage.setItem('business_type', type);
            localStorage.setItem('businessType', type);
            setLoading(false);
            return;
          }
          
          if (user) {
            // Try to get from accounts table
            const { data } = await client
              .from('accounts')
              .select('business_type')
              .eq('id', user.id)
              .single();
              
            if (data && data.business_type) {
              setBusinessType(data.business_type);
              localStorage.setItem('business_type', data.business_type);
              localStorage.setItem('businessType', data.business_type);
            } else {
              // Default to laundromat if nothing found
              setBusinessType('laundromat');
              localStorage.setItem('business_type', 'laundromat');
              localStorage.setItem('businessType', 'laundromat');
            }
          }
        } catch (err) {
          console.error('Error fetching user or account data:', err);
          // Default to laundromat on error
          setBusinessType('laundromat');
          localStorage.setItem('business_type', 'laundromat');
          localStorage.setItem('businessType', 'laundromat');
        }
      } catch (err) {
        console.error('Error in business type initialization:', err);
        setError(err.message);
        // Default to laundromat on error
        setBusinessType('laundromat');
        localStorage.setItem('business_type', 'laundromat');
        localStorage.setItem('businessType', 'laundromat');
      } finally {
        setLoading(false);
      }
    };

    initializeBusinessType();
  }, []);

  // Update localStorage whenever businessType changes
  useEffect(() => {
    if (businessType) {
      localStorage.setItem('business_type', businessType);
      localStorage.setItem('businessType', businessType);
    }
  }, [businessType]);

  const value = {
    businessType,
    setBusinessType,  // Expose this to allow updates
    loading,
    error
  };

  return (
    <BusinessContext.Provider value={value}>
      {children}
    </BusinessContext.Provider>
  );
};

export const useBusinessType = () => {
  const context = useContext(BusinessContext);
  if (!context) {
    throw new Error('useBusinessType must be used within a BusinessProvider');
  }
  return context;
};