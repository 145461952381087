import React, { useState, useRef, useEffect, useCallback, useLayoutEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { cn } from "../../utils";

/**
 * ScrollableTabs component - Renders a horizontally scrollable tab interface with overflow navigation
 * 
 * @param {Object[]} tabs - Array of tab objects { id, label, icon, onClick, isActive }
 * @param {string} className - Optional additional className for the container
 * @param {React.ReactNode} renderTab - Optional custom tab renderer function
 */
const ScrollableTabs = ({ 
  tabs = [], 
  className,
  renderTab
}) => {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const tabsContainerRef = useRef(null);
  
  // Default tab renderer if none provided
  const defaultRenderTab = (tab) => (
    <button
      key={tab.id}
      className={cn(
        "flex items-center gap-2 px-3 py-2 rounded-md whitespace-nowrap transition-colors relative",
        tab.isActive 
          ? "bg-gray-900 text-white" 
          : "bg-gray-100 text-gray-600 hover:bg-gray-200",
        "shrink-0"
      )}
      onClick={tab.onClick}
    >
      {tab.icon && <tab.icon className="w-4 h-4" />}
      {tab.label && <span className="text-sm font-medium">{tab.label}</span>}
      {tab.indicator && tab.indicator}
    </button>
  );
  
  const checkForOverflow = useCallback(() => {
    if (tabsContainerRef.current) {
      const container = tabsContainerRef.current;
      const { scrollLeft, scrollWidth, clientWidth } = container;
      
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth - 5); // 5px buffer for rounding errors
    }
  }, []);
  
  // FIX: Use a proper dependency array and run the check after a layout effect
  useLayoutEffect(() => {
    // This runs after DOM updates but before browser paint
    checkForOverflow();
    
    // Also listen for window resize to recheck
    window.addEventListener('resize', checkForOverflow);
    return () => window.removeEventListener('resize', checkForOverflow);
  }, [checkForOverflow, tabs.length]); // Only rerun when tabs change
  
  // Scroll left
  const scrollLeft = () => {
    if (tabsContainerRef.current) {
      const container = tabsContainerRef.current;
      container.scrollBy({ left: -200, behavior: 'smooth' });
      setTimeout(checkForOverflow, 300);
    }
  };
  
  // Scroll right
  const scrollRight = () => {
    if (tabsContainerRef.current) {
      const container = tabsContainerRef.current;
      container.scrollBy({ left: 200, behavior: 'smooth' });
      setTimeout(checkForOverflow, 300);
    }
  };
  
  return (
    <div className={cn("relative flex items-center", className)}>
      {showLeftArrow && (
        <button 
          onClick={scrollLeft}
          className="absolute left-0 z-10 flex items-center justify-center w-8 h-8 bg-white rounded-full shadow-md text-gray-600 hover:text-gray-900"
          aria-label="Scroll left"
        >
          <ChevronLeft className="w-5 h-5" />
        </button>
      )}
      
      <div 
        ref={tabsContainerRef}
        className="flex overflow-x-auto scrollbar-hide py-2 px-1 gap-2 items-center max-w-full"
        onScroll={checkForOverflow}
        style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
      >
        {tabs.map(tab => (
          renderTab ? renderTab(tab) : defaultRenderTab(tab)
        ))}
      </div>
      
      {showRightArrow && (
        <button 
          onClick={scrollRight}
          className="absolute right-0 z-10 flex items-center justify-center w-8 h-8 bg-white rounded-full shadow-md text-gray-600 hover:text-gray-900"
          aria-label="Scroll right"
        >
          <ChevronRight className="w-5 h-5" />
        </button>
      )}
    </div>
  );
};

export default ScrollableTabs; 