import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  DollarSign, 
  Clock, 
  Calendar, 
  MapPin, 
  Truck, 
  Info, 
  Trash,
  Plus,
  Settings,
  ChevronDown,
  ChevronUp,
  Edit,
  X,
  Scale,
  Percent,
  CheckCircle,
  ShoppingBag,
  AlertCircle
} from 'lucide-react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Switch } from '../ui/switch';
import { Textarea } from '../ui/text-area';
import { cn } from '../../lib/utils';
import ScrollableTabs from './ScrollableTabs';
import { BlackSwitch } from './SimpleWDFDropOff';
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle, 
  DialogFooter,
  DialogClose
} from '../ui/dialog';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '../ui/collapsible';
import { 
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent
} from '../ui/accordion';
import ExtrasModal from './ExtrasModal';
import { getSupabaseClient } from '../../supabaseClient';

/**
 * A simplified Pickup & Delivery component for Dry Cleaning service
 */
const SimpleDCPickupDelivery = ({ data = {}, onChange, locationData = {} }) => {
  // Set default enabled value to true
  const [pudData, setPudData] = useState(() => {
    // If empty data is provided, start with defaults
    if (!data || Object.keys(data).length === 0) {
      return {
        enabled: true,
        pricing: {
          baseRate: '',
          minimumOrder: '',
          deliveryFee: ''
        }
      };
    }
    return data;
  });

  // Track active zone for pricing/scheduling
  const [activeZoneForPricing, setActiveZoneForPricing] = useState(null);
  const [activeZoneForSchedule, setActiveZoneForSchedule] = useState(null);
  // Track active zone for turnaround times
  const [activeZoneId, setActiveZoneId] = useState(null);

  const [activeTab, setActiveTab] = useState('general');
  const [zipcode, setZipcode] = useState('');
  
  // Zone modal state
  const [isAddZoneOpen, setIsAddZoneOpen] = useState(false);
  const [zoneToEdit, setZoneToEdit] = useState(null);
  const [zoneFormData, setZoneFormData] = useState({
    name: '',
    zipcodes: ''
  });
  
  const [rawInputValues, setRawInputValues] = useState({
    regularPerPound: '',
    sameDayPrice: '',
    sameDayTurnaroundHours: '',
    deliveryFee: '',
    minimumAmount: '',
    zoneRegularPerPound: {},
    zoneSameDayPrice: {},
    zoneSameDayTurnaroundHours: {},
    zoneDeliveryFee: {},
    zoneMinimumOrder: {},
    weeklyRate: '',
    biweeklyRate: '',
    monthlyRate: ''
  });

  // For time window management
  const [newWindow, setNewWindow] = useState({
    day: '',
    startTime: '',
    endTime: ''
  });

  // Required fields tracking
  const [requiredFieldsStatus, setRequiredFieldsStatus] = useState({
    general: true,
    serviceAreas: false,
    pricing: false,
    turnaround: false,
    schedule: false,
    instructions: false,
    discounts: true, // Conditionally required based on toggle
    extras: true     // Conditionally required based on toggle
  });

  // State for zone modal
  const [showZoneModal, setShowZoneModal] = useState(false);
  const [editingZone, setEditingZone] = useState(null);
  // State for delete confirmation modal
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [zoneToDelete, setZoneToDelete] = useState(null);

  // State for extras modal
  const [extrasModalOpen, setExtrasModalOpen] = useState(false);
  const [extrasModalType, setExtrasModalType] = useState(null);
  const [priceListModalOpen, setPriceListModalOpen] = useState(false);

  // Previous metadata ref to prevent unnecessary database updates
  const prevMetadataRef = useRef(null);
  
  // Track whether component is mounted and ready
  const isReady = useRef(false);
  
  // Debounce function to prevent too many database calls
  const debounce = (func, delay) => {
    let timer;
    return function(...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };
  
  // Function to update the database with the latest metadata
  const updateDatabase = async (metadata) => {
    try {
      // Only proceed if we have locationData with an id
      if (!locationData || !locationData.id) {
        console.warn('Cannot update database: missing location ID');
        return;
      }
      
      // Get the Supabase client - use business type from location data if available
      const businessType = locationData?.business_type || 'laundromat';
      const supabase = getSupabaseClient(businessType);
      
      // Check if the record exists
      const { data: existingSettings, error: fetchError } = await supabase
        .from('vapi_location_settings')
        .select('id, metadata')
        .eq('id', locationData.id)
        .maybeSingle();
      
      if (fetchError) {
        console.error('Error checking location settings:', fetchError);
        return;
      }
      
      // Prepare result variable
      let result;
      
      // If record exists, update it
      if (existingSettings) {
        // Create a merged metadata object that preserves other services
        const existingMetadata = existingSettings.metadata || {};
        const mergedMetadata = {
          ...existingMetadata,
          services: {
            ...(existingMetadata.services || {}),
            "3": {
              ...(existingMetadata.services?.["3"] || {}),
              ...metadata.services["3"]
            }
          }
        };
        
        // Update the record
        result = await supabase
          .from('vapi_location_settings')
          .update({ metadata: mergedMetadata })
          .eq('id', locationData.id);
      } else {
        // Insert a new record
        result = await supabase
          .from('vapi_location_settings')
          .insert({ 
            id: locationData.id,
            metadata: metadata
          });
      }
      
      // Log results
      if (result?.error) {
        console.error('Error updating location settings:', result.error);
      } else {
        
      }
    } catch (error) {
      console.error('Error in database update:', error);
    }
  };
  
  // Create a debounced version of updateDatabase
  const debouncedUpdateDatabase = useCallback(
    debounce((metadata) => {
      if (locationData?.id) {
        updateDatabase(metadata).catch(err => {
          console.error('Error in debouncedUpdateDatabase:', err);
        });
      }
    }, 1000),
    [locationData]
  );

  // Function to transform the component state to metadata format
const transformToMetadata = (data) => {
  // Start with an empty metadata object
  const metadata = {};
  
  // General information
  metadata['enabled'] = data.enabled !== false;
  
  // Handle notes from either top-level or other.notes
  if (data.notes || data.other?.notes) {
    metadata['notes'] = data.notes || data.other?.notes || '';
  }
  
  // Add service areas to metadata
  if (data.serviceAreas) {
    metadata['service_areas'] = {
      enabled: data.serviceAreas.enabled !== false,
      zipcodes: data.serviceAreas.zipcodes || []
    };
    
    // Only include zone-specific data when there are multiple zones
    const zones = data.serviceAreas.zones || [];
    if (zones.length > 0) {
      metadata['service_areas']['zones'] = zones.map(zone => ({
        id: zone.id,
        name: zone.name,
        zipcodes: zone.zipcodes,
        
        // Only include zone-specific settings if there are multiple zones
        ...(zones.length > 1 && zone.pricing ? {
          pricing: {
            delivery_fee: zone.pricing.deliveryFee,
            minimum_order: zone.pricing.minimumOrder
          }
        } : {}),
        
        ...(zones.length > 1 && zone.schedule ? {
          schedule: {
            timeWindows: zone.schedule.timeWindows
          }
        } : {}),
        
        ...(zones.length > 1 && zone.turnaround ? {
          turnaround: {
            standard_hours: zone.turnaround.standardHours,
            standard_days: zone.turnaround.standardDays
          }
        } : {})
      }));
    }
  }
  
  // Delivery settings
  if (data.delivery) {
    metadata['delivery'] = {
      min_lead_time: data.delivery.minLeadTime,
      max_lead_time: data.delivery.maxLeadTime,
      auto_assign: data.delivery.autoAssign,
      fee: data.delivery.fee
    };
  }
  
  // Pricing data
  if (data.pricing) {
    metadata['pricing'] = {};
    
    // Items list
    if (data.pricing.items && data.pricing.items.length > 0) {
      metadata['pricing']['items'] = data.pricing.items;
    }
    
    // Minimum order
    if (data.pricing.minimumOrder) {
      metadata['pricing']['minimum_order'] = {
        enabled: data.pricing.minimumOrder.enabled !== false,
        amount: data.pricing.minimumOrder.amount
      };
    }
    
    // Delivery fee
    if (data.pricing.deliveryFee) {
      metadata['pricing']['delivery_fee'] = data.pricing.deliveryFee;
    }
  }
  
  // Turnaround data
  if (data.turnaround) {
    metadata['turnaround'] = {};
    
    if (data.turnaround.standardHours) {
      metadata['turnaround']['standard_hours'] = data.turnaround.standardHours;
    }
    
    // Check for both standard_days and standardDays properties
    const standardDays = data.turnaround.standard_days !== undefined ? 
                         data.turnaround.standard_days : 
                         data.turnaround.standardDays;
    
    // Only set if it's not undefined
    if (standardDays !== undefined) {
      // Ensure it's not empty string - convert empty string to null
      metadata['turnaround']['standard_days'] = 
        standardDays === '' ? null : standardDays;
      
      console.log(`transformToMetadata: Setting standard_days to ${metadata['turnaround']['standard_days']}`);
    }
    
    if (data.turnaround.expressEnabled) {
      metadata['turnaround']['express_enabled'] = true;
      metadata['turnaround']['express_hours'] = data.turnaround.expressHours;
      metadata['turnaround']['express_fee'] = data.turnaround.expressFee;
    }
  }
  
  // Schedule data
  if (data.schedule && data.schedule.timeWindows) {
    metadata['schedule'] = metadata['schedule'] || {};
    metadata['schedule']['mode'] = data.schedule.mode || 'same_every_day';
    metadata['schedule']['time_windows'] = {};
    
    Object.entries(data.schedule.timeWindows).forEach(([day, windows]) => {
      if (windows && windows.length > 0) {
        metadata['schedule']['time_windows'][day.toLowerCase()] = windows.map(window => ({
          start_time: window.startTime,
          end_time: window.endTime
        }));
      }
    });
  }
  
  // Discounts
  if (data.discounts) {
    metadata['discounts'] = {
      enabled: data.discounts.enabled !== false
    };
    
    if (data.discounts.enabled && data.discounts.recurring) {
      metadata['discounts']['recurring'] = {};
      const recurring = data.discounts.recurring;
      
      // Weekly
      if (recurring.weekly && recurring.weekly.enabled) {
        metadata['discounts']['recurring']['weekly'] = {
          enabled: true,
          rate: recurring.weekly.rate
        };
      }
      
      // Biweekly
      if (recurring.biweekly && recurring.biweekly.enabled) {
        metadata['discounts']['recurring']['biweekly'] = {
          enabled: true,
          rate: recurring.biweekly.rate
        };
      }
      
      // Monthly
      if (recurring.monthly && recurring.monthly.enabled) {
        metadata['discounts']['recurring']['monthly'] = {
          enabled: true,
          rate: recurring.monthly.rate
        };
      }
    }
  }
  
  // Extras
  if (data.extras?.addons || data.addons) {
    metadata['extras'] = {};
    
    // Use either extras.addons or addons directly (for compatibility)
    const addonsData = data.extras?.addons || data.addons;
    if (addonsData) {
      metadata['extras']['addons'] = {
        enabled: addonsData.enabled !== false
      };
      
      if (addonsData.items && addonsData.items.length > 0) {
        metadata['extras']['addons']['items'] = addonsData.items.map(item => ({
          name: item.name,
          price: item.price,
          unit: item.unit || 'item'
        }));
      }
    }
  }
  
  // Instructions data
  if (data.instructions) {
    console.log("Saving instructions to DB:", data.instructions);
    metadata['instructions'] = {
      first_pickup: data.instructions.firstPickup || ''
    };
    console.log('Adding instructions to metadata:', metadata['instructions']);
  }
  
  return metadata;
};

  // Function to build component state from metadata format
  const buildStateFromMetadata = (metadata, defaultData) => {
    if (!metadata) return defaultData;
    
    const result = {
      ...defaultData,
      enabled: metadata.enabled !== false,
      notes: metadata.notes || '',
      
      // General delivery settings
      ...(metadata.delivery ? {
        delivery: {
          minLeadTime: metadata.delivery.min_lead_time,
          maxLeadTime: metadata.delivery.max_lead_time,
          autoAssign: metadata.delivery.auto_assign,
          fee: metadata.delivery.fee
        }
      } : {}),
      
      // Add instructions - map from database format
      ...(metadata.instructions ? {
        instructions: {
          firstPickup: metadata.instructions.first_pickup || ''
        }
      } : {}),
      
      // Service areas - properly map the structure
      ...(metadata.service_areas ? {
        serviceAreas: {
          enabled: metadata.service_areas.enabled !== false,
          zipcodes: metadata.service_areas.zipcodes || [],
          zones: metadata.service_areas.zones?.map(zone => ({
            id: zone.id || String(Date.now()),
            name: zone.name || '',
            zipcodes: zone.zipcodes || [],
            fee: zone.fee,
            
            // Map zone-specific pricing, schedule, and turnaround if available
            ...(zone.pricing ? {
              pricing: {
                ...zone.pricing,
                // Handle minimum order specifically
                ...(zone.pricing.minimumOrder ? {
                  minimumOrder: zone.pricing.minimumOrder
                } : {})
              },
              customPricing: true
            } : {}),
            
            ...(zone.schedule ? {
              schedule: {
                ...zone.schedule,
                // Ensure timeWindows has the correct structure
                timeWindows: zone.schedule.timeWindows || {}
              },
              customSchedule: true
            } : {}),
            
            ...(zone.turnaround ? {
              turnaround: {
                ...zone.turnaround,
                standardHours: zone.turnaround.standardHours,
                standardDays: zone.turnaround.standardDays || null
              },
              customTurnaround: true
            } : {})
          })) || []
        }
      } : { serviceAreas: { enabled: false, zipcodes: [], zones: [] } }),
      
      // Pricing info
      ...(metadata.pricing ? {
        pricing: {
          // Convert items if available
          items: metadata.pricing.items || [],
          
          // Minimum order
          ...(metadata.pricing.minimum_order ? {
            minimumOrder: {
              enabled: metadata.pricing.minimum_order.enabled !== false,
              amount: metadata.pricing.minimum_order.amount
            }
          } : {}),
          
          // Map delivery fee if available in pricing
          deliveryFee: metadata.pricing.delivery_fee
        }
      } : {}),
      
      // Schedule
      ...(metadata.schedule ? {
        schedule: {
          mode: metadata.schedule.mode || 'same_every_day',
          
          // Map time windows
          timeWindows: metadata.schedule.time_windows ? 
            Object.entries(metadata.schedule.time_windows).reduce((acc, [day, windows]) => {
              // Capitalize the first letter of the day
              const capitalizedDay = day.charAt(0).toUpperCase() + day.slice(1);
              acc[capitalizedDay] = windows.map(window => ({
                startTime: window.start_time,
                endTime: window.end_time
              }));
              return acc;
            }, {}) : {}
        }
      } : {}),
      
      // Turnaround info
      ...(metadata.turnaround ? {
        turnaround: {
          standardHours: metadata.turnaround.standard_hours,
          standardDays: metadata.turnaround.standard_days !== undefined ? metadata.turnaround.standard_days : null,
          
          // Express turnaround
          expressEnabled: metadata.turnaround.express_enabled,
          expressHours: metadata.turnaround.express_hours,
          expressFee: metadata.turnaround.express_fee
        }
      } : {}),
      
      // Discounts
      ...(metadata.discounts ? {
        discounts: {
          enabled: metadata.discounts.enabled !== false,
          
          ...(metadata.discounts.recurring ? {
            recurring: {
              // Weekly discount
              ...(metadata.discounts.recurring.weekly ? {
                weekly: {
                  enabled: metadata.discounts.recurring.weekly.enabled !== false,
                  rate: metadata.discounts.recurring.weekly.rate
                }
              } : {}),
              
              // Biweekly discount
              ...(metadata.discounts.recurring.biweekly ? {
                biweekly: {
                  enabled: metadata.discounts.recurring.biweekly.enabled !== false,
                  rate: metadata.discounts.recurring.biweekly.rate
                }
              } : {}),
              
              // Monthly discount
              ...(metadata.discounts.recurring.monthly ? {
                monthly: {
                  enabled: metadata.discounts.recurring.monthly.enabled !== false,
                  rate: metadata.discounts.recurring.monthly.rate
                }
              } : {})
            }
          } : {})
        }
      } : {}),
      
      // Extras/Addons
      ...(metadata.extras?.addons ? {
        extras: { 
          addons: {
            enabled: metadata.extras.addons.enabled !== false,
            items: metadata.extras.addons.items || []
          }
        }
      } : {}),
      
      // Map to addons directly for compatibility
      ...(metadata.extras?.addons ? {
        addons: {
          enabled: metadata.extras.addons.enabled !== false,
          items: metadata.extras.addons.items || []
        }
      } : {})
    };
    
    
    
    return result;
  };

  // Initialize component with metadata if available
  useEffect(() => {
    // Only run once when the component mounts and locationData is available
    if (!isReady.current && locationData && locationData.id) {
      
      
      // Check if we have existing metadata from the props
      if (data && data.metadata && data.metadata.services && 
          data.metadata.services[3] && data.metadata.services[3].pickupDelivery) {
        
        // Store the metadata as previous to prevent immediate update
        prevMetadataRef.current = JSON.parse(JSON.stringify(data.metadata));
        
        // Use buildStateFromMetadata to properly transform metadata to component state
        const metadataPickupDelivery = data.metadata.services[3].pickupDelivery;
        const initialStateFromMetadata = buildStateFromMetadata(metadataPickupDelivery, pudData);
        
        
        // Update state with the initialized data
        setPudData(prevState => {
          const newState = {
            ...prevState,
            ...initialStateFromMetadata
          };
          
          // Update required fields status based on loaded data
          updateRequiredFieldsStatus(newState);
          
          return newState;
        });
      } else {
        
        
        // If no metadata is available, try to fetch it directly from the database
        fetchMetadataFromDatabase();
      }
      
      isReady.current = true;
    }
  }, [locationData, data]);
  
  // Function to fetch metadata directly from database
  const fetchMetadataFromDatabase = async () => {
    if (!locationData?.id) {
      console.warn('SimpleDCPickupDelivery - Cannot fetch metadata: missing location ID');
      return;
    }
    
    try {
      
      
      // Get Supabase client
      const businessType = locationData.business_type || 'laundromat';
      const supabase = getSupabaseClient(businessType);
      
      // Query for location settings
      const { data: settings, error } = await supabase
        .from('vapi_location_settings')
        .select('id, metadata')
        .eq('id', locationData.id)
        .maybeSingle();
      
      if (error) {
        console.error('SimpleDCPickupDelivery - Error fetching metadata:', error);
        return;
      }
      
      if (settings?.metadata?.services?.[3]?.pickupDelivery) {
        
        
        // Use buildStateFromMetadata to transform the metadata to component state
        const metadataPickupDelivery = settings.metadata.services[3].pickupDelivery;
        
        // Log the instructions data from the database
        console.log("Instructions data from database:", metadataPickupDelivery.instructions);
        
        const initialStateFromMetadata = buildStateFromMetadata(metadataPickupDelivery, pudData);
        
        // Log the transformed instructions data
        console.log("Transformed instructions data:", initialStateFromMetadata.instructions);
        
        // Update state with the initialized data
        setPudData(prevState => {
          const newState = {
            ...prevState,
            ...initialStateFromMetadata
          };
          
          // Update required fields status
          updateRequiredFieldsStatus(newState);
          
          return newState;
        });
        
        // Store metadata to prevent immediate update
        prevMetadataRef.current = JSON.parse(JSON.stringify(settings.metadata));
      } else {
        
      }
    } catch (error) {
      console.error('SimpleDCPickupDelivery - Error in fetchMetadataFromDatabase:', error);
    }
  };
  
  // Helper to update required fields status
  const updateRequiredFieldsStatus = (stateData) => {
    const pricing = stateData.pricing || {};
    const serviceAreas = stateData.serviceAreas || {};
    const schedule = stateData.schedule || {};
    const turnaround = stateData.turnaround || {};
    
    setRequiredFieldsStatus(prev => ({
      ...prev,
      general: true,
      serviceAreas: serviceAreas.enabled !== false,
      pricing: !!pricing.items?.length,
      schedule: !!schedule.mode,
      turnaround: !!turnaround.standardHours,
      instructions: true, // Optional
      discounts: true, // Optional
      extras: true // Optional
    }));
  };

  // Effect to update parent component when data changes
  useEffect(() => {
    // Check if required fields are completed to enable progression
    const allRequiredFieldsComplete = Object.entries(requiredFieldsStatus)
      .every(([_, isComplete]) => isComplete === true);
    
    // Transform the pudData to metadata format
    const metadataFields = transformToMetadata(pudData);
    
    // Create the full metadata object with correct structure
    const fullMetadata = {
      services: {
        "3": {
          pickupDelivery: metadataFields
        }
      }
    };
    
    // Update the parent with both pudData and metadata
    onChange({
      ...pudData,
      requirementsToAdvance: allRequiredFieldsComplete,
      metadata: fullMetadata  // Include metadata for parent component
    });
    
    // Check if metadata has changed to avoid unnecessary database updates
    if (prevMetadataRef.current === null || 
        JSON.stringify(prevMetadataRef.current) !== JSON.stringify(fullMetadata)) {
      
      // Save the new metadata for comparison
      prevMetadataRef.current = fullMetadata;
      
      // Update the database if location data is available
      if (locationData?.id) {
        
        debouncedUpdateDatabase(fullMetadata);
      }
    }
  }, [pudData, requiredFieldsStatus, onChange, locationData, debouncedUpdateDatabase]);

  // Reset activeZoneId when changing away from turnaround tab
  useEffect(() => {
    if (activeTab !== 'turnaround') {
      setActiveZoneId(null);
    }
  }, [activeTab]);

  // Effect to update required fields status
  useEffect(() => {
    // Only validate if the service is enabled
    if (!pudData.enabled) {
      setRequiredFieldsStatus({
        general: true,
        serviceAreas: true,
        pricing: true,
        schedule: true,
        instructions: true,
        discounts: true,
        extras: true
      });
      return;
    }

    // Get references to each section's data
    const delivery = pudData.delivery || {};
    const serviceAreas = pudData.serviceAreas || {};
    const pricing = pudData.pricing || {};
    const schedule = pudData.schedule || {};
    const sameDay = pricing.sameDay || {};
    const instructions = pudData.instructions || {};
    const discounts = pudData.discounts || {};
    const extras = pudData.extras || {};
    const specialtyItems = extras.specialtyItems || {};
    const addons = extras.addons || {};
    const items = pricing.items || []; // Add this line to get pricing items

    // SERVICE AREAS: User must have at least 1 service zone added
    const hasZones = Array.isArray(serviceAreas.zones) && serviceAreas.zones.length > 0;
    const isServiceAreasComplete = hasZones;

    // PRICING: Check for each service zone and global settings
    // For global pricing settings:
    const hasGlobalPricing = !!pricing.regularPerPound && !!delivery.fee;
    
    // Check if all service zones have specific pricing or use the global settings
    const allZonesHavePricing = !hasZones || serviceAreas.zones.every(zone => 
      (zone.pricing?.regularPerPound && zone.pricing?.deliveryFee) || 
      (!zone.pricing || (!zone.pricing.regularPerPound && !zone.pricing.deliveryFee)) // If a zone has no specific pricing, it uses global
    );
    
    const isPricingComplete = hasGlobalPricing && allZonesHavePricing;

    // TURNAROUND TIME: Check that turnaround times are set
    // For global turnaround settings:
    const turnaroundData = pudData.turnaround || {};
    
    // Debug current state of turnaround data
    console.log("Checking turnaround requirements with data:", JSON.stringify(turnaroundData, null, 2));
    
    // 1. Standard turnaround field must not be blank (using either standard_days or standardDays)
    // Important: We need to check for both 0 and non-empty - 0 is valid (same day)
    const hasStandardDays = (turnaroundData.standard_days !== undefined && 
                             turnaroundData.standard_days !== null && 
                             turnaroundData.standard_days !== '') || 
                            (turnaroundData.standardDays !== undefined && 
                             turnaroundData.standardDays !== null && 
                             turnaroundData.standardDays !== '') ||
                            (turnaroundData.standard_days === 0 || turnaroundData.standardDays === 0);
                             
    // 2. If express service is enabled, both Hours and Fee fields must be filled in
    const expressEnabled = turnaroundData.expressEnabled || false;
    
    // Check express hours
    const hasExpressHours = turnaroundData.expressHours !== undefined && 
                           turnaroundData.expressHours !== null && 
                           turnaroundData.expressHours !== '';
    
    // Check express fee
    const hasExpressFee = turnaroundData.expressFee !== undefined && 
                         turnaroundData.expressFee !== null && 
                         turnaroundData.expressFee !== '';
                         
    // Express requirements are met if either express is disabled OR both hours and fee are set
    const expressRequirementsMet = !expressEnabled || (expressEnabled && hasExpressHours && hasExpressFee);
    
    console.log(`Turnaround requirements check details:
      hasStandardDays: ${hasStandardDays}
      expressEnabled: ${expressEnabled}
      hasExpressHours: ${hasExpressHours}
      hasExpressFee: ${hasExpressFee}
      expressRequirementsMet: ${expressRequirementsMet}
      standard_days: ${turnaroundData.standard_days}
      standardDays: ${turnaroundData.standardDays}
      expressHours: ${turnaroundData.expressHours}
      expressFee: ${turnaroundData.expressFee}
    `);
    
    // Check if all service zones have specific turnaround time or use the global settings
    // CORRECTION: Zone-specific turnaround is OPTIONAL - they use global values if not set
    // We should NOT enforce zone-specific turnaround as a requirement
    const allZonesHaveTurnaround = true; // Always true since zone-specific turnaround is optional
    
    console.log("Zone-specific turnaround check bypassed - zones use global turnaround by default");
    
    // The turnaround tab is complete if:
    // 1. Global standard days are set
    // 2. If express service is enabled, both hours and fee are set
    // (Zone-specific turnaround is optional)
    const isTurnaroundComplete = hasStandardDays && expressRequirementsMet;

    // SCHEDULE: Check that at least 1 time window exists for pickup and delivery
    const globalTimeWindows = schedule.timeWindows || {};
    const hasGlobalTimeWindows = Object.values(globalTimeWindows).some(windows => 
      Array.isArray(windows) && windows.length > 0
    );
    
    console.log(`Schedule requirements check: hasGlobalTimeWindows=${hasGlobalTimeWindows}, timeWindows=`, globalTimeWindows);
    
    // If we have at least one time window globally, we're good - no need to check zones
    // since they're optional
    let hasValidSchedule = hasGlobalTimeWindows;
    
    // Only check zones if global schedule doesn't have time windows
    if (!hasValidSchedule && hasZones) {
      // Check if any zone has time windows
      for (const zone of serviceAreas.zones) {
        const zoneSchedule = zone.schedule || {};
        const zoneTimeWindows = zoneSchedule.timeWindows || {};
        const hasZoneTimeWindows = Object.values(zoneTimeWindows).some(windows => 
          Array.isArray(windows) && windows.length > 0
        );
        
        if (hasZoneTimeWindows) {
          hasValidSchedule = true;
          break;
        }
      }
    }

    // INSTRUCTIONS: Instructions for first pickup must not be empty
    const hasValidInstructions = !!instructions.firstPickup && instructions.firstPickup.trim() !== '';

    // DISCOUNTS: If enabled, rate must not be empty for any rows where offer toggle is on
    let discountsComplete = true;
    if (discounts.enabled) {
      // Check recurring discounts
      const recurringDiscounts = discounts.recurring || {};
      if (recurringDiscounts.firstOrder && recurringDiscounts.firstOrder.enabled && !recurringDiscounts.firstOrder.rate) {
        discountsComplete = false;
      }
      if (recurringDiscounts.secondOrder && recurringDiscounts.secondOrder.enabled && !recurringDiscounts.secondOrder.rate) {
        discountsComplete = false;
      }
      if (recurringDiscounts.thirdOrder && recurringDiscounts.thirdOrder.enabled && !recurringDiscounts.thirdOrder.rate) {
        discountsComplete = false;
      }
      if (recurringDiscounts.fourthOrder && recurringDiscounts.fourthOrder.enabled && !recurringDiscounts.fourthOrder.rate) {
        discountsComplete = false;
      }
      if (recurringDiscounts.fifthOrder && recurringDiscounts.fifthOrder.enabled && !recurringDiscounts.fifthOrder.rate) {
        discountsComplete = false;
      }
    }

    // EXTRAS: If toggles are on, must have at least one item
    let extrasComplete = true;
    if (specialtyItems.enabled && (!specialtyItems.items || specialtyItems.items.length === 0)) {
      extrasComplete = false;
    }
    if (addons.enabled && (!addons.items || addons.items.length === 0)) {
      extrasComplete = false;
    }

    // Set the final required fields status
    const finalStatus = {
      general: true, 
      serviceAreas: isServiceAreasComplete,
      pricing: isPricingComplete,
      turnaround: isTurnaroundComplete,
      schedule: hasValidSchedule,
      instructions: hasValidInstructions,
      discounts: !discounts.enabled || discountsComplete,
      extras: !specialtyItems.enabled && !addons.enabled || extrasComplete
    };
    
    // Log all requirement statuses for debugging
    console.log("SimpleDCPickupDelivery - All requirements status:", {
      serviceAreas: isServiceAreasComplete,
      pricing: isPricingComplete,
      turnaround: {
        complete: isTurnaroundComplete,
        hasStandardDays: hasStandardDays,
        expressEnabled: expressEnabled,
        expressRequirementsMet: expressRequirementsMet,
        allZonesComplete: allZonesHaveTurnaround
      },
      schedule: {
        complete: hasValidSchedule,
        hasGlobalTimeWindows: hasGlobalTimeWindows
      },
      instructions: hasValidInstructions,
      discounts: !discounts.enabled || discountsComplete,
      extras: !specialtyItems.enabled && !addons.enabled || extrasComplete
    });
    
    setRequiredFieldsStatus(finalStatus);
  }, [pudData]);

  // Format time for display
  const formatTimeDisplay = (time24) => {
    if (!time24) return '';
    
    try {
      const [hours, minutes] = time24.split(':');
      const hour = parseInt(hours, 10);
      const minute = parseInt(minutes, 10);
      
      const period = hour >= 12 ? 'PM' : 'AM';
      const hour12 = hour % 12 || 12;
      
      return `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
    } catch (e) {
      return time24;
    }
  };

  // Handler functions
  const handleUpdate = (updates) => {
    setPudData(prev => ({
      ...prev,
      ...updates
    }));
  };

  const handleDeliveryUpdate = (updates) => {
    setPudData(prev => {
      const delivery = prev.delivery || {};
      return {
        ...prev,
        delivery: {
          ...delivery,
          ...updates
        }
      };
    });
  };

  const handlePricingUpdate = (updates) => {
    setPudData(prev => ({
      ...prev,
      pricing: {
        ...prev.pricing,
        ...updates
      }
    }));
  };

  const handleTurnaroundUpdate = (updates) => {
    setPudData(prev => {
      const turnaround = prev.turnaround || {};
      
      // Handle standard_days specially to ensure it's properly formatted
      let processedUpdates = { ...updates };
      if ('standard_days' in updates) {
        // Ensure it's either null or a number, not undefined
        processedUpdates.standard_days = updates.standard_days === undefined ? null : updates.standard_days;
        
        // Also set standardDays for compatibility since transformToMetadata looks for standardDays
        processedUpdates.standardDays = processedUpdates.standard_days;
        
        console.log(`Setting turnaround.standard_days to ${processedUpdates.standard_days} and standardDays to ${processedUpdates.standardDays}`);
      }
      
      const updatedData = {
        ...prev,
        turnaround: {
          ...turnaround,
          ...processedUpdates
        }
      };
      
      // Trigger a database update with the new data
      if (locationData?.id) {
        // Create the metadata
        const metadataFields = transformToMetadata(updatedData);
        const fullMetadata = {
          services: {
            "3": {  // Use service ID 3 for Dry Cleaning
              pickupDelivery: metadataFields
            }
          }
        };
        
        // Update the database
        console.log("Turnaround update - sending to database:", fullMetadata);
        debouncedUpdateDatabase(fullMetadata);
      }
      
      return updatedData;
    });
  };

  const handleExpressTurnaroundUpdate = (updates) => {
    setPudData(prev => {
      // The issue is that the structure doesn't match what's actually being used in render
      // The render function uses expressHours and expressFee directly on turnaround,
      // not nested in expressTurnaround
      const updatedData = {
        ...prev,
        turnaround: {
          ...prev.turnaround,
          // Apply updates directly to turnaround, not in expressTurnaround
          ...updates
        }
      };
      
      // Trigger a database update with the new data
      if (locationData?.id) {
        // Create the metadata
        const metadataFields = transformToMetadata(updatedData);
        const fullMetadata = {
          services: {
            "3": {  // Use service ID 3 for Dry Cleaning
              pickupDelivery: metadataFields
            }
          }
        };
        
        // Update the database
        console.log("Express turnaround update - sending to database:", fullMetadata);
        debouncedUpdateDatabase(fullMetadata);
      }
      
      return updatedData;
    });
  };

  const handleZoneTurnaroundUpdate = (zoneId, updates) => {
    setPudData(prev => {
      const zones = [...(prev.serviceAreas?.zones || [])];
      const zoneIndex = zones.findIndex(z => z.id === zoneId);
      
      if (zoneIndex === -1) return prev;
      
      const zone = zones[zoneIndex];
      const updatedZone = {
        ...zone,
        turnaround: {
          ...(zone.turnaround || {}),
          ...updates
        }
      };
      
      zones[zoneIndex] = updatedZone;
      
      return {
        ...prev,
        serviceAreas: {
          ...(prev.serviceAreas || {}),
          zones
        }
      };
    });
  };

  const handleSameDayUpdate = (updates) => {
    setPudData(prev => {
      const pricing = prev.pricing || {};
      const sameDay = pricing.sameDay || {};
      return {
        ...prev,
        pricing: {
          ...pricing,
          sameDay: {
            ...sameDay,
            ...updates
          }
        }
      };
    });
  };

  const handleScheduleUpdate = (updates) => {
    setPudData(prev => {
      const schedule = prev.schedule || {};
      const updatedSchedule = {
        ...schedule,
        ...updates
      };
      
      const updatedData = {
        ...prev,
        schedule: updatedSchedule
      };
      
      // Trigger a database update with the new data
      if (locationData?.id) {
        // Create the metadata
        const metadataFields = transformToMetadata(updatedData);
        const fullMetadata = {
          services: {
            "3": {  // Use service ID 3 for Dry Cleaning
              pickupDelivery: metadataFields
            }
          }
        };
        
        // Update the database
        console.log("Schedule update - sending to database:", fullMetadata);
        debouncedUpdateDatabase(fullMetadata);
      }
      
      return updatedData;
    });
  };

  const handleOtherUpdate = (updates) => {
    setPudData(prev => {
      const other = prev.other || {};
      const updatedData = {
        ...prev,
        other: {
          ...other,
          ...updates
        }
      };
      
      // Trigger a database update with the new data
      if (locationData?.id) {
        // Create the metadata
        const metadataFields = transformToMetadata(updatedData);
        const fullMetadata = {
          services: {
            "3": {  // Use service ID 3 for Dry Cleaning
              pickupDelivery: metadataFields
            }
          }
        };
        
        // Update the database
        console.log("Other info update - sending to database:", fullMetadata);
        debouncedUpdateDatabase(fullMetadata);
      }
      
      return updatedData;
    });
  };

  const handleServiceAreasUpdate = (updates) => {
    // Get current service areas
    const currentServiceAreas = pudData.serviceAreas || { 
      enabled: false, 
      zipcodes: [], 
      zones: [] 
    };
    
    // Update service areas with new values
    const updatedServiceAreas = {
      ...currentServiceAreas,
      ...updates
    };
    
    // Update the state
    setPudData(prev => ({
      ...prev,
      serviceAreas: updatedServiceAreas
    }));
    
    // Log the updated state
    
    
    // Check if service areas are valid
    const hasZipcodes = updatedServiceAreas.zipcodes && updatedServiceAreas.zipcodes.length > 0;
    const hasZones = updatedServiceAreas.zones && updatedServiceAreas.zones.length > 0;
    const isServiceAreasValid = updatedServiceAreas.enabled ? (hasZipcodes || hasZones) : true;
    
    // Update the required fields status
    setRequiredFieldsStatus(prev => ({
      ...prev,
      serviceAreas: isServiceAreasValid
    }));
    
    // Update parent component
    if (onChange) {
      const metadataFields = transformToMetadata({
        ...pudData,
        serviceAreas: updatedServiceAreas
      });
      
      const fullMetadata = {
        services: {
          "3": {
            pickupDelivery: metadataFields
          }
        }
      };
      
      onChange({
        ...pudData,
        serviceAreas: updatedServiceAreas,
        requirementsToAdvance: isServiceAreasValid,
        metadata: fullMetadata
      });
    }
  };

  const handleAddZipcode = () => {
    if (!zipcode || zipcode.length < 5) return;
    
    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zipcodes = serviceAreas.zipcodes || [];
      
      // Check if already exists
      if (zipcodes.includes(zipcode)) {
        return prev;
      }
      
      return {
        ...prev,
        serviceAreas: {
          ...serviceAreas,
          zipcodes: [...zipcodes, zipcode]
        }
      };
    });
    
    // Clear input
    setZipcode('');
  };

  const handleRemoveZipcode = (zipToRemove) => {
    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zipcodes = serviceAreas.zipcodes || [];
      
      return {
        ...prev,
        serviceAreas: {
          ...serviceAreas,
          zipcodes: zipcodes.filter(zip => zip !== zipToRemove)
        }
      };
    });
  };

  // Add a generic handler for time windows
  const handleAddTimeWindow = (day, startTime, endTime) => {
    if (!day || !startTime || !endTime) return;
    
    setPudData(prev => {
      const schedule = prev.schedule || {};
      const windows = schedule.timeWindows || {};
      const dayWindows = windows[day] || [];
      
      // Create updated schedule with new time window
      const updatedSchedule = {
        ...schedule,
        timeWindows: {
          ...windows,
          [day]: [...dayWindows, { startTime, endTime }]
        }
      };
      
      const updatedData = {
        ...prev,
        schedule: updatedSchedule
      };
      
      // Trigger a database update with the new data
      if (locationData?.id) {
        // Create the metadata
        const metadataFields = transformToMetadata(updatedData);
        const fullMetadata = {
          services: {
            "3": {  // Use service ID 3 for Dry Cleaning
              pickupDelivery: metadataFields
            }
          }
        };
        
        // Update the database
        console.log("Added time window - sending to database:", fullMetadata);
        debouncedUpdateDatabase(fullMetadata);
      }
      
      return updatedData;
    });
    
    // Reset form
    setNewWindow({
      day: '',
      startTime: '',
      endTime: ''
    });
  };

  // Update the handleRemoveTimeWindow function
  const handleRemoveTimeWindow = (day, index) => {
    setPudData(prev => {
      const schedule = prev.schedule || {};
      const windows = schedule.timeWindows || {};
      const dayWindows = [...(windows[day] || [])];
      
      dayWindows.splice(index, 1);
      
      const updatedDayWindows = dayWindows.length > 0 ? dayWindows : undefined;
      
      const updatedWindows = {
        ...windows,
        [day]: updatedDayWindows
      };
      
      // If the day has no windows, remove it
      if (!updatedDayWindows) {
        delete updatedWindows[day];
      }
      
      const updatedData = {
        ...prev,
        schedule: {
          ...schedule,
          timeWindows: updatedWindows
        }
      };
      
      // Trigger a database update with the new data
      if (locationData?.id) {
        // Create the metadata
        const metadataFields = transformToMetadata(updatedData);
        const fullMetadata = {
          services: {
            "3": {  // Use service ID 3 for Dry Cleaning
              pickupDelivery: metadataFields
            }
          }
        };
        
        // Update the database
        console.log("Removed time window - sending to database:", fullMetadata);
        debouncedUpdateDatabase(fullMetadata);
      }
      
      return updatedData;
    });
  };

  // Zone management handlers
  const handleOpenAddZone = () => {
    setZoneToEdit(null);
    setZoneFormData({
      name: '',
      zipcodes: ''
    });
    setIsAddZoneOpen(true);
  };

  const handleOpenEditZone = (zone) => {
    setZoneToEdit(zone);
    setZoneFormData({
      name: zone.name,
      zipcodes: zone.zipcodes.join(', ')
    });
    setIsAddZoneOpen(true);
  };

  const handleCloseZoneModal = () => {
    setIsAddZoneOpen(false);
    setZoneToEdit(null);
  };

  const handleSaveZone = () => {
    // Basic validation
    if (!zoneFormData.name.trim()) {
      return; // Should show validation error
    }

    // Parse zipcodes from comma-separated string
    const zipcodesArray = zoneFormData.zipcodes
      .split(',')
      .map(zip => zip.trim())
      .filter(zip => zip.length === 5 && !isNaN(zip));

    if (zipcodesArray.length === 0) {
      return; // Should show validation error
    }

    const newZone = {
      id: zoneToEdit ? zoneToEdit.id : Date.now().toString(),
      name: zoneFormData.name.trim(),
      zipcodes: zipcodesArray,
      pricing: zoneToEdit?.pricing || {
        regularPerPound: '',
        deliveryFee: '',
        minimumOrder: '',
        sameDay: {
          enabled: false,
          cutoff: '',
          pricePerPound: '',
          turnaroundHours: ''
        }
      },
      schedule: zoneToEdit?.schedule || {
        timeWindows: {}
      }
    };

    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zones = [...(serviceAreas.zones || [])];
      
      if (zoneToEdit) {
        // Update existing zone
        const index = zones.findIndex(z => z.id === zoneToEdit.id);
        if (index !== -1) {
          zones[index] = newZone;
        }
      } else {
        // Add new zone
        zones.push(newZone);
      }
      
      return {
        ...prev,
        serviceAreas: {
          ...serviceAreas,
          zones
        }
      };
    });

    // Close modal
    handleCloseZoneModal();
  };

  const handleRemoveZone = (zoneId) => {
    // Find zone to display its name in confirmation modal
    const zoneToRemove = pudData.serviceAreas?.zones?.find(zone => zone.id === zoneId) || { id: zoneId, name: 'this zone' };
    setZoneToDelete(zoneToRemove);
    setShowDeleteConfirmModal(true);
  };

  // Actual function to delete zone after confirmation
  const confirmDeleteZone = () => {
    if (!zoneToDelete) return;
    
    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zones = (serviceAreas.zones || []).filter(zone => zone.id !== zoneToDelete.id);
      
      return {
      ...prev,
        serviceAreas: {
          ...serviceAreas,
          zones
        }
      };
    });
    
    // Close modal and reset state
    setShowDeleteConfirmModal(false);
    setZoneToDelete(null);
  };

  // Function to close the modal without deleting
  const cancelDeleteZone = () => {
    setShowDeleteConfirmModal(false);
    setZoneToDelete(null);
  };

  // Handler for zone-specific pricing updates
  const handleZonePricingUpdate = (zoneId, updates) => {
    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zones = [...(serviceAreas.zones || [])];
      
      const zoneIndex = zones.findIndex(zone => zone.id === zoneId);
      if (zoneIndex === -1) return prev;
      
      const updatedZone = {
        ...zones[zoneIndex],
        pricing: {
          ...zones[zoneIndex].pricing,
          ...updates
        }
      };
      
      zones[zoneIndex] = updatedZone;
      
      return {
        ...prev,
        serviceAreas: {
          ...serviceAreas,
          zones
        }
      };
    });
  };

  // Handler for zone-specific same day updates
  const handleZoneSameDayUpdate = (zoneId, updates) => {
    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zones = [...(serviceAreas.zones || [])];
      
      const zoneIndex = zones.findIndex(zone => zone.id === zoneId);
      if (zoneIndex === -1) return prev;
      
      const updatedZone = {
        ...zones[zoneIndex],
        pricing: {
          ...zones[zoneIndex].pricing,
          sameDay: {
            ...zones[zoneIndex].pricing.sameDay,
            ...updates
          }
        }
      };
      
      zones[zoneIndex] = updatedZone;
      
      return {
        ...prev,
        serviceAreas: {
          ...serviceAreas,
          zones
        }
      };
    });
  };

  // Handler for zone-specific schedule updates
  const handleZoneScheduleUpdate = (zoneId, updates) => {
    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zones = [...(serviceAreas.zones || [])];
      
      const zoneIndex = zones.findIndex(zone => zone.id === zoneId);
      if (zoneIndex === -1) return prev;
      
      const updatedZone = {
        ...zones[zoneIndex],
        schedule: {
          ...zones[zoneIndex].schedule,
          ...updates
        }
      };
      
      zones[zoneIndex] = updatedZone;
      
      return {
        ...prev,
        serviceAreas: {
          ...serviceAreas,
          zones
        }
      };
    });
  };

  // Handler for zone-specific time window management
  const handleAddZoneTimeWindow = (zoneId, day, startTime, endTime) => {
    if (!day || !startTime || !endTime) return;
    
    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zones = [...(serviceAreas.zones || [])];
      
      const zoneIndex = zones.findIndex(zone => zone.id === zoneId);
      if (zoneIndex === -1) return prev;
      
      const zone = zones[zoneIndex];
      const schedule = zone.schedule || {};
      const timeWindows = schedule.timeWindows || {};
      const dayWindows = timeWindows[day] || [];
      
      const updatedZone = {
        ...zone,
        schedule: {
          ...schedule,
          timeWindows: {
            ...timeWindows,
            [day]: [...dayWindows, { startTime, endTime }]
          }
        }
      };
      
      zones[zoneIndex] = updatedZone;
      
      return {
        ...prev,
        serviceAreas: {
          ...serviceAreas,
          zones
        }
      };
    });
    
    // Reset form
    setNewWindow({
      day: '',
      startTime: '',
      endTime: ''
    });
  };

  const handleRemoveZoneTimeWindow = (zoneId, day, index) => {
    setPudData(prev => {
      const serviceAreas = prev.serviceAreas || {};
      const zones = [...(serviceAreas.zones || [])];
      
      const zoneIndex = zones.findIndex(zone => zone.id === zoneId);
      if (zoneIndex === -1) return prev;
      
      const zone = zones[zoneIndex];
      const schedule = zone.schedule || {};
      const timeWindows = schedule.timeWindows || {};
      const dayWindows = [...(timeWindows[day] || [])];
      
      dayWindows.splice(index, 1);
      
      const updatedDayWindows = dayWindows.length > 0 ? dayWindows : undefined;
      
      const updatedTimeWindows = {
        ...timeWindows,
        [day]: updatedDayWindows
      };
      
      // If the day has no windows, remove it
      if (!updatedDayWindows) {
        delete updatedTimeWindows[day];
      }
      
      const updatedZone = {
        ...zone,
        schedule: {
          ...schedule,
          timeWindows: updatedTimeWindows
        }
      };
      
      zones[zoneIndex] = updatedZone;
      
      return {
        ...prev,
        serviceAreas: {
          ...serviceAreas,
          zones
        }
      };
    });
  };

  // Helper function to get pricing for a zone (or use global pricing if zone pricing is empty)
  const getZonePricing = (zone) => {
    const globalPricing = pudData.pricing || {};
    const globalDelivery = pudData.delivery || {};
    const zonePricing = zone?.pricing || {};
    
    return {
      regularPerPound: zonePricing.regularPerPound || globalPricing.regularPerPound,
      deliveryFee: zonePricing.deliveryFee || globalDelivery.fee,
      minimumOrder: zonePricing.minimumOrder || globalDelivery.minimumOrder,
      sameDay: {
        ...globalPricing.sameDay,
        ...zonePricing.sameDay
      }
    };
  };
  
  // Helper function to copy global pricing to a zone
  const copyGlobalPricingToZone = (zoneId) => {
    const globalPricing = pudData.pricing || {};
    const globalDelivery = pudData.delivery || {};
    
    handleZonePricingUpdate(zoneId, {
      regularPerPound: globalPricing.regularPerPound,
      deliveryFee: globalDelivery.fee,
      minimumOrder: globalDelivery.minimumOrder,
      sameDay: {
        ...globalPricing.sameDay
      }
    });
  };
  
  // Helper function to copy global schedule to a zone
  const copyGlobalScheduleToZone = (zoneId) => {
    const globalSchedule = pudData.schedule || {};
    
    handleZoneScheduleUpdate(zoneId, {
      timeWindows: { ...globalSchedule.timeWindows }
    });
  };

  // Handler for minimum order updates
  const handleMinimumOrderUpdate = (updates) => {
    setPudData(prev => {
      const delivery = prev.delivery || {};
      const minimumOrder = delivery.minimumOrder || {};
      
      return {
        ...prev,
        delivery: {
          ...delivery,
          minimumOrder: {
            ...minimumOrder,
            ...updates
          }
        }
      };
    });
  };

  const handleInstructionsUpdate = (updates) => {
    setPudData(prev => {
      const instructions = prev.instructions || {};
      const updatedData = {
        ...prev,
        instructions: {
          ...instructions,
          ...updates
        }
      };
      
      // Log the updated instructions
      console.log('Updated instructions:', updatedData.instructions);
      
      // Trigger a database update with the new data
      if (locationData?.id) {
        // Create the metadata
        const metadataFields = transformToMetadata(updatedData);
        const fullMetadata = {
          services: {
            "3": {  // Use service ID 3 for Dry Cleaning
              pickupDelivery: metadataFields
            }
          }
        };
        
        // Update the database
        console.log("Instructions update - sending to database:", fullMetadata);
        debouncedUpdateDatabase(fullMetadata);
      }
      
      return updatedData;
    });
  };

  const handleDiscountsUpdate = (updates) => {
    setPudData(prev => {
      const discounts = prev.discounts || {};
      return {
        ...prev,
        discounts: {
          ...discounts,
          ...updates
        }
      };
    });
  };

  const handleRecurringDiscountUpdate = (type, updates) => {
    setPudData(prev => {
      const discounts = prev.discounts || {};
      const recurring = discounts.recurring || {};
      const typeData = recurring[type] || {};
      
      return {
        ...prev,
        discounts: {
          ...discounts,
          recurring: {
            ...recurring,
            [type]: {
              ...typeData,
              ...updates
            }
          }
        }
      };
    });
  };

  const handleExtrasUpdate = (type, updates) => {
    setPudData(prev => {
      const extras = prev.extras || {};
      const typeData = extras[type] || {};
      
      return {
        ...prev,
        extras: {
          ...extras,
          [type]: {
            ...typeData,
            ...updates
          }
        }
      };
    });
  };

  // Tab button component
  const TabButton = ({ active, icon: Icon, label, onClick }) => (
    <button
      className={cn(
        "flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-colors",
        active 
          ? "bg-gray-900 text-white" 
          : "bg-white hover:bg-gray-100 text-gray-700"
      )}
      onClick={onClick}
    >
      <Icon className="w-4 h-4 mr-2" />
      <span>{label}</span>
    </button>
  );

  // Render tab content functions
  const renderGeneralContent = () => {
    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <Settings className="w-5 h-5 text-gray-400" />
            <div>
              <span className="text-sm font-medium text-gray-700">Pickup & Delivery Service</span>
              <p className="text-xs text-gray-500 mt-1">
                Enable pickup and delivery for your Dry Cleaning service
              </p>
            </div>
          </div>
          <BlackSwitch 
            checked={pudData.enabled} 
            onCheckedChange={(checked) => handleUpdate({ enabled: checked })}
          />
        </div>
        
        <AnimatePresence>
          {pudData.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className="flex items-center p-4 rounded-lg bg-blue-50 border border-blue-100">
                <Info className="w-5 h-5 text-blue-500 mr-3 flex-shrink-0" />
                <p className="text-sm text-blue-700">
                  Configure your pickup and delivery service details in the tabs below. You'll need to set up delivery areas, pricing, and schedule to complete this section.
                </p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  const renderServiceAreasContent = () => {
    // Get service areas data safely
    const serviceAreas = pudData.serviceAreas || { 
      enabled: false, 
      zipcodes: [], 
      zones: [] 
    };
    
    // Log for debugging
    
    
    // Function to add a new zone
    const addNewZone = () => {
      setZoneFormData({
        name: '',
        zipcodes: ''
      });
      setEditingZone(null);
      setShowZoneModal(true);
    };
    
    // Function to edit a zone
    const editZone = (zone) => {
      setZoneFormData({
        name: zone.name,
        zipcodes: (zone.zipcodes || []).join(', ')
      });
      setEditingZone(zone);
      setShowZoneModal(true);
    };
    
    // Function to delete a zone
    const confirmDeleteZone = () => {
      if (!zoneToDelete) return;
      
      // Filter out the zone to delete
      const updatedZones = serviceAreas.zones.filter(z => z.id !== zoneToDelete.id);
      
      // Update the state
      handleServiceAreasUpdate({
        zones: updatedZones
      });
      
      // Close the modal
      setShowDeleteConfirmModal(false);
      setZoneToDelete(null);
    };
    
    // Function to cancel delete
    const cancelDeleteZone = () => {
      setShowDeleteConfirmModal(false);
      setZoneToDelete(null);
    };
    
    // Function to handle zone modal submission
    const handleZoneModalSubmit = (formData) => {
      // Convert the comma-separated zipcodes to an array
      const zipcodes = formData.zipcodes
        .split(',')
        .map(zip => zip.trim())
        .filter(zip => zip.length > 0);
      
      // If editing an existing zone
      if (editingZone) {
        const updatedZones = serviceAreas.zones.map(zone => {
          if (zone.id === editingZone.id) {
            return {
              ...zone,
              name: formData.name,
              zipcodes
            };
          }
          return zone;
        });
        
        handleServiceAreasUpdate({
          zones: updatedZones
        });
      } else {
        // Creating a new zone
        const newZone = {
          id: String(Date.now()),
          name: formData.name,
          zipcodes,
          pricing: {},
          schedule: {},
          turnaround: {}
        };
        
        handleServiceAreasUpdate({
          zones: [...serviceAreas.zones, newZone]
        });
      }
      
      // Close modal and reset form
      setShowZoneModal(false);
      setZoneFormData({ name: '', zipcodes: '' });
      setEditingZone(null);
    };
    
    return (
      <div className="space-y-6">
        <div className="bg-white rounded-lg border border-gray-200 p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900">Service Zones</h3>
            <Button 
              onClick={handleOpenAddZone}
              size="sm"
              className="bg-gray-900 hover:bg-gray-800 text-white"
            >
              <Plus className="w-4 h-4 mr-2" />
              Add Zone
            </Button>
          </div>
          
          <p className="text-sm text-gray-500 mb-6">
            Create service zones with specific ZIP codes for different delivery areas
          </p>
          
          {serviceAreas.zones.length === 0 ? (
            <div className="flex flex-col items-center justify-center p-8 border border-dashed border-gray-300 rounded-lg bg-gray-50">
              <MapPin className="w-10 h-10 text-gray-400 mb-3" />
              <p className="text-sm font-medium text-gray-700 mb-2">No service zones defined yet</p>
              <p className="text-sm text-gray-500 mb-4 text-center max-w-md">
                Define zones to organize your delivery areas by neighborhood, city district, or service territory
              </p>
              <Button 
                onClick={handleOpenAddZone}
                variant="outline"
                className="border-gray-900 text-gray-900 hover:bg-gray-100"
              >
                <Plus className="w-4 h-4 mr-2" />
                Create Your First Zone
              </Button>
            </div>
          ) : (
            <Accordion
              type="single"
              collapsible
              className="w-full space-y-3"
            >
              {serviceAreas.zones.map((zone) => (
                <AccordionItem 
                  key={zone.id} 
                  value={zone.id}
                  className="border border-gray-200 rounded-lg overflow-hidden shadow-sm px-0 py-0 border-b-0 mb-3"
                >
                  <AccordionTrigger className="px-4 py-3 hover:bg-gray-50 hover:no-underline w-full [&[data-state=open]>svg]:rotate-180">
                    <div className="flex justify-between items-center w-full">
                      <div className="flex items-center">
                        <MapPin className="w-5 h-5 text-gray-500 mr-2" />
                        <span className="font-medium text-gray-900">{zone.name}</span>
                        <span className="ml-2 text-sm text-gray-500">
                          ({zone.zipcodes.length} {zone.zipcodes.length === 1 ? 'ZIP' : 'ZIPs'})
                        </span>
                      </div>
                      <div className="flex items-center gap-1">
                        {/* Replace Button with div styled like a button */}
                        <div
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent accordion from toggling
                            handleOpenEditZone(zone);
                          }}
                          className="p-1 h-8 text-gray-500 hover:text-gray-900 hover:bg-gray-100 rounded-md flex items-center justify-center cursor-pointer"
                        >
                          <Edit className="w-4 h-4" />
                        </div>
                        <div
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent accordion from toggling
                            handleRemoveZone(zone.id);
                          }}
                          className="p-1 h-8 text-gray-500 hover:text-red-600 hover:bg-gray-100 rounded-md flex items-center justify-center cursor-pointer"
                        >
                          <Trash className="w-4 h-4" />
                        </div>
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="px-4 pb-4 pt-0 bg-gray-50 border-t border-gray-200">
                    <div className="pt-3">
                      <h4 className="text-sm font-medium text-gray-700 mb-3">ZIP Codes in this zone:</h4>
                      <div className="flex flex-wrap gap-2">
                        {zone.zipcodes.map(zip => (
                          <div 
                            key={zip} 
                            className="flex items-center px-3 py-1 bg-white border border-gray-200 rounded-full"
                          >
                            <span className="text-sm text-gray-800">{zip}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </div>
        
        {/* Zone Dialog */}
        <Dialog open={isAddZoneOpen} onOpenChange={setIsAddZoneOpen}>
          <DialogContent className="sm:max-w-md">
            <DialogHeader>
              <DialogTitle className="text-xl font-semibold text-gray-900">
                {zoneToEdit ? 'Edit Service Zone' : 'Add New Service Zone'}
              </DialogTitle>
            </DialogHeader>
            
            <div className="space-y-5 py-4">
              <div>
                <Label htmlFor="zone-name" className="text-sm font-medium text-gray-700 mb-1 block">
                  Zone Name
                </Label>
                <Input
                  id="zone-name"
                  value={zoneFormData.name}
                  onChange={(e) => setZoneFormData(prev => ({ ...prev, name: e.target.value }))}
                  placeholder="e.g., Downtown, East Side, Premium Area"
                  className="w-full border-gray-300 focus:border-gray-900 focus:ring-1 focus:ring-gray-900"
              />
              <p className="text-xs text-gray-500 mt-1">
                  Give your zone a descriptive name that identifies the area
              </p>
            </div>
            
            <div>
                <Label htmlFor="zone-zipcodes" className="text-sm font-medium text-gray-700 mb-1 block">
                  ZIP Codes for this zone
                </Label>
                <Textarea
                  id="zone-zipcodes"
                  value={zoneFormData.zipcodes}
                  onChange={(e) => setZoneFormData(prev => ({ ...prev, zipcodes: e.target.value }))}
                  placeholder="e.g., 10001, 10002, 10003"
                  className="w-full min-h-[120px] border-gray-300 focus:border-gray-900 focus:ring-1 focus:ring-gray-900"
                />
              <p className="text-xs text-gray-500 mt-1">
                  Enter multiple ZIP codes separated by commas (e.g., 90210, 90211)
              </p>
              </div>
            </div>
            
            <DialogFooter className="flex justify-between sm:justify-between border-t border-gray-200 pt-4">
              <Button
                type="button"
                variant="outline"
                onClick={handleCloseZoneModal}
                className="border-gray-300 text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </Button>
              <Button 
                type="button"
                onClick={handleSaveZone}
                disabled={!zoneFormData.name.trim() || !zoneFormData.zipcodes.trim()}
                className="bg-gray-900 hover:bg-gray-800 text-white"
              >
                {zoneToEdit ? 'Update Zone' : 'Add Zone'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  // Updated Pricing content with SimpleWDFDropOff.js style Minimum Order Required
  const renderPricingContent = () => {
    // Get pricing data safely
    const pricing = pudData.pricing || {};
    const minimumOrder = pricing.minimumOrder || { enabled: false, amount: '' };
    const items = pricing.items || []; // Make sure items is properly defined
    const serviceAreas = pudData.serviceAreas || { zones: [] }; // Define serviceAreas with a default empty zones array
    
    return (
      <div className="space-y-4">
        {/* Minimum Order Requirement */}
        <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center gap-3">
              <DollarSign className="w-5 h-5 text-gray-400" />
              <div>
                <span className="text-sm font-medium text-gray-700">Minimum Order Requirement</span>
                <p className="text-xs text-gray-500 mt-1">
                  Require a minimum order value for pickup & delivery
                </p>
              </div>
            </div>
            <BlackSwitch 
              checked={minimumOrder.enabled || false} 
              onCheckedChange={(checked) => handlePricingUpdate({
                minimumOrder: {
                  ...minimumOrder,
                  enabled: checked
                }
              })}
            />
          </div>
          
          <AnimatePresence>
            {minimumOrder.enabled && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
              >
                <div className="mt-3 pt-3 border-t border-gray-200">
                  <Label htmlFor="minimum-order">Minimum Order Amount ($)</Label>
                  <Input
                    id="minimum-order"
                    type="number"
                    min="0"
                    step="0.01"
                    value={rawInputValues.minimumAmount || minimumOrder.amount || ''}
                    onChange={(e) => {
                      const value = e.target.value;
                      setRawInputValues(prev => ({ ...prev, minimumAmount: value }));
                      
                      if (value.trim() === '') return;
                      const numValue = parseFloat(value);
                      
                      if (!isNaN(numValue) && numValue >= 0) {
                        handlePricingUpdate({
                          minimumOrder: {
                            ...minimumOrder,
                            amount: numValue
                          }
                        });
                      }
                    }}
                    onBlur={() => {
                      // Clear raw input value if empty
                      if (rawInputValues.minimumAmount === '') {
                        setRawInputValues(prev => ({ ...prev, minimumAmount: '' }));
                      }
                    }}
                    className="mt-1"
                  />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        
        {/* Delivery Fee section */}
        <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3 mb-3">
            <Truck className="w-5 h-5 text-gray-400" />
            <div>
              <span className="text-sm font-medium text-gray-700">Delivery Fee</span>
              <p className="text-xs text-gray-500 mt-1">
                Additional fee charged for pickup & delivery service
              </p>
            </div>
          </div>
          
          <div className="mt-2">
            <Label htmlFor="delivery-fee">Delivery Fee ($)</Label>
            <Input
              id="delivery-fee"
              type="number"
              min="0"
              step="0.01"
              value={rawInputValues.deliveryFee || pricing.deliveryFee || ''}
              onChange={(e) => {
                const value = e.target.value;
                setRawInputValues(prev => ({ ...prev, deliveryFee: value }));
                
                if (value.trim() === '') return;
                const numValue = parseFloat(value);
                
                if (!isNaN(numValue) && numValue >= 0) {
                  handlePricingUpdate({ deliveryFee: numValue });
                }
              }}
              onBlur={() => {
                if (rawInputValues.deliveryFee === '') {
                  setRawInputValues(prev => ({ ...prev, deliveryFee: '' }));
                }
              }}
              className="mt-1"
            />
            <p className="text-xs text-gray-500 mt-1">
              Leave empty if you don't charge a separate fee for delivery
            </p>
          </div>
        </div>
        
        {/* Pricing Items Section - Moved below Delivery Fee and styled like SimpleDCDropOff.js */}
        <div className="p-4 rounded-lg border border-gray-200">
          <div className="flex justify-between items-center mb-3">
            <h3 className="font-medium">Dry Cleaning Price List</h3>
            <div className="flex gap-2">
              <Button 
                variant="outline" 
                size="sm"
                onClick={() => {
                  // Get Drop-off data from localStorage
                  try {
                    const dropOffData = JSON.parse(localStorage.getItem('dcDropOffData') || '{}');
                    console.log('Retrieved drop-off data:', dropOffData); // For debugging
                    
                    // Make sure we have items to copy
                    if (dropOffData?.pricing?.items?.length > 0) {
                      console.log('Copying items:', dropOffData.pricing.items);
                      // Update Pickup & Delivery price list
                      handlePricingUpdate({ items: dropOffData.pricing.items });
                    } else {
                      // Alert if no items found
                      alert('No price items found in Drop-off service. Please add items to Drop-off first.');
                    }
                  } catch (error) {
                    console.error('Error copying from drop-off:', error);
                    alert('Could not copy from Drop-off. Please try again.');
                  }
                }}
                className="flex items-center gap-1"
              >
                <span>Copy from Drop-off</span>
              </Button>
              {items.length > 0 && (
                <Button 
                  variant="outline" 
                  size="sm"
                  onClick={() => openExtrasModal('pricing')}
                  className="flex items-center gap-1"
                >
                  <Edit className="w-3 h-3" />
                  <span>Edit Items</span>
                </Button>
              )}
            </div>
          </div>
          
          {/* Display pricing items - missing in original code */}
          {items.length > 0 ? (
            <div className="space-y-2 max-h-80 overflow-y-auto pr-2">
              {items.map((item, index) => (
                <div 
                  key={index} 
                  className="flex justify-between items-center py-2 border-b border-gray-100 last:border-0"
                >
                  <span className="text-sm">{item.name}</span>
                  <span className="text-sm font-medium">${parseFloat(item.price).toFixed(2)}</span>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center border border-dashed border-gray-300 rounded-md p-6">
              <button 
                type="button"
                className="flex items-center gap-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                onClick={() => openExtrasModal('pricing')}
              >
                <Plus className="w-4 h-4" />
                <span>Add Price Items</span>
              </button>
            </div>
          )}
          
          {items.length === 0 && (
            <p className="text-xs text-gray-500 text-center mt-3">
              Click to add dress shirts, pants, suits, etc. with their prices
            </p>
          )}
        </div>
        
        {/* Zone Pricing */}
        {serviceAreas.zones.length > 1 && (
          <div className="bg-white rounded-lg border border-gray-200 p-6 space-y-4">
            <h3 className="text-lg font-medium text-gray-900">Zone-Specific Pricing</h3>
            <p className="text-sm text-gray-500">
              Configure specific pricing for each service zone. If no pricing is set, the default pricing will be used.
            </p>
            
            <Accordion
              type="single"
              collapsible
              className="w-full space-y-3"
            >
              {serviceAreas.zones.map((zone) => (
                <AccordionItem 
                  key={`zone-${zone.id}`}
                  value={zone.id}
                  className="border border-gray-200 rounded-lg overflow-hidden shadow-sm px-0 py-0 border-b-0 mb-3"
                >
                  <AccordionTrigger className="px-4 py-3 hover:bg-gray-50 hover:no-underline w-full [&[data-state=open]>svg]:rotate-180">
                    <div className="flex justify-between items-center w-full">
                      <div className="flex items-center">
                        <DollarSign className="w-5 h-5 text-gray-500 mr-2" />
                        <span className="font-medium text-gray-900">{zone.name} Pricing</span>
                        {((zone.pricing && Object.keys(zone.pricing).length > 0) && 
                          ((zone.pricing.deliveryFee && zone.pricing.deliveryFee !== pricing.deliveryFee) || 
                          (zone.pricing.minimumOrder && zone.pricing.minimumOrder !== pricing.minimumOrder?.amount))) && (
                          <span className="ml-2 px-2 py-1 text-xs bg-blue-100 text-blue-800 rounded-full">
                            Custom Pricing
                          </span>
                        )}
                      </div>
                      <Button
                        size="sm"
                        variant="ghost"
                        className="p-1 h-8 text-gray-500 hover:text-blue-600 hover:bg-gray-100 mr-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          copyGlobalPricingToZone(zone.id);
                        }}
                      >
                        <span className="text-xs">Copy Default</span>
                      </Button>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="px-4 pb-4 pt-0 bg-gray-50 border-t border-gray-200">
                    <div className="space-y-4 pt-3">
                      <div>
                        <Label htmlFor={`zone-${zone.id}-delivery-fee`}>Delivery Fee</Label>
                        <div className="flex mt-1">
                          <div className="flex items-center px-3 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                            <span className="text-gray-500">$</span>
                          </div>
                          <Input
                            id={`zone-${zone.id}-delivery-fee`}
                            type="number"
                            min="0"
                            step="0.01"
                            value={rawInputValues.zoneDeliveryFee?.[zone.id] || zone.pricing?.deliveryFee || ''}
                            onChange={(e) => {
                              const value = e.target.value;
                              setRawInputValues(prev => ({
                                ...prev,
                                zoneDeliveryFee: {
                                  ...prev.zoneDeliveryFee,
                                  [zone.id]: value
                                }
                              }));
                              
                              if (value.trim() === '') {
                                handleZonePricingUpdate(zone.id, { deliveryFee: '' });
                                return;
                              }
                              
                              const numValue = parseFloat(value);
                              if (!isNaN(numValue)) {
                                handleZonePricingUpdate(zone.id, { deliveryFee: numValue });
                              }
                            }}
                            className="rounded-l-none"
                            placeholder={pricing.deliveryFee || "Default"}
                          />
                        </div>
                        <p className="text-xs text-gray-500 mt-1">
                          Leave blank to use default delivery fee
                        </p>
                      </div>
                      
                      <div>
                        <Label htmlFor={`zone-${zone.id}-minimum-order`}>Minimum Order</Label>
                        <div className="flex mt-1">
                          <div className="flex items-center px-3 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md">
                            <span className="text-gray-500">$</span>
                          </div>
                          <Input
                            id={`zone-${zone.id}-minimum-order`}
                            type="number"
                            min="0"
                            step="0.01"
                            value={rawInputValues.zoneMinimumOrder?.[zone.id] || zone.pricing?.minimumOrder || ''}
                            onChange={(e) => {
                              const value = e.target.value;
                              setRawInputValues(prev => ({
                                ...prev,
                                zoneMinimumOrder: {
                                  ...prev.zoneMinimumOrder,
                                  [zone.id]: value
                                }
                              }));
                              
                              if (value.trim() === '') {
                                handleZonePricingUpdate(zone.id, { minimumOrder: '' });
                                return;
                              }
                              
                              const numValue = parseFloat(value);
                              if (!isNaN(numValue)) {
                                handleZonePricingUpdate(zone.id, { minimumOrder: numValue });
                              }
                            }}
                            className="rounded-l-none"
                            placeholder={pricing.minimumOrder?.amount || "Default"}
                          />
                        </div>
                        <p className="text-xs text-gray-500 mt-1">
                          Leave blank to use default minimum order amount
                        </p>
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        )}
      </div>
    );
  };

  const renderTurnaroundTimeContent = () => {
    // Get turnaround data safely
    const turnaround = pudData.turnaround || {};
    
    // Check both properties for standard days
    const standardDays = turnaround.standard_days !== undefined ? 
                        turnaround.standard_days : 
                        turnaround.standardDays;
                        
    console.log(`Rendering turnaround section with standard_days: ${turnaround.standard_days}, standardDays: ${turnaround.standardDays}, using: ${standardDays}`);
    
    const hasStandardDays = standardDays !== undefined && standardDays !== null && standardDays !== '';
    
    // Express turnaround
    const expressEnabled = turnaround.expressEnabled || false;
    const expressHours = turnaround.expressHours || '';
    const expressFee = turnaround.expressFee || '';
    
    // Get service areas data
    const serviceAreas = pudData.serviceAreas || { zones: [] };
    const hasZones = serviceAreas.zones && serviceAreas.zones.length > 0;
    
    return (
      <div className="space-y-6">
        {/* Standard turnaround */}
        <div className="bg-white rounded-lg border border-gray-200 p-4 space-y-4">
          <div className="flex items-start">
            <Clock className="w-5 h-5 text-gray-400 mr-3 flex-shrink-0 mt-0.5" />
            <div>
              <h3 className="text-sm font-medium text-gray-700">Standard Turnaround</h3>
              <p className="text-xs text-gray-500 mt-1">
                Set the normal turnaround time for dry cleaning orders.
              </p>
            </div>
          </div>
          
          <div className="space-y-3 mt-2">
            <Label htmlFor="standard-days" className="text-sm">Standard Days (leave empty for same day)</Label>
            <Input
              id="standard-days"
              type="number"
              min="1"
              value={standardDays === undefined || standardDays === null ? '' : standardDays.toString()}
              onChange={(e) => {
                const value = e.target.value.trim();
                const parsedValue = value === '' ? null : parseInt(value, 10);
                console.log(`Standard days input changed to: "${value}", parsed as: ${parsedValue}`);
                
                handleTurnaroundUpdate({
                  standard_days: parsedValue,
                  // Also set standardDays directly for compatibility
                  standardDays: parsedValue
                });
              }}
              placeholder="1"
              className="w-full"
            />
            <p className="text-xs text-gray-500">
              The normal number of days needed to complete a customer's order.
            </p>
          </div>
        </div>
        
        {/* Express turnaround */}
        <div className="bg-white rounded-lg border border-gray-200 p-4 space-y-4">
          <div className="flex items-center justify-between">
            <div className="flex items-start">
              <Clock className="w-5 h-5 text-gray-400 mr-3 flex-shrink-0 mt-0.5" />
              <div>
                <h3 className="text-sm font-medium text-gray-700">Express Turnaround</h3>
                <p className="text-xs text-gray-500 mt-1">
                  Offer faster processing for an additional fee.
                </p>
              </div>
            </div>
            <BlackSwitch 
              checked={expressEnabled}
              onCheckedChange={(checked) => handleTurnaroundUpdate({ expressEnabled: checked })}
            />
          </div>
          
          {expressEnabled && (
            <div className="space-y-4 mt-2 pl-8">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Label htmlFor="express-hours">Hours</Label>
                  <Input
                    id="express-hours"
                    type="number"
                    min="1"
                    value={expressHours}
                    onChange={(e) => handleExpressTurnaroundUpdate({ expressHours: e.target.value ? parseInt(e.target.value, 10) : '' })}
                    placeholder="4"
                    className="w-full mt-1"
                  />
                  <p className="text-xs text-gray-500 mt-1">
                    How many hours for express service
                  </p>
                </div>
                <div>
                  <Label htmlFor="express-fee">Fee ($)</Label>
                  <Input
                    id="express-fee"
                    type="number"
                    min="0"
                    step="0.01"
                    value={expressFee}
                    onChange={(e) => handleExpressTurnaroundUpdate({ expressFee: e.target.value ? parseFloat(e.target.value) : '' })}
                    placeholder="5.00"
                    className="w-full mt-1"
                  />
                  <p className="text-xs text-gray-500 mt-1">
                    Additional charge for express service
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        
        {/* Zone-specific turnaround times */}
        {hasZones && serviceAreas.zones.length > 1 && (
          <div className="bg-white rounded-lg border border-gray-200 p-6">
            <div className="mb-4">
              <h3 className="text-lg font-medium text-gray-900">Zone-Specific Turnaround Times</h3>
              <p className="text-sm text-gray-500 mt-1">
                Set different turnaround times for each service zone
              </p>
            </div>
            
            <Accordion
              type="single"
              collapsible
              className="w-full space-y-3"
            >
              {serviceAreas.zones.map((zone) => (
                <AccordionItem 
                  key={zone.id} 
                  value={zone.id}
                  className="border border-gray-200 rounded-lg overflow-hidden shadow-sm px-0 py-0 border-b-0 mb-3"
                >
                  <AccordionTrigger className="px-4 py-3 hover:bg-gray-50 hover:no-underline w-full [&[data-state=open]>svg]:rotate-180">
                    <div className="flex justify-between items-center w-full">
                      <div className="flex items-center">
                        <Clock className="w-5 h-5 text-gray-500 mr-2" />
                        <span className="font-medium text-gray-900">{zone.name}</span>
                        <span className="ml-2 text-sm text-gray-500">
                          Turnaround Times
                        </span>
                      </div>
                    </div>
                  </AccordionTrigger>
                  
                  <AccordionContent className="px-4 py-3 bg-gray-50">
                    <div className="space-y-4">
                      <div>
                        <Label htmlFor={`zone-${zone.id}-standard-days`}>Standard Days</Label>
                        <Input
                          id={`zone-${zone.id}-standard-days`}
                          type="number"
                          min="1"
                          value={zone.turnaround?.standardDays || zone.turnaround?.standard_days || ''}
                          onChange={(e) => {
                            const value = e.target.value.trim();
                            const parsedValue = value === '' ? null : parseInt(value, 10);
                            handleZoneTurnaroundUpdate(zone.id, {
                              standardDays: parsedValue,
                              standard_days: parsedValue
                            });
                          }}
                          className="mt-1"
                          placeholder={standardDays || "Default"}
                        />
                        <p className="text-xs text-gray-500 mt-1">
                          Leave blank to use default standard days
                        </p>
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        )}
      </div>
    );
  };

  const renderScheduleContent = () => {
    const schedule = pudData.schedule || {};
    const timeWindows = schedule.timeWindows || {};
    const serviceAreas = pudData.serviceAreas || {};
    const zones = serviceAreas.zones || [];
    const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const renderTimeWindowForm = (onAdd, zoneId = null) => (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        <div>
          <Label htmlFor={zoneId ? `day-${zoneId}` : "day"}>Day</Label>
          <select
            id={zoneId ? `day-${zoneId}` : "day"}
            className="w-full mt-1 rounded-md border border-gray-300 shadow-sm px-3 py-2 text-sm"
            value={newWindow.day}
            onChange={(e) => setNewWindow({...newWindow, day: e.target.value})}
          >
            <option value="">Select day</option>
            {weekdays.map(day => (
              <option key={day} value={day}>{day}</option>
            ))}
          </select>
        </div>
        
        <div>
          <Label htmlFor={zoneId ? `start-time-${zoneId}` : "start-time"}>Start Time</Label>
          <Input
            id={zoneId ? `start-time-${zoneId}` : "start-time"}
            type="time"
            value={newWindow.startTime}
            onChange={(e) => setNewWindow({...newWindow, startTime: e.target.value})}
            className="mt-1"
          />
          </div>
          
                <div>
          <Label htmlFor={zoneId ? `end-time-${zoneId}` : "end-time"}>End Time</Label>
          <div className="flex items-center gap-2">
            <Input
              id={zoneId ? `end-time-${zoneId}` : "end-time"}
              type="time"
              value={newWindow.endTime}
              onChange={(e) => setNewWindow({...newWindow, endTime: e.target.value})}
              className="mt-1 flex-1"
            />
                      <Button
                        type="button"
              onClick={onAdd}
              disabled={!newWindow.day || !newWindow.startTime || !newWindow.endTime}
              className="mt-1 bg-gray-900 hover:bg-gray-800 text-white"
            >
              <Plus className="w-4 h-4" />
                      </Button>
                  </div>
                </div>
      </div>
    );

    const renderTimeWindowsList = (windows, onRemove) => {
      if (Object.keys(windows).length === 0) {
        return (
          <div className="text-center p-4 border border-dashed border-gray-300 rounded-md">
            <p className="text-sm text-gray-500">No time windows added yet</p>
          </div>
        );
      }
      
      return (
        <div className="space-y-4">
          {Object.entries(windows).map(([day, dayWindows]) => (
            <div key={`time-${day}`} className="border border-gray-200 rounded-md p-3">
              <h5 className="font-medium text-sm mb-2">{day}</h5>
              <div className="space-y-2">
                {dayWindows.map((window, index) => (
                  <div key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded">
                    <span className="text-sm">
                      {formatTimeDisplay(window.startTime)} - {formatTimeDisplay(window.endTime)}
                    </span>
                    <div
                      onClick={() => onRemove(day, index)}
                      className="text-gray-500 hover:text-red-500 cursor-pointer"
                    >
                      <Trash className="w-4 h-4" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      );
    };

    return (
      <div className="space-y-6">
        {/* Default Schedule */}
        <div className="bg-white rounded-lg border border-gray-200 p-6">
          <div className="mb-4">
            <h3 className="text-lg font-medium text-gray-900">Default Pickup & Delivery Schedule</h3>
            <p className="text-sm text-gray-500 mt-1">
              Define the default schedule for when customers can request pickup and delivery services
            </p>
          </div>
          
          <div className="space-y-4">
            <h4 className="text-sm font-medium text-gray-700">Time Windows</h4>
            <p className="text-xs text-gray-500">
              Add time windows when customers can schedule pickups and deliveries. A window is a period of time when laundry will be picked up or delivered (e.g., 8am-12pm).
            </p>
            
            {renderTimeWindowForm(() => handleAddTimeWindow(newWindow.day, newWindow.startTime, newWindow.endTime))}
            
            {/* Display existing time windows */}
            <div className="mt-4">
              {renderTimeWindowsList(timeWindows, handleRemoveTimeWindow)}
            </div>
          </div>
        </div>

        {/* Zone-specific Schedules */}
        {zones.length > 1 && (
          <div className="bg-white rounded-lg border border-gray-200 p-6 space-y-4">
            <h3 className="text-lg font-medium text-gray-900">Zone-Specific Schedules</h3>
            <p className="text-sm text-gray-500">
              Configure schedules for specific service zones. If no schedule is set, the default schedule will be used.
            </p>
            
            <Accordion
              type="single"
              collapsible
              className="w-full space-y-3"
            >
              {zones.map((zone) => (
                <AccordionItem 
                  key={`schedule-${zone.id}`}
                  value={zone.id}
                  className="border border-gray-200 rounded-lg overflow-hidden shadow-sm px-0 py-0 border-b-0 mb-3"
                >
                  <AccordionTrigger className="px-4 py-3 hover:bg-gray-50 hover:no-underline w-full [&[data-state=open]>svg]:rotate-180">
                    <div className="flex justify-between items-center w-full">
                      <div className="flex items-center">
                        <Clock className="w-5 h-5 text-gray-500 mr-2" />
                        <span className="font-medium text-gray-900">{zone.name} Schedule</span>
                        {zone.schedule?.timeWindows && 
                        Object.keys(zone.schedule.timeWindows).length > 0 && 
                        JSON.stringify(zone.schedule.timeWindows) !== JSON.stringify(schedule.timeWindows) && (
                          <span className="ml-2 px-2 py-1 text-xs bg-blue-100 text-blue-800 rounded-full">
                            Custom Schedule
                          </span>
                        )}
                      </div>
                      <Button
                        size="sm"
                        variant="ghost"
                        className="p-1 h-8 text-gray-500 hover:text-blue-600 hover:bg-gray-100 mr-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          copyGlobalScheduleToZone(zone.id);
                        }}
                      >
                        <span className="text-xs">Copy Default</span>
                      </Button>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="px-4 pb-4 pt-0 bg-gray-50 border-t border-gray-200">
                    <div className="pt-3 space-y-4">
                      <p className="text-sm text-gray-700">
                        Add time windows specific to the {zone.name} service zone
                      </p>
                      
                      {renderTimeWindowForm(
                        () => handleAddZoneTimeWindow(zone.id, newWindow.day, newWindow.startTime, newWindow.endTime),
                        zone.id
                      )}
                      
                      <div className="mt-4">
                        {renderTimeWindowsList(
                          zone.schedule?.timeWindows || {},
                          (day, index) => handleRemoveZoneTimeWindow(zone.id, day, index)
                        )}
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        )}
      </div>
    );
  };

  const renderInstructionsContent = () => {
    const instructions = pudData.instructions || {};
    
    return (
      <div className="space-y-4">
        <div className="bg-white rounded-lg border border-gray-200 p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">First Pickup Instructions</h3>
          
          <div>
            <Label htmlFor="first-pickup-instructions">
              Instructions for the first pickup
            </Label>
            <Textarea
              id="first-pickup-instructions"
              value={instructions.firstPickup === null ? '' : instructions.firstPickup || ''}
              onChange={(e) => {
                console.log(`Current instructions value: "${instructions.firstPickup}"`);
                const value = e.target.value;
                console.log(`Setting firstPickup to: "${value}"`);
                handleInstructionsUpdate({ firstPickup: value });
              }}
              placeholder="Enter any special instructions for the first pickup..."
              className="mt-1 min-h-[150px] w-full"
            />
            <p className="text-xs text-gray-500 mt-1">
              Provide any special instructions or notes for the driver during the first pickup
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderOtherContent = () => (
    <div className="space-y-4">
      <div>
        <Label htmlFor="special-instructions">Additional Information</Label>
        <textarea
          id="special-instructions"
          value={pudData.other?.notes || pudData.notes || ''}
          onChange={(e) => {
            // Update both other.notes and top-level notes for compatibility
            setPudData(prev => ({
              ...prev,
              notes: e.target.value,
              other: {
                ...prev.other,
                notes: e.target.value
              }
            }));
          }}
          placeholder="Any additional information about your Dry Cleaning pickup & delivery service..."
          className="w-full mt-1 p-2 min-h-[120px] border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
    </div>
  );

  const renderDiscountsContent = () => {
    // Get discounts data safely
    const discounts = pudData.discounts || {};
    const recurring = discounts.recurring || {};
    const weekly = recurring.weekly || { enabled: false, rate: '' };
    const biweekly = recurring.biweekly || { enabled: false, rate: '' };
    const monthly = recurring.monthly || { enabled: false, rate: '' };
    
    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <Percent className="w-5 h-5 text-gray-400" />
            <div>
              <span className="text-sm font-medium text-gray-700">Different Discounts than Drop Off?</span>
              <p className="text-xs text-gray-500 mt-1">
                Set special pricing options for pickup & delivery that differ from your drop-off service.
              </p>
            </div>
          </div>
          <BlackSwitch 
            checked={discounts.enabled || false} 
            onCheckedChange={(checked) => handleDiscountsUpdate({ enabled: checked })}
          />
        </div>

        <AnimatePresence>
          {discounts.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
              className="space-y-4"
            >
              <div className="p-4 bg-white rounded-lg border border-gray-200">
                <h4 className="text-sm font-medium text-gray-900 mb-2">Recurring Service Discounts</h4>
                <p className="text-xs text-gray-500 mb-4">
                  Offer special pricing for customers who sign up for recurring Dry Cleaning service.
                </p>
                      
                <div className="overflow-hidden border border-gray-200 rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Frequency
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Offer
                        </th>
                        <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Discount (%)
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {/* Weekly Row */}
                      <tr>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">Weekly</div>
                          <div className="text-xs text-gray-500">Every week</div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <BlackSwitch
                            checked={weekly.enabled}
                            onCheckedChange={(checked) => handleRecurringDiscountUpdate('weekly', { enabled: checked })}
                          />
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="flex items-center">
                            <Input
                              type="number"
                              min="0"
                              max="100"
                              step="1"
                              value={rawInputValues.weeklyRate || weekly.rate || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setRawInputValues(prev => ({ ...prev, weeklyRate: value }));
                                
                                if (value.trim() === '') return;
                                const numValue = parseFloat(value);
                                
                                if (!isNaN(numValue) && numValue >= 0 && numValue <= 100) {
                                  handleRecurringDiscountUpdate('weekly', { rate: numValue });
                                }
                              }}
                              className="w-20 rounded-md"
                              disabled={!weekly.enabled}
                            />
                            <span className="ml-2 text-gray-500">%</span>
                          </div>
                        </td>
                      </tr>

                      {/* Bi-weekly Row */}
                      <tr>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">Bi-weekly</div>
                          <div className="text-xs text-gray-500">Every two weeks</div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <BlackSwitch
                            checked={biweekly.enabled}
                            onCheckedChange={(checked) => handleRecurringDiscountUpdate('biweekly', { enabled: checked })}
                          />
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="flex items-center">
                            <Input
                              type="number"
                              min="0"
                              max="100"
                              step="1"
                              value={rawInputValues.biweeklyRate || biweekly.rate || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setRawInputValues(prev => ({ ...prev, biweeklyRate: value }));
                                
                                if (value.trim() === '') return;
                                const numValue = parseFloat(value);
                                
                                if (!isNaN(numValue) && numValue >= 0 && numValue <= 100) {
                                  handleRecurringDiscountUpdate('biweekly', { rate: numValue });
                                }
                              }}
                              className="w-20 rounded-md"
                              disabled={!biweekly.enabled}
                            />
                            <span className="ml-2 text-gray-500">%</span>
                          </div>
                        </td>
                      </tr>

                      {/* Monthly Row */}
                      <tr>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">Monthly</div>
                          <div className="text-xs text-gray-500">Once a month</div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <BlackSwitch
                            checked={monthly.enabled}
                            onCheckedChange={(checked) => handleRecurringDiscountUpdate('monthly', { enabled: checked })}
                          />
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="flex items-center">
                            <Input
                              type="number"
                              min="0"
                              max="100"
                              step="1"
                              value={rawInputValues.monthlyRate || monthly.rate || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setRawInputValues(prev => ({ ...prev, monthlyRate: value }));
                                
                                if (value.trim() === '') return;
                                const numValue = parseFloat(value);
                                
                                if (!isNaN(numValue) && numValue >= 0 && numValue <= 100) {
                                  handleRecurringDiscountUpdate('monthly', { rate: numValue });
                                }
                              }}
                              className="w-20 rounded-md"
                              disabled={!monthly.enabled}
                            />
                            <span className="ml-2 text-gray-500">%</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                <div className="mt-3 text-sm text-gray-500 flex items-start gap-2">
                  <CheckCircle className="w-4 h-4 text-gray-400 mt-0.5 flex-shrink-0" />
                  <span>These rates will override the regular price per pound when a customer selects a recurring service.</span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  const renderExtrasContent = () => {
    // Get extras data safely
    const extras = pudData.extras || {};
    const addons = extras.addons || { enabled: false, items: [] };
    
    const openExtrasModal = (type) => {
      setExtrasModalType(type);
      setExtrasModalOpen(true);
    };
    
    const closeExtrasModal = () => {
      setExtrasModalOpen(false);
    };
    
    const handleSaveExtrasItems = (items) => {
      if (extrasModalType === 'addon') {
        handleExtrasUpdate('addons', { items });
      }
    };
    
    return (
      <div className="space-y-4">
        {/* Service Add-ons Section */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <Plus className="w-5 h-5 text-gray-400" />
            <div>
              <span className="text-sm font-medium text-gray-700">Service Add-ons</span>
              <p className="text-xs text-gray-500 mt-1">
                Additional services customers can add to their order (e.g. stain treatment, rush service)
              </p>
            </div>
          </div>
          <BlackSwitch 
            checked={addons.enabled || false} 
            onCheckedChange={(checked) => handleExtrasUpdate('addons', { enabled: checked })}
          />
        </div>
        
        <AnimatePresence>
          {addons.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
            >
              <div className="p-4 rounded-lg bg-white border border-gray-200">
                {addons.items && addons.items.length > 0 ? (
                  <div className="space-y-3">
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-sm font-medium">Service Add-ons ({addons.items.length})</span>
                      <Button 
                        variant="outline" 
                        size="sm"
                        onClick={() => openExtrasModal('addon')}
                        className="flex items-center gap-1"
                      >
                        <Edit className="w-3 h-3" />
                        <span>Edit Add-ons</span>
                      </Button>
                    </div>
                    <div className="max-h-36 overflow-y-auto pr-2">
                      {addons.items.map((item, index) => (
                        <div key={index} className="flex justify-between items-center py-2 border-b border-gray-100 last:border-0">
                          <span className="text-sm">{item.name}</span>
                          <div className="flex items-center">
                            <span className="text-sm font-medium">${item.price}</span>
                            <span className="text-xs text-gray-500 ml-1">/ {item.unit}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center border border-dashed border-gray-300 rounded-md p-6">
                    <button 
                      type="button"
                      className="flex items-center gap-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                      onClick={() => openExtrasModal('addon')}
                    >
                      <Plus className="w-4 h-4" />
                      <span>Add Service Add-on</span>
                    </button>
                  </div>
                )}
                {!addons.items || addons.items.length === 0 && (
                  <p className="text-xs text-gray-500 text-center mt-3">
                    Click to configure service add-ons and their pricing
                  </p>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        
        {/* Modal for editing extras */}
        {extrasModalOpen && (
          <ExtrasModal 
            open={extrasModalOpen}
            onClose={closeExtrasModal}
            type="addon"
            items={addons.items || []}
            onSave={handleSaveExtrasItems}
          />
        )}
      </div>
    );
  };

  // Move openExtrasModal to component level so it's accessible to all render functions
  const openExtrasModal = (type) => {
    setExtrasModalType(type);
    setExtrasModalOpen(true);
  };
  
  // Add the closeExtrasModal function
  const closeExtrasModal = () => {
    setExtrasModalOpen(false);
  };

  return (
    <div className="space-y-6">
      <div className="border-b">
        <ScrollableTabs
          tabs={[
            {
              id: 'general',
              label: 'General',
              onClick: () => setActiveTab('general'),
              isActive: activeTab === 'general',
              indicator: null // Remove blue dot from General tab completely
            },
            {
              id: 'serviceAreas',
              label: 'Service Areas',
              onClick: () => setActiveTab('serviceAreas'),
              isActive: activeTab === 'serviceAreas',
              indicator: !requiredFieldsStatus.serviceAreas ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'pricing',
              label: 'Pricing',
              onClick: () => setActiveTab('pricing'),
              isActive: activeTab === 'pricing',
              indicator: pudData.enabled && !(pudData.pricing?.items?.length > 0) ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'turnaround',
              label: 'Turnaround Time',
              onClick: () => {
                setActiveTab('turnaround');
                // Reset zone states when switching to this tab
                setActiveZoneId(null);
              },
              isActive: activeTab === 'turnaround',
              indicator: pudData.enabled && !requiredFieldsStatus.turnaround ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'schedule',
              label: 'Schedule',
              onClick: () => setActiveTab('schedule'),
              isActive: activeTab === 'schedule',
              indicator: pudData.enabled && !requiredFieldsStatus.schedule ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'instructions',
              label: 'Instructions',
              onClick: () => setActiveTab('instructions'),
              isActive: activeTab === 'instructions',
              indicator: pudData.enabled && !requiredFieldsStatus.instructions ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'discounts',
              label: 'Discounts',
              onClick: () => setActiveTab('discounts'),
              isActive: activeTab === 'discounts',
              indicator: pudData.enabled && !requiredFieldsStatus.discounts ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'extras',
              label: 'Extras',
              onClick: () => setActiveTab('extras'),
              isActive: activeTab === 'extras',
              indicator: pudData.enabled && !requiredFieldsStatus.extras ? (
                <div className="absolute -top-1.5 right-1 w-3 h-3 bg-blue-500 rounded-full"></div>
              ) : null
            },
            {
              id: 'other',
              label: 'Other',
              onClick: () => setActiveTab('other'),
              isActive: activeTab === 'other'
            }
          ]}
          className="px-2 py-2"
        />
      </div>

      <div className="p-2 sm:p-4">
        {activeTab === 'general' && renderGeneralContent()}
        {activeTab === 'serviceAreas' && renderServiceAreasContent()}
        {activeTab === 'pricing' && renderPricingContent()}
        {activeTab === 'turnaround' && renderTurnaroundTimeContent()}
        {activeTab === 'schedule' && renderScheduleContent()}
        {activeTab === 'instructions' && renderInstructionsContent()}
        {activeTab === 'discounts' && renderDiscountsContent()}
        {activeTab === 'extras' && renderExtrasContent()}
        {activeTab === 'other' && renderOtherContent()}
      </div>

      {/* Delete confirmation modal */}
      <Dialog open={showDeleteConfirmModal} onOpenChange={setShowDeleteConfirmModal}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Confirm Deletion</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p className="text-gray-700">
              Are you sure you want to delete the service zone "{zoneToDelete?.name}"?
            </p>
            <p className="text-sm text-gray-500 mt-2">
              This action cannot be undone. All zipcodes and settings associated with this zone will be removed.
            </p>
          </div>
          <DialogFooter className="flex items-center justify-end gap-2 mt-4">
            <Button 
              variant="outline" 
              onClick={cancelDeleteZone}
            >
              Cancel
            </Button>
            <Button 
              variant="destructive" 
              onClick={confirmDeleteZone}
              className="bg-red-600 hover:bg-red-700 text-white"
            >
              Delete Zone
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      
      {/* Move the ExtrasModal here so it's always available */}
      {extrasModalOpen && (
        <ExtrasModal 
          open={extrasModalOpen}
          onClose={closeExtrasModal}
          type={extrasModalType === 'pricing' ? 'pricing' : 'addon'}
          items={extrasModalType === 'pricing' ? pudData.pricing?.items || [] : pudData.extras?.addons?.items || []}
          onSave={(items) => {
            if (extrasModalType === 'pricing') {
              handlePricingUpdate({ items });
            } else if (extrasModalType === 'addon') {
              handleExtrasUpdate('addons', { items });
            }
            setExtrasModalOpen(false);
          }}
        />
      )}
      
      {/* Dry Cleaning Price List Modal */}
      <Dialog open={priceListModalOpen} onOpenChange={setPriceListModalOpen}>
        <DialogContent className="sm:max-w-md" style={{ zIndex: 10000 }}>
          <DialogHeader>
            <DialogTitle>Dry Cleaning Price List</DialogTitle>
          </DialogHeader>
          <div className="max-h-[70vh] overflow-y-auto">
            <div className="space-y-4 py-4">
              {pudData.pricing?.items?.length > 0 ? (
                <div className="grid grid-cols-2 gap-4">
                  {pudData.pricing.items.map((item, index) => (
                    <div key={index} className="flex justify-between border-b pb-2">
                      <span className="font-medium">{item.name}</span>
                      <span>${parseFloat(item.price).toFixed(2)}</span>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-8 text-gray-500">
                  <p>No items added yet.</p>
                  <p className="text-sm mt-2">Click "Edit Items" to add items to your price list.</p>
                </div>
              )}
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setPriceListModalOpen(false)}>Close</Button>
            <Button onClick={() => {
              setPriceListModalOpen(false);
              openExtrasModal('pricing');
            }}>Edit Items</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SimpleDCPickupDelivery; 

